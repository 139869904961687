export const CORPORATE_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/corporates/get-corporate`,
    delete:`${process.env.REACT_APP_API}/corporates/delete-corporate`,
    toggleStatus:`${process.env.REACT_APP_API}/corporates/activate-or-block-corporate`,
    archive:`${process.env.REACT_APP_API}/corporates/archive-corporate`,
    restore:`${process.env.REACT_APP_API}/corporates/restore-corporate`,
    archivedList:`${process.env.REACT_APP_API}/corporates/get-archived-corporates`,
    archivedCorporate:`${process.env.REACT_APP_API}/corporates/get-archived-corporate`,
    list:`${process.env.REACT_APP_API}/corporates/get-corporates`,
    create:`${process.env.REACT_APP_API}/corporates/create-corporate`,
    update:`${process.env.REACT_APP_API}/corporates/update-corporate`,
    employee:`${process.env.REACT_APP_API}/passengers/get-corporate-customers`,
    removeEmployee:`${process.env.REACT_APP_API}/passengers/remove-employee-from-corporate`,
    addEmployee:`${process.env.REACT_APP_API}/passengers/add-employee-to-corporate`,
    registerEmployee:`${process.env.REACT_APP_API}/passengers/create-corporate-passenger`,
    passengerList:`${process.env.REACT_APP_API}/passengers/get-passengers`,
    pay_with_chapa:`${process.env.REACT_APP_API}/transactions/pay-with-chapa`,
    import_corporate_employee:`${process.env.REACT_APP_API}/passengers/import-corporate-employees`,
    corporate_wallet:`${process.env.REACT_APP_API}/corporate-wallets/get-corporate-wallet`,
    corporate_transactions:`${process.env.REACT_APP_API}/corporate-transactions/get-corporate-transaction`,
    corporate_employees:`${process.env.REACT_APP_API}/passengers/get-corporate-customers`,
    transfer_to_multiple_employees:`${process.env.REACT_APP_API}/corporate-wallets/transfer-wallet-to-corporate-employees`,
}

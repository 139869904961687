import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NewNewsComponent from "../component/new-blog-component";
import BlogPage from "../page/blog-page";
export const BlogRoute: RouteObject = {
  path: "/blogs",
  element: (
    <AuthGuard role={['super_admin','admin']}>
      <BlogPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewNewsComponent editMode="detail" /> },
    { path: ":id", element: <NewNewsComponent editMode="new" /> },
    
  ],
};

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedServiceComponent from "../component/archived-service-component";
import NewServiceComponent from "../component/new-service-component";
import ServicePage from "../page/service-page";
export const ServiceRoute: RouteObject = {
  path: "/service",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <ServicePage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewServiceComponent editMode="detail" /> },
    { path: ":id", element: <NewServiceComponent editMode="new" /> },
    { path: "archived", element: <ArchivedServiceComponent /> },
  ],
};

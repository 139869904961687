export interface Category {
  id?: string;
  name: string;
  description: string;
  capacity: number;
  initialFee:number;
  archiveReason?:string;
  kidInitialFee?: number;
  isForKabbaKids: CategoryStatus;
  kidsPerKilometerCost?: number;
  perKilometerCost: Load;
  perTrafficJamCost: Load;
  driverCommission:number;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  deletedBy?: string;
}
interface Load {
  easy: number;
  moderate: number;
  difficult: number;
  extreme: number;
}


export enum CategoryStatus{
  ForKabbaKids = 'Kabba_Kids',
  ForKabbaMain = 'Kabba_Main',
  Both = 'Both'
}
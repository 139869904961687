import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedSchoolComponent from "../component/archived-school-component";
import NewSchoolComponent from "../component/new-school-component";
import SchoolPage from "../page/school-page";
export const SchoolRoute: RouteObject = {
  path: "/registration/school",
  element: (
    <AuthGuard role={["super_admin", "admin",'operator']}>
      <SchoolPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewSchoolComponent editMode="detail" />,
    },
    { path: ":id", element: <NewSchoolComponent editMode="new" /> },
    { path: "archived", element: <ArchivedSchoolComponent /> },
  ],
};

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ActivityDetailComponent from "../component/activity-detail-component";
import ActivityPage from "../page/activity-page";
export const ActivityRoute: RouteObject = {
  path: "/activity",
  element: (
    <AuthGuard role={["admin", "super_admin"]}>
      <ActivityPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <ActivityDetailComponent editMode="detail" />,
    },
  ],
};

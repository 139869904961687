import { ActionIcon, Tooltip } from "@mantine/core";
import { Trip } from "../../../models/trip.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { useChangeTripStatusMutation } from "../store/trip.query";
import { TripStatus } from "../../../shared/utility/enum/other.enum";

type TACProps = {
  trip?: Trip;
  confirmationHeader?: string;
  confirmationMessage?: string;
  newStatus?: TripStatus;
  yesText?: string;
  tooltipText?: string;
  icon?: any;
  iconClass?: string;
};
const TripActionComponent = ({
  trip,
  confirmationHeader,
  confirmationMessage,
  newStatus,
  yesText,
  tooltipText,
  icon,
  iconClass,
}: TACProps) => {
  const [changeStatus, changeStatusResponse] = useChangeTripStatusMutation();

  return (
    <>
      <Confirmation
        type={"notify"}
        header={confirmationHeader ? confirmationHeader : "Action"}
        message={
          confirmationMessage
            ? confirmationMessage
            : "Are you sure you want to perform this action?"
        }
        onYes={(val: string) => {
          changeStatus({
            id: trip?.id as string,
            status: newStatus as TripStatus,
          });
        }}
        yesText={yesText ? yesText : "Take Action"}
      >
        <Tooltip label={tooltipText ? tooltipText : "Take Action"}>
          <ActionIcon
            variant="filled"
            className={iconClass as string}
            size={"sm"}
            loading={
              changeStatusResponse?.isLoading &&
              changeStatusResponse?.originalArgs?.id == trip?.id
            }
          >
            <span>{icon && icon}</span>
          </ActionIcon>
        </Tooltip>
      </Confirmation>
    </>
  );
};

export default TripActionComponent;

import { HoverCard, Text } from "@mantine/core";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BusStop } from "../../../models/bus-stop.model";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetBusStopsQuery } from "../store/bus-stop.query";

export default function BusStopPage() {
  const params = useParams();
  const location = useLocation();

  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["city"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getBusStops, busStops] = useLazyGetBusStopsQuery();

  useEffect(() => {
    getBusStops(collection).then((busStops) => {
      const data: SetStateAction<Filter[]> = [];
      //add any new city to cityList
      busStops.data?.data?.map((busStop) => {
        const cityName = busStop?.city?.name;
        if (cityName) {
          if (Object.keys(cityList).indexOf(cityName) == -1) {
            cityList[cityName] = busStop.cityId;
          }
        }
      });
      // setup filter from the cityList
      Object.keys(cityList).map((key) => {
        data.push({
          field: "cityId",
          value: cityList[key],
          name: key,
          operator: "=",
        });
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/bus-stop/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: BusStop[] | undefined = busStops.data?.data;
  const config: EntityConfig<BusStop> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/settings-and-configurables/bus-stop",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (busStop) => {
          return (
            <Text size="sm" className={busStop?.deletedAt ? "text-danger":''}>
              {busStop.name}
            </Text>
          );
        },
      },
      {
        key: "description",
        name: "Description",
        render: (value) => {
          return (
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Text size="sm" className={"cursor-pointer"}>
                  {value.description.length > 25
                    ? value.description.substring(0, 22) + "..."
                    : value.description}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown
                className={"text-justify break-all wrap max-h-60 overflow-auto"}
              >
                <Text size="sm">{value.description}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        key: "",
        name: "City",
        render: (value) => {
          if(value?.cityId && !value?.city) return <Text size={"sm"} className="text-red-500">City is archived</Text>
          else if(value?.city && value.city) return <Text size={"sm"}>{value.city.name}</Text>
        }
        
      },
      { key: "lat", name: "Latitude" },
      { key: "lng", name: "Longitude" },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "isActive", name: "Status" },
    ],
    filter: [filterData],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Bus Stops"}
        total={busStops?.data?.count}
        itemsLoading={busStops.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

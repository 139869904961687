/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Trip } from "../../../models/trip.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { TripStatus } from "../../../shared/utility/enum/other.enum";
import { appApi } from "../../../store/app.api";
import { TRIP_ENDPOINT } from "../trip.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const tripQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTripLists: builder.query<Collection<Trip>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${TRIP_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getTrip: builder.query<Trip, string>({
      query: (id: string) => ({
        url: `${TRIP_ENDPOINT.trip}/${id}`,
        method: "GET",
      }),
    }),
    cancelTrip: builder.mutation<Trip, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${TRIP_ENDPOINT.cancel_trip}`,
        method: "POST",
        data: data,
        permissions: "manage-trip",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            tripQuery.util.updateQueryData(
              "getTripLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((trip: Trip) => {
                  if (trip?.id === param.id) {
                    return data;
                  }
                  return trip;
                });
              }
            )
          );

          dispatch(
            tripQuery.util.updateQueryData(
              "getTrip",
              data?.id as string,
              (draft) => {
                draft.status = data.status;
              }
            )
          );
          notification("success", "Successfully cancelled");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    changeTripStatus: builder.mutation<
      Trip,
      { id: string; status: TripStatus }
    >({
      query: (data: { id: string; status: TripStatus }) => ({
        url: `${TRIP_ENDPOINT.changeStatus}`,
        method: "PUT",
        data: data,
        permissions: "manage-trip",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            tripQuery.util.updateQueryData(
              "getTripLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((trip: Trip) => {
                  if (trip?.id === param.id) {
                    return data;
                  }
                  return trip;
                });
              }
            )
          );

          dispatch(
            tripQuery.util.updateQueryData(
              "getTrip",
              data?.id as string,
              (draft) => {
                draft.status = data.status;
              }
            )
          );
          notification("success", "Successfully cancelled");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    startTrip: builder.mutation<Trip, any>({
      query: (data: string) => ({
        url: `${TRIP_ENDPOINT.start_trip}/${data}`,
        method: "POST",
        // data: data,
        permissions: "manage-trip",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            tripQuery.util.updateQueryData(
              "getTripLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((trip: Trip) => {
                  if (trip?.id === param) {
                    return data;
                  }
                  return trip;
                });
              }
            )
          );

          dispatch(
            tripQuery.util.updateQueryData(
              "getTrip",
              data?.id as string,
              (draft) => {
                draft.status = data.status;
              }
            )
          );
          notification("success", "Successfully started");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    completeTrip: builder.mutation<Trip, any>({
      query: (data: string) => ({
        url: `${TRIP_ENDPOINT.complete_trip}`,
        method: "PUT",
        data: data,
        permissions: "manage-trip",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            tripQuery.util.updateQueryData(
              "getTripLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((trip: Trip) => {
                  if (trip?.id === param) {
                    return data;
                  }
                  return trip;
                });
              }
            )
          );

          dispatch(
            tripQuery.util.updateQueryData(
              "getTrip",
              data?.id as string,
              (draft) => {
                draft.status = data.status;
              }
            )
          );
          notification("success", "Successfully completed");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetTripQuery,
  useLazyGetTripListsQuery,
  useCancelTripMutation,

  useStartTripMutation,
  useCompleteTripMutation,
  useChangeTripStatusMutation,
} = tripQuery;

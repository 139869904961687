/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Service } from "../../../models/service.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { SERVICE_ENDPOINT } from "../service.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let serviceDetail:string;

const serviceQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getServices: builder.query<Collection<Service>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${SERVICE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createService: builder.mutation<Service, any>({
      query: (data: Service) => ({
        url: `${SERVICE_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission:'manage-service'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            serviceQuery.util.updateQueryData(
              "getServices",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count = draft.count + 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteService: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${SERVICE_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission:'manage-service'

      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            serviceQuery.util.updateQueryData(
              "getServices",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((service) => service.id !== id);
                draft.count -= 1;
              }
            )
          );
          dispatch(
            serviceQuery.util.updateQueryData(
              "getArchivedServices",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter((service) => service.id !== id);
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getService: builder.query<Service, string>({
      query: (id: string) => ({
        url: `${SERVICE_ENDPOINT.service}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
           await queryFulfilled;
          serviceDetail=param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedService: builder.query<Service, string>({
      query: (id: string) => ({
        url: `${SERVICE_ENDPOINT.archived_service}/${id}`,
        method: "GET",
      }),
    }),
    updateService: builder.mutation<Service, any>({
      query: (data: Service) => ({
        url: `${SERVICE_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission:'manage-service'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            serviceQuery.util.updateQueryData(
              "getServices",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((service) => {
                  if (service.id === data.id) return data;
                  else {
                    return service;
                  }
                });
              }
            )
          );
          dispatch(
            serviceQuery.util.updateQueryData(
              "getService",
              serviceDetail,
              (draft) => {
               if(data){
                Object.assign(draft,data);
               }
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveService: builder.mutation<Service, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${SERVICE_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission:'manage-service'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const {data} = await queryFulfilled;
          dispatch(
            serviceQuery.util.updateQueryData(
              "getServices",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((provider) => {
                  if (provider.id === arg.id) {
                    return data;
                  } else {
                    return provider;
                  }
                });
              }
            )
          );
          dispatch(
            serviceQuery.util.updateQueryData(
              "getService",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          dispatch(
            serviceQuery.util.updateQueryData(
              "getArchivedService",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedServices: builder.query<Collection<Service>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${SERVICE_ENDPOINT.archived_services}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    restoreService: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${SERVICE_ENDPOINT.restore_service}/${id}`,
        method: "POST",
        permission:'manage-service'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            serviceQuery.util.updateQueryData(
              "getServices",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((provider) => {
                  if (provider.id === id) {
                    return data;
                  } else {
                    return provider;
                  }
                });
              }
            )
          );
          dispatch(
            serviceQuery.util.updateQueryData("getService", id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            serviceQuery.util.updateQueryData(
              "getArchivedService",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetServiceQuery,
  useLazyGetServicesQuery,
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
  useArchiveServiceMutation,
  useLazyGetArchivedServicesQuery,
  useRestoreServiceMutation,
  useLazyGetArchivedServiceQuery,
} = serviceQuery;

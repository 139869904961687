export const SCHOOL_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/schools/get-school`,
    delete:`${process.env.REACT_APP_API}/schools/delete-school`,
    toggleStatus:`${process.env.REACT_APP_API}/schools/activate-or-block-school`,
    archive:`${process.env.REACT_APP_API}/schools/archive-school`,
    restore:`${process.env.REACT_APP_API}/schools/restore-school`,
    archivedList:`${process.env.REACT_APP_API}/schools/get-archived-schools`,
    archivedSchool:`${process.env.REACT_APP_API}/schools/get-archived-school`,
    list:`${process.env.REACT_APP_API}/schools/get-schools`,
    create:`${process.env.REACT_APP_API}/schools/create-school`,
    update:`${process.env.REACT_APP_API}/schools/update-school`,
    updateLogo:`${process.env.REACT_APP_API}/schools/update-school-logo/`,

    getSchoolKids: `${process.env.REACT_APP_API}/parents/get-kids-with-school-id`,

}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chat } from "../../../models/chat.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { User } from "../../../models/user.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CHAT_ENDPOINT } from "../chat.endpoint";

let chatCollection: CollectionQuery;
let accountCollection: CollectionQuery;
let peopleCollection: {id: string,  q:CollectionQuery};
const chatQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
   

    getChats: builder.query<Collection<Chat>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CHAT_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            chatCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getAccounts: builder.query<Collection<User>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CHAT_ENDPOINT.getAccounts}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            accountCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getPeopleInChats: builder.query<Collection<User>, typeof peopleCollection>({
      query: (data: typeof peopleCollection) => ({
        url: `${CHAT_ENDPOINT.getPeopleInChat}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.q),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            peopleCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),


  }),
  overrideExisting: true,
});
export const {
  useLazyGetChatsQuery,
  useLazyGetPeopleInChatsQuery,
  useLazyGetAccountsQuery
} = chatQuery;

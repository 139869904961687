import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { City } from "../../../models/city.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetCitiesQuery } from "../store/city.query";

export default function CityPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy:[{field:'createdAt',direction:'desc'}]
  });

  const [getCities, cities] = useLazyGetCitiesQuery();

  useEffect(() => {
    getCities(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");

    } else {
      setViewMode("list");
    }
    if (location.pathname === "/city/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: City[] | undefined = cities.data?.data;
  const config: EntityConfig<City> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/settings-and-configurables/city",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name",render:(city)=>{
        return (
          <Text size="sm" className={city?.deletedAt ? "text-danger":''}>
            {city.name}
          </Text>
        );
      } },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "isActive", name: "Status" },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Cities"}
        total={cities?.data?.count}
        itemsLoading={cities.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,withArchived:e
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if(collection.skip !== skip || collection.top !== top){
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: [
              "name",
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Trip } from "../../../models/trip.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useCompleteTripMutation,
  useLazyGetTripListsQuery,
} from "../store/trip.query";

import { ActionIcon, Button, Tooltip } from "@mantine/core";
import {
  IconArrowBack,
  IconCheck,
  IconPlayerPlay,
  IconUserCheck,
} from "@tabler/icons";
import { IconCarGarage, IconUserPin } from "@tabler/icons-react";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { TripStatus } from "../../../shared/utility/enum/other.enum";
import TripActionComponent from "../component/trip-action-component";

export default function TripPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["booking"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getTrips, trips] = useLazyGetTripListsQuery();
  const [completeTrip, completeTripResponse] = useCompleteTripMutation();
  useEffect(() => {
    if (
      params?.assignmentId !== null &&
      params?.assignmentId !== "" &&
      params?.assignmentId !== undefined
    ) {
      if (collection?.filter && collection?.filter?.length > 0) {
        // If collection.filter exists and has at least one item
        const updatedFilters = [...collection.filter];
        updatedFilters.unshift([
          {
            field: "booking.assignmentId",
            operator: "=",
            value: params?.assignmentId,
          },
        ]);
        getTrips({
          ...collection,
          filter: updatedFilters,
        });
      } else {
        // If collection.filter does not exist or is empty
        getTrips({
          ...collection,
          filter: [
            [
              {
                field: "booking.assignmentId",
                operator: "=",
                value: params?.assignmentId,
              },
            ],
          ],
        });
      }
    }
  }, [collection, params?.assignmentId]);

  const data: Trip[] | undefined = trips.data?.data;
  const config: EntityConfig<Trip> = {
    primaryColumn: {
      key: ["booking", "routeName"],
      name: "Route",
      hideSort: true,
    },
    rootUrl: "/trips",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: ["booking", "routeName"], name: "Route", hideSort: true },
      {
        key: ["booking", "driverName"],
        name: "Driver",
        hideSort: true,
        render(value: Trip) {
          return (
            <span>
              {`${value?.booking?.driverName ?? ""}(${
                value?.booking?.driverPhoneNumber ?? ""
              })`}
            </span>
          );
        },
      },
      {
        key: ["booking", "passengerName"],
        name: "Passenger",
        hideSort: true,
        render(value: Trip) {
          return (
            <span>
              {`${value?.booking?.passengerName ?? ""}(${
                value?.booking?.passengerPhoneNumber ?? ""
              })`}
            </span>
          );
        },
      },
      {
        key: "shift",
        name: "Shift",
        render: (value) =>
          value?.shift
            ? value?.shift?.charAt(0).toUpperCase() + value.shift.slice(1)
            : "",
      },
      { key: ["booking", "pickupName"], name: "Pickup", hideSort: true },
      {
        key: ["booking", "destinationName"],
        name: "Destination",
        hideSort: true,
      },
      { key: "createdAt", name: "Started At", isDate: true },
      {
        key: "status",
        name: "Status",
        render(value) {
          if (value.status === "Created") return "Active";
          else return value.status;
        },
      },

      {
        key: "actions",
        name: "Actions",
        hideSort: true,
        render(value: Trip) {
          return (
            <div className="flex justify-center items-center  space-x-1">
              {renderActions(value)}
            </div>
          );
        },
      },
    ],
    filter: [
      [
        {
          field: "status",
          operator: "=",
          value: TripStatus.CREATED,
          name: "Active",
        },
        // {
        //   field: "status",
        //   operator: "=",
        //   value: TripStatus.DRIVER_ARRIVED,
        //   name: "Driver Arrived",
        // },
        {
          field: "status",
          operator: "=",
          value: TripStatus.BOARDED,
          name: "Boarded",
        },
        // {
        //   field: "status",
        //   operator: "=",
        //   value: TripStatus.SKIPPED,
        //   name: "Skipped",
        // },
        {
          field: "status",
          operator: "=",
          value: TripStatus.NOSHOW,
          name: "No Show",
        },
        {
          field: "status",
          operator: "=",
          value: TripStatus.ARRIVED,
          name: "Passenger Arrived",
        },
        {
          field: "status",
          operator: "=",
          value: TripStatus.COMPLETED,
          name: "Completed",
        },
      ],
    ],
  };

  function renderActions(trip: Trip) {
    if (
      trip?.status == TripStatus.ARRIVED ||
      // trip?.status == TripStatus.SKIPPED ||
      trip?.status == TripStatus.NOSHOW
    ) {
      return (
        <Confirmation
          type={"notify"}
          header={"Complete Trip"}
          message={"Are you sure you want to complete this trip?"}
          onYes={(val: string) => {
            completeTrip({ routeId: trip?.routeId });
          }}
          yesText={"Submit"}
        >
          <Tooltip label={"Complete Trip"}>
            <ActionIcon
              variant="filled"
              className={"bg-green-500 text-white"}
              size={"sm"}
              loading={
                completeTripResponse?.isLoading &&
                completeTripResponse?.originalArgs?.routeId == trip?.routeId
              }
            >
              <span>
                <IconCheck size={16} strokeWidth={1.5} />
              </span>
            </ActionIcon>
          </Tooltip>
        </Confirmation>
      );
    }

    if (trip.status == TripStatus.CREATED) {
      return (
        <>
          <TripActionComponent
            trip={trip}
            confirmationHeader="Driver Arrived"
            confirmationMessage={`Are you sure you the driver has arrived to the passenger's location?`}
            newStatus={TripStatus.DRIVER_ARRIVED}
            yesText="Submit"
            tooltipText="Driver Arrived"
            iconClass="bg-green-500 text-white"
            icon={<IconCarGarage size={16} strokeWidth={1.5} />}
            key={trip?.id}
          />

          {/* <TripActionComponent
            trip={trip}
            confirmationHeader="Skip"
            confirmationMessage={`Are you sure you want to skip this trip?`}
            newStatus={TripStatus.SKIPPED}
            yesText="Submit"
            tooltipText="Skip Trip"
            iconClass="bg-red-500 text-white"
            icon={<IconPlayerSkipForwardFilled size={16} strokeWidth={1.5} />}
            key={trip?.id}
          /> */}
        </>
      );
    }

    if (trip.status == TripStatus.DRIVER_ARRIVED) {
      return (
        <>
          <TripActionComponent
            trip={trip}
            confirmationHeader="Board Passenger"
            confirmationMessage={`Are you sure you want to board the passenger for this trip?`}
            newStatus={TripStatus.BOARDED}
            yesText="Submit"
            tooltipText="Board Passenger"
            iconClass="bg-green-500 text-white"
            icon={<IconUserCheck size={16} strokeWidth={1.5} />}
            key={trip?.id}
          />

          {/* <TripActionComponent
            trip={trip}
            confirmationHeader="Skip"
            confirmationMessage={`Are you sure you want to skip this trip?`}
            newStatus={TripStatus.SKIPPED}
            yesText="Submit"
            tooltipText="Skip Trip"
            iconClass="bg-red-500 text-white"
            icon={<IconPlayerSkipForwardFilled size={16} strokeWidth={1.5} />}
            key={trip?.id}
          /> */}

          <TripActionComponent
            trip={trip}
            confirmationHeader="No Show"
            confirmationMessage={`Are you sure the passenger didn't show up?`}
            newStatus={TripStatus.NOSHOW}
            yesText="Submit"
            tooltipText="No Show"
            iconClass="bg-green-500 text-white"
            icon={<IconPlayerPlay size={16} strokeWidth={1.5} />}
            key={trip?.id}
          />
        </>
      );
    }

    if (trip.status == TripStatus.BOARDED) {
      return (
        <>
          <TripActionComponent
            trip={trip}
            confirmationHeader="Drop Off"
            confirmationMessage={`Are you sure the passenger has arrived to the destination?`}
            newStatus={TripStatus.ARRIVED}
            yesText="Submit"
            tooltipText="Drop Off"
            iconClass="bg-primary text-white"
            icon={<IconUserPin size={16} strokeWidth={1.5} />}
            key={trip?.id}
          />
        </>
      );
    }
  }

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        header={
          <div className="w-full border-b p-2 flex items-center justify-between">
            <div>Trips</div>
            <div>
              <Button
                size="xs"
                onClick={() => navigate(-1)}
                className="text-white bg-primary"
                leftIcon={<IconArrowBack size={10} />}
              >
                Back
              </Button>
            </div>
          </div>
        }
        showNewButton={false}
        showExport={false}
        showSelector={false}
        showArchivedCheckBox={false}
        title={"Trips"}
        total={trips?.data?.count}
        itemsLoading={trips.isLoading || trips?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            filter: [
              [
                { field: "booking.routeName", operator: "like", value: data },
                { field: "booking.driverName", operator: "like", value: data },
                {
                  field: "booking.driverPhoneNumber",
                  operator: "like",
                  value: data,
                },
                {
                  field: "booking.passengerName",
                  operator: "like",
                  value: data,
                },
                {
                  field: "booking.passengerPhoneNumber",
                  operator: "like",
                  value: data,
                },
              ],
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

export const GROUP_ENDPOINT={

    //group-endpoint
    detail:`${process.env.REACT_APP_API}/groups/get-group`,
    delete:`${process.env.REACT_APP_API}/groups/delete-group`,
    toggleStatus:`${process.env.REACT_APP_API}/groups/activate-or-block-group`,
    archive:`${process.env.REACT_APP_API}/groups/archive-group`,
    restore:`${process.env.REACT_APP_API}/groups/restore-group`,
    archivedList:`${process.env.REACT_APP_API}/groups/get-archived-groups`,
    archivedGroup:`${process.env.REACT_APP_API}/groups/get-archived-group`,
    list:`${process.env.REACT_APP_API}/groups/get-groups`,
    create:`${process.env.REACT_APP_API}/groups/create-group`,
    update:`${process.env.REACT_APP_API}/groups/update-group`,


    //group-assignment-endpoints

    getParentsForAssignment: `${process.env.REACT_APP_API}/parents/get-parents`,
    listAssignment: `${process.env.REACT_APP_API}/groups/get-group-assignments`,
    detailAssignment: `${process.env.REACT_APP_API}/groups/get-group-assignment`,
    getAssignmentsByGroup: `${process.env.REACT_APP_API}/groups/get-group-assignment-with-group-id`,

    addGroupAssignments: `${process.env.REACT_APP_API}/groups/add-group-assignments`,
    removeGroupAssignment: `${process.env.REACT_APP_API}/groups/remove-group-assignment`,

    getTotalPayment: `${process.env.REACT_APP_API}/groups/calculate-total-payment-of-parent`,


    updateDriverAssignment: `${process.env.REACT_APP_API}/driver-assignments/update-driver-assignment`,
    createDriverAssignmentForGroup: `${process.env.REACT_APP_API}/driver-assignments/create-driver-assignment`,


}

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NewRequestComponent from "../component/new-request-component";
import GroupPage from "../page/request-page";
export const RequestRoute: RouteObject = {
  path: "/request",
  element: (
    <AuthGuard role={['super_admin',"admin",'operator']}>
      <GroupPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewRequestComponent editMode="detail" /> },
    { path: ":id", element: <NewRequestComponent editMode="new" /> },
  ],
};

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Select,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconCurrentLocation,
  IconDeviceFloppy,
  IconMap,
  IconSlash,
  IconTrash,
} from "@tabler/icons";
import { lazy, Suspense, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { BusStop } from "../../../models/bus-stop.model";
import { City } from "../../../models/city.model";
import { CollectionQuery } from "../../../models/collection.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { Location } from "../../../shared/component/Map/map-component";
import { notification } from "../../../shared/component/notification/utility/notification";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetCitiesQuery } from "../../city/store/city.query";
import {
  useActivateBusStopMutation,
  useArchiveBusStopMutation,
  useCreateBusStopMutation,
  useDeleteBusStopMutation,
  useLazyArchivedBusStopQuery,
  useLazyGetBusStopQuery,
  useRestoreBusStopMutation,
  useUpdateBusStopMutation,
} from "../store/bus-stop.query";

const MapComponent = lazy(() => import("../../../shared/component/Map/map-component"));
export interface Props {
  editMode: string;
}

export default function NewBusStopComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();

  const defaultValue: BusStop = {
    id: "",
    name: "",
    cityId: "",
    description: "",
    isActive: true,
    lat: 0,
    lng: 0,
  };

  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 3 letters")
      .required("Name is required"),
    description: Yup.string().required("Description is required"),
    cityId: Yup.string().required("City is required"),
    lat: Yup.number().required("Latitude is required"),
    lng: Yup.number().required("Longitude is required"),
  });

  const [citiesCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 1000,
  });

  const [getCities, cities] = useLazyGetCitiesQuery();
  const [getBusStop, busStop] = useLazyGetBusStopQuery();
  const [getArchivedBusStop, archivedBusStop] = useLazyArchivedBusStopQuery();
  const [createBusStop, newBusStop] = useCreateBusStopMutation();
  const [updateBusStop, updateResponse] = useUpdateBusStopMutation();
  const [archiveBusStop, archiveResponse] = useArchiveBusStopMutation();
  const [activateBusStop, activateResponse] = useActivateBusStopMutation();
  const [restoreBusStop, restoreCall] = useRestoreBusStopMutation();
  const [deleteBusStop, deleteCall] = useDeleteBusStopMutation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<BusStop>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  function checkLocation() {
    setLocationLoading(true);
    setTimeout(async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          notification("success", "Location has been resolved successfully!");
          setLocationLoading(false);
        },
        (err) => notification("error", "Error resolving your location!")
      );
    }, 0);
  }

  const locationDefault = { lat: 8.993441, lng: 38.7663447 };

  const [location, setLocation] = useState<Location>(locationDefault);

  const [locationLoading, setLocationLoading] = useState<boolean>(false);

  const [, setBusStopId] = useState("");

  useEffect(() => {
    if (editMode === "detail") {
     if(params?.type!==undefined && params?.type==="archived"){
      getArchivedBusStop(`${params?.id}`).then((response: any) => {
        reset(response.data);
        setLocation({ lat: response.data.lat, lng: response.data.lng });
        setBusStopId(response.data.id);
      });
    }
  else 
 { getBusStop(`${params?.id}`).then((response: any) => {
    reset(response.data);
    setLocation({ lat: response?.data?.lat, lng: response?.data?.lng });
    setBusStopId(response?.data?.id);
  });}
} else {
      reset(defaultValue);
    }
    getCities({
      ...citiesCollection,
      filter: [
        [{ field: "isActive", value: true, operator: "=" }],
      ],
    });
  }, [params, editMode, getBusStop, getCities, citiesCollection]);

  function ParseCitySelector() {
    const _cities: { value: string; label: string }[] = [];
    if (cities.data) {
      cities.data.data.map((city: City) => {
        _cities.push({ value: city.id ?? "", label: city.name });
      });
    }
    return _cities;
  }

  function onSubmit(data: any) {
    data.lat = parseFloat(location.lat.toString());
    data.lng = parseFloat(location.lng.toString());
    if (editMode === "new") {
      createBusStop(data).then(() => reset(defaultValue));
    } else {
      const busStop: BusStop = { id: params.id, ...data };
      updateBusStop(busStop);
    }
  }
  const onError = (errors: any, e: any) => {};

  function setSearchLocation(position: google.maps.LatLngLiteral) {
    setLocation({ lat: position.lat, lng: position.lng });
  }
  function setName(name: string) {
   
  }

  const [activeTab, setActiveTab] = useState<string | null>("main");
  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Bus Stop" : busStop?.data?.name ?? ""}
        item={busStop}
        path={`/settings-and-configurables/bus-stop/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (busStop.isFetching || updateResponse.isLoading)
          }
        />

        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Bus Stop Information</Tabs.Tab>
            <Tabs.Tab value="location">Location</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex  justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <div className="flex-col space-y-4">
                  <div className="flex w-full space-x-4  justify-between">
                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.name && `${errors?.name?.message}`}
                      withAsterisk
                      label="Name"
                      placeholder="Name"
                      {...register("name")}
                    />
                  </div>

                  <div className="flex w-full space-x-4  justify-between">
                    <Textarea
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={
                        errors.description && `${errors?.description?.message}`
                      }
                      withAsterisk
                      label="Description"
                      placeholder="Description"
                      {...register("description")}
                    />
                  </div>

                  <div className="flex w-full space-x-4  justify-between">
                    <Controller
                      render={({ field }) => (
                        <Select
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          label="City"
                          placeholder="City"
                          data={ParseCitySelector()}
                          withAsterisk
                          error={errors.cityId && `${errors?.cityId?.message}`}
                          searchable
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                      name="cityId"
                      control={control}
                    />
                  </div>

                  <div className="flex w-full space-x-4  justify-between mt-5">
                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.lng && `${errors?.lng?.message}`}
                      withAsterisk
                      label="Longitude"
                      value={location.lng}
                      onChange={(e) => {
                        try {
                          const val = parseFloat(e.currentTarget.value);
                          setValue("lng", val ?? 0);
                          setLocation({ lng: val, lat: location.lat });
                        } catch (e) {
                          
                        }
                      }}
                    />

                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.lat && `${errors?.lat?.message}`}
                      withAsterisk
                      label="Latitude"
                      value={location.lat}
                      onChange={(e) => {
                        try {
                          const val = parseFloat(e.currentTarget.value);
                          setValue("lat", val ?? 0);
                          setLocation({ lat: val, lng: location.lng });
                        } catch (e) {
                          
                        }
                      }}
                    />
                  </div>

                  <div className="flex w-full space-x-4  justify-between">
                    <Button
                      variant={"default"}
                      onClick={() => {
                        setActiveTab("location");
                      }}
                      className="w-1/5 bg-primary text-white py-2 hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                      classNames={{ label: "dark:text-white" }}
                    >
                      <IconCurrentLocation />
                      <IconSlash />
                      <IconMap />
                    </Button>
                  </div>
                </div>
                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={newBusStop.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {((params?.type==='archived' && archivedBusStop?.data?.deletedAt)||(params?.type==='active' && busStop?.data?.deletedAt))  ? (
                        <>
                          <Confirmation
                            type={"danger"}
                            message={
                              "Are you sure you want to delete it permanently?"
                            }
                            onYes={() =>
                              deleteBusStop(`${params.id}`)
                            }
                            header={`Permanent Delete confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={deleteCall.isLoading}
                              className={`bg-danger text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconTrash size={20} strokeWidth={1.5} />
                              <span>Delete</span>{" "}
                            </Button>
                          </Confirmation>
                          <Confirmation
                            type={"notify"}
                            message={`Are you sure you want to restore it`}
                            onYes={() =>
                              restoreBusStop(`${params.id}`)
                            }
                            header={`Restore confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={restoreCall.isLoading}
                              className={`bg-green-500 text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconArrowBackUp size={20} strokeWidth={1.5} />
                              <span>Restore</span>
                            </Button>
                          </Confirmation>
                        </>
                      ) : (<><Confirmation
                          type={"notify"}
                          message={
                            "Are you sure you want to archive this bus-stop?"
                          }
                          header={"Archive Confirmation"}
                          result={"single"}
                          resultRequired={true}
                          selectorLabel={"Please select a reason"}
                          labelDescription={
                            "Why are you archiving this bus-stop?"
                          }
                          options={Constants.ArchiveReason.map(
                            (reason) => {
                              return { label: reason, value: reason };
                            }
                          )}
                          customInput={true}
                          yesText={"Archive"}
                          onYes={(data: string) => {
                            archiveBusStop({
                              id: `${params.id}`,
                              reason: data,
                            });
                          }}
                        >
                          <Button
                            variant="filled"
                            loading={archiveResponse.isLoading}
                            className="bg-danger text-white"
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <span>
                              <IconArchive size={22} strokeWidth={1.5} />
                            </span>
                            <span>Archive</span>
                          </Button>
                        </Confirmation>
                        <Confirmation
                          type={"notify"}
                          message={
                            "Are you sure you want to " +
                            (busStop.data?.isActive
                              ? "deactivate"
                              : "activate") +
                            " it?"
                          }
                          onYes={() =>
                            schema.validate(busStop.data).then((data) => {
                              const busStop: BusStop = {
                                ...data,
                                isActive: data.isActive,
                              };
                              activateBusStop(busStop);
                            })
                          }
                          header={
                            (busStop.data?.isActive
                              ? "Deactivate"
                              : "Activate") + " Confirmation"
                          }
                        >
                          <Button
                            variant="filled"
                            loading={activateResponse.isLoading}
                            className={`${
                              busStop.data?.isActive
                                ? " bg-danger"
                                : "bg-green-500"
                            } text-white`}
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current h-6"
                                viewBox="0 0 48 48"
                              >
                                <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                              </svg>
                            </span>
                            {busStop.data?.isActive ? (
                              <span>Deactivate</span>
                            ) : (
                              <span>Activate</span>
                            )}
                          </Button>
                        </Confirmation>
                      </>
                        
                      )}


                        {params?.type === 'active' && (
                          <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={updateResponse.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Update</span>
                        </Button>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="location" pt="xs">
            <div className="flex p-5 justify-between h-full">
              <div>
                <Text>Latitude: {location.lat}</Text>
                <Text>Longitude: {location.lng}</Text>
              </div>
              <div className="flex-col space-y-4">
                <Button
                  classNames={{ label: "flex space-x-1 " }}
                  variant="default"
                  className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                  leftIcon={<IconCurrentLocation />}
                  loading={locationLoading}
                  onClick={checkLocation}
                >
                  <span>Load Current Location</span>
                </Button>
              </div>
            </div>
            <Divider className={"my-2"} />

            {/*StaticMap Component*/}
            <Suspense>
              <MapComponent
                setLocation={setLocation}
                location={location}
                placesProps={{ setLocation: setSearchLocation, setName: setName }}
              />
            </Suspense>

            <Divider className={"my-2"} />
            <div className="w-full flex justify-end items-center">
              <Button
                variant="filled"
                className="bg-primary text-white"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={locationLoading}
                onClick={() => {
                  setValue("lat", location.lat);
                  setValue("lng", location.lng);
                  setActiveTab("main");
                }}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </div>
          </Tabs.Panel>
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}

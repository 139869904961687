/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Feedback } from "../../../models/feedback.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { FEEDBACK_ENDPOINT } from "../feedback.endpoint";

let feedbackCollection: CollectionQuery;
const feedbackQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
   

    getFeedbacks: builder.query<Collection<Feedback>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${FEEDBACK_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            feedbackCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteFeedback: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${FEEDBACK_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission:'manage-categories'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            feedbackQuery.util.updateQueryData(
              "getFeedbacks",
              feedbackCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (feedback) => feedback.id !== id
                );
                draft.count -= 1;
              }
            )
          );
         
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetFeedbacksQuery,
  useDeleteFeedbackMutation
} = feedbackQuery;

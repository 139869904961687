export const BUS_STOP_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/cities/get-city-bus-stop`,
    archived_bus_stop:`${process.env.REACT_APP_API}/cities/get-archived-bus-stop`,
    delete:`${process.env.REACT_APP_API}/cities/delete-city-bus-stop`,
    toggleStatus:`${process.env.REACT_APP_API}/cities/activate-or-block-city-bus-stop`,
    archive:`${process.env.REACT_APP_API}/cities/archive-city-bus-stop`,
    restore:`${process.env.REACT_APP_API}/cities/restore-city-bus-stop`,
    archivedList:`${process.env.REACT_APP_API}/cities/get-archived-city-bus-stops`,
    archivedBusStop:`${process.env.REACT_APP_API}/cities/get-archived-city-bus-stop`,
    list:`${process.env.REACT_APP_API}/cities/get-city-bus-stops`,
    create:`${process.env.REACT_APP_API}/cities/create-city-bus-stop`,
    update:`${process.env.REACT_APP_API}/cities/update-city-bus-stop`
}
import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Category } from "../../../models/category.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetCategoriesQuery } from "../store/category.query";

export default function CategoryPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getCategories, categories] = useLazyGetCategoriesQuery();

  useEffect(() => {
    getCategories(collection, true);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/category/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Category[] | undefined = categories.data?.data;
  const config: EntityConfig<Category> = {
    primaryColumn: { key: "name", name: "Name" },
    rootUrl: "/settings-and-configurables/category",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name",
      render: (category) => {
        return (
          <Text size="sm" className={category?.deletedAt ? "text-danger" : ""}>
            {category.name}
          </Text>
        );
      }, },
      { key: "capacity", name: "Capacity" },
      {
        key: "initialFee",
        name: "Initial Fee",
        render: (category) => {
          return <span>{`${Number(category.initialFee).toFixed(2)}ETB`}</span>;
        },
      },
      {
        key: ["perKilometerCost", "easy"],
        name: "Per KM Cost based on Road Difficulty Level",
        hideSort: true,
        render: (category) => {
          return (
            <div className="flex-col space-y-1">
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-green-500 font-semibold">
                  Easy:
                </span>
                <span className="text-xs text-green-500">
                  {category?.perKilometerCost?.easy}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-yellow-500 font-semibold">
                  Moderate:
                </span>
                <span className="text-xs  text-yellow-500">
                  {category?.perKilometerCost?.moderate}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-red-400 font-semibold">
                  Difficult:
                </span>
                <span className="text-xs  text-red-400">
                  {category?.perKilometerCost?.difficult}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-red-500 font-semibold">
                  Extreme:
                </span>
                <span className="text-xs text-red-500">
                  {category?.perKilometerCost?.extreme}
                  {`ETB`}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: ["perTrafficJamCost", "easy"],
        name: "Per Traffic Jam Cost",
        hideSort: true,
        render: (category) => {
          return (
            <div className="flex-col space-y-1">
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-green-500 font-semibold">
                  Easy:
                </span>
                <span className="text-xs text-green-500">
                  {category?.perTrafficJamCost?.easy}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-yellow-500 font-semibold">
                  Moderate:
                </span>
                <span className="text-xs text-yellow-500">
                  {category?.perTrafficJamCost?.moderate}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-red-400 font-semibold">
                  Difficult:
                </span>
                <span className="text-xs  text-red-400">
                  {category?.perTrafficJamCost?.difficult}
                  {`ETB`}
                </span>
              </div>
              <div className="flex space-x-1 items-center">
                <span className="text-xs text-red-500 font-semibold">
                  Extreme:
                </span>
                <span className="text-xs text-red-500">
                  {category?.perTrafficJamCost?.extreme}
                  {`ETB`}
                </span>
              </div>
            </div>
          );
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Categories"}
        total={categories?.data?.count}
        itemsLoading={categories.isLoading || categories.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,withArchived:e
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "description"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

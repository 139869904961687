import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  NumberInput,
  Stack,
  Text
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CollectionQuery } from "../../../models/collection.model";
import { RoutePrice } from "../../../models/route-price.model";
import { Route } from "../../../models/route.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import {
  useLazyGetRoutePricesQuery,
  useRestoreDriverCommissionMutation,
  useRestoreRoutePriceMutation,
  useUpdateDriverCommissionMutation,
  useUpdateRoutePriceMutation
} from "../store/route.query";

type StationProps = {
  route?: Route;
};

export default function PriceComponent(props: StationProps) {
  const { route } = props;

  const [currentId, setCurrentId] = useState<string>("");
  const [getRoutePrices, routePrices] = useLazyGetRoutePricesQuery();

  const [updateRoutePrice, routePriceUpdate] = useUpdateRoutePriceMutation();
  const [restoreRoutePrice, routePriceRestore] = useRestoreRoutePriceMutation();

  const [updateDriverCommission, commissionUpdate] =
    useUpdateDriverCommissionMutation();
  const [restoreDriverCommission, commissionRestore] =
    useRestoreDriverCommissionMutation();

  const [collection] = useState<CollectionQuery>({
    skip: 0,
    includes: ["category"],
    orderBy: [{ field: "categoryId", direction: "asc" }],
  });

  useEffect(() => {
    getRoutePrices({ query: collection, routeId: `${route?.id}` });
  }, [route?.id, getRoutePrices, collection]);

  const form = useForm();

  const row = (routePrice: RoutePrice) => {
    return (
      <div key={routePrice.id} className={"px-10"}>
        <Group position={"apart"}>
          <Stack spacing={"xs"}>
            <Text fw={500}>{routePrice.category.name}</Text>
            <div className={"ml-4"}>
              <Text fz={"sm"} c={"dimmed"}>
                {routePrice.category.description}
              </Text>
              <Text fz={"sm"} c={"dimmed"}>
                Passengers: {routePrice.category.capacity}
              </Text>
            </div>
          </Stack>
          <Stack align={"center"} spacing={20} className={"my-2"}>
            <Stack align={"center"} className={"items-end"}>
              <div className="flex space-x-2">
                <NumberInput
                  className={"w-64"}
                  label="Price (Birr)"
                  defaultValue={routePrice.fee}
                  required
                  min={0}
                  name={`fee_${routePrice.id}`}
                  onChange={(value) => {
                    const data: any = {};
                    if (!value) {
                      data[`fee_${routePrice.id}`] =
                        "Route price can not be empty or zero ";
                      form.setErrors(data);
                    } else {
                      data[`fee_${routePrice.id}`] = value;
                      form.setValues(data);
                    }
                  }}
                />
                <Group position="center" className="flex items-end">
                  <Confirmation
                    header={"Update Confirmation"}
                    message={
                      "Are you sure you want to update this route's price?"
                    }
                    onYes={() => {
                      const val = form.values[`fee_${routePrice.id}`];
                      if (val) {
                        updatePrice(
                          routePrice,
                          parseInt(val.toString()) ?? routePrice.fee
                        );
                      }
                    }}
                    yesText={"Yes"}
                    noText={"No"}
                  >
                    <Button
                      loading={
                        routePriceUpdate.isLoading &&
                        currentId === routePrice.id
                      }
                      variant="filled"
                      className="bg-primary text-white"
                      size={"xs"}
                    >
                      Update
                    </Button>
                  </Confirmation>

                  <Confirmation
                    header={"Restore Confirmation"}
                    message={
                      "Are you sure you want to restore this route's price to it's default value?"
                    }
                    onYes={() => restorePrice(routePrice)}
                    yesText={"Yes"}
                    noText={"No"}
                  >
                    <Button
                      loading={
                        routePriceRestore.isLoading &&
                        currentId === routePrice.id
                      }
                      variant="filled"
                      className="bg-primary text-white"
                      size={"xs"}
                    >
                      Restore
                    </Button>
                  </Confirmation>
                </Group>
              </div>
            </Stack>
            <Stack align={"center"} className={"items-end"}>
              <div className="flex space-x-2">
                <NumberInput
                  className={"w-64"}
                  label="Driver Fee (%)"
                  required
                  min={1}
                  max={100}
                  defaultValue={routePrice.driverCommission}
                  name={`commission_${routePrice.id}`}
                  onChange={(value) => {
                    const data: any = {};
                    if (!value) {
                      data[`commission_${routePrice.id}`] =
                        "Commission can not be empty or zero";
                      form.setErrors(data);
                    } else {
                      data[`commission_${routePrice.id}`] = value;
                      form.setValues(data);
                    }
                  }}
                />
                <Group position={"center"} className="flex items-end">
                  <Confirmation
                    header={"Update Confirmation"}
                    message={
                      "Are you sure you want to update this route's driver commission?"
                    }
                    onYes={() => {
                      const val = form.values[`commission_${routePrice.id}`];
                      if (val) {
                        updateCommission(
                          routePrice,
                          parseInt(val.toString()) ??
                            routePrice.driverCommission
                        );
                      }
                    }}
                    yesText={"Yes"}
                    noText={"No"}
                  >
                    <Button
                      loading={
                        commissionUpdate.isLoading &&
                        currentId === routePrice.id
                      }
                      variant="filled"
                      className="bg-primary text-white"
                      size={"xs"}
                    >
                      Update
                    </Button>
                  </Confirmation>
                  <Confirmation
                    header={"Restore Confirmation"}
                    message={
                      "Are you sure you want to restore this route's driver commission to it's default value?"
                    }
                    onYes={() => restoreFee(routePrice)}
                    yesText={"Yes"}
                    noText={"No"}
                  >
                    <Button
                      loading={
                        commissionRestore.isLoading &&
                        currentId === routePrice.id
                      }
                      variant="filled"
                      className="bg-primary text-white"
                      size={"xs"}
                    >
                      Restore
                    </Button>
                  </Confirmation>
                </Group>
              </div>
            </Stack>
          </Stack>
        </Group>
        <Divider size="sm" className={"my-2"} />
      </div>
    );
  };

  function updateCommission(routePrice: RoutePrice, commission: number) {
    setCurrentId(routePrice.id ?? "");
    updateDriverCommission({
      id: routePrice.id,
      routeId: route?.id,
      driverCommission: commission,
    }).finally(() => {
      setCurrentId("");
    });
  }
  function restoreFee(routePrice: RoutePrice) {
    setCurrentId(routePrice.id ?? "");
    restoreDriverCommission({
      id: routePrice.id,
      routeId: route?.id,
    }).finally(() => {
      setCurrentId("");
    });
  }
  function updatePrice(routePrice: RoutePrice, fee: number) {
    setCurrentId(routePrice.id ?? "");
    updateRoutePrice({
      id: routePrice.id,
      routeId: route?.id,
      fee: fee,
    }).finally(() => {
      setCurrentId("");
    });
  }
  function restorePrice(routePrice: RoutePrice) {
    setCurrentId(routePrice.id ?? "");
    restoreRoutePrice({
      id: routePrice.id,
      routeId: route?.id,
    }).finally(() => {
      setCurrentId("");
    });
  }

  return (
    <div className="h-full p-4 relative">
      <LoadingOverlay visible={routePrices.isFetching} />
      <Stack spacing="md">
        <form
          onSubmit={(data) => {
            
          }}
          className="w-full gap-y-4 dark:text-white"
        >
          {routePrices?.data?.data?.map((price: RoutePrice) => {
            return row(price);
          })}
          {(routePrices.isError ||
            routePrices?.data?.data?.length === 0 ||
            routePrices.isFetching) && <EmptyIcon />}
        </form>
      </Stack>
    </div>
  );
}

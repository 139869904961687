import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";

import NewFaqComponent from "../component/new-faq-component";

import FaqPage from "../page/faq-page";
export const FaqRoute: RouteObject = {
  path: "/faq",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <FaqPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewFaqComponent editMode="detail" />,
    },
    { path: ":id", element: <NewFaqComponent editMode="new" /> },
  ],
};

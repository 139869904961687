import { Loader, Text, Timeline, Tooltip } from "@mantine/core";
import { IconDeviceFloppy, IconTrash, IconX } from "@tabler/icons";
import dateFormat from "dateformat";
import { Activity } from "../../../models/activity.model";
import { Collection } from "../../../models/collection.model";

interface ActivityLogProps {
  displayLog: boolean;
  setDisplayLog: Function;
  activities?: Collection<Activity>;
  loading?: boolean;
  item?: any;
}

export const ActivityLog = (props: ActivityLogProps) => {
  return props.displayLog ? (
    <div className="w-72 border px-2">
      <div className="h-12 border-0 border-b flex items-center justify-between mb-2 w-full">
        <div className="h-full flex dark:text-white items-center font-semibold ">
          Activity Logger
        </div>
        <div>
          <Tooltip label={<span className="text-xs">Close logger</span>}>
            <span onClick={() => props.setDisplayLog(false)}>
              <IconX size={15} />
            </span>
          </Tooltip>
        </div>
      </div>
      {props.item && (
        <>
          <Timeline
            active={props.activities?.data?.length ?? 0}
            bulletSize={24}
            lineWidth={2}
          >
            {props.loading && (
              <Timeline.Item>
                <div className={"h-32 w-full"}>
                  <Loader className={"mx-auto my-10"} />
                </div>
              </Timeline.Item>
            )}

            {props.activities?.data?.map((activity) => (
              <Timeline.Item
                bullet={<IconDeviceFloppy size={12} />}
                title={activity?.action}
              >
                <Text color="dimmed" size="sm">
                  {`${activity.action}d by `}
                  <Text variant="link" component="span" inherit>
                    {activity?.user?.name}
                  </Text>
                </Text>
                <Text size="xs" mt={4}>
                  {dateFormat(activity?.createdAt, "mmmm dd, yyyy - hh:MM TT")}
                </Text>
              </Timeline.Item>
            ))}

            {props.item.currentData?.deletedAt && (
              <Timeline.Item title="Deleted" bullet={<IconTrash size={12} />}>
                <Text color="dimmed" size="sm">
                  <Text variant="link" component="span" inherit>
                    Deleted by {props.item.currentData?.user?.name}
                  </Text>
                </Text>
                <Text size="xs" mt={4}>
                  {dateFormat(
                    props.item.currentData?.deletedAt,
                    "mmmm dd, yyyy - hh:mm TT"
                  )}
                </Text>
              </Timeline.Item>
            )}
          </Timeline>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

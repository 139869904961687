import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import BookingDetailComponent from "../component/booking-detail-component";
import BookingPage from "../page/booking-list-page";
export const BookingRoute: RouteObject = {
  path: "/bookings",
  element: (
    <AuthGuard role={['super_admin','admin','finance','operator']}>
      <BookingPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <BookingDetailComponent /> },
  ],
};

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Tabs,
  TextInput,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconBus,
  IconDeviceFloppy,
  IconFilePlus,
  IconTrash,
} from "@tabler/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Category } from "../../../models/category.model";
import { CollectionQuery } from "../../../models/collection.model";
import { Owner } from "../../../models/owner.model";
import { Vehicle } from "../../../models/vehicle.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Constants } from "../../../shared/constants/Constants";
import { VehicleColor } from "../../../shared/utility/enum/color.enum";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import { useLazyGetOwnersQuery } from "../../owner/store/owner.query";
import {
  useActivateVehicleMutation,
  useArchiveVehicleMutation,
  useCreateVehicleMutation,
  useDeleteVehicleMutation,
  useLazyGetArchivedVehicleQuery,
  useLazyGetVehicleQuery,
  useRestoreVehicleMutation,
  useUpdateVehicleMutation,
} from "../store/vehicle.query";
import VehicleFileUploadComponent from "./vehicle-file-upload.component";
interface SelectItem {
  value: string;
  label: string;
}
interface Props {
  editMode: string;
}
let schema = yup.object().shape({
  ownerId: yup.string().required("Owner is required"),
  code: yup
    .number()
    .typeError("Code must be a number")
    .positive("Code must be positive number")
    .required("Code is required"),
  plateNumber: yup.string().required("Plate number is required"),
  model: yup.string().required("Model is required"),
  categoryId: yup.string().required("Category is required"),
  color: yup.string().required("Color is required"),
});

export default function NewVehicleComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();
  const [ownerCollection, setOwnerCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });
  const [currentVehicle, setCurrentVehicle] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>();
  const [categorySearchKey, setCategorySearchKey] = useState<string>();

  const [ownerData, setOwnerData] = useState<any[]>([]);

  const defaultValue: Vehicle = {
    id: "",
    code: "",
    plateNumber: "",
    model: "",
    categoryId: "",
    color: "",
  };

  const [getVehicle, vehicle] = useLazyGetVehicleQuery();
  const [getArchivedVehicle, archivedVehicle] =
    useLazyGetArchivedVehicleQuery();
  const [createVehicle, newVehicle] = useCreateVehicleMutation();
  const [activateVehicle, activateResponse] = useActivateVehicleMutation();
  const [updateVehicle, updateResponse] = useUpdateVehicleMutation();
  const [archiveVehicle, archiveResponse] = useArchiveVehicleMutation();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [getOwners, owners] = useLazyGetOwnersQuery();
  const [deleteVehicle, deleteResponse] = useDeleteVehicleMutation();
  const [restoreVehicle, restoreResponse] = useRestoreVehicleMutation();

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Vehicle>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const vehicleColor = (
    Object.keys(VehicleColor) as (keyof typeof VehicleColor)[]
  ).map((key, index) => ({
    label: VehicleColor[key],
    value: VehicleColor[key],
    key: VehicleColor[key],
  }));

  useEffect(() => {
    getCategories({
      skip: 0,
      search: categorySearchKey,
      searchFrom: ["name"],
    }).then((response) => {
      const data = response.data?.data?.map((category: Category, idx) => {
        if (category) {
          return { value: category.id, label: category.name };
        }
      });

      setData(data ? data : []);
    });
  }, [categorySearchKey]);

  useEffect(() => {
    getOwners({
      ...ownerCollection,
      filter: [[{ field: "enabled", value: true, operator: "=" }]],
      search: searchKey,
      searchFrom: ["name", "email", "phoneNumber", "tinNumber", "website"],
    });
  }, [searchKey]);

  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type === "active") {
        getVehicle(`${params?.id}`).then((response: any) => {
          if (response?.data) {
            setCurrentVehicle(response);
            reset(response.data);
          }
        });
      } else {
        getArchivedVehicle(`${params?.id}`).then((response: any) => {
          if (response?.data) {
            setCurrentVehicle(response);
            reset(response.data);
          }
        });
      }
    } else {
      reset(defaultValue);
    }
  }, [params.id, editMode]);
  function selectOwners(): readonly (string | SelectItem)[] {
    let initialOwners: SelectItem[] = [];

    if (owners?.data?.data) {
      initialOwners = owners.data.data.map((owner: Owner) => ({
        value: owner?.id || "",
        label: `${owner?.name} (${owner?.phoneNumber})`,
      }));
    }

    const ownerToAdd = {
      value: currentVehicle?.data?.owner?.id || "",
      label: `${currentVehicle?.data?.owner?.name} (${currentVehicle?.data?.owner?.phoneNumber})`,
    };

    if (
      currentVehicle?.data?.owner &&
      !initialOwners?.some((owner) => owner.value === ownerToAdd.value)
    ) {
      initialOwners?.unshift(ownerToAdd);
    }

    return initialOwners;
  }
  const onSubmit = (data: any) => {
    if (editMode === "new") {
      createVehicle(data).then((response: any) => {
        if (!response?.error) {
          reset(defaultValue);
        }
      });
    } else {
      const vehicle: Vehicle = { id: params.id, ...data };
      updateVehicle(vehicle);
    }
  };

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex  justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? "New Vehicle" : vehicle?.data?.plateNumber ?? ""
        }
        item={vehicle}
        path={`/registration/vehicle/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "new"
              ? owners?.isLoading
              : updateResponse?.isLoading ||
                vehicle?.isLoading ||
                vehicle?.isFetching ||
                archivedVehicle?.isLoading ||
                archivedVehicle?.isFetching
          }
        />
        <>
          <Tabs defaultValue="Vehicle">
            <Tabs.List>
              <Tabs.Tab value="Vehicle" icon={<IconBus size={14} />}>
                Vehicle info
              </Tabs.Tab>
              {editMode === "detail" && (
                <Tabs.Tab value="document" icon={<IconFilePlus size={14} />}>
                  Upload document
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="Vehicle" pt="xs">
              <div className="flex  justify-center h-full">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full gap-y-4 dark:text-white"
                >
                  <div className="flex-col space-y-4">
                    <div className="flex w-full space-x-4">
                      <Controller
                        name="ownerId"
                        control={control}
                        render={({ field }) => (
                          <Select
                            className="w-full"
                            label="Owner"
                            placeholder="Owner"
                            nothingFound={
                              <div className="flex relative w-full justify-center items-center">
                                <LoadingOverlay
                                  visible={
                                    owners?.isLoading || owners?.isFetching
                                  }
                                />
                                <EmptyIcon />
                              </div>
                            }
                            withAsterisk
                            searchable
                            onKeyUp={debounce(
                              (event) => setSearchKey(event.target.value),
                              1000
                            )}
                            error={
                              errors.ownerId && `${errors?.ownerId?.message}`
                            }
                            data={selectOwners()}
                            {...field}
                          />
                        )}
                      />
                      <TextInput
                        classNames={{ label: "dark:text-white" }}
                        className="w-full"
                        error={
                          errors.plateNumber &&
                          `${errors?.plateNumber?.message}`
                        }
                        withAsterisk
                        label="Plate number"
                        placeholder="B95684"
                        {...register("plateNumber")}
                      />
                    </div>
                    <div className="flex w-full space-x-4  justify-between">
                      <TextInput
                        classNames={{ label: "dark:text-white" }}
                        className="w-full"
                        error={errors.code && `${errors?.code?.message}`}
                        withAsterisk
                        label="Code"
                        placeholder="2"
                        {...register("code")}
                      />
                      <TextInput
                        classNames={{ label: "dark:text-white" }}
                        className="w-full"
                        error={errors.model && `${errors?.model?.message}`}
                        withAsterisk
                        label="Model"
                        placeholder="V8"
                        {...register("model")}
                      />
                    </div>
                    <div className="flex w-full space-x-4 justify-between">
                      <div className="w-full">
                        <Controller
                          name="color"
                          control={control}
                          render={({ field }) => (
                            <Select
                              withAsterisk
                              className="w-full"
                              searchable
                              nothingFound={
                                <div className="fle w-full justify-center items-center">
                                  <EmptyIcon />
                                </div>
                              }
                              error={
                                errors?.color && `${errors?.color?.message}`
                              }
                              radius={"xs"}
                              classNames={{
                                label: "dark:text-white",
                              }}
                              label="Color"
                              placeholder="Pick one"
                              data={vehicleColor}
                              maxDropdownHeight={400}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <Controller
                        name="categoryId"
                        control={control}
                        render={({ field }) => (
                          <Select
                            searchable
                            withAsterisk
                            className="w-full"
                            label="Category"
                            placeholder="Pick one"
                            nothingFound={
                              <div className="flex relative w-full justify-center items-center">
                                <EmptyIcon />
                              </div>
                            }
                            onKeyUp={debounce(
                              (event) =>
                                setCategorySearchKey(event.target.value),
                              1000
                            )}
                            error={
                              errors.categoryId &&
                              `${errors?.categoryId?.message}`
                            }
                            data={[...data]}
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <div className="w-full flex justify-end items-center">
                      <Group position="right" mt="xl">
                        {editMode === "new" && (
                          <>
                            <Button
                              classNames={{ label: "flex space-x-1 " }}
                              variant="default"
                              className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                              type="reset"
                              size="xs"
                              onClick={() => {
                                reset();
                                setValue("ownerId", "");
                              }}
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="fill-current h-6"
                                  viewBox="0 0 32 32"
                                >
                                  <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                                </svg>
                              </span>
                              <span>Reset</span>
                            </Button>
                            <Button
                              variant="filled"
                              className="bg-primary text-white"
                              type="submit"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                              loading={newVehicle.isLoading}
                            >
                              <span>
                                <IconDeviceFloppy size={22} strokeWidth={1.5} />
                              </span>
                              <span>Submit</span>
                            </Button>
                          </>
                        )}
                        {editMode === "detail" && (
                          <>
                            {(params?.type === "archived" &&
                              archivedVehicle?.data?.deletedAt) ||
                            (params?.type === "active" &&
                              vehicle?.data?.deletedAt) ? (
                              <>
                                <Confirmation
                                  type={"danger"}
                                  message={
                                    "Are you sure you want to delete it permanently?"
                                  }
                                  onYes={() =>
                                    deleteVehicle({
                                      id: `${params?.id}`,
                                      ownerId: currentVehicle?.data?.ownerId,
                                    }).then((response: any) => {
                                      if (response?.data) {
                                        navigate(-1);
                                      }
                                    })
                                  }
                                  header={`Permanent Delete confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={deleteResponse.isLoading}
                                    className={`bg-danger text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconTrash size={20} strokeWidth={1.5} />
                                    <span>Delete</span>{" "}
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={`Are you sure you want to restore it`}
                                  onYes={() =>
                                    restoreVehicle({
                                      id: `${params?.id}`,
                                      ownerId:
                                        params?.type === "archived"
                                          ? archivedVehicle?.data?.ownerId
                                          : vehicle?.data?.ownerId,
                                    })
                                  }
                                  header={`Restore confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={restoreResponse.isLoading}
                                    className={`bg-green-500 text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconArrowBackUp
                                      size={20}
                                      strokeWidth={1.5}
                                    />
                                    <span>Restore</span>
                                  </Button>
                                </Confirmation>
                              </>
                            ) : (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this vehicle?"
                                  }
                                  header={`Archive ${vehicle?.data?.plateNumber}`}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this vehicle?"
                                  }
                                  options={Constants.VehicleArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(reason: string) => {
                                    archiveVehicle({
                                      id: vehicle.data?.id,
                                      ownerId: vehicle.data?.ownerId,
                                      reason: reason,
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to " +
                                    (vehicle.data?.enabled
                                      ? "deactivate"
                                      : "activate") +
                                    " it?"
                                  }
                                  onYes={() => activateVehicle(`${params.id}`)}
                                  header={
                                    (vehicle.data?.enabled
                                      ? "Deactivate"
                                      : "Activate") + " Confirmation"
                                  }
                                >
                                  <Button
                                    variant="filled"
                                    loading={activateResponse.isLoading}
                                    className={`${
                                      vehicle.data?.enabled
                                        ? " bg-danger"
                                        : "bg-green-500"
                                    } text-white`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="fill-current h-6"
                                        viewBox="0 0 48 48"
                                      >
                                        <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                      </svg>
                                    </span>
                                    {vehicle.data?.enabled ? (
                                      <span>Deactivate</span>
                                    ) : (
                                      <span>Activate</span>
                                    )}
                                  </Button>
                                </Confirmation>

                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={newVehicle.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </Group>
                    </div>
                  </div>
                </form>
              </div>
            </Tabs.Panel>

            <Tabs.Panel value="document" pt="xs">
              <div className="flex-col w-full space-y-4">
                <VehicleFileUploadComponent editMode={editMode} />
              </div>
            </Tabs.Panel>
          </Tabs>
        </>
      </ActivityLogWrapperComponent>
    </div>
  );
}

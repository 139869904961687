import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Avatar,
  Button,
  Checkbox,
  Group,
  Input,
  LoadingOverlay,
  Radio,
  Select,
  Tabs,
  TextInput,
} from "@mantine/core";
import { useId } from "@mantine/hooks";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconFilePlus,
  IconTrash,
  IconUser,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { City } from "../../../models/city.model";
import { Owner } from "../../../models/owner.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetActivitiesQuery } from "../../../shared/store/query/sharedQuery";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import countryCodes from "../../../shared/utility/countryCodes.json";
import { useLazyGetCitiesQuery } from "../../city/store/city.query";
import {
  useActivateOwnerMutation,
  useArchiveOwnerMutation,
  useCreateOwnerMutation,
  useDeleteOwnerMutation,
  useLazyGetArchivedOwnerQuery,
  useLazyGetOwnerQuery,
  useRestoreOwnerMutation,
  useUpdateOwnerMutation,
} from "../store/owner.query";
import OwnerFileUploadComponent from "./owner-file-upload.component";

interface Props {
  editMode: string;
}

export default function NewOwnerComponent(props: Props) {
  const { editMode } = props;
  const id = useId();
  const params = useParams();
  const location = useLocation();
  const [logo, setLogo] = useState<any>();

  let schema: any = yup.object().shape({
    name: yup
      .string()
      .min(5, "Name length must be 5 and greater")
      .max(100, "Name length must be 100 and less")
      .required("Name is required"),
    email: yup
      .string()
      .email("Should be a valid email")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Phone number is not valid"
      ),
    gender: yup.string().test("required", "Gender is required", (value) => {
      return getValues("isCompany") || getValues("gender") !== ""
        ? true
        : false;
    }),
    tinNumber: yup
      .string()
      .matches(/^\d+$/, "TIN number is not valid")
      .min(6, "TIN number length must be 6 up to 15")
      .max(15, "TIN number length must be 6 up to 15")
      .required("TIN number is required"),
    address: yup
      .object()
      .shape({
        country: yup.string().required("Country is required"),
        city: yup.string().required("City is required"),
        subCity: yup.string(),
        woreda: yup.string(),
        houseNumber: yup.string().required("House Number is required"),
      })
      .required("Address is required"),
    website: yup.string().default(""),
    isCompany: yup.boolean().oneOf([true, false]).default(false),
  });

  const defaultValue: Owner = {
    id: "",
    name: "",
    email: "",
    tinNumber: 0,
    website: "",
    isCompany: false,
    address: {
      country: "Ethiopia",
      city: "",
      subCity: "",
      woreda: "",
      houseNumber: "",
    },
    gender: "",
    phoneNumber: "",
  };

  const [getCities, cities] = useLazyGetCitiesQuery();
  const [getOwner, owner] = useLazyGetOwnerQuery();
  const [createOwner, newOwner] = useCreateOwnerMutation();
  const [updateOwner, updateResponse] = useUpdateOwnerMutation();
  const [restoreOwner, restoredOwner] = useRestoreOwnerMutation();
  const [deleteOwner, deleteResponse] = useDeleteOwnerMutation();
  const [getActivities, activities] = useLazyGetActivitiesQuery();
  const [archiveOwner, archiveResponse] = useArchiveOwnerMutation();
  const [activateOwner, activateResponse] = useActivateOwnerMutation();
  const [getArchivedOwner, archivedOwner] = useLazyGetArchivedOwnerQuery();

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Owner>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("+251");
  const [data] = useState<any>(countryCodes);
  const [citySelection, setCitySelection] = useState<string>("");


  const [isCompanyClicked, setIsCompanyClicked] = useState<boolean>(false);


  useEffect(() => {
    getCities({
      skip: 0,
      top: 100,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
    })
  }, []);
  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type && params?.type === 'archived') {
        getArchivedOwner(`${params?.id}`).then((response: any) => {
          setLogo(response.data.logo);
          setIsCompanyClicked(response.data?.isCompany);
          reset(response.data);
          setUserId(response.data.id);
        });
      } else {
        getOwner(`${params?.id}`).then((response: any) => {
          setLogo(response.data.logo);
          setIsCompanyClicked(response.data?.isCompany);
          reset(response.data);
          setUserId(response.data.id);
        });
      }
    } else {
      reset(defaultValue);
      setIsCompanyClicked(false);
    }
  }, [params, editMode, getOwner]);

  useEffect(() => {
    if (isCompanyClicked) {
      setValue("gender", "");
    }
  }, [isCompanyClicked, setValue]);

  function onSubmit(data: any) {
    if (editMode === "new") {
      createOwner({
        ...data,
        phoneNumber: data.phoneNumber.split(" ").join(""),
      }).then((response: any) => {
        if (!response?.error) {
          reset(defaultValue);
        }
      });
    } else {
      const owner: Owner = {
        id: params.id,
        ...data,
        phoneNumber: data.phoneNumber.split(" ").join(""),
      };
      updateOwner(owner);
    }
  }
  const onError = (error: any) => {};


  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? (
            "New Owner"
          ) : (
            <div className="h-full space-x-2 flex items-center">
              {logo && (
                <Avatar
                  src={downloadUrlParser({
                    path: logo?.path,
                    filename: logo?.filename,
                    originalname: logo?.originalname,
                    mimetype: logo?.mimetype,
                  })}
                  alt="photo"
                />
              )}
              <div>{owner?.data?.name}</div>
            </div>
          )
        }
        item={owner}
        path={`/registration/owner/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            (editMode === "new"
              ? newOwner.isLoading
              : updateResponse.isLoading) || owner.isLoading
          }
        />
        <>
          <Tabs defaultValue="Vehicle">
            <Tabs.List>
              <Tabs.Tab value="Vehicle" icon={<IconUser size={14} />}>
                Basic owner info
              </Tabs.Tab>
              {editMode === "detail" && (
                <Tabs.Tab value="document" icon={<IconFilePlus size={14} />}>
                  Upload document
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="Vehicle" pt="xs">
              <div className="flex  justify-center h-full">
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="w-full gap-y-4 dark:text-white"
                >
                  <Accordion
                    defaultValue={["info", "address"]}
                    multiple
                    variant="separated"
                    classNames={{ control: "bg-secondary text-gray-700" }}
                  >
                    <Accordion.Item value="info">
                      <Accordion.Control>
                        Basic Owner Information
                      </Accordion.Control>
                      <Accordion.Panel>
                        <div className="flex-col space-y-4">
                          <div className="flex w-full space-x-4  justify-between">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={errors.name && `${errors?.name?.message}`}
                              withAsterisk
                              label="Name"
                              placeholder="Owner Name"
                              {...register("name")}
                            />
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.email && `${errors?.email?.message}`
                              }
                              withAsterisk
                              label="Email"
                              placeholder="email@example.com"
                              {...register("email")}
                            />
                          </div>
                          <div className="flex w-full space-x-4 justify-between">
                            <div className="flex w-full">
                              <Select
                                radius={"xs"}
                                searchable
                                nothingFound={
                                  <div className="fle w-full justify-center items-center">
                                    <EmptyIcon />
                                  </div>
                                }
                                onChange={(code) => {
                                  code && setCode(code);
                                }}
                                classNames={{
                                  input:
                                    "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                                }}
                                value={code}
                                label="Code"
                                data={data?.map((item: any) => ({
                                  label: `${item.name} (${item.dial_code})`,
                                  value: item.dial_code,
                                  key: item.name,
                                }))}
                                maxDropdownHeight={400}
                              />
                              <Input.Wrapper
                                id={id}
                                className="w-full"
                                label="Phone number"
                                required
                                error={
                                  errors.phoneNumber &&
                                  `${errors?.phoneNumber?.message}`
                                }
                              >
                                <Input
                                  classNames={{ input: "rounded-l-none" }}
                                  component={InputMask}
                                  mask={`${code} 999 999 999`}
                                  id={id}
                                  placeholder="Owner phone"
                                  {...register("phoneNumber")}
                                />
                              </Input.Wrapper>
                            </div>

                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.tinNumber &&
                                `${errors?.tinNumber?.message}`
                              }
                              withAsterisk
                              label="TIN Number"
                              type={"number"}
                              placeholder="2332325"
                              {...register("tinNumber")}
                            />
                          </div>
                          <div className="flex w-full justify-between mt-2">
                            <div className="flex w-full items-center">
                              <Checkbox
                                label="Check if it is company"
                                color={"dark"}
                                {...register("isCompany")}
                                onClick={() =>
                                  setIsCompanyClicked(!isCompanyClicked)
                                }
                              />
                            </div>
                            {!isCompanyClicked && (
                              <div className="px-4 w-full flex items-center justify-start">
                                <Radio.Group label="Gender" withAsterisk >
                                  <Group>

                                  <Radio
                                    value="male"
                                    label="Male"
                                    {...register("gender")}
                                  />
                                  <Radio
                                    value="female"
                                    label="Female"
                                    {...register("gender")}
                                  />
                                  </Group>
                                  
                                  <span className="text-red-500 text-xs">
                                    {errors.gender && errors.gender?.message}
                                  </span>
                                </Radio.Group>
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.website && `${errors?.website?.message}`
                              }
                              label="Website"
                              placeholder="www.example.com"
                              {...register("website")}
                            />
                          </div>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="address">
                      <Accordion.Control>Address</Accordion.Control>
                      <Accordion.Panel>
                        <div className="flex-col space-y-4">
                          <div className="flex w-full space-x-4  justify-between">
                            <Controller
                              control={control}
                              name="address.country"
                              render={({ field }) => (
                                <Select
                                  withAsterisk
                                  className="w-full"
                                  searchable
                                  nothingFound={
                                    <div className="fle w-full justify-center items-center">
                                      <EmptyIcon />
                                    </div>
                                  }
                                  error={
                                    errors.address?.country &&
                                    `${errors?.address?.country?.message}`
                                  }
                                  radius={"xs"}
                                  classNames={{
                                    label: "dark:text-white",
                                  }}
                                  defaultValue="Ethiopia"
                                  label="Country"
                                  data={data?.map((item: any) => ({
                                    label: `${item.name}`,
                                    value: item.name,
                                    key: item.name,
                                  }))}
                                  maxDropdownHeight={400}
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              control={control}
                              name="address.city"
                              render={({ field }) => (
                                <Select
                                  withAsterisk
                                  className="w-full"
                                  searchable
                                  nothingFound={
                                    <div className="fle w-full justify-center items-center">
                                      <EmptyIcon />
                                    </div>
                                  }
                                  error={
                                    errors.address?.city &&
                                    `${errors?.address?.city?.message}`
                                  }
                                  radius={"xs"}
                                  classNames={{
                                    label: "dark:text-white",
                                  }}
                                  defaultValue="Ethiopia"
                                  label="City"
                                  data={
                                    cities.data
                                      ? cities.data.data.map((item: City) => ({
                                          label: `${item.name}`,
                                          value: item.name ?? "",
                                        }))
                                      : []
                                  }
                                  maxDropdownHeight={400}
                                  {...field}
                                />
                              )}

                              />
                          </div>
                          <div className="flex w-full space-x-4 justify-between">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.address?.subCity &&
                                `${errors?.address?.subCity.message}`
                              }
                              label="Sub City"
                              placeholder="Kirkos"
                              {...register("address.subCity")}
                            />
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              error={
                                errors.address?.woreda &&
                                `${errors?.address?.woreda?.message}`
                              }
                              label="Woreda"
                              placeholder="7"
                              {...register("address.woreda")}
                            />
                          </div>
                          <div className="w-full flex space-x-4">
                            <TextInput
                              classNames={{ label: "dark:text-white" }}
                              className="w-full"
                              withAsterisk
                              error={
                                errors.address?.houseNumber &&
                                `${errors?.address?.houseNumber?.message}`
                              }
                              label="House Number"
                              placeholder="B234"
                              {...register("address.houseNumber")}
                            />
                          </div>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  </Accordion>
                  <div className="w-full flex justify-end items-center">
                    <Group position="right" mt="xl">
                      {editMode === "new" && (
                        <>
                          <Button
                            classNames={{ label: "flex space-x-1 " }}
                            variant="default"
                            className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                            type="reset"
                            size="xs"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current h-6"
                                viewBox="0 0 32 32"
                              >
                                <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                              </svg>
                            </span>
                            <span>Reset</span>
                          </Button>
                          <Button
                            variant="filled"
                            className="bg-primary text-white"
                            type="submit"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                            loading={newOwner.isLoading}
                          >
                            <span>
                              <IconDeviceFloppy size={22} strokeWidth={1.5} />
                            </span>
                            <span>Submit</span>
                          </Button>
                        </>
                      )}
                      {editMode === "detail" && (
                        <>
                          {(params?.type === "archived" &&
                            archivedOwner?.data?.deletedAt) ||
                          (params?.type === "active" &&
                            owner?.data?.deletedAt) ? (
                            <>
                              <Confirmation
                                type={"danger"}
                                message={
                                  "Are you sure you want to delete it permanently?"
                                }
                                onYes={() => deleteOwner(`${params?.id}`)}
                                header={`Permanent Delete confirmation `}
                              >
                                <Button
                                  variant="filled"
                                  loading={deleteResponse.isLoading}
                                  className={`bg-danger text-white flex space-x-2 items-center`}
                                  type="button"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                >
                                  <IconTrash size={20} strokeWidth={1.5} />
                                  <span>Delete</span>{" "}
                                </Button>
                              </Confirmation>
                              <Confirmation
                                type={"notify"}
                                message={`Are you sure you want to restore it`}
                                onYes={() => restoreOwner(`${params?.id}`)}
                                header={`Restore confirmation `}
                              >
                                <Button
                                  variant="filled"
                                  loading={restoredOwner.isLoading}
                                  className={`bg-green-500 text-white flex space-x-2 items-center`}
                                  type="button"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                >
                                  <IconArrowBackUp
                                    size={20}
                                    strokeWidth={1.5}
                                  />
                                  <span>Restore</span>
                                </Button>
                              </Confirmation>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Confirmation
                                type={"notify"}
                                message={
                                  "Are you sure you want to archive this owner?"
                                }
                                header={`Archive ${owner?.data?.name}`}
                                result={"single"}
                                resultRequired={true}
                                selectorLabel={"Please select a reason"}
                                labelDescription={
                                  "Why are you archiving this owner?"
                                }
                                options={Constants.OwnerArchiveReason.map((reason) => {
                                  return { label: reason, value: reason };
                                })}
                                customInput={true}
                                yesText={"Archive"}
                                onYes={(reason: string) => {
                                  archiveOwner({
                                    id: `${params.id}`,
                                    reason: reason,
                                  });
                                }}
                              >
                                <Button
                                  variant="filled"
                                  loading={archiveResponse.isLoading}
                                  className="bg-danger text-white"
                                  type="button"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                >
                                  <span>
                                    <IconArchive size={22} strokeWidth={1.5} />
                                  </span>
                                  <span>Archive</span>
                                </Button>
                              </Confirmation>
                              <Confirmation
                                type={"notify"}
                                message={
                                  <span>{`Are you sure you want to ${
                                    owner?.data?.enabled
                                      ? "Deactivate"
                                      : "Activate"
                                  } it`}</span>
                                }
                                onYes={() => activateOwner(`${params.id}`)}
                                header={`${
                                  owner.data?.enabled
                                    ? "Deactivation"
                                    : "Activation"
                                } Confirmation`}
                              >
                                <Button
                                  variant="filled"
                                  loading={activateResponse.isLoading}
                                  className={`${
                                    owner.data?.enabled
                                      ? " bg-danger"
                                      : "bg-green-500"
                                  } text-white`}
                                  type="button"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="fill-current h-6"
                                      viewBox="0 0 48 48"
                                    >
                                      <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                    </svg>
                                  </span>
                                  {owner?.data?.enabled ? (
                                    <span>Deactivate</span>
                                  ) : (
                                    <span>Activate</span>
                                  )}
                                </Button>
                              </Confirmation>
                              <Button
                                variant="filled"
                                className="bg-primary text-white"
                                type="submit"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                                loading={updateResponse.isLoading}
                              >
                                <span>
                                  <IconDeviceFloppy
                                    size={22}
                                    strokeWidth={1.5}
                                  />
                                </span>
                                <span>Update</span>
                              </Button>
                            </>
                          )}
                        </>
                      )}
         
                    </Group>
                  </div>
                </form>
              </div>
            </Tabs.Panel>

            <Tabs.Panel value="document" pt="xs">
              <div className="flex-col w-full space-y-4">
                <OwnerFileUploadComponent />
              </div>
            </Tabs.Panel>
          </Tabs>
        </>
      </ActivityLogWrapperComponent>
    </div>
  );
}

export const PROVIDER_ENDPOINT={
    list:`${process.env.REACT_APP_API}/drivers/get-drivers`,
    create:`${process.env.REACT_APP_API}/drivers/create-driver`,
    provider:`${process.env.REACT_APP_API}/drivers/get-driver`,
    unassignedVehicles:`${process.env.REACT_APP_API}/owners/get-unassigned-vehicles`,
    delete:`${process.env.REACT_APP_API}/drivers/delete-driver`,
    update:`${process.env.REACT_APP_API}/drivers/update-driver`,
    archive:`${process.env.REACT_APP_API}/drivers/archive-driver`,
    archived_providers:`${process.env.REACT_APP_API}/drivers/get-archived-drivers`,
    archived_provider:`${process.env.REACT_APP_API}/drivers/get-archived-driver`,
    restore_provider:`${process.env.REACT_APP_API}/drivers/restore-driver`,
    activate: `${process.env.REACT_APP_API}/drivers/activate-or-block-driver`,
    create_bank_account:`${process.env.REACT_APP_API}/drivers/add-driver-bank-account`,
    create_sub_account:`${process.env.REACT_APP_API}/drivers/add-driver-sub-account`,
    update_bank_account:`${process.env.REACT_APP_API}/drivers/update-driver-bank-account`, 
    remove_bank_account:`${process.env.REACT_APP_API}/drivers/remove-driver-bank-account`,
    archive_bank_account:`${process.env.REACT_APP_API}/drivers/archive-driver-bank-account`,
    bank_account_list:`${process.env.REACT_APP_API}/drivers/get-bank-accounts`,
    archived_bank_account_list:`${process.env.REACT_APP_API}/drivers/get-archived-bank-accounts`,
    bank:`${process.env.REACT_APP_API}/drivers/get-bank-account`,
    restore_bank_Account:`${process.env.REACT_APP_API}/drivers/restore-driver-bank-account`,
    update_document:`${process.env.REACT_APP_API}/drivers/update-document`,
    download_file:`${process.env.REACT_APP_API}/download/file`,
    chappa_bank_list: `${process.env.REACT_APP_API}/get-banks`,

    getProviderKidAssignment:`${process.env.REACT_APP_API}/groups/get-group-assignments`,

}
import { Loader } from "@googlemaps/js-api-loader";

type CDProps = { lng: number; lat: number };

export async function CalculateDistance(A: CDProps, B: CDProps):Promise<number> {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "",
  });

  let distance = 0;

  await loader.load().then(async () => {
    // build request
    const origin = { lat: parseFloat(String(A.lat)), lng: parseFloat(String(A.lng)) };
    const destination = { lat: parseFloat(String(B.lat)), lng: parseFloat(String(B.lng)) };

    const request = {
      origins: [origin],
      destinations: [destination],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };

    const service = new google.maps.DistanceMatrixService();

    distance = await service.getDistanceMatrix(request).then((response) => {      
      return response.rows[0].elements[0].distance.value;
    }).catch(() => {return 0});
  });

  return distance;
}
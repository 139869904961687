import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ChatPage from "../page/chat-page";
export const ChatRoute: RouteObject = {
  path: "/chat",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <ChatPage />
    </AuthGuard>
  ),
};

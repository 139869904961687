import { Collection, CollectionQuery } from "../../../models/collection.model";
import { DriverFee } from "../../../models/driver-fee.model";
import { Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { RoutePrice } from "../../../models/route-price.model";
import { RouteStation } from "../../../models/route-station.model";
import { Route } from "../../../models/route.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { ROUTE_ENDPOINT } from "../route-assignment.endpoint";

let routeCollection: CollectionQuery;
let routeAssignmentCollection: CollectionQuery;
let archivedRouteCollection: CollectionQuery;
let routeBusStationsCollection: { query: CollectionQuery; id: string };
let routePricesCollection: { query: CollectionQuery; routeId: string };

const routeAssignmentQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.archivedRoute}/${id}`,
        method: "get",
      }),
    }),

    getArchivedRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROUTE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedRouteCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    getRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROUTE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    getRoutePrices: builder.query<
      Collection<RoutePrice>,
      { query: CollectionQuery; routeId: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_prices}/${data.routeId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routePricesCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    getRouteStations: builder.query<
      Collection<RouteStation>,
      { query: CollectionQuery; id: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_stations}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeBusStationsCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),

    createRoute: builder.mutation<Route, Route>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permissions: "manage-routes",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRoute: builder.mutation<Route, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permissions: "manage-routes",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateRoute: builder.mutation<Route, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update}`,
        method: "put",
        data: { ...newData, isActive: !newData.isActive },
        permissions: "manage-routes",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRoute: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.delete}/${id}`,
        method: "delete",
        permissions: "manage-routes",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getArchivedRoutes",
                archivedRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((route) => route.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreRoute: builder.mutation<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.restore}/${id}`,
        method: "post",
        permissions: "manage-routes",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully restored");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getArchivedRoutes",
                archivedRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((route) => route.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );

            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addRoutePrice: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_prices}`,
        method: "post",
        data: newData,
        permissions: "manage-routes",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getRouteAssignment: builder.query<RouteAssignment, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignment}/${id}`,
        method: "GET",
      }),
    }),

    addRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_assignment}`,
        method: "post",
        data: newData,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addBulkRouteAssignment: builder.mutation<RouteAssignment[], any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_bulk_route_assignment}`,
        method: "post",
        data: newData,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    data.map((item) => {
                      draft.data.push(item);
                    });
                    draft.count += data.length;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addRouteStations: builder.mutation<RouteStation, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_stations}`,
        method: "post",
        data: newData,
        permissions: "manage-route-station",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_route_assignment}`,
        method: "put",
        data: newData,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    startRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (id: any) => ({
        url: `${ROUTE_ENDPOINT.startAssignment}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully started");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    completeRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (id: any) => ({
        url: `${ROUTE_ENDPOINT.completeAssignment}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully completed");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    cancelRouteAssignment: builder.mutation<
      RouteAssignment,
      { id: string; cancelledReason: { reason: string } }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.cancel_route_assignment}`,
        method: "post",
        data: data,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRouteAssignment: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.delete_route_assignment}/${id}`,
        method: "delete",
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (assignment) => assignment.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getDriverFees: builder.query<Collection<DriverFee>, CollectionQuery>({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.driver_fee_list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      providesTags:['payment_status']
    }),

    getDriverFee: builder.query<DriverFee, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.driver_fee}/${id}`,
        method: "get",
      }),
      providesTags:['payment_status']
    }),

    addAssignmentPayment: builder.mutation<
      boolean,
      { paymentMethod: string; driverId: string; assignmentIds: string[] }
    >({
      query: (data: any) => ({
        url: `${ROUTE_ENDPOINT.pay_for_assignments}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully submitted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((assignment) => {
                      return (
                        param.assignmentIds.indexOf(
                          assignment.id ?? "ignore"
                        ) === -1
                      );
                    });
                    draft.count -= param.assignmentIds.length;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getRouteAssignments: builder.query<
      Collection<RouteAssignment>,
      CollectionQuery
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignments}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeAssignmentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getDriverAssignments: builder.query<any, any>({
      query: (data: { collection: CollectionQuery; id: string }) => ({
        url: `${ROUTE_ENDPOINT.driver_assignments}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
    }),
    getRouteDrivers: builder.query<
      Collection<Provider>,
      { query: CollectionQuery; routeId: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_drivers}/${data.routeId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
    }),
    paymentForMultipleAssignment: builder.mutation<any, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.payment_for_multiple_assignments}`,
        method: "post",
        data: newData,
        responseType: "arraybuffer",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    driversFeeStatusUpdate: builder.mutation<
      any,
      {
        driverFeeIds: string[];
        paymentStatus: string;
        transactionNumber?: string;
      }
    >({
      query: (data: any) => ({
        url: `${ROUTE_ENDPOINT.driver_fee_status_update}`,
        method: "post",
        data: data,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
     invalidatesTags:['payment_status']
    }),
   
  }),
  overrideExisting: true,
});

export const {
  useLazyGetRouteQuery,
  useLazyGetRouteStationsQuery,
  useLazyGetRoutePricesQuery,
  useLazyGetArchivedRouteQuery,
  useLazyGetRoutesQuery,
  useLazyGetArchivedRoutesQuery,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useActivateRouteMutation,
  useRestoreRouteMutation,
  useDeleteRouteMutation,
  useAddRoutePriceMutation,
  useAddAssignmentPaymentMutation,

  useStartRouteAssignmentMutation,
  useCompleteRouteAssignmentMutation,

  useAddBulkRouteAssignmentMutation,
  useAddRouteAssignmentMutation,
  useCancelRouteAssignmentMutation,
  useAddRouteStationsMutation,
  useUpdateRouteAssignmentMutation,
  useDeleteRouteAssignmentMutation,
  useLazyGetRouteAssignmentQuery,
  useLazyGetRouteAssignmentsQuery,
  useLazyGetDriverFeesQuery,
  useLazyGetDriverFeeQuery,
  useLazyGetRouteDriversQuery,

  useLazyGetDriverAssignmentsQuery,
  useDriversFeeStatusUpdateMutation,
  usePaymentForMultipleAssignmentMutation,
} = routeAssignmentQuery;

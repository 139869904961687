export const REQUEST_ENDPOINT={
    //request-endpoint
    detail:`${process.env.REACT_APP_API}/requests/get-request`,
    delete:`${process.env.REACT_APP_API}/requests/delete-request`,
    archive:`${process.env.REACT_APP_API}/requests/archive-request`,
    restore:`${process.env.REACT_APP_API}/requests/restore-request`,
    archivedList:`${process.env.REACT_APP_API}/requests/get-archived-requests`,
    archivedRequest:`${process.env.REACT_APP_API}/requests/get-archived-request`,
    list:`${process.env.REACT_APP_API}/requests/get-requests-for-dashboard`,
    create:`${process.env.REACT_APP_API}/requests/create-request-for-dashboard`,
    update:`${process.env.REACT_APP_API}/requests/update-request`,

    assignDriverForRequest: `${process.env.REACT_APP_API}/requests/assign-driver`,
    payForRequestManually: `${process.env.REACT_APP_API}/request-payments/pay-for-request-manually`
}

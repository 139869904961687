import { ActionIcon, Button, LoadingOverlay, Table } from "@mantine/core";
import { IconArrowBadgeRight, IconTrash } from "@tabler/icons";
import { debounce } from "lodash-es";
import { Suspense, lazy, useEffect, useState } from "react";
import { CollectionQuery } from "../../../models/collection.model";
import { Permission } from "../../../models/permission.model";
import { Role } from "../../../models/role.model";
import Card from "../../../shared/component/Card/card-component";
import { CollectionSelectorConfig } from "../../../shared/component/collection-selector/model/collection-selector-config";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import {
  useCreateRolePermissionsMutation,
  useLazyGetAvailableRolePermissionsQuery,
  useLazyGetPermissionsQuery,
  useLazyGetRolesQuery,
  useRemoveRolePermissionsMutation,
} from "../store/role.query";

const ModalCollectionSelector = lazy(
  () =>
    import(
      "../../../shared/component/collection-selector/components/modal-collection-selector"
    )
);

const collectionSelectorModalConfig: CollectionSelectorConfig = {
  multiple: true,
  size: "40%",
  identity: "id",
  visibleColumn: [{ key: "name", name: "Name" }],
  primaryColumn: { key: "name", name: "Name" },
};

export function RolePage() {
  const [getPermissions, permissions] = useLazyGetPermissionsQuery();
  const [createRolePermission, createResponse] =
    useCreateRolePermissionsMutation();
  const [getRoles, roles] = useLazyGetRolesQuery();
  const [removeRolePermission, removeResponse] =
    useRemoveRolePermissionsMutation();

  const [selectedRole, setSelectedRole] = useState<Role>();
  const [loadingId, setLoadingId] = useState<string>("");
  const [permissionModalOpen, setPermissionModalOpen] =
    useState<boolean>(false);
  const [getRolePermissions, rolePermissions] =
    useLazyGetAvailableRolePermissionsQuery();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 15,
  });
  const [collectionRequest, setCollectionRequest] = useState<CollectionQuery>({
    skip: 0,
    top: 15,
  });

  useEffect(() => {
    getRoles({
      skip: 0,
      orderBy: [{ field: "name", direction: "asc" }],
      filter: [[{ field: "protected", value: false, operator: "=" }]],
    });
  }, [getRoles]);

  useEffect(() => {
    getPermissions(collection);
    async function getRoleData() {
      const role = await getRoles({
        skip: 0,
        orderBy: [{ field: "name", direction: "asc" }],
        filter: [[{ field: "protected", value: false, operator: "=" }]],
      });
      if (role.data && !selectedRole) {
        setSelectedRole(role.data?.data[0]);
      }
    }
    getRoleData();
  }, [collection]);

  useEffect(() => {
    if (selectedRole) {
      getRolePermissions({
        roleId: selectedRole?.id,
        collection: collectionRequest,
      });
    }
  }, [selectedRole, collectionRequest]);

  const title = (
    <div className="w-full h-8 items-center px-4 bg-primary text-white flex justify-between">
      <div>Permissions</div>
      <div>
        <Button
          leftIcon={<IconArrowBadgeRight />}
          size="xs"
          onClick={() => setPermissionModalOpen(true)}
          className="bg-primary border border-white"
        >
          Assign
        </Button>
      </div>
    </div>
  );

  return (
    <div className="m-4">
      <Card
        title={"Add Permission to roles"}
        classNames={{ header: "w-full bg-primary text-white", body: "" }}
      >
        <div className="w-full flex justify-center space-x-8 p-4">
          <div className="w-2/4 h-full">
            <Table className=" border">
              <thead>
                <tr>
                  <th className="bg-primary text-white h-12">
                    <div className="w-full flex justify-between items-center">
                      <div>Role</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {roles?.data?.data?.map((role) => {
                  return (
                    <tr>
                      <td
                        onClick={() => setSelectedRole(role)}
                        className={`cursor-pointer hover:bg-primary  hover:text-white ${
                          selectedRole?.id === role.id
                            ? "bg-primary text-white"
                            : ""
                        }`}
                      >
                        {role.name}
                      </td>
                    </tr>
                  );
                })}
                {roles?.isLoading && (
                  <tr>
                    <td>
                      <LoadingOverlay visible />
                      <EmptyIcon />
                    </td>
                  </tr>
                )}
                {roles?.data?.data?.length === 0 && (
                  <tr>
                    <td>
                      <EmptyIcon />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="w-80 border">
            {/* <CollectionSelector
          title={title}
          config={collectionSelectorModalConfig}
          total={roles.data?.count}
          items={roles.data?.data}
          loading={roles.isLoading || roles?.isFetching}
          paginationChange={(skip: number, top: number) =>
            setCollection({ ...collection, skip: skip, top: top })
          }
          onCheck={(data: any) => {
            
          }}
          search={debounce((key: string) => {
            setCollection({
              ...colection,
              search: key,
              searchFrom: ["name"],
            });
          }, 1000)}
        /> */}
            <Table className="relative border">
              <LoadingOverlay visible={rolePermissions?.isFetching} />
              <thead>
                <tr>
                  <th className="bg-primary text-white">{title}</th>
                </tr>
              </thead>
              <tbody>
                {rolePermissions?.data?.data?.map((permission: Permission) => {
                  return (
                    <tr>
                      <td className="flex justify-between">
                        <span>{permission.name}</span>
                        <ActionIcon
                          onClick={() => {
                            setLoadingId(permission.id);
                            removeRolePermission({
                              roleId: selectedRole?.id,
                              permissionId: permission?.id,
                            });
                          }}
                          loading={
                            removeResponse?.isLoading &&
                            loadingId === permission.id
                          }
                          color={"red"}
                          variant="default"
                          className="text-danger"
                        >
                          <IconTrash size="1rem" />
                        </ActionIcon>
                      </td>
                    </tr>
                  );
                })}
                {rolePermissions?.isLoading && (
                  <tr>
                    {" "}
                    <td>
                      <LoadingOverlay visible />
                      <EmptyIcon />
                    </td>
                  </tr>
                )}
                {rolePermissions?.data?.data?.length === 0 && (
                  <tr>
                    <td>
                      <EmptyIcon />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="w-full flex justify-end mt-4 mb-4 px-2">
              <Pagination
                total={rolePermissions?.data?.count ?? 0}
                pageSize={[5, 10, 15]}
                defaultPageSize={15}
                onPaginationChange={(skip: number, top: number) => {
                  setCollectionRequest({ skip: skip, top: top });
                }}
              />
            </div>
          </div>
          <Suspense>
            <ModalCollectionSelector
              config={collectionSelectorModalConfig}
              hasSort={true}
              selectedRows={rolePermissions?.data?.data}
              total={permissions.data?.count}
              items={permissions.data?.data?.filter((permission) => {
                return !rolePermissions?.data?.data?.some(
                  (rolePermission) => {
                    return rolePermission.id === permission.id;
                  }
                );
              }
              )}
              
              itemsLoading={permissions.isLoading || permissions?.isFetching}
              onPaginationChange={(skip: number, top: number) =>
                setCollection({ ...collection, skip: skip, top: top })
              }
              onDone={(data) => {
                const selectedId = data.map((permission: Permission) => {
                  return permission.id;
                });
                createRolePermission({
                  roleId: selectedRole?.id,
                  permissions: selectedId,
                });
              }}
              setModalOpened={(data) => {
                setPermissionModalOpen(data);
              }}
              modalOpened={permissionModalOpen}
              search={debounce((key) => {
                setCollection({
                  ...collection,
                  search: key,
                  searchFrom: ["name", "key"],
                });
              }, 1000)}
            />
          </Suspense>
        </div>
      </Card>
    </div>
  );
}

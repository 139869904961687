import {
  Button,
  Checkbox,
  Divider,
  Group,
  Modal,
  Radio,
  Stack,
  Textarea,
  Title,
} from "@mantine/core";
import { ReactNode, useState } from "react";

interface Props {
  type?: "notify" | "danger";

  icon?: ReactNode;
  header: string;
  subHeader?: string;
  headerAlignment?: "left" | "center" | "right";
  message: any;

  selectorLabel?: string;
  labelDescription?: string;

  result?: "single" | "multiple";
  resultRequired?: boolean;
  options?: { label: string; value: string }[];

  customInput?: boolean;

  onYes: Function;
  yesText?: string;
  cancelOption?: boolean;
  onNo?: Function;
  noText?: string;

  loading?: boolean;

  children: ReactNode;

  optionalConfirmation?: boolean;
  optionalStatus?: boolean;

  size?: string;
}
export default function Confirmation(props: Props) {
  const [inputData, setInputData] = useState("");
  const [radioValue, setRadioValue] = useState<string>();
  const [checkBoxValue, setCheckBoxValue] = useState<string[]>([]);
  const [opened, setOpened] = useState(false);
  const [error, setError] = useState("");

  function getResult() {
    if (props.result === "single") {
      if (props.resultRequired && (radioValue || inputData)) {
        return radioValue ?? inputData;
      } else {
        setError("Please select an item!");
        return -1;
      }
    } else if (props.result === "multiple") {
      if (props.resultRequired && (checkBoxValue.length > 0 || inputData)) {
        if (inputData) {
          checkBoxValue.push(inputData);
        }
        return checkBoxValue;
      } else {
        setError("Please select at least one item or write your input!");
        return -1;
      }
    }
    return undefined;
  }

  return (
    <>
      <Modal
        opened={opened}
        size={props.size ?? "md"}
        className={
          props.type === "notify" ? "border-yellow-500" : "border-red-500"
        }
        onClose={() => setOpened(false)}
        title={props?.header}
      >
        {/*Modal Header*/}
        <Group
          position={props.headerAlignment ?? "left"}
          className={"-mt-4 mb-3"}
        >
          <Stack
            align={
              props.headerAlignment === "left"
                ? "flex-start"
                : props.headerAlignment === "center"
                ? "center"
                : "flex-end"
            }
          >
            {props.icon && <div className={"mb-3"}>{props.icon}</div>}
            {props.subHeader && <Title order={6}>{props.subHeader}</Title>}
          </Stack>
        </Group>

        {/*Modal Content*/}
        {props.message && <div className={"my-3"}>{props.message}</div>}

        {/*Single result options*/}
        {props.result === "single" && props.options && (
          <div className={"overflow-x-auto mx-2"}>
            <Radio.Group
              value={radioValue}
              onChange={setRadioValue}
              label={props.selectorLabel}
              description={props.labelDescription}
              withAsterisk
            >
              <Group
                position="left"
                className="flex flex-col my-4 space-y-2"
                align="flex-start"
              >
                {props.options.map((option, index) => {
                  return (
                    <Radio
                      key={index}
                      value={option.value}
                      label={option.label}
                    />
                  );
                })}
              </Group>
            </Radio.Group>
          </div>
        )}

        {/*Multiple result options*/}
        {props.result === "multiple" && props.options && (
          <div className={"overflow-x-auto mx-2"}>
            <Checkbox.Group
              value={checkBoxValue}
              onChange={setCheckBoxValue}
              label={props.selectorLabel}
              description={props.labelDescription}
              withAsterisk
            >
              <Group position="left" className="my-4 space-y-2">
                {props.options.map((option, index) => {
                  return (
                    <Checkbox
                      key={index}
                      value={option.value}
                      label={option.label}
                    />
                  );
                })}
              </Group>
            </Checkbox.Group>
          </div>
        )}

        {/*Custom Input*/}
        {props.customInput && (
          <Textarea
            classNames={{ label: "text-xs semibold" }}
            size="sm"
            label="Or other"
            value={inputData}
            onChange={(event) => setInputData(event.target.value)}
          />
        )}
        <Divider className={"my-3"} />

        {/*Modal Footer*/}
        {/*Modal Footer Error*/}
        {error && (
          <div className={"font-semibold text-danger my-2"}>* {error}</div>
        )}
        {/*Modal Footer Buttons*/}
        <Group position={"right"}>
          {!props.cancelOption && (
            <Button
              variant="default"
              className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
              classNames={{ label: "flex space-x-1" }}
              size={"xs"}
              onClick={() => {
                setOpened(false);
                if (props.onNo) {
                  props.onNo();
                }
              }}
            >
              <span>{props.noText ?? "Cancel"}</span>
            </Button>
          )}

          {props.onYes && (
            <Button
              variant="filled"
              className={`bg-${
                props.type === "danger" ? "danger" : "primary"
              } text-white`}
              classNames={{ label: "flex space-x-1" }}
              size={"xs"}
              loading={props.loading}
              onClick={() => {
                const result = getResult();
                if (result !== -1) {
                  setOpened(false);
                  if (result) {
                    props.onYes(result);
                  } else {
                    props.onYes();
                  }
                }
              }}
            >
              <span>{props.yesText ?? "OK"}</span>
            </Button>
          )}
        </Group>
      </Modal>
      <div
        onClick={() => {
          if (props.optionalConfirmation && !props.optionalStatus) {
            props.onYes();
          } else {
            setOpened(true);
          }
        }}
      >
        {props.children}
      </div>
    </>
  );
}

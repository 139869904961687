export const OWNER_ENDPOINT = {
  list: `${process.env.REACT_APP_API}/owners/get-owners`,
  owner: `${process.env.REACT_APP_API}/owners/get-owner`,
  create: `${process.env.REACT_APP_API}/owners/create-owner`,
  delete: `${process.env.REACT_APP_API}/owners/delete-owner`,
  update: `${process.env.REACT_APP_API}/owners/update-owner`,
  archive: `${process.env.REACT_APP_API}/owners/archive-owner`,
  archived_owners: `${process.env.REACT_APP_API}/owners/get-archived-owners`,
  archived_owner: `${process.env.REACT_APP_API}/owners/get-archived-owner`,
  restore_owner: `${process.env.REACT_APP_API}/owners/restore-owner`,
  activate: `${process.env.REACT_APP_API}/owners/activate-or-block-owner`,
  vehicles: `${process.env.REACT_APP_API}/owners/get-vehicles`,
  restore_owner_vehicle: `${process.env.REACT_APP_API}/owners/restore-owner-vehicle`,
  activities: `${process.env.REACT_APP_API}/activities/get-activities`,
  update_document: `${process.env.REACT_APP_API}/owners/update-document`,
};

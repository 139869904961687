import { Text } from "@mantine/core";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Route } from "../../../models/route.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetRoutesQuery } from "../store/route.query";

export default function RoutePage() {
  const params = useParams();
  const location = useLocation();

  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["city"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getRoutes, routes] = useLazyGetRoutesQuery();

  useEffect(() => {
    getRoutes(collection).then((routes) => {
      const data: SetStateAction<Filter[]> = [];
      //add any new city to cityList
      routes.data?.data?.map((route) => {
        const cityName = route?.city?.name;
        if (cityName) {
          if (Object.keys(cityList).indexOf(cityName) == -1) {
            cityList[cityName] = route.cityId;
          }
        }
      });
      // setup filter from the cityList
      Object.keys(cityList).map((key) => {
        data.push({
          field: "cityId",
          value: cityList[key],
          name: key,
          operator: "=",
        });
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/route/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Route[] | undefined = routes.data?.data;
  const config: EntityConfig<Route> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/settings-and-configurables/route",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (route) => {
          return (
            <Text size="sm" className={route?.deletedAt ? "text-danger" : ""}>
              {route.name}
            </Text>
          );
        },
      },
      {
        key: "",
        name: "City",
        render: (value) => value?.city?.name ?? " ---- ",
      },
      {
        key: "difficultyLevel",
        name: "Difficulty Level",
        render: (value) => Constants.Levels(value.difficultyLevel),
      },
      {
        key: "trafficJamLevel",
        name: "Traffic Jam Level",
        render: (value) => Constants.Levels(value.trafficJamLevel),
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      {
        key: "totalDistance",
        name: "Total Distance",
        render: (value) => `${value?.totalDistance?.toFixed(2) ?? 0} KM`,
      },

      { key: "isActive", name: "Status" },
    ],
    filter: [filterData],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Routes"}
        total={routes?.data?.count}
        itemsLoading={routes.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

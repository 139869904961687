import { forwardRef } from "react";
import { Group, Loader } from "@mantine/core";

export default function SelectItem(loading: boolean) {
  return forwardRef<HTMLDivElement, any>(
    ({ label, value, ...others }: any, ref) => {
      if (loading) {
        return (
          <div ref={ref} {...others}>
            <Group noWrap>
              <Loader size={"xs"} />
              <div className={"text-zinc-900"}>Loading</div>
            </Group>
          </div>
        );
      } else {
        return (
          <div ref={ref} {...others}>{label}</div>
        );
      }
    }
  );
}

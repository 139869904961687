import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedGroupComponent from "../component/archived-group-component";
import NewGroupComponent from "../component/new-group-component";
import GroupPage from "../page/group-page";
export const GroupRoute: RouteObject = {
  path: "/registration/group",
  element: (
    <AuthGuard role={['super_admin',"admin",'operator']}>
      <GroupPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewGroupComponent editMode="detail" /> },
    { path: ":id", element: <NewGroupComponent editMode="new" /> },
    { path: "archived", element: <ArchivedGroupComponent /> },
  ],
};

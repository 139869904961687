import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Badge,
  Button,
  Group,
  List,
  LoadingOverlay,
  Select,
  Stack,
  Tabs,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconCheckbox,
  IconDeviceFloppy,
  IconSearch,
  IconTrash
} from "@tabler/icons";
import dateFormat from "dateformat";
import { debounce } from "lodash-es";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { CollectionQuery } from "../../../models/collection.model";
import { DriverAssignment } from "../../../models/driver-assignment.model";
import { Group as GroupModel } from "../../../models/group.model";
import { Candidate, Job, JobStatus } from "../../../models/job.model";
import { Route } from "../../../models/route.model";
import AuthContext from "../../../shared/auth/context/authContext";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Location } from "../../../shared/component/Map/map-component";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetRoutesQuery } from "../../route/store/route.query";
import {
  useArchiveJobMutation,
  useCreateDriverAssignmentMutation,
  useCreateJobMutation,
  useDeleteJobMutation,
  useLazyGetArchivedJobQuery,
  useLazyGetCandidatesQuery,
  useLazyGetDriverAssignmentsForJobQuery,
  useLazyGetJobQuery,
  useLazyGetUnassignedGroupsQuery,
  useOpenOrCloseJobMutation,
  useRemoveCandidateMutation,
  useRestoreJobMutation,
  useUnassignDriverMutation,
  useUpdateJobMutation,
} from "../store/job.query";

export interface Props {
  editMode: string;
}

export default function NewJobComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();

  const defaultValue: Job = {
    id: "",
    title: "",
    startLocation: "",
    description: "",
    groupId: "",
    maxApplicantNumber: 0,
    minimumRating: 0,
    routePreference: "",
  };

  const nameRegEx =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'\-]*)$/gi;
  let schema = Yup.object().shape({
    title: Yup.string()
      .typeError("Title should be a string")
      .required("Title is required"),
    maxApplicantNumber: Yup.number()
      .typeError("Maximum Applicant Number should be a number")
      .required("Maximum Applicant Number is required"),
    minimumRating: Yup.number()
      .typeError("Minimum rating should be a number")
      .min(1, "Minimum rating should be greater than 0")
      .max(5, "Minimum rating should be less than or equal to 5")
      .required("Minimum rating is required"),
    startLocation: Yup.string()
      .min(3, "Start Location should have at least 5 characters")
      .max(100, "Start Location should not be more than 100 characters")
      .matches(nameRegEx, "Remove invalid characters")
      .required("Start Location is required"),
    description: Yup.string().required("Description is required"),
    groupId: Yup.string().typeError("Group is required").required("Group is required"),
  });

  const Auth = useContext(AuthContext);
  const [getJob, job] = useLazyGetJobQuery();
  const [getArchivedJob, archivedJob] = useLazyGetArchivedJobQuery();
  const [getCandidates, candidates] = useLazyGetCandidatesQuery();
  const [createJob, createResponse] = useCreateJobMutation();
  const [updateJob, updateResponse] = useUpdateJobMutation();
  const [assignDriver, assignResponse] = useCreateDriverAssignmentMutation();
  const [removeCandidate, removeResponse] = useRemoveCandidateMutation();
  const [unassignDriver, unassignDriverResponse] = useUnassignDriverMutation();
  const [openOrCloseJob, openOrCloseJobResponse] = useOpenOrCloseJobMutation();
  const [getGroups, groups] = useLazyGetUnassignedGroupsQuery();
  const [restoreJob, restoreCall] = useRestoreJobMutation();
  const [deleteJob, deleteCall] = useDeleteJobMutation();

  const [archiveJob, archiveResponse] = useArchiveJobMutation();

  const [getRoutes, routes] = useLazyGetRoutesQuery();

  const [getAssignment, assignment] = useLazyGetDriverAssignmentsForJobQuery();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<Job>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [searchParam, setSearchParam] = useState<string>("");

  const locationDefault = { lat: 8.993441, lng: 38.7663447 };

  const [location, setLocation] = useState<Location>(locationDefault);

  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [preferredRoute, setPreferredRoute] = useState<string | null>(null);
  const [preferredDate, setPreferredDate] = useState<string | null>(null);
  const [minimumRating, setMinimumRating] = useState<string | null>(null);
  const [getDrivers, drivers] = useLazyGetProvidersQuery();

  const [routeSearch, setRouteSearch] = useState<string>("");
  const [driverSearch, setDriverSearch] = useState<string>("");

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 100,
    includes: ["driver", "driver.preferences"],
  });

  const [routeCollection, setRouteCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 100,
  });

  const [groupCollection, setGroupCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    searchFrom: ["name"],
    filter: [[{ field: "driverId", operator: "isNull", value: "" }]],
  });

  const [candidateCollection, setCandidateCollection] =
    useState<CollectionQuery>({
      skip: 0,
      top: 10,
      includes: ["job", "job.group"],
    });

  const [assignmentCollection, setAssignmentCollection] =
    useState<CollectionQuery>({
      skip: 0,
      top: 100,
    });

  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["preferences"],
    filter: [
      [{ field: "vehicleId", value: "", operator: "notNull" }],
      [{ field: "enabled", value: true, operator: "=" }],
      [{ field: "name", value: driverSearch, operator: "like" }],
    ],
  });

  function resetForm() {
    reset(defaultValue);
  }

  useEffect(() => {

    if(editMode === "detail"){
      getAssignment({
        ...assignmentCollection,
        filter: [[{ field: "jobId", operator: "=", value: params.id }]],
        includes: [
          "driver",
          "driver.vehicle",
          "vehicle.category",
          "driver.preferences",
        ],
      });
    }
  }, [assignmentCollection, params.id]);

  useEffect(() => {
    if (editMode === "detail") {
      reset(defaultValue);

      if (params?.type === "archived") {
        getArchivedJob(`${params?.id}`).then((response: any) => {
          reset(response.data);
        });
      } else {
        getJob(`${params?.id}`).then((response: any) => {
          reset(response.data);
        });
      }
    } else {
      resetForm();
    }
  }, [params.id, editMode, getJob, updateJob]);

  useEffect(() => {
    getGroups(groupCollection);
  }, [groupCollection]);

  useEffect(() => {
    getRoutes(routeCollection);
  }, [routeCollection]);

  useEffect(() => {
    if (editMode == "detail") {
      if (params.id) {
        getCandidates({
          id: params.id,
          collection: {
            ...candidateCollection,
            routeId: preferredRoute ?? "",
            datePreference: preferredDate ?? "",
            averageRate: minimumRating ?? "",
            filter: [[{ field: "jobId", operator: "=", value: params.id }]],
          },
        });
      }
    }
  }, [
    candidateCollection,
    getCandidates,
    params.id,
    minimumRating,
    preferredDate,
    preferredRoute,
  ]);

  function onSubmit(data: any) {
    if (editMode === "new") {
      createJob(data)
        .unwrap()
        .then((result) => {
          reset(defaultValue);
        });
    } else {
      const job: Job = {
        id: params.id,
        ...data,
      };

      updateJob(job)
        .unwrap()
        .then((response) => {
          
        });
    }
  }

  function assignDriverCall(driverId?: string, jobId?: string) {
    assignDriver({
      driverId: driverId??"",
      groupId: job?.data?.groupId??"",
      jobId: jobId??"",
    })
      .unwrap()
      .then((response) => {
        
        getAssignment({
          ...assignmentCollection,
          filter: [[{ field: "jobId", operator: "=", value: params.id }]],
          includes: [
            "driver",
            "driver.vehicle",
            "vehicle.category",
            "driver.preferences",
          ],
        });
      });
  }

  function openOrCloseJobCall(data: { id: string; status: JobStatus }) {
    openOrCloseJob(data)
      .unwrap()
      .then((response) => {
      });
  }

  function removeCandidateCall(driverId?: string) {
    if (driverId) {
      removeCandidate({ driverId: driverId ?? "", jobId: job?.data?.id ?? "" })
        .unwrap()
        .then((response) => {
        });
    }
  }
  function unassignDriverCall(id?: string) {
    if (id) {
      unassignDriver({id:id, jobId: params.id ?? ""})
        .unwrap()
        .then((response) => {
          setActiveTab("candidate");
        });
    }
  }

  function parseRoutes() {
    const data: { value: string; label: string }[] = [];
    if (routes.data) {
      routes?.data?.data.map((route: Route) => {
        let label = route.name;
        if (route?.city) {
          label += " (" + route.city.name + ")";
        }
        data.push({
          value: route.id ?? "",
          label: label,
        });
      });
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  function setName(name: string) {
  }

  const onError = (errors: any, e: any) =>{};

  function _parseGroups() {
    const data: { value: string; label: string }[] = [];

    if (groups?.data?.data) {
      groups.data.data.forEach((group: GroupModel) => {
        if (group.id && group.name) {
          data.push({ value: group.id, label: group.name });
        }
      });
    }

    if(job?.data?.group){
      data.push({value: job?.data?.group.id ?? "", label: job?.data?.group.name ?? ""})
    }

    return data;
  }
  function _parseRoutes() {
    const data: { value: string; label: string }[] = [];

    if (routes?.data?.data) {
      routes.data.data.forEach((route: Route) => {
        if (route.id && route.name) {
          data.push({ value: route.id, label: route.name });
        }
      });
    }

    return data;
  }

  const [activeTab, setActiveTab] = useState<string | null>("main");

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Job" : ""}
        item={job}
        path={`/registration/job/detail/${params?.type}/${
          params.id ?? "e622d73c-b708-4f53-ad38-0e50fb097b61"
        }`}
        id={params.id ?? "e622d73c-b708-4f53-ad38-0e50fb097b61"}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (job.isFetching ||
              updateResponse.isLoading ||
              assignResponse.isLoading)
          }
        />

        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
            {editMode == "detail" && (
              <Tabs.Tab value="candidate">Candidates</Tabs.Tab>
            )}
            {editMode === "detail" && job.data?.driverId && (
              <Tabs.Tab value="assignment">Assignment</Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex  justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <Accordion
                  defaultValue={["info", "address", "other"]}
                  multiple
                  variant="separated"
                  classNames={{ control: "bg-secondary text-gray-700" }}
                >
                  <Accordion.Item value="info">
                    <Accordion.Control>Job Information</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.title && `${errors?.title?.message}`}
                            withAsterisk
                            label="Title"
                            placeholder="Title"
                            {...register("title")}
                          />

                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.startLocation &&
                              `${errors?.startLocation?.message}`
                            }
                            withAsterisk
                            label="Start Location"
                            placeholder="Start Location"
                            {...register("startLocation")}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between">
                          <Controller
                            render={({ field }) => (
                              <Select
                                radius={"xs"}
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors?.routePreference &&
                                  `${errors?.routePreference?.message}`
                                }
                                label="Route Preference"
                                placeholder="Route Preference"
                                data={_parseRoutes()}
                                clearable
                                {...field}
                              />
                            )}
                            name="routePreference"
                            control={control}
                          />

                          <Controller
                            render={({ field }) => (
                              <Select
                                searchable
                                radius={"xs"}
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors?.groupId &&
                                  `${errors?.groupId?.message}`
                                }
                                onKeyUp={debounce((e) => {
                                  setGroupCollection({
                                    ...groupCollection,
                                    search: e.target.value,
                                  });
                                }, 1000)}
                                label="Group"
                                placeholder="Group"
                                data={_parseGroups()}
                                withAsterisk
                                clearable
                                {...field}
                              />
                            )}
                            name="groupId"
                            control={control}
                          />
                        </div>
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.maxApplicantNumber &&
                              `${errors?.maxApplicantNumber?.message}`
                            }
                            withAsterisk
                            label="Maximum number of applicants"
                            placeholder="Maximum number of applicants"
                            {...register("maxApplicantNumber")}
                          />

                          {/* <Controller
                            render={({ field }) => (
                              <Select
                                radius={"xs"}
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors?.minimumRating &&
                                  `${errors?.minimumRating?.message}`
                                }
                                label="Minimum rating"
                                placeholder="Minimum rating"
                                data={[
                                  { label: "1", value: "1" },
                                  { label: "2", value: "2" },
                                  { label: "3", value: "3" },
                                  { label: "4", value: "4" },
                                  { label: "5", value: "5" },
                                ]}
                                withAsterisk
                                clearable
                                {...field}
                              />
                            )}
                            name="minimumRating"
                            control={control}
                          /> */}
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            type="number"
                            error={
                              errors.minimumRating &&
                              `${errors?.minimumRating?.message}`
                            }
                            withAsterisk
                            label="Minimum rating"
                            placeholder="Minimum rating"
                            {...register("minimumRating")}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between">
                          <Textarea
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.description &&
                              `${errors?.description?.message}`
                            }
                            withAsterisk
                            label="Description"
                            placeholder="Description"
                            {...register("description")}
                          />
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={createResponse.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {(params?.type === "archived" &&
                          archivedJob?.data?.deletedAt) ||
                        (params?.type === "active" && job?.data?.deletedAt) ? (
                          <>
                            <Confirmation
                              type={"danger"}
                              message={
                                "Are you sure you want to delete it permanently?"
                              }
                              onYes={() => deleteJob(`${params?.id}`)}
                              header={`Permanent Delete confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={deleteCall.isLoading}
                                className={`bg-danger text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconTrash size={20} strokeWidth={1.5} />
                                <span>Delete</span>{" "}
                              </Button>
                            </Confirmation>
                            <Confirmation
                              type={"notify"}
                              message={`Are you sure you want to restore it`}
                              onYes={() => restoreJob(`${params?.id}`)}
                              header={`Restore confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={restoreCall.isLoading}
                                className={`bg-green-500 text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconArrowBackUp size={20} strokeWidth={1.5} />
                                <span>Restore</span>
                              </Button>
                            </Confirmation>
                          </>
                        ) : (
                          <>
                            {Auth?.user?.id !== job?.data?.id && (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this Job?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this Job?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveJob({
                                      id: `${
                                        params.id ??
                                        "e622d73c-b708-4f53-ad38-0e50fb097b61"
                                      }`,
                                      reason: data,
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>

                                {(job?.data?.status == JobStatus.Open ||
                                  job?.data?.status == JobStatus.Close) && (
                                  <Button
                                    variant="filled"
                                    className="bg-primary text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    onClick={() => {
                                      openOrCloseJobCall({
                                        id: job?.data?.id ?? "",
                                        status:
                                          job?.data?.status == JobStatus.Open
                                            ? JobStatus.Close
                                            : JobStatus.Open,
                                      });
                                    }}
                                    size={"xs"}
                                    loading={updateResponse.isLoading}
                                  >
                                    <span>
                                      <IconDeviceFloppy
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>
                                      {job?.data?.status == JobStatus.Open
                                        ? "Close"
                                        : "Open"}
                                    </span>
                                  </Button>
                                )}

                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          {editMode === "detail" && (
            <Tabs.Panel value="candidate" pt="xs">
              <Stack align={"flex-start"} className={"mt-3 mb-5"}>
                <Group position={"right"} className={"w-full"}>
                  <Select
                    placeholder={"Minimum rating"}
                    value={minimumRating}
                    clearable
                    onChange={(value) => {
                      setMinimumRating(value);
                     
                    }}
                    data={["1", "2", "3", "4", "5"]}
                  />
                  <Select
                    placeholder={"Route Preference"}
                    searchable
                    clearable
                    onKeyUp={debounce((e) => {
                      setRouteSearch(e.target.value);
                    }, 1000)}
                    value={preferredRoute}
                    onChange={(value) => {
                      setPreferredRoute(value);
                      setCollection({
                        ...collection,
                        routeId: value ?? undefined,
                      });
                    }}
                    data={parseRoutes()}
                  />

                  <Select
                    placeholder={"Date Preference"}
                    value={preferredDate}
                    clearable
                    onChange={(value) => {
                      setPreferredDate(value);
                      setCollection({
                        ...collection,
                        datePreference: value ?? undefined,
                      });
                    }}
                    data={[
                      { value: "Mon", label: "Monday" },
                      { value: "Tue", label: "Tuesday" },
                      { value: "Wed", label: "Wednesday" },
                      { value: "Thu", label: "Thursday" },
                      { value: "Fri", label: "Friday" },
                      { value: "Sat", label: "Saturday" },
                      { value: "Sun", label: "Sunday" },
                    ]}
                  />
                  <TextInput
                    onChange={(value) => {
                      setCandidateCollection({
                        ...candidateCollection,
                        searchFrom: ["driver.name", "driver.phoneNumber"],
                        search: value.currentTarget.value,
                      });
                    }}
                    icon={<IconSearch />}
                    placeholder="Search"
                  />
                </Group>
              </Stack>
              <div className="flex-col space-y-4 overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Driver
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Category
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Group
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Rating
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Route Preference
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date Preference
                      </th>

                      <th scope="col" className="py-3 px-6">
                        Applied At
                      </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidates.isSuccess &&
                      candidates.data.data.length > 0 &&
                      candidates.data?.data.map(
                        (candidate: Candidate, idx: number) => (
                          <tr
                            key={idx}
                            className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {candidate?.driver?.name}{" "}
                              {candidate?.driverId === job.data?.driverId && (
                                <Badge
                                  variant="gradient"
                                  gradient={{
                                    from: "teal",
                                    to: "lime",
                                    deg: 105,
                                  }}
                                >
                                  Assigned
                                </Badge>
                              )}
                            </th>
                            <td className="py-4 px-6 text-xs">
                              {candidate?.driver?.vehicle?.category?.name}
                            </td>
                            <td className="py-4 px-6 text-xs">
                              {candidate?.job?.group?.name}
                            </td>
                            <td className="py-4 px-6 text-xs">
                              {candidate?.driver?.phoneNumber}
                            </td>
                            <td className="py-4 px-6 text-xs">
                              {candidate?.driver?.averageRate?.rate.toFixed(
                                2
                              ) ?? "1"}
                            </td>

                            <td className="py-4 px-6 text-xs">
                              <List>
                                {candidate?.driver?.preferences?.map(
                                  (preference) => (
                                    <List.Item>
                                      {preference.routeName}
                                    </List.Item>
                                  )
                                )}
                              </List>
                            </td>

                            <td className="py-4 px-6 text-xs">
                              <List>
                                {candidate?.driver?.datePreferences?.map(
                                  (preference) => (
                                    <List.Item>{preference}</List.Item>
                                  )
                                )}
                              </List>
                            </td>

                            <td className="py-4 px-6 text-center">
                              {dateFormat(
                                candidate?.createdAt,
                                "mmmm dS, yyyy hh:mm TT"
                              )}
                            </td>
                            {/* {(job?.data?.status == JobStatus.Open ||
                              !job?.data?.driverId) && ( */}
                            <td className="py-4 px-2">
                              <div className="flex space-x-2">
                                {(job?.data?.status == JobStatus.Open ||
                                  !job?.data?.driverId) && (
                                  <Confirmation
                                    type={"notify"}
                                    message={
                                      "Are you sure you want to assign this candidate to the job?"
                                    }
                                    header={"Assign Candidate?"}
                                    yesText={"Assign"}
                                    onYes={(data: string) => {
                                      assignDriverCall(
                                        candidate.driverId,
                                        candidate.jobId
                                      );
                                    }}
                                  >
                                    <Tooltip label="Assign Driver">
                                    <Button
                                      variant="filled"
                                      loading={assignResponse.isLoading}
                                      className="bg-green-500 text-white mb-2"
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <IconCheckbox />
                                    </Button>
                                    </Tooltip>
                                  </Confirmation>
                                )}

                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to remove this candidate?"
                                  }
                                  header={"Remove Candidate?"}
                                  yesText={"Remove"}
                                  onYes={(data: string) => {
                                    removeCandidateCall(candidate?.driverId);
                                  }}
                                >
                                  <Tooltip label="Remove candidate">
                                    <Button
                                      variant="filled"
                                      loading={removeResponse.isLoading}
                                      className="bg-danger text-white"
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <span>
                                        <IconArchive
                                          size={22}
                                          strokeWidth={1.5}
                                        />
                                      </span>
                                      {/*<span>Archive</span>*/}
                                      {/*<Tooltip label={"Archive this kid"}>*/}
                                      {/*    Archive this kid*/}
                                      {/*</Tooltip>*/}
                                    </Button>
                                  </Tooltip>
                                </Confirmation>
                              </div>
                            </td>
                            {/* )} */}
                          </tr>
                        )
                      )}
                  </tbody>
                </table>

                {candidates.isLoading || !candidates?.data?.data.length ? (
                  <div className="w-full relative flex justify-center items-center h-56">
                    <LoadingOverlay
                      visible={candidates.isLoading}
                      overlayBlur={2}
                    />
                    <EmptyIcon />
                  </div>
                ) : (
                  <></>
                )}

                {candidates.isSuccess && candidates.data.data.length > 0 && (
                  <div className="w-full flex justify-end items-center mt-2 mb-2">
                    <Pagination
                      total={candidates?.data ? candidates?.data?.count : 0}
                      defaultPageSize={10}
                      pageSize={[5, 10, 15, 20]}
                      onPaginationChange={(skip: number, top: number) => {
                        setCollection({ ...collection, skip: skip, top: top });
                      }}
                    />
                  </div>
                )}
              </div>
            </Tabs.Panel>
          )}

          {editMode === "detail" && job.data?.driverId && (
            <Tabs.Panel value="assignment" pt="xs">
              <div className="flex-col space-y-4 overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Driver
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Vehicle
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Category
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Rating
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Route Preference
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date Preference
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignment.isSuccess &&
                      assignment.data.data.length > 0 &&
                      assignment.data?.data.map(
                        (driverAssignment: DriverAssignment, idx: number) => (
                          <tr
                            key={idx}
                            className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {driverAssignment?.driver?.name}
                            </th>
                            <td className="py-4 px-6 text-xs">
                              {driverAssignment?.driver?.phoneNumber}
                            </td>
                            <td className="py-4 px-6 text-xs">
                              {driverAssignment?.driver?.vehicle?.model} {"-"}{" "}
                              {driverAssignment?.driver?.vehicle?.plateNumber}
                            </td>
                            <td className="py-4 px-6 text-xs">
                              {
                                driverAssignment?.driver?.vehicle?.category
                                  ?.name
                              }
                            </td>

                            <td className="py-4 px-6 text-xs">
                              {driverAssignment?.driver?.averageRate?.rate.toFixed(
                                2
                              ) ?? "1"}
                            </td>

                            <td className="py-4 px-6 text-xs">
                              <List>
                                {driverAssignment?.driver?.preferences?.map(
                                  (preference) => (
                                    <List.Item>
                                      {preference.routeName}
                                    </List.Item>
                                  )
                                )}
                              </List>
                            </td>

                            <td className="py-4 px-6 text-xs">
                              <List>
                                {driverAssignment?.driver?.datePreferences?.map(
                                  (preference) => (
                                    <List.Item>{preference}</List.Item>
                                  )
                                )}
                              </List>
                            </td>

                            {/* {(job?.data?.status == JobStatus.Open ||
                              !job?.data?.driverId) && ( */}
                            <td className="py-4 px-2">
                              <div className="flex space-x-2">
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to remove this assignment?"
                                  }
                                  header={"Remove Assignment?"}
                                  yesText={"Remove"}
                                  onYes={(data: string) => {
                                    unassignDriverCall(
                                      driverAssignment?.id
                                    );
                                  }}
                                >
                                  <Tooltip label="Remove Assignment">
                                    <Button
                                      variant="filled"
                                      loading={unassignDriverResponse.isLoading}
                                      className="bg-danger text-white"
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <span>
                                        <IconArchive
                                          size={22}
                                          strokeWidth={1.5}
                                        />
                                      </span>
                                      {/*<span>Archive</span>*/}
                                      {/*<Tooltip label={"Archive this kid"}>*/}
                                      {/*    Archive this kid*/}
                                      {/*</Tooltip>*/}
                                    </Button>
                                  </Tooltip>
                                </Confirmation>
                              </div>
                            </td>
                            {/* )} */}
                          </tr>
                        )
                      )}
                  </tbody>
                </table>

                {assignment.isLoading || !assignment?.data?.data.length ? (
                  <div className="w-full relative flex justify-center items-center h-56">
                    <LoadingOverlay
                      visible={assignment.isLoading}
                      overlayBlur={2}
                    />
                    <EmptyIcon />
                  </div>
                ) : (
                  <></>
                )}

                {assignment.isSuccess && assignment.data.data.length > 0 && (
                  <div className="w-full flex justify-end items-center mt-2 mb-2">
                    <Pagination
                      total={assignment?.data ? assignment?.data?.count : 0}
                      defaultPageSize={10}
                      pageSize={[5, 10, 15, 20]}
                      onPaginationChange={(skip: number, top: number) => {
                        setCollection({ ...collection, skip: skip, top: top });
                      }}
                    />
                  </div>
                )}
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}

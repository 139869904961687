/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Notification } from "../../../models/notification.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { NOTIFICATION_ENDPOINT } from "../notification.endpoint";

let notificationCollection: CollectionQuery;
const notificationQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<Collection<Notification>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${NOTIFICATION_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notificationCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createNotification: builder.mutation<Notification, Notification>({
      query: (data: Notification) => ({
        url: `${NOTIFICATION_ENDPOINT.createNotification}`,
        method: "POST",
        data: data,
        // permission:'manage-categories'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            notificationQuery.util.updateQueryData(
              "getNotifications",
              notificationCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count = draft.count + 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteNotification: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${NOTIFICATION_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission: "manage-categories",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            notificationQuery.util.updateQueryData(
              "getNotifications",
              notificationCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (notification) => notification.id !== id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    sendByUploadFile: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${NOTIFICATION_ENDPOINT.send_by_upload}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully uploaded");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetNotificationsQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useSendByUploadFileMutation,
} = notificationQuery;

import { Category } from "./category.model";
import { DriverAssignment } from "./driver-assignment.model";
import { Provider } from "./provider.model";

export interface Group{
    id?: string;
    name: string;
    status?: GroupStatus;
    numberOfSeat?: NumberOfSeat;
    driverId?: string;
    driver?: Provider
    driverName?: string;
    driverPhone?: string;
    category?: Category;
    availableSeats?: number;
    archiveReason?:string;
    kidsRoadDifficultyCost?: number;
    driverAssignments?: DriverAssignment[];
    categoryId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    deletedBy?: string;
}

export enum GroupStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum NumberOfSeat {
    TaxiGroup = "4",
    MiniBusGroup = "12",
    VIPGroup = "1",
}
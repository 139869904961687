export const ROUTE_ENDPOINT = {
  detail: `${process.env.REACT_APP_API}/routes/get-route`,
  delete: `${process.env.REACT_APP_API}/routes/delete-route`,
  toggleStatus: `${process.env.REACT_APP_API}/routes/activate-or-block-route`,
  archive: `${process.env.REACT_APP_API}/routes/archive-route`,
  restore: `${process.env.REACT_APP_API}/routes/restore-route`,
  archivedList: `${process.env.REACT_APP_API}/routes/get-archived-routes`,
  archivedRoute: `${process.env.REACT_APP_API}/routes/get-archived-route`,
  list: `${process.env.REACT_APP_API}/routes/get-routes`,
  create: `${process.env.REACT_APP_API}/routes/create-route`,
  update: `${process.env.REACT_APP_API}/routes/update-route`,
  route_stations: `${process.env.REACT_APP_API}/routes/get-route-stations`,
  add_route_stations: `${process.env.REACT_APP_API}/routes/add-route-stations`,
  route_prices: `${process.env.REACT_APP_API}/routes/get-route-prices`,
  add_route_prices: `${process.env.REACT_APP_API}/routes/add-route-prices`,
  restore_driver_commission: `${process.env.REACT_APP_API}/routes/restore-route-price-driver-commission`,
  update_driver_commission: `${process.env.REACT_APP_API}/routes/update-route-price-driver-commission`,
  restore_route_price: `${process.env.REACT_APP_API}/routes/restore-route-price-fee`,
  update_route_price: `${process.env.REACT_APP_API}/routes/update-route-price-fee`,
  route_drivers: `${process.env.REACT_APP_API}/assignments/get-drivers-in-route`,
  get_route_assignments: `${process.env.REACT_APP_API}/assignments/get-assignments`,
  add_route_assignment: `${process.env.REACT_APP_API}/assignments/create-assignment`,
  cancel_route_assignment: `${process.env.REACT_APP_API}/assignments/cancel-assignment`,
  add_bulk_route_assignment: `${process.env.REACT_APP_API}/assignments/create-bulk-assignment`,
  update_route_assignment: `${process.env.REACT_APP_API}/assignments/update-assignment`,
  delete_route_assignment: `${process.env.REACT_APP_API}/assignments/delete-assignment`,
  activate_or_block_route: `${process.env.REACT_APP_API}/routes/activate-or-block-route`,
  update_time: `${process.env.REACT_APP_API}/routes/update-route-pickuptime`,
};

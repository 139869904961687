import { Category } from "./category.model";
import GroupAssignment from "./group-assignment.model";
import { Parent } from "./parent.model";
import { School } from "./school.model";

export interface Kid{
    id: string;
    name: string;
    age: number;
    grade: string;
    gender: Gender;
    status: KidStatus;
    distanceFromSchool?: number;

    discountType?: string;
    discountAmount?: number;
    remark?: string;
    kidFee?:number;
    archiveReason?: string;
    kidTravelStatus?: KidTravelStatus;
    categoryId?: string;
    categoryName?: string;
    category?: Category;
    transportationTime?: TransportationTime;
    startDate?: string;

    estimatedMonthlyFee?: number;
    createdBy?: string;
    createdAt ?: Date;
    updatedAt ?: Date;
    updatedBy?: string;
    enabled?: boolean;
    deletedAt?: Date;
    deletedBy?: string;
    parentId?: string;
    profileImage?: ProfileImage;
    schoolName?: string;
    schoolId?: string;
    parent?: Parent;
    school?: School;
    groupAssignment?: GroupAssignment;
}

export enum KidTravelStatus {
    Alone = 'Alone',
    WithSiblings = 'With_Siblings',
    WithOthers = 'With_Others',
  }

  export enum TransportationTime {
    Morning = 'Morning',
    Afternoon = 'Afternoon',
    Both = 'Both',
  }

export enum Gender {
    Male = 'male',
    Female = 'female',
}

export enum KidStatus {
    InSchool = 'In_School',
    AtHome = 'At_Home',
    OnTheWay = 'On_The_Way',
    Empty = ''
}

interface ProfileImage{
    filename:string;
    mimetype:string;
    originalname:string;
    path:string;
    size:number;
}
import { Button } from '@mantine/core';
import { lazy, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { EditCircle } from "tabler-icons-react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { DriverFee } from "../../../models/driver-fee.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { notification } from '../../../shared/component/notification/utility/notification';
import { useDriversFeeStatusUpdateMutation, useLazyGetDriverFeesQuery } from "../store/route-assignment.query";

const PaymentStatusModalComponent =lazy(()=>import("../sub-component/payment-status-modal-component"));


export default function AssignmentPaymentHistoryComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["driver", "driver.bankAccounts"],
    orderBy:[{field:'createdAt',direction:'desc'}]
  });

  const [selectedItems, setSelectedItems] = useState<DriverFee[]>([]);

  const [openStatusModal,setOpenStatusModal] = useState(false); 

  const [getDriverFees, driverFees] = useLazyGetDriverFeesQuery();
  const [updatePaymentStatus, updatePaymentStatusResult] = useDriversFeeStatusUpdateMutation();

  useEffect(() => {
    getDriverFees(collection);
  }, [collection]);

  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id, location]);


  const data: DriverFee[] | undefined = driverFees.data?.data;
  const config: EntityConfig<DriverFee> = {
    primaryColumn: { key: ["driver", "name"], name: "Driver" },
    detailUrl: "/assignment/payment/detail",
    rootUrl: "/assignment/payment",
    identity: "id",
    name: "Driver",
    visibleColumn: [
      { key: ["driver","name"], name: "Driver",render:(driverFee)=>{
        return(<div>{driverFee?.driver?.name} {"("+driverFee?.driver?.phoneNumber+")"}</div>)
      }},
     
      { key: "fee", name: "Fee" , render(value) {
        return value.fee.toFixed(2) + " ETB";
      },},
      { key: "", name: "No of Assignments", render: value => value.assignmentIds.length },
      
      { key: "paymentMethod", name: " Payment Method" },
      { key: ["driver","bankAccount", "accountNumber"], name: "Account Number", render(value) {
        return value.accountNumber;
      }, },
      { key:"transactionNumber",name:'Transaction NO'},
      { key: "paymentStatus", name: "Status", render:(driverFee)=>{
        return(<div className="text-gray-900 dark:text-white">{driverFee?.paymentStatus}</div>)
      }},
      { key: "createdAt", name: "Completed On", isDate: true },
    ],
  };

  const onPaymnetStatusUpdate = (data:any)=>{
    const driverFeeIds = selectedItems?.map((driverFee)=>{
      return driverFee?.id
    })
    updatePaymentStatus({driverFeeIds:driverFeeIds,paymentStatus:data.status,transactionNumber:data.transaction_number??null}).then((response:any)=>{
          if(response?.data){
              setOpenStatusModal(false);
          }
    })

  }

  const onItemsSelected = (items: DriverFee[]) => {
    setSelectedItems(items);
  };
  const header = <div className="flex w-full justify-end">
  <Button  className="bg-primary text-white" onClick={()=>{
    if(selectedItems?.length===0){
      notification('info','Please select at least on payment')
    }else{
      setOpenStatusModal(true)
    }
  }} size="xs" leftIcon={<EditCircle/>}>
      Update Sstatus
  </Button>
</div>
  return (
    <div className="h-full mt-2">
      <EntityList
        viewMode={viewMode}
        showExport={viewMode === "list"}
        showSelector={viewMode === "list"}
        title={"Payment History"}
        header={viewMode!=='detail'?header:<></>}
        firstChildStyle={viewMode === "list" ? "border-0" : "border"}
        total={driverFees?.data?.count}
        itemsLoading={driverFees.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onItemsSelected={(item) => {
          onItemsSelected(item);
        }}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["driver.name","driver.phoneNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      {
      openStatusModal && <PaymentStatusModalComponent data={{status:selectedItems?.[0].paymentStatus??''}} isLoading={updatePaymentStatusResult?.isLoading} opened={openStatusModal} close={()=>setOpenStatusModal(false)} submit={(data)=>onPaymnetStatusUpdate(data)} />
     }
    </div>
  );
}

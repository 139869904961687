import { createRoot } from "react-dom/client";
import NotificationComponent from "../components/notification";
export const notification = (
  type: "success" | "error" | "warning" | "info",
  message: string
) => {
  const notificationContainer = document.createElement("div");
  notificationContainer.id = "notification-container";
  document.body.appendChild(notificationContainer);
  const root = createRoot(notificationContainer);
  root.render(
    <NotificationComponent
      type={type}
      message={message}
      container={notificationContainer}
    />
  );
};

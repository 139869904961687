export enum TripStatus {
  CREATED = "Created",
  BOARDED = "Boarded",
  DRIVER_ARRIVED = "DriverArrived",
  ARRIVED = "Arrived",
  // SKIPPED = "Skipped",
  NOSHOW = "NoShow",
  COMPLETED = "Completed",
}
export enum NewBookingStatus {
  CREATED = "Created",
  BOOKED = "Booked",
  CANCELLED = "Cancelled",
  EXPIRED = "Expired",
}
export enum ChapaPaymentStatus {
  Pending = "pending",
  Success = "success",
  Timeout = "timeout",
  Cancelled = "cancelled",
  Failed = "failed",
  Error = "error",
}

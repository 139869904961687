import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedUserComponent from "../component/archived-user-component";
import NewUserComponent from "../component/new-user-component";
import UserPage from "../page/user-page";
export const UserRoute: RouteObject = {
  path: "/user-management/user",
  element: (
    <AuthGuard role={["super_admin"]}>
      <UserPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewUserComponent editMode="detail" /> },
    { path: ":id", element: <NewUserComponent editMode="new" /> },
    { path: "archived", element: <ArchivedUserComponent /> },
  ],
};

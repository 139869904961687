import {
  ActionIcon,
  Button,
  Group,
  HoverCard, Modal,
  Text,
  Tooltip
} from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { SetStateAction, useEffect, useState } from "react";
import { BusStop } from "../../../models/bus-stop.model";
import {
  CollectionQuery,
  Filter,
  Order
} from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteBusStopMutation,
  useLazyGetArchivedBusStopsQuery,
  useRestoreBusStopMutation
} from "../store/bus-stop.query";

export default function ArchivedBusStopComponent() {
  const [getArchivedBusStops, archivedBusStops] =
    useLazyGetArchivedBusStopsQuery();
  const [restoreBusStop, restoreCall] = useRestoreBusStopMutation();
  const [deleteBusStop, deleteCall] = useDeleteBusStopMutation();
  const [id, setId] = useState("");

  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});

  //Archive reason modal state
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  //Collection query state
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["city"],
    orderBy:[{field:'deletedAt',direction:'desc'}]
  });

  useEffect(() => {
    getArchivedBusStops(collection).then((archivedBusStops) => {
      const data: SetStateAction<Filter[]> = [];
      //add any new city to cityList
      archivedBusStops.data?.data?.map((busStop) => {
        const cityName = busStop?.city?.name;
        if (cityName) {
          if (Object.keys(cityList).indexOf(cityName) == -1) {
            cityList[cityName] = busStop.cityId;
          }
        }
      });
      // setup filter from the cityList
      Object.keys(cityList).map((key) => {
        data.push({
          field: "cityId",
          value: cityList[key],
          name: key,
          operator: "=",
        });
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, [collection]);

  const data: BusStop[] | undefined = archivedBusStops.data?.data;
  const config: EntityConfig<BusStop> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/bus-stop",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      {
        key: "description",
        name: "Description",
        render: (value) => {
          return (
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Text size="sm" className={"cursor-pointer"}>
                  {value.description.length > 25
                    ? value.description.substring(0, 22) + "..."
                    : value.description}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown
                className={"text-justify break-all wrap max-h-60 overflow-auto"}
              >
                <Text size="sm">{value.description}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        key: "",
        name: "City",
        print:false,
        render: (value) => value?.city?.name ?? " ---- ",
      },
      { key: "lat", name: "Latitude" },
      { key: "lng", name: "Longitude" },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      { key: "isActive", name: "Status" },
      {
        key: "",
        name: "Action",
        print:false,
        render: (busStop) => {
          return (
            <div className="flex-col space-y-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${busStop.id}`);
                  restoreBusStop(`${busStop.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <Tooltip label="Restore">
                <ActionIcon
                  variant="filled"
                  className="bg-primary text-white"
                  size={"sm"}
                  loading={restoreCall.isLoading && `${busStop.id}` === id}
                >
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </ActionIcon>
                </Tooltip>
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${busStop.id}`);
                  deleteBusStop(`${busStop?.id}`).then(() => setId(""));
                }}
              >
                <Tooltip label="Remove">
                <ActionIcon
                  variant="filled"
                  className="bg-danger text-white"
                  size={"sm"}
                  loading={deleteCall.isLoading && `${busStop.id}` === id}
                >
                  <IconTrash size={20} strokeWidth={1.5} />
                </ActionIcon>
                </Tooltip>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  size={"sm"}
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: busStop?.name,
                      reason: busStop?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    filter: [filterData],
  };

  return (
    <div className="w-full">
      <EntityList
        viewMode={"list"}
        title={"Archived Bus Stops"}
        total={archivedBusStops?.data?.count}
        itemsLoading={archivedBusStops.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        showNewButton={false}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />

      {/* Archive reason Modal */}

      <Modal
        opened={archiveReason?.opened}
        onClose={() => setArchiveReason({ ...archiveReason, opened: false })}
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason, opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

export enum VehicleColor {
    Silver = 'Silver',
    Gold = 'Gold',
    Burgundy = 'Burgundy',
    BlueBlack = 'Blue Black',
    White = 'White',
    Black = 'Black',
    Red = 'Red',
    Blue = 'Blue',
    Yellow = 'Yellow',
    Orange = 'Orange',
    Green = 'Green',
    Brown = "Brown",
    Gray = "Gray"
  }
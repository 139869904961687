import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import WalletDetail from "../component/wallet-detail.component";
import WalletPage from "../page/wallet-page";
export const WalletRoute: RouteObject = {
  path: "/wallets",
  element: (
    <AuthGuard role={['super_admin','admin','finance']}>
      <WalletPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:key/:type/:id",
      element: <WalletDetail  />,
    },
  ],
};

export const CUSTOMROUTE_ENDPOINT = {
  detail: `${process.env.REACT_APP_API}/custom-routes/get-custom-route`,
  delete: `${process.env.REACT_APP_API}/custom-routes/delete-custom-route`,
  toggleStatus: `${process.env.REACT_APP_API}/custom-routes/activate-or-block-custom-route`,
  archive: `${process.env.REACT_APP_API}/custom-routes/archive-custom-route`,
  restore: `${process.env.REACT_APP_API}/custom-routes/restore-custom-route`,
  archivedList: `${process.env.REACT_APP_API}/custom-routes/get-archived-custom-routes`,
  archivedCustomRoute: `${process.env.REACT_APP_API}/custom-routes/get-archived-custom-route`,
  list: `${process.env.REACT_APP_API}/custom-routes/get-custom-routes`,
  create: `${process.env.REACT_APP_API}/custom-routes/create-custom-route`,
  update: `${process.env.REACT_APP_API}/custom-routes/update-custom-route`,
  groupedList: `${process.env.REACT_APP_API}/custom-routes/group-custom-routes`,
};

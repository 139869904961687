export const ROUTE_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/routes/get-route`,
    delete:`${process.env.REACT_APP_API}/routes/delete-route`,
    toggleStatus:`${process.env.REACT_APP_API}/routes/activate-or-block-route`,
    archive:`${process.env.REACT_APP_API}/routes/archive-route`,
    restore:`${process.env.REACT_APP_API}/routes/restore-route`,
    archivedList:`${process.env.REACT_APP_API}/routes/get-archived-routes`,
    archivedRoute:`${process.env.REACT_APP_API}/routes/get-archived-route`,
    list:`${process.env.REACT_APP_API}/routes/get-routes`,
    create:`${process.env.REACT_APP_API}/routes/create-route`,
    update:`${process.env.REACT_APP_API}/routes/update-route`,
    route_stations:`${process.env.REACT_APP_API}/routes/get-route-stations`,
    add_route_stations:`${process.env.REACT_APP_API}/routes/add-route-stations`,
    route_prices:`${process.env.REACT_APP_API}/routes/get-route-prices`,
    add_route_prices:`${process.env.REACT_APP_API}/routes/add-route-prices`,
    route_drivers:`${process.env.REACT_APP_API}/assignments/get-drivers-in-route`,
    get_route_assignment:`${process.env.REACT_APP_API}/assignments/get-assignment`,
    get_route_assignments:`${process.env.REACT_APP_API}/assignments/get-assignments`,
    add_route_assignment:`${process.env.REACT_APP_API}/assignments/create-assignment`,
    cancel_route_assignment:`${process.env.REACT_APP_API}/assignments/cancel-assignment`,
    add_bulk_route_assignment:`${process.env.REACT_APP_API}/assignments/create-bulk-assignment`,
    update_route_assignment:`${process.env.REACT_APP_API}/assignments/update-assignment`,
    delete_route_assignment:`${process.env.REACT_APP_API}/assignments/delete-assignment`,
    startAssignment:`${process.env.REACT_APP_API}/assignments/start-assignment`,
    completeAssignment:`${process.env.REACT_APP_API}/assignments/complete-assignment`,

    // Driver Fee
    pay_for_assignments:`${process.env.REACT_APP_API}/assignments/pay-for-driver-assignment`,
    driver_fee_list:`${process.env.REACT_APP_API}/driver-fees/get-driver-fees`,
    driver_fee:`${process.env.REACT_APP_API}/driver-fees/get-driver-fee`,
    driver_fee_status_update:`${process.env.REACT_APP_API}/driver-fees/change-driver-fee-status`,

    //Driver Assignments
    driver_assignments:`${process.env.REACT_APP_API}/assignments/get-driver-assignments`,

    payment_for_multiple_assignments:`${process.env.REACT_APP_API}/assignments/pay-for-multiple-driver-assignment`,

}

export const SERVICE_ENDPOINT={
    list:`${process.env.REACT_APP_API}/services/get-services`,
    service:`${process.env.REACT_APP_API}/services/get-service`,
    create:`${process.env.REACT_APP_API}/services/create-service`,
    delete:`${process.env.REACT_APP_API}/services/delete-service`,
    update:`${process.env.REACT_APP_API}/services/update-service`,
    archive:`${process.env.REACT_APP_API}/services/archive-service`,
    archived_services:`${process.env.REACT_APP_API}/services/get-archived-services`,
    archived_service:`${process.env.REACT_APP_API}/services/get-archived-service`,
    restore_service:`${process.env.REACT_APP_API}/services/restore-service`,
}
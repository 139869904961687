export const CATEGORY_ENDPOINT = {
  list: `${process.env.REACT_APP_API}/categories/get-categories`,
  category: `${process.env.REACT_APP_API}/categories/get-category`,
  create: `${process.env.REACT_APP_API}/categories/create-category`,
  delete: `${process.env.REACT_APP_API}/categories/delete-category`,
  update: `${process.env.REACT_APP_API}/categories/update-category`,
  archive: `${process.env.REACT_APP_API}/categories/archive-category`,
  archived_categories: `${process.env.REACT_APP_API}/categories/get-archived-categories`,
  archived_category: `${process.env.REACT_APP_API}/categories/get-archived-category`,
  restore_category: `${process.env.REACT_APP_API}/categories/restore-category`,
};

import {Modal, Image, Text, Group} from '@mantine/core';

export default function ImagePreview(props:{opened: boolean, setOpened: Function, url?: string, placeHolder: string}) {

    return (
        <>
            <Modal
                opened={props.opened}
                onClose={() => props.setOpened(false)}
                fullScreen
            >
               <Group position={"center"}>
                   <Image
                       fit="contain"
                       width={"95vw"}
                       height={"85vh"}
                       src={props.url}
                       withPlaceholder
                       placeholder={<Text align="center">{props.placeHolder}</Text>}
                   />
               </Group>
            </Modal>
        </>
    );
}

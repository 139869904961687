export const FAQ_ENDPOINT = {
  list: `${process.env.REACT_APP_API}/faqs/get-faqs`,
  faq: `${process.env.REACT_APP_API}/faqs/get-faq`,
  archived_faqs: `${process.env.REACT_APP_API}/faqs/get-archived-faqs`,
  archived_faq: `${process.env.REACT_APP_API}/faqs/get-archived-faq`,
  create: `${process.env.REACT_APP_API}/faqs/create-faq`,
  delete: `${process.env.REACT_APP_API}/faqs/delete-faq`,
  update: `${process.env.REACT_APP_API}/faqs/update-faq`,
  archive: `${process.env.REACT_APP_API}/faqs/archive-faq`,

  restore_faq: `${process.env.REACT_APP_API}/faqs/restore-faq`,
};

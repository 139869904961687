import { Popover, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Faq } from "../../../models/faq.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetFaqsQuery } from "../store/faq.query";

export default function FaqPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
    includes: ["category"],
  });

  const [getFaqs, faqs] = useLazyGetFaqsQuery();

  useEffect(() => {
    getFaqs(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/faq/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);
  const data: Faq[] | undefined = faqs.data?.data;

  const config: EntityConfig<Faq> = {
    primaryColumn: {
      key: "question",
      name: "Question",
      render: (faq) => {
        return (
          <Text size="sm" className={faq?.deletedAt ? "text-danger" : ""}>
            {faq.question}
          </Text>
        );
      },
    },
    detailUrl: "detail",
    rootUrl: "/faq",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "question",
        name: "Question",
        render: (faq) => {
          return (
            <Text size="sm" className={faq?.deletedAt ? "text-danger" : ""}>
              {faq.question}
            </Text>
          );
        },
      },
      {
        key: "description",
        name: "description",
        hideSort: true,
        render: (eachFaq: Faq) => {
          return (
            <>
              <Popover width={500} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <Text className="cursor-pointer flex items-center text-xs">
                    {eachFaq?.answer && eachFaq?.answer?.length > 25
                      ? eachFaq?.answer?.substring(0, 22) + "..."
                      : eachFaq?.answer}
                  </Text>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="xs">{eachFaq?.answer}</Text>
                </Popover.Dropdown>
              </Popover>
            </>
          );
        },
      },
      { key: ["category", "name"], name: "Category", hideSort: true },
      { key: "createdAt", name: "Registered at", isDate: true },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        showExport={false}
        viewMode={viewMode}
        title={"FAQs"}
        total={faqs?.data?.count}
        itemsLoading={faqs.isLoading || faqs?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["question"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

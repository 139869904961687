/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category } from "../../../models/category.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CATEGORY_ENDPOINT } from "../category.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const categoryQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCategories: builder.query<Collection<Category>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CATEGORY_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createCategory: builder.mutation<Category, Category>({
      query: (data: Category) => ({
        url: `${CATEGORY_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission:'manage-categories'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategories",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count = draft.count + 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteCategory: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission:'manage-categories'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategories",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (category) => category.id !== id
                );
                draft.count -= 1;
              }
            )
          );
          dispatch(
            categoryQuery.util.updateQueryData(
              "getArchivedCategories",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (category) => category.id !== id
                );
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getCategory: builder.query<Category, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.category}/${id}`,
        method: "GET",
      }),
    }),
    updateCategory: builder.mutation<Category, Category>({
      query: (data: Category) => ({
        url: `${CATEGORY_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission:'manage-categories'
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategories",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((category) => {
                  if (category.id === data.id) return data;
                  else {
                    return category;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveCategory: builder.mutation<Category, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${CATEGORY_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission:'manage-categories'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;

          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategories",
              currentCollection,
              (draft) => {
                draft.data = draft.data.map((category) => {
                  if (category?.id === data?.data?.id) {
                    return data?.data;
                  }
                  return category;
                });
              }
            )
          );
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategory",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt;
              }
            )
          );
          dispatch(
            categoryQuery.util.updateQueryData(
              "getArchivedCategory",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt ?? "";
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedCategory: builder.query<Category, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.archived_category}/${id}`,
        method: "GET",
      }),
    }),
    getArchivedCategories: builder.query<Collection<Category>, CollectionQuery>(
      {
        query: (data: CollectionQuery) => ({
          url: `${CATEGORY_ENDPOINT.archived_categories}`,
          method: "GET",
          params: collectionQueryBuilder(data),
        }),
        async onQueryStarted(param, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            archivedCollection = param;
          } catch (error: any) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        },
      }
    ),
    restoreCategory: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${CATEGORY_ENDPOINT.restore_category}/${id}`,
        method: "POST",
        permission:'manage-categories'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            categoryQuery.util.updateQueryData(
              "getArchivedCategory",
              id,
              (draft) => {
                draft.deletedAt = "";
              }
            )
          );
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategory",
              id,
              (draft) => {
                draft.deletedAt = "";
              }
            )
          );
          notification("success", "Successfully restored");
          dispatch(
            categoryQuery.util.updateQueryData(
              "getCategories",
              currentCollection,
              (draft) => {
                draft.data = draft.data.map(
                  (category) => {
                    if(category.id === id){
                      return data
                    }
                    return category
                  }
                );
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetCategoryQuery,
  useLazyGetCategoriesQuery,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useArchiveCategoryMutation,
  useLazyGetArchivedCategoriesQuery,
  useRestoreCategoryMutation,
  useLazyGetArchivedCategoryQuery,
} = categoryQuery;

import { HoverCard, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { Job } from "../../../models/job.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetJobsQuery } from "../store/job.query";

export default function JobPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ['group'],
    orderBy: [{field: "createdAt", direction: "desc"}]
  });

  const [getJobs, jobs] = useLazyGetJobsQuery();

  useEffect(() => {
    getJobs(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/job/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Job[] | undefined = jobs.data?.data;

  const config: EntityConfig<Job> = {
    primaryColumn: { key: "title", name: "Title" },
    detailUrl: "detail",
    rootUrl: "/registration/job",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "title", name: "Title",render:(job)=>{
        return (
          <Text size="sm" className={job?.deletedAt ? "text-danger":''}>
            {job.title}
          </Text>
        );
      } },
      { key: "groupName", name: "Group" },
      { key: "startLocation", name: "Start Location" },
      { key: "description", name: "Description", 
      render: (value) => (
        <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <Text size="sm" className={"cursor-pointer"}>
                {value.description.length > 25
                  ? value.description.substring(0, 22) + "..."
                  : value.description}
              </Text>
            </HoverCard.Target>
            <HoverCard.Dropdown
              className={"text-justify break-all wrap max-h-60 overflow-auto"}
            >
              <Text size="sm">{value.description}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
      )
    },
    { key: "maxApplicantNumber", name: "Max No 0f Applicant" },
      { key: "createdAt", name: "Posted At", isDate: true },
      { key: "status", name: "Status"},
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Jobs"}
        total={jobs?.data?.count}
        itemsLoading={jobs.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,withArchived:e
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["title", "startLocation"],
          });
        }}
        onFilterChange={(data: any) =>{
          if(data.length > 0 || collection?.filter) {
            setCollection({ ...collection, filter: data })
          }
        }
          
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

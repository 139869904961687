export const USER_ENDPOINT = {
  detail: `${process.env.REACT_APP_API}/users/get-user`,
  delete: `${process.env.REACT_APP_API}/users/delete-user`,
  toggleStatus: `${process.env.REACT_APP_API}/users/activate-or-block-user`,
  archive: `${process.env.REACT_APP_API}/users/archive-user`,
  restore: `${process.env.REACT_APP_API}/users/restore-user`,
  archivedList: `${process.env.REACT_APP_API}/users/get-archived-users`,
  archivedUser: `${process.env.REACT_APP_API}/users/get-archived-user`,
  list: `${process.env.REACT_APP_API}/users/get-users`,
  create: `${process.env.REACT_APP_API}/users/create-user`,
  update: `${process.env.REACT_APP_API}/users/update-user`,
  updateProfile: `${process.env.REACT_APP_API}/users/update-user-profile-image/`,
  roles: `${process.env.REACT_APP_API}/roles/get-roles`,
  user_role: `${process.env.REACT_APP_API}/accounts/get-user-roles`,
  role_permissions: `${process.env.REACT_APP_API}/roles/get-role-permissions`,
  add_account_role: `${process.env.REACT_APP_API}/accounts/add-account-role`,
  remove_account_role: `${process.env.REACT_APP_API}/accounts/remove-account-role`,
  add_account_role_permission: `${process.env.REACT_APP_API}/accounts/add-account-permission`,
 user_role_permissions: `${process.env.REACT_APP_API}/accounts/get-user-permissions-by-role-id`,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Kid } from "../../../models/kids.model";
import { School } from "../../../models/school.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { SCHOOL_ENDPOINT } from "../school.endpoint";

let schoolCollection: CollectionQuery;
let archivedSchoolCollection: CollectionQuery;
let schoolKidsCollection: CollectionQuery;
const schoolQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchool: builder.query<School, string>({
      query: (id: string) => ({
        url: `${SCHOOL_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedSchool: builder.query<School, string>({
      query: (id: string) => ({
        url: `${SCHOOL_ENDPOINT.archivedSchool}/${id}`,
        method: "get",
      }),
    }),

    getArchivedSchools: builder.query<Collection<School>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${SCHOOL_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedSchoolCollection = param;
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    getSchools: builder.query<Collection<School>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${SCHOOL_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            schoolCollection = param;
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),
    getSchoolKids: builder.query<Collection<Kid>, {id: string; q: CollectionQuery}>({
      query: (data: {id: string; q: CollectionQuery}) => ({
        url: `${SCHOOL_ENDPOINT.getSchoolKids}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.q),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            schoolKidsCollection = param.q;
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    createSchool: builder.mutation<School, School>({
      query: (newData: any) => ({
        url: `${SCHOOL_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permissions:'manage-schools'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    updateSchool: builder.mutation<School, School>({
      query: (newData: any) => ({
        url: `${SCHOOL_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permissions:'manage-schools'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    updateLogo: builder.mutation<School, any>({
      query: (newData: any) => ({
        url: `${SCHOOL_ENDPOINT.updateLogo}` + newData.id,
        method: "post",
        data: newData.data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((school) => {
                      if (school.id === data.id) return data;
                      else {
                        return school;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    activateSchool: builder.mutation<School, string>({
      query: (id: string) => ({
        url: `${SCHOOL_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permissions:'manage-schools'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData("getSchool", param, (draft) => {
                draft.enabled = data.enabled;
              })
            );
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    archiveSchool: builder.mutation<School, { id: string; reason: string }>({
      query: (data) => ({
        url: `${SCHOOL_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permissions:'manage-schools'
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection
                ,
                (draft) => {
                  draft.data = draft?.data?.map((provider) => {
                    if (provider.id === arg.id) {
                      return data;
                    } else {
                      return provider;
                    }
                  });
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchool",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData(
                "getArchivedSchool",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
            
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    deleteSchool: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${SCHOOL_ENDPOINT.delete}/${id}`,
        method: "delete",
        permissions:'manage-schools'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
           
            dispatch(
              schoolQuery.util.updateQueryData(
                "getArchivedSchools",
                archivedSchoolCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((school) => school.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((school) => school.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully deleted");
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),

    restoreSchool: builder.mutation<School, string>({
      query: (id: string) => ({
        url: `${SCHOOL_ENDPOINT.restore}/${id}`,
        method: "post",
        permissions:'manage-schools'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchools",
                schoolCollection
                ,
                (draft) => {
                  draft.data = draft?.data?.map((provider) => {
                    if (provider.id === id) {
                      return data;
                    } else {
                      return provider;
                    }
                  });
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData(
                "getSchool",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              schoolQuery.util.updateQueryData(
                "getArchivedSchool",
              id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          
          }
        } catch(error:any) {
          notification("error", error?.error?.data?.message ? error?.error?.data?.message:'Error try again');
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
   useLazyGetSchoolQuery,
    useLazyGetSchoolsQuery,
    useLazyGetSchoolKidsQuery,
    useLazyGetArchivedSchoolQuery,
    useLazyGetArchivedSchoolsQuery,
    useActivateSchoolMutation,
    useArchiveSchoolMutation,
    useCreateSchoolMutation,
    useDeleteSchoolMutation,
    useRestoreSchoolMutation,
    useUpdateSchoolMutation,
    useUpdateLogoMutation

} = schoolQuery;

import {
  Checkbox,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Table
} from "@mantine/core";
import { IconArrowBack, IconFilter } from "@tabler/icons";
import dateFormat from "dateformat";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { Transaction } from "../../../models/transaction.model";
import AuthContext from "../../../shared/auth/context/authContext";
import Card from "../../../shared/component/Card/card-component";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import {
  useLazyGetCorporateQuery,
  useLazyGetCorporateTransactionsQuery,
  useLazyGetCorporateWalletQuery
} from "../store/corporate.query";

export default function CorporateTransaction() {
  const params = useParams();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { user } = useContext(AuthContext);
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

  const [getCorporate, corporate] = useLazyGetCorporateQuery();
  const [getCorporateTransaction, transactions] =
    useLazyGetCorporateTransactionsQuery();
  const [getCorporateWallet, corporateWallet] =
    useLazyGetCorporateWalletQuery();

  const rows = transactions?.data?.data?.map(
    (transaction: any, index: number) => (
      <tr
        onClick={() => {
          setSelectedTransaction(transaction);
          setOpened(true);
        }}
        key={index}
        className={`border-0 border-l-2 cursor-pointer  ${
          transaction.amount > 0
            ? "light:bg-green-50 border-green-500"
            : "light:bg-red-50 border-red-500"
        }`}
      >
        <td className="capitalize items-center space-x-2 flex">
          {transaction.method.toLowerCase() === "chapa" ? (
            <Image
              className="text-gray-700"
              height={35}
              width={60}
              radius="md"
              src="/chapa-logo.png"
              alt="logo"
            />
          ) : transaction.method.toLowerCase() === "telebirr" ? (
            <Image
              className="light:bg-gray-400 light:py-2"
              height={25}
              width={65}
              radius="md"
              src="/ktelebirr-logo.png"
              alt="logo"
            />
          ) : (
            <>
              <Image
                className="light:bg-gray-400 light:py-2"
                height={16}
                width={16}
                radius="md"
                src="/favicon-32x32.ico"
                alt="logo"
              />
              <div>{` Kabba`}</div>
            </>
          )}
        </td>
        <td className="text-xs">
          <span className="hidden md:block">{transaction?.reason}</span>
        </td>
        <td className="text-xs">
          {transaction?.status === "2" && "success"}
          {transaction?.status === "1" && "pending"}
          {transaction?.status === "0" && "failed"}
          {transaction?.status === "success" && "success"}
          {transaction?.status === "pending" && "pending"}
          {transaction?.status === "failed" && "failed"}
        </td>
        <td
          className={` ${
            transaction?.amount > 0 ? "text-green-500" : "text-red-500"
          }`}
        >
          {Number(transaction?.amount)?.toFixed(2)}ETB
        </td>
      </tr>
    )
  );

  useEffect(() => {
    getCorporateTransaction({ id: params.id, collection: collection });
  }, [getCorporateTransaction, collection, params.id]);

  useEffect(() => {
    getCorporateWallet(`${params.id}`);
    getCorporate(`${params?.id}`);
  }, [getCorporateWallet, getCorporate, params.id]);

  useEffect(() => {
    const filter = [
      filterValue.map((value) => {
        return { field: "method", value: value, operator: "=" };
      }),
    ];
    setCollection({ ...collection, filter: filter });
  }, [filterValue]);

  return (
    <Card
      className="mt-12 bg-white"
      titleParentClassNames="w-full"
      title={
        <div className="flex w-full justify-between items-center">
          <div>Transactions</div>
          <div onClick={() => navigate(-1)}>
            <IconArrowBack size={18} />
          </div>
        </div>
      }
    >
      <div className="">
        <div className="w-full  p-2  flex md:flex-row flex-col justify-between items-center bg-gradient-to-r from-primary to-sky-50 ">
          <div className="h-full w-full hidden md:block  flex  px-4">
            <Image
              className="text-gray-700"
              height={60}
              width={150}
              radius="md"
              src="/kabba-logo.png"
              alt="logo"
            />
          </div>
          <div className="w-full flex md:justify-end">
            <div className="flex-col h-fit space-y-2">
              <div className="font-semibold text-white md:text-gray-800 ">
                {corporate?.data?.name}
              </div>
              {corporateWallet?.data?.balance && (
                <div className="font-semibold flex space-x-2 text-white md:text-gray-800 ">
                  <span>Balance:</span>
                  <span className="text-sm flex items-center text-green-600">
                    {new Intl.NumberFormat().format(
                      corporateWallet?.data?.balance?.toFixed(2)
                    )}
                    ETB
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <>
          <Table
            className="w-full "
            horizontalSpacing="lg"
            withBorder
            highlightOnHover
            verticalSpacing={"md"}
          >
            <thead>
              <tr>
                <th>
                  <div className="flex space-x-2 items-center">
                    <span>Done via</span>

                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <span>
                          <IconFilter size={16} />
                        </span>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>Filter By Payment Gateway</Menu.Label>
                        <Menu.Item className="w-full">
                          <Checkbox.Group
                            value={filterValue}
                            onChange={setFilterValue}
                            className="flex-col space-y-2"
                          >
                            <Checkbox
                              className="w-full"
                              value="Chapa"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-semibold",
                              }}
                              label="Chapa"
                            />
                            <Checkbox
                              className="w-full"
                              value="Telebirr"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-semibold",
                              }}
                              label="Telebirr"
                            />
                            <Checkbox
                              className="w-full"
                              value="Kabba"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-semibold",
                              }}
                              label="Kabba"
                            />
                          </Checkbox.Group>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </th>
                <th>
                  <span className="hidden md:block">Reason</span>
                </th>
                <th>Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.isLoading ||
              transactions?.isFetching ||
              !transactions?.data?.data.length ? (
                <tr className="relative">
                  <td
                    colSpan={5}
                    className="w-full justify-center items-center"
                  >
                    <>
                      <LoadingOverlay
                        visible={
                          transactions?.isLoading || transactions?.isFetching
                        }
                      />
                      <EmptyIcon />
                    </>
                  </td>
                </tr>
              ) : (
                rows
              )}
            </tbody>
          </Table>
          {transactions?.isSuccess && transactions?.data?.data?.length > 0 && (
            <div className="w-full flex justify-end items-center mt-2">
              <Pagination
                total={transactions?.data ? transactions?.data?.count : 0}
                defaultPageSize={10}
                pageSize={[10, 15, 20]}
                onPaginationChange={(skip: number, top: number) => {
                  setCollection({ ...collection, skip: skip, top: top });
                }}
              />
            </div>
          )}
        </>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<span className="font-semibold">Transaction</span>}
        size={"md"}
        centered
      >
        <div className="w-full flex-col space-y-2 border">
          <div className="flex justify-between px-2 bg-gradient-to-r from-primary to-orange-50">
            <div className=" w-full flex items-center">
              <Image
                className=" text-gray-700"
                height={40}
                width={100}
                radius="md"
                src="/kabba-logo.png"
                alt="logo"
              />
            </div>
            <div className=" w-full items-center space-x-2 justify-end  flex ">
              {selectedTransaction?.method === "chapa" ? (
                <Image
                  className="text-gray-700"
                  height={40}
                  width={75}
                  radius="md"
                  src="/chapa-logo.png"
                  alt="logo"
                />
              ) : selectedTransaction?.method === "Telebirr" ? (
                <Image
                  className="light:bg-gray-400 light:py-2"
                  height={25}
                  width={60}
                  radius="md"
                  src="/ktelebirr-logo.png"
                  alt="logo"
                />
              ) : (
                <>
                  <Image
                    className="light:bg-gray-400 light:py-2"
                    height={32}
                    width={32}
                    radius="md"
                    src="/favicon-32x32.ico"
                    alt="logo"
                  />
                  <div>{` Kabba`}</div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex text-xs item-center px-2">
              {dateFormat(
                selectedTransaction?.createdAt,
                "mmmm dS, yyyy hh:mm TT"
              )}
            </div>
          </div>
          <div className="flex px-2 justify-between border items-center border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Corporate</div>
            <div className="h-12 text-xs flex items-center">
              {corporate?.data?.name}
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">
              Transaction number
            </div>
            <div className="h-12 text-xs flex items-center">
              {selectedTransaction?.transactionNumber}
            </div>
          </div>
          {/* <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
              <div className="h-12 text-sm flex items-center">Narrator</div>
              <div className="h-12 text-xs flex items-center">
                {selectedTransaction?.depositedBy?.name}
              </div>
            </div> */}
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Amount</div>
            <div className="h-12 text-xs flex items-center">
              {Number(selectedTransaction?.amount)?.toFixed(2)}
              ETB
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Reason</div>
            <div className="h-12 text-xs flex items-center">
              {selectedTransaction?.reason}
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
}

export const PARENT_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/parents/get-parent`,
    delete:`${process.env.REACT_APP_API}/parents/delete-parent`,
    toggleStatus:`${process.env.REACT_APP_API}/parents/activate-or-block-parent`,
    archive:`${process.env.REACT_APP_API}/parents/archive-parent`,
    restore:`${process.env.REACT_APP_API}/parents/restore-parent`,
    archivedList:`${process.env.REACT_APP_API}/parents/get-archived-parents`,
    archivedParent:`${process.env.REACT_APP_API}/parents/get-archived-parent`,
    list:`${process.env.REACT_APP_API}/parents/get-parents`,
    estimateKidFee:`${process.env.REACT_APP_API}/parents/calculate-kid-fee`,
    create:`${process.env.REACT_APP_API}/parents/create-parent`,
    update:`${process.env.REACT_APP_API}/parents/update-parent`,
    changeStatus: `${process.env.REACT_APP_API}/parents/change-status`,

    //kids

    getParentKids: `${process.env.REACT_APP_API}/parents/get-kids-with-parent-id`,

    getKid: `${process.env.REACT_APP_API}/parents/get-kid`,
    getKids: `${process.env.REACT_APP_API}/parents/get-kids`,
    getArchivedKids: `${process.env.REACT_APP_API}/parents/get-archived-kids-with-parent-id`,

    createKid: `${process.env.REACT_APP_API}/parents/add-kid`,
    toggleKidStatus: `${process.env.REACT_APP_API}/parents/activate-or-block-kid`,
    deleteKid: `${process.env.REACT_APP_API}/parents/remove-kid`,
    restoreKid: `${process.env.REACT_APP_API}/parents/restore-kid`,
    archiveKid: `${process.env.REACT_APP_API}/parents/archive-kid`,
    updateKid: `${process.env.REACT_APP_API}/parents/update-kid-dashboard`,

    updateKidProfile: `${process.env.REACT_APP_API}/parents/update-kid-profile-image`,
    payManually: `${process.env.REACT_APP_API}/payments/pay-manually`,

    createParentPreference: `${process.env.REACT_APP_API}/parent-preferences/create-parent-preference-dashboard` 

}

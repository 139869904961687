import { Button } from "@mantine/core";
import { IconArchive } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Feedback } from "../../../models/feedback.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteFeedbackMutation,
  useLazyGetFeedbacksQuery,
} from "../store/feedback.query";

export default function FeedbackPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [getFeedbacks, feedbacks] = useLazyGetFeedbacksQuery();
  const [deleteFeedback, deleteResponse] = useDeleteFeedbackMutation();

  useEffect(() => {
    getFeedbacks(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/city/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Feedback[] | undefined = feedbacks.data?.data;
  const config: EntityConfig<Feedback> = {
    primaryColumn: { key: "name", name: "Name" },
    rootUrl: "/feedback",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "phoneNumber", name: "Phone Number" },
      { key: "subject", name: "Subject" },
      { key: "description", name: "Description" },
      { key: "createdAt", name: "Created at", isDate: true },
      {
        key: "actions",
        name: "Actions",
        render: (value: any) => {
          return (
        
              <Confirmation
                type={"notify"}
                message={"Are you sure you want to delete this feedback?"}
                header={`Delete`}
                yesText={"Delete"}
                onYes={(reason: string) => {
                  deleteFeedback(value.id);
                }}
              >
                <Button
                  variant="filled"
                  className="bg-danger text-white"
                  type="button"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                >
                  <span>
                    <IconArchive size={22} strokeWidth={1.5} />
                  </span>
                </Button>
              </Confirmation>
          );
        },
      },
    ],
    filter: [
      [
        {
          field: "subject",
          value: "App Issues",
          operator: "=",
          name: "App Issues",
        },
        {
          field: "subject",
          value: "Complaint",
          operator: "=",
          name: "Complaint",
        },
        {
          field: "subject",
          value: "Thank you!",
          operator: "=",
          name: "Thank you!",
        },
        { field: "subject", value: "other", operator: "=", name: "Other" },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Feedbacks"}
        total={feedbacks?.data?.count}
        itemsLoading={feedbacks.isFetching}
        showNewButton={false}
        showExport={false}
        showArchivedCheckBox={false}
        showSelector={false}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

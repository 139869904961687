import { Avatar, Input, LoadingOverlay, Tabs } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useInView } from "react-cool-inview";
import { useLocation, useParams } from "react-router-dom";
import { Chat } from "../../../models/chat.model";
import { CollectionQuery } from "../../../models/collection.model";
import { entityViewMode } from "../../../models/entity-config.model";
import { CredentialType } from "../../../models/notification.model";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useLazyGetAccountsQuery,
  useLazyGetChatsQuery,
  useLazyGetPeopleInChatsQuery,
} from "../store/chat.query";

export default function ChatPage() {
  const params = useParams();
  const location = useLocation();
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);
  const [type, setType] = useState<string | null>("parent");
  const [selectedChat, setSelectedChat] = useState<string | null>();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [senderId, setSenderId] = useState<string | null>(null);
  const [senderSearch, setSenderSearch] = useState<string | null>(null);
  const [receiverSearch, setReceiverSearch] = useState<string | null>(null);
  const [receiverId, setReceiverId] = useState<string | null>(null);
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["accountSender", "accountReceiver"],
  });

  const [peopleCollection, setPeopleCollection] = useState<CollectionQuery>({
    top: 10,
    skip: 0,
  });
  const [accountCollection, setAccountCollection] = useState<CollectionQuery>({
    top: 10,
    skip: 0,
  });

  const [getAccounts, accounts] = useLazyGetAccountsQuery();

  const [getChats, chats] = useLazyGetChatsQuery();
  const [getPeople, people] = useLazyGetPeopleInChatsQuery();
  const defaultValue = {
    title: "",
    body: "",
    type: CredentialType.All,
  };

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "50px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve }) => {
      // Pause observe when loading data
      setCollection({ ...collection, top: (collection?.top ?? 0) + 10 });

      unobserve();
      // Load more data
      setCollection({ ...collection, top: (collection?.top ?? 0) + 10 });
    },
  });

  useEffect(() => {
    getAccounts({
      ...accountCollection,
      filter: [[{ field: "type", value: type, operator: "=" }]],
    });
  }, [accountCollection, type]);

  useEffect(() => {
    if (senderId) {
      getPeople({ id: senderId ?? "", q: peopleCollection });
    }
  }, [senderId]);

  useEffect(() => {
   if(selectedChat){
    getChats({
      ...collection,
      filter: [
        [
          { field: "senderId", operator: "=", value: selectedChat },
          { field: "receiverId", operator: "=", value: selectedChat },
        ],
      ],
      orderBy: [{ field: "createdAt", direction: "desc" }],
    });
   }
  }, [selectedChat, collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/city/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);


  const data: Chat[] | undefined = chats.data?.data;

  return (
    <div className="flex flex-col items-start justify-start">
        <div className="flex w-full space-x-8 justify-center">
          <div className="flex-col space-y-2 p-2  rounded w-1/4">
            <div className="h-full overflow-y-aduto">
              <Tabs
                defaultValue={type}
                onTabChange={(tab) => {
                  setType(tab);
                  setSelectedChat(null);
                }}
              >
                <Tabs.List>
                  <Tabs.Tab value="parent">Parent</Tabs.Tab>
                  <Tabs.Tab value="driver">Driver</Tabs.Tab>
                </Tabs.List>
                <div className="w-full my-2">
                  <Input
                    icon={<IconSearch />}
                    onKeyUp={debounce(
                      (event: any) =>
                        setAccountCollection({
                          skip: 0,
                          top: 10,
                          search: event?.target?.value,
                          searchFrom: ["email", "name", "phoneNumber"],
                        }),
                      1000
                    )}
                    className="w-full"
                    placeholder="Search here"
                  />
                </div>
                <Tabs.Panel
                  value="parent"
                  pt="xs"
                  className="flex-col dark:text-white relative dark:bg-none min-h-screen space-y-1 "
                >
                  <LoadingOverlay
                    visible={accounts?.isLoading || accounts?.isFetching}
                  />
                  {accounts?.data?.data && accounts?.data?.data?.length > 0 ? (
                    accounts?.data?.data?.map((account) => {
                      return (
                        <div
                          onClick={() => setSelectedChat(account?.id)}
                          className={`flex space-x-4 cursor-pointer text-sm   items-center border-primary  rounded ${
                            selectedChat === account.id &&
                            "bg-primary text-white"
                          }  hover:bg-primary hover:text-white`}
                        >
                          <Avatar
                            src={downloadUrlParser({
                              path: account?.profileImage?.path,
                              filename: account?.profileImage?.filename,
                              originalname: account?.profileImage?.originalname,
                              mimetype: account?.profileImage?.mimetype,
                            })}
                            alt="it's me"
                            className="rounded-full h-12 w-12 m-1"
                          />
                          <div className="flex-col space-y-1">
                            <div>{account?.name}</div>
                            <div className="text-xs">{account?.type}</div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <EmptyIcon />
                  )}
                </Tabs.Panel>

                <Tabs.Panel
                  value="driver"
                  pt="xs"
                  className="flex-col space-y-1 relative  dark:text-white dark:bg-none  min-h-screen"
                >
                  <LoadingOverlay
                    visible={accounts?.isLoading || accounts?.isFetching}
                  />
                  {accounts?.data?.data && accounts?.data?.data?.length > 0 ? (
                    accounts?.data?.data?.map((account) => {
                      return (
                        <div
                          onClick={() => setSelectedChat(account?.id)}
                          className={`flex space-x-4 cursor-pointer text-sm   items-center border-primary  rounded ${
                            selectedChat === account?.id &&
                            "bg-primary text-white"
                          } hover:bg-primary hover:text-white`}
                        >
                          <Avatar
                            src={downloadUrlParser({
                              path: account?.profileImage?.path,
                              filename: account?.profileImage?.filename,
                              originalname: account?.profileImage?.originalname,
                              mimetype: account?.profileImage?.mimetype,
                            })}
                            alt="it's me"
                            className="rounded-full h-12 w-12 m-1"
                          />
                          <div className="flex-col space-y-1">
                            <div>{account?.name}</div>
                            <div className="text-xs">{account?.type}</div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <EmptyIcon />
                  )}
                </Tabs.Panel>
                <div className="mt-2"><Pagination total={accounts?.data?.count??0} pageSize={[10]} defaultPageSize={10} onPaginationChange={(skip:number,top:number)=>{
                 setAccountCollection({...accountCollection,skip:skip, top:top});
                }}/></div>
              </Tabs>
            </div>
          </div>
          <div className="flex  p-2 mt-10 mb-4 shadow relative overflow-y-auto rounded spacey-2 border w-1/2">
            <div className="flex-col space-y-4 h-96 w-full  ">
              <LoadingOverlay visible={chats?.isLoading || chats.isFetching} />
              {chats?.data?.data && chats?.data?.data?.length > 0 ? (
                chats?.data?.data?.map((chat) => {
                  if (chat.senderId === selectedChat) {
                    return (
                      <div className="flex space-x-2 items-center justify-end">
                        <div className="right-chat w-fit  relative bg-gray-300 rounded-lg text-sm p-2 text-black">
                          {chat.body ?? ""}
                        </div>
                        <Avatar
                          src={downloadUrlParser({
                            path: chat?.accountSender?.profileImage?.path,
                            filename: chat?.accountSender?.profileImage?.filename,
                            originalname:
                              chat?.accountSender?.profileImage?.originalname,
                            mimetype: chat?.accountSender?.profileImage?.mimetype,
                          })}
                          alt="it's me"
                          className="rounded-full border border-gray-300 flex self-end h-12 w-12 m-1"
                        />
                      </div>
                    );
                  }
                  return (
                    <div className="flex space-x-2 items-center">
                      <Avatar
                        src={downloadUrlParser({
                          path: chat?.accountSender?.profileImage?.path,
                          filename: chat?.accountSender?.profileImage?.filename,
                          originalname:
                            chat?.accountSender?.profileImage?.originalname,
                          mimetype: chat?.accountSender?.profileImage?.mimetype,
                        })}
                        alt="it's me"
                        className="rounded-full border flex  h-12 w-12 m-1"
                      />
                      <div className="chat relative w-fit bg-gray-600 rounded-lg text-sm p-2 text-white">
                        {chat?.body ?? ""}
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyIcon />
              )}
              {(chats?.data?.data && chats?.data?.data?.length > 0) && (
                <div ref={observe} className="invisible">
                  Infinite Scroll
                </div>
              )}
            </div>
          </div>
        </div>
    </div>
  );
}

export const JOB_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/jobs/get-job`,
    delete:`${process.env.REACT_APP_API}/jobs/delete-jobs`,
    toggleStatus:`${process.env.REACT_APP_API}/jobs/activate-or-block-jobs`,
    archive:`${process.env.REACT_APP_API}/jobs/archive-job`,
    restore:`${process.env.REACT_APP_API}/jobs/restore-job`,
    archivedList:`${process.env.REACT_APP_API}/jobs/get-archived-jobs`,
    archivedJob:`${process.env.REACT_APP_API}/jobs/get-archived-job`,
    list:`${process.env.REACT_APP_API}/jobs/get-jobs`,
    create:`${process.env.REACT_APP_API}/jobs/create-job`,
    update:`${process.env.REACT_APP_API}/jobs/update-job`,
    openOrClose: `${process.env.REACT_APP_API}/jobs/open-or-close-job`,


    getCandidates: `${process.env.REACT_APP_API}/jobs/search-candidates`,
    removeCandidate: `${process.env.REACT_APP_API}/jobs/remove-candidate`,

    createDriverAssignment: `${process.env.REACT_APP_API}/driver-assignments/create-driver-assignment`,
    
    getUnassignedGroups:`${process.env.REACT_APP_API}/groups/get-unassigned-groups`,

    unassignDriver: `${process.env.REACT_APP_API}/driver-assignments/delete-driver-assignment`,
    getDriverAssignmentsForJob: `${process.env.REACT_APP_API}/driver-assignments/get-driver-assignments`,
}

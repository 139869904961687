/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Transaction } from "../../../models/transaction.model";
import { Wallet } from "../../../models/wallet.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { WALLET_ENDPOINT } from "../wallet.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let passengerTransactionCollection: { passengerId: string; collection: CollectionQuery };
let corporateTransactionCollection: { corporateId: string; collection: CollectionQuery };

const walletQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getWallets: builder.query<Collection<Wallet>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${WALLET_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getCorporateWallets: builder.query<Collection<Wallet>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${WALLET_ENDPOINT.corporate_wallets}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getCorporateWallet: builder.query<Wallet, string>({
      query: (id: string) => ({
        url: `${WALLET_ENDPOINT.corporate_wallet}/${id}`,
        method: "GET",
      }),
    }),
    getPassengerWallet: builder.query<Wallet, string>({
      query: (id: string) => ({
        url: `${WALLET_ENDPOINT.passenger_wallet}/${id}`,
        method: "GET",
      }),
    }),
    getArchivedWallets: builder.query<Collection<Wallet>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${WALLET_ENDPOINT.archived_wallets}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getPassengerTransaction: builder.query<Collection<Transaction>, any>({
      query: (data: { passengerId: string; collection: CollectionQuery }) => ({
        url: `${WALLET_ENDPOINT.passenger_transactions}/${data.passengerId}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          passengerTransactionCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    getCorporateTransaction: builder.query<
      Collection<Transaction>,
      { corporateId: string; collection: CollectionQuery }
    >({
      query: (data: { corporateId: string; collection: CollectionQuery }) => ({
        url: `${WALLET_ENDPOINT.corporate_transactions}/${data.corporateId}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          corporateTransactionCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    addBalanceToCorporate: builder.mutation<any, any>({
      query: (data: { corporateId: string; balance: number }) => ({
        url: `${WALLET_ENDPOINT.add_balance_to_corporate}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            walletQuery.util.updateQueryData(
              "getCorporateWallets",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((wallet) => {
                  if (wallet?.corporateId === param?.corporateId) {
                    return { ...wallet, balance: data?.balance };
                  }
                  return wallet;
                });
              }
            )
          );
          dispatch(
            walletQuery.util.updateQueryData(
              "getCorporateWallet",
              param?.corporateId,
              (draft) => {
                draft.balance = data?.balance;
              }
            )
          );
          dispatch(
            walletQuery.util.updateQueryData(
              "getCorporateTransaction",
              corporateTransactionCollection,
              (draft) => {
                draft.data=[data.transaction,...draft.data];
              }
            )
          );
         
          notification("success", "Balance is successfully added");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
    addBalanceToPassenger: builder.mutation<any, any>({
      query: (data: { passengerId: string; balance: number }) => ({
        url: `${WALLET_ENDPOINT.add_balance_to_passenger}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            walletQuery.util.updateQueryData(
              "getWallets",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((wallet) => {
                  if (wallet?.passengerId === param?.passengerId) {
                    return {
                      ...wallet,
                      balance: +(wallet.balance + parseFloat(data?.amount)),
                    };
                  }
                  return wallet;
                });
              }
            )
          );
          dispatch(
            walletQuery.util.updateQueryData(
              "getPassengerWallet",
              param?.passengerId,
              (draft) => {
                draft.balance +=parseFloat(data?.amount);
              }
            )
          );

          dispatch(
            walletQuery.util.updateQueryData(
              "getPassengerTransaction",
              passengerTransactionCollection,
              (draft) => {
                draft.data=[data,...draft.data];
              }
            )
          );
          notification("success", "Balance is successfully added");
        } catch (error: any) {

          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetArchivedWalletsQuery,
  useLazyGetPassengerWalletQuery,
  useLazyGetWalletsQuery,
  useLazyGetPassengerTransactionQuery,
  useLazyGetCorporateWalletQuery,
  useLazyGetCorporateWalletsQuery,
  useLazyGetCorporateTransactionQuery,
  useAddBalanceToCorporateMutation,
  useAddBalanceToPassengerMutation,
} = walletQuery;

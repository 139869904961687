import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedPassengerComponent from "../component/archived-passenger-component";
import PassengerDetailComponent from "../component/passenger-detail-component";
import PassengerPage from "../page/passenger-page";
export const PassengerRoute: RouteObject = {
  path: "/registration/passenger",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <PassengerPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <PassengerDetailComponent editMode="detail" /> },
    { path: "archived", element: <ArchivedPassengerComponent /> },
  ],
};

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedCityComponent from "../component/archived-city-component";
import NewCityComponent from "../component/new-city-component";
import CityPage from "../page/city-page";
export const CityRoute: RouteObject = {
  path: "/settings-and-configurables/city",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <CityPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewCityComponent editMode="detail" /> },
    { path: ":id", element: <NewCityComponent editMode="new" /> },
    { path: "archived", element: <ArchivedCityComponent /> },
  ],
};

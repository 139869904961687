import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedBusStopComponent from "../component/archived-bus-stop-component";
import NewBusStopComponent from "../component/new-bus-stop-component";
import BusStopPage from "../page/bus-stop-page";
export const BusStopRoute: RouteObject = {
  path: "/settings-and-configurables/bus-stop",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <BusStopPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewBusStopComponent editMode="detail" /> },
    { path: ":id", element: <NewBusStopComponent editMode="new" /> },
    { path: "archived", element: <ArchivedBusStopComponent /> },
  ],
};

import { Provider } from "./provider.model";
import { Route } from "./route.model";

export const AssignmentStatus: Record<string, string> = {
  Assigned: "blue",
  Cancelled: "red",
  Started: "yellow",
  Completed: "teal",
};
export interface RouteAssignment {
  id?: string;
  routeId?: string;
  route?: Route;
  driverId?: string;
  driver?: Provider;
  vehicleId?: string;
  vehiclePlateNumber?: string;
  status?: string;
  fee?: 0;
  assignmentDate?: Date;
  remark?: string;
  cancellationReason?: string;
  isPaid?: true;
  availableSeats?: 0;
  startingTime?: Date;
  completedTime?: Date;
  shift?: string;
  assignmentWeekDays?: string[];
  driverPhone?: string;
  driverName?: string;
  vehicleModel?: string;
  routeName?: string;
  morningPickupTime?: string;
  afternoonPickupTime?: string;
  pickupTime?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  deletedBy?: string;
}

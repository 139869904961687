import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { Service } from "../../../models/service.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteServiceMutation,
  useLazyGetArchivedServicesQuery,
  useRestoreServiceMutation
} from "../store/service.query";
export default function ArchivedServiceComponent() {
  const params = useParams();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedServices, archivedServices] =
    useLazyGetArchivedServicesQuery();
  const [restoreService, restoredService] = useRestoreServiceMutation();
  const [deleteService, deleteResponse] = useDeleteServiceMutation();

  useEffect(() => {
    getArchivedServices(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id]);

  const data: Service[] | undefined = archivedServices.data?.data;
  const config: EntityConfig = {
    primaryColumn: { key: "title", name: "Title" },
    rootUrl: "/service",
    showDetail: false,
    identity: "id",
    name: "title",
    visibleColumn: [
      { key: "title", name: "Title" },
      { key: "createdAt", name: "Created At", isDate: true },
      {
        key: "",
        name: "Action",
        print:false,
        hideSort: true,
        render: (service: any) => {
          return (
            <div className="flex space-x-1 items-center justify-center">
              <Confirmation
                type={"notify"}
                message={`Are you sure you want to restore it`}
                onYes={() => restoreService(`${service.id}`)}
                header={`Restore confirmation `}
              >
                <div className="px-2 bg-primary rounded text-white cursor-pointer relative">
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Confirmation
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                onYes={() => deleteService(`${service?.id}`)}
                header={`Permanent Delete confirmation `}
              >
                <div className="px-2 bg-danger rounded text-white cursor-pointer">
                  <IconTrash size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: service?.name,
                      reason: service?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
  };
  return (
    <div className="w-full h-full">
      <EntityList
        viewMode={viewMode}
        showNewButton={false}
        showExport={false}
        title={"Archived Services"}
        total={archivedServices?.data?.count}
        itemsLoading={
          archivedServices.isLoading ||archivedServices?.isFetching||
          restoredService?.isLoading ||
          deleteResponse?.isLoading
        }
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["title", "description"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

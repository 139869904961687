import {
  Box,
  Group,
  Input,
  LoadingOverlay,
  Radio,
  ScrollArea,
  Select,
  Table,
  Text,
  ThemeIcon,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { NumericFormat } from "react-number-format";
import { CollectionQuery, Filter } from "../../../models/collection.model";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import { useLazyGetRoutesQuery } from "../../route/store/route.query";
import {
  useLazyGetKidsCompanyEarningQuery,
  useLazyGroupKidsEarningByCategoryQuery,
  useLazyGroupKidsEarningByCreatedDateQuery,
  useLazyGroupKidsEarningByFormatQuery,
} from "../store/dashboard.query";
import { decodeMonth } from "../../../shared/constants/Constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const useStyles = createStyles((theme) => ({
  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `${rem(3)} solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Vehicles Status",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Earnings",
    },
  },
};

export default function KidsEarningDashboardPage() {
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const { classes, theme } = useStyles();
  const [value, setDateRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [dateFilter, setDateFilterModel] = useState<Filter[]>([]);
  const [categoryFilter, setCategoryFilterModel] = useState<Filter[]>([]);
  const [bar_state_data, setBarData] = useState<any>();
  const [totalEarning, setTotalEarning] = useState<number>();

  const [getRoutes, routes] = useLazyGetRoutesQuery();
  const [GroupKidsEarningsByCreatedDate, createdDateResponse] =
    useLazyGroupKidsEarningByCreatedDateQuery();
  const [getCompanyEarnings, companyEarnings] =
    useLazyGetKidsCompanyEarningQuery();
  const [groupEarningByFormat, earningOnFormat] =
    useLazyGroupKidsEarningByFormatQuery();
  const [groupEarningbyCategory, earningOnCategory] =
    useLazyGroupKidsEarningByCategoryQuery();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 5,
  });

  useEffect(() => {
    if (value?.length > 0) {
      let date = [];
      if (value?.[0] && value?.[1]) {
        date.push({
          field: "createdAt",
          value: [dayjs(value?.[0]).format(), dayjs(value?.[1]).format()],
          operator: "between",
        });
      }

      setDateFilterModel(date);
    } else {
      setDateFilterModel([]);
    }
  }, [value]);

  useEffect(() => {
    let filter = collection.filter;
    let formatFilter = collection.filter;
    if (categoryFilter && categoryFilter?.length > 0) {
      filter = [
        ...(filter ?? []),
        [
          {
            field: "kids.categoryId",
            value: categoryFilter?.[0]?.value,
            operator: "=",
          },
        ],
      ];
      formatFilter = [
        ...(formatFilter ?? []),
        [
          {
            field: "kid.categoryId",
            value: categoryFilter?.[0]?.value,
            operator: "=",
          },
        ],
      ];
    }
    groupEarningByFormat({
      format: "date",
      collection: { ...collection, filter: formatFilter },
    });
    getCompanyEarnings({
      ...collection,
      filter: filter,
      includes: ["parent", "parent.kids"],
    }).then((response) => {
      if (
        collection.skip === 0 &&
        collection?.top === 5 &&
        collection?.filter?.[0].length === 0
      ) {
        setTotalEarning(response?.data?.total);
      }
    });
  }, [collection]);

  useEffect(() => {
    let filter = [];
    if (filterData) {
      filter.push(filterData);
    } else {
    }
    if (dateFilter.length > 0) {
      filter.push(dateFilter);
    }
    // if (categoryFilter.length > 0) {
    //   filter.push(categoryFilter);
    // }

    setCollection({ ...collection, filter: filter });
  }, [filterData, categoryFilter, dateFilter]);

  useEffect(() => {
    let labels: string[];
    let data: number[];
    getCategories({ skip: 0 });
    async function getNewKidsEarningsEveryDate() {
      const response = await GroupKidsEarningsByCreatedDate(createdDateFormat);
      if (response?.data) {
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? dateFormat(item.createdAt, {
                hour: false,
                hour12: false,
                weekday: false,
                minute: false,
                day: false,
              })
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response.data.map((item: any) => item.total);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Earnings",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    getNewKidsEarningsEveryDate();
  }, [createdDateFormat]);

  useEffect(() => {
    let filter = collection.filter;
    if (categoryFilter && categoryFilter.length > 0) {
      filter = [
        ...(filter ?? []),
        [
          {
            field: "kid.categoryId",
            value: categoryFilter[0].value,
            operator: "=",
          },
        ],
      ];
    }
    groupEarningbyCategory({ ...collection, filter: filter });
  }, [collection]);

  const rows = earningOnFormat?.data?.map((row: any) => {
    return (
      <tr key={row.createdAt}>
        <td>
          {dateFormat(row.createdAt, { hour: false, weekday: true, day: true })}
        </td>

        <td>{Intl.NumberFormat().format(row?.total ?? 0)}</td>
      </tr>
    );
  });

  const categoryIncomeRows = earningOnCategory?.data?.map((row: any) => {
    return (
      <tr key={row.categoryId}>
        <td>{row.categoryName}</td>

        <td className="space-x-2">
          <span>{(row?.companyEarning ?? 0)?.toFixed(2)}</span>
        </td>
      </tr>
    );
  });

  return (
    <div className="w-full p-4">
      <div className="flex w-full h-16 px-2 justify-between items-center mt-2 border border-sky-300 mb-2">
        <span className="capitalize font-semibold text-primary">
          Kids Earning dashboard report
        </span>
      </div>
      <div className="flex space-x-2 justify-between">
        <div className=" w-full shadow border rounded shadow-sky-100 p-2">
          <div className="mb-2 flex justify-between px-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Earning status on date basis
            </Text>
          </div>
          <div className="flex space-x-4 w-full items-center">
            {/* <Input.Wrapper label="Category" size="xs" className="w-full">
                <Input size="xs" />
              </Input.Wrapper> */}
            <Input.Wrapper label="Date" size="xs" className="w-full">
              <DatePickerInput
                className="w-full text-sm"
                clearable
                dropdownType="modal"
                type="range"
                size="xs"
                //@ts-ignore
                placeholder="Pick date range"
                allowSingleDateInRange
                value={value}
                onChange={setDateRangeValue}
                maw={400}
              />
            </Input.Wrapper>
            <Input.Wrapper label="Category" size="xs" className="w-full">
              <div className="flex">
                <Select
                  searchable
                  className="w-full "
                  size="xs"
                  clearable
                  nothingFound={
                    <div className="fle w-full justify-center items-center">
                      <EmptyIcon />
                    </div>
                  }
                  onChange={(data) => {
                    if (data) {
                      setCategoryFilterModel([
                        {
                          field: "kid.categoryId",
                          value: data,
                          operator: "=",
                        },
                      ]);
                    } else {
                      setCategoryFilterModel([]);
                    }
                  }}
                  placeholder="Filter by category"
                  data={
                    categories?.data?.data?.map((item: any) => ({
                      label: `${item.name}`,
                      value: item.id,
                    })) ?? []
                  }
                />
              </div>
            </Input.Wrapper>
          </div>
          <ScrollArea className="relative">
            <LoadingOverlay
              visible={
                earningOnFormat?.isLoading || earningOnFormat?.isFetching
              }
            />
            <Table fontSize={12} sx={{ minWidth: 500 }} verticalSpacing="xs">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Income</th>
                </tr>
              </thead>
              <tbody>
                {earningOnFormat?.data && earningOnFormat?.data?.length > 0 ? (
                  rows
                ) : (
                  <EmptyIcon />
                )}
              </tbody>
            </Table>
          </ScrollArea>
          <div className="flex justify-end w-full space-x-1">
            <UnstyledButton
              className="border"
              disabled={collection?.skip === 0}
              onClick={() =>
                setCollection({
                  ...collection,
                  skip: (collection?.skip ?? 5) - 5,
                })
              }
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                  <IconChevronLeft size="1.1rem" />
                </ThemeIcon>
              </Box>
            </UnstyledButton>
            <UnstyledButton
              className="border"
              onClick={() =>
                setCollection({
                  ...collection,
                  skip: (collection?.skip ?? 5) + 5,
                })
              }
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                  <IconChevronRight size="1.1rem" />
                </ThemeIcon>
              </Box>
            </UnstyledButton>
          </div>
        </div>
        <div className="h-full w-full space-y-4 rounded flex-col justify-center items-center shadow shadow-sky-100 p-2">
          {companyEarnings?.data && (
            <div className="flex-col space-y-2  border  w-full  items-center">
              <div className="text-2xl font-semibold flex justify-center w-full">
                Total Company Earning
              </div>
              <div className="text-4xl px-2 font-bold flex justify-center w-full">
                <NumericFormat
                  disabled
                  suffix={` ETB`}
                  className="max-w-fit mb-2 bg-none dark:bg-none"
                  value={companyEarnings?.data?.total ?? 0}
                  thousandSeparator=","
                  decimalScale={2}
                />
              </div>
            </div>
          )}
          <div>
            <LoadingOverlay
              visible={
                earningOnFormat?.isLoading || earningOnFormat?.isFetching
              }
            />
            <Table fontSize={12} withBorder verticalSpacing="xs">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Income</th>
                </tr>
              </thead>
              <tbody>{categoryIncomeRows}</tbody>
            </Table>
            {/* <div className="flex justify-end w-full space-x-1">
              <UnstyledButton
                className="border"
                disabled={collection?.skip === 0}
                onClick={() =>
                  setCategoryCollection({
                    ...categoryCollection,
                    skip: (categoryCollection?.skip ?? 5) - 5,
                  })
                }
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                    <IconChevronLeft size="1.1rem" />
                  </ThemeIcon>
                </Box>
              </UnstyledButton>
              <UnstyledButton
                className="border"
                onClick={() =>
                  setCategoryCollection({
                    ...categoryCollection,
                    skip: (categoryCollection?.skip ?? 5) + 5,
                  })
                }
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                    <IconChevronRight size="1.1rem" />
                  </ThemeIcon>
                </Box>
              </UnstyledButton>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex-col space-y-4 justify-between mt-5 shadow p-2 border rounded">
        <div className="flex space-x-12  ">
          <Radio.Group
            // label="Date Format"
            defaultValue={createdDateFormat}
            onChange={(e) => {
              setCreatedDateFormat(e);
            }}
          >
            <Group mt="xs">
              <Radio value="month" label="Monthly" />
              <Radio value="year" label="Yearly" />
            </Group>
          </Radio.Group>
        </div>
        <div className="flex space-x-2 justify-between mt-10 shadow">
          <div className="h-92 w-full border rounded flex justify-center shadow-lg shadow-sky-100 p-2">
            {createdDateResponse?.isLoading ? (
              <LoadingOverlay visible />
            ) : (
              bar_state_data && (
                <Bar options={bar_options} data={bar_state_data} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

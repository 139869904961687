export const DASHBOARD_ENDPOINT = {
  users: `${process.env.REACT_APP_API}/users/get-users`,
  passengers: `${process.env.REACT_APP_API}/passengers/get-passengers`,
  drivers: `${process.env.REACT_APP_API}/drivers/get-drivers`,
  owners: `${process.env.REACT_APP_API}/owners/get-owners`,
  bookings: `${process.env.REACT_APP_API}/bookings/get-bookings`,
  vehicles: `${process.env.REACT_APP_API}/owners/get-vehicles`,
  assignments: `${process.env.REACT_APP_API}/assignments/get-assignments`,
  total_deposit: `${process.env.REACT_APP_API}/transactions/get-total-transaction-deposits-sum`,
  count_vehicles_category: `${process.env.REACT_APP_API}/owners/count-vehicles-by-category`,
  count_passengers_by_gender: `${process.env.REACT_APP_API}/passengers/group-passengers-by-gender`,
  count_passengers_by_address: `${process.env.REACT_APP_API}/passengers/group-passengers-by-address`,
  count_drivers_by_gender: `${process.env.REACT_APP_API}/drivers/group-drivers-by-gender`,
  count_owners_by_address: `${process.env.REACT_APP_API}/owners/group-owners-by-address`,
  group_kids_by_created_date: `${process.env.REACT_APP_API}/parents/group-kids-by-created-date`,
  group_drivers_by_address: `${process.env.REACT_APP_API}/drivers/group-drivers-by-address`,
  group_owners_by_gender: `${process.env.REACT_APP_API}/owners/group-owners-by-gender`,
  group_owners_by_status: `${process.env.REACT_APP_API}/owners/group-owners-by-status`,
  group_parents_by_status: `${process.env.REACT_APP_API}/parents/group-parents-by-status`,
  group_parents_by_enabled: `${process.env.REACT_APP_API}/parents/group-parents-by-enabled`,
  group_kids_by_status: `${process.env.REACT_APP_API}/parents/group-kids-by-status`,
  group_kids_by_gender: `${process.env.REACT_APP_API}/parents/group-kids-by-gender`,
  group_kids_by_transportation_time: `${process.env.REACT_APP_API}/parents/group-kids-by-transportation-time`,
  routes: `${process.env.REACT_APP_API}/routes/get-routes`,
  booking: `${process.env.REACT_APP_API}/bookings/get-company-earning`,
  driver_earning: `${process.env.REACT_APP_API}/bookings/get-driver-earning`,
  driver_weekly_earning: `${process.env.REACT_APP_API}/bookings/get-driver-weekly-earning`,
  route_view: `${process.env.REACT_APP_API}/routes/update-route-view`,
  group_assignment_by_routes: `${process.env.REACT_APP_API}/assignments/group-assignments-by-routes`,
  group_assignment_by_status: `${process.env.REACT_APP_API}/assignments/group-assignments-by-status`,
  group_bookings_by_date: `${process.env.REACT_APP_API}/bookings/group-bookings-by-created-date`,
  get_booking_value: `${process.env.REACT_APP_API}/bookings/get-booking-value`,
  get_most_booked_routes: `${process.env.REACT_APP_API}/bookings/get-most-booked-routes`,
  group_bookings_by_status: `${process.env.REACT_APP_API}/bookings/group-bookings-by-status`,
  group_bookings_by_assignment: `${process.env.REACT_APP_API}/bookings/group-bookings-by-assignment`,
  group_booking_by_route: `${process.env.REACT_APP_API}/bookings/group-bookings-by-route`,
  group_booking_by_created_month: `${process.env.REACT_APP_API}/bookings/group-bookings-by-created-date`,
  //earning
  group_earning_by_created_date: `${process.env.REACT_APP_API}/assignments/group-earning-by-created-date`,
  group_kids_earning_by_created_date: `${process.env.REACT_APP_API}/payments/group-earning-by-created-date`,
  group_earning_by_format: `${process.env.REACT_APP_API}/assignments/group-earning-by-created-date`,
  group_kids_earning_by_format: `${process.env.REACT_APP_API}/payments/group-earning-by-created-date`,
  get_company_earning: `${process.env.REACT_APP_API}/assignments/get-company-earning`,
  get_kids_company_earning: `${process.env.REACT_APP_API}/payments/get-company-earning`,
  group_earning_by_category: `${process.env.REACT_APP_API}/assignments/group-earning-by-category`,
  group_kids_earning_by_category: `${process.env.REACT_APP_API}/payments/group-earning-by-category`,
  group_assignment_by_route: `${process.env.REACT_APP_API}/assignments/group-assignments-by-routes`,
  total_wallet_ballance: `${process.env.REACT_APP_API}/wallets/get-total-wallet-balance`,
  group_corporates_by_created_month: `${process.env.REACT_APP_API}/corporates/count-corporates-by-created-month`,
  group_corporates_by_created_date: `${process.env.REACT_APP_API}/corporates/group-corporates-by-created-date`,
  group_corporates_by_status: `${process.env.REACT_APP_API}/corporates/group-corporates-by-status`,
  corporate_transaction_spending: `${process.env.REACT_APP_API}/corporate-transactions/get-corporate-transaction-spending`,
  corporate_transaction_deposit: `${process.env.REACT_APP_API}/corporate-transactions/get-corporate-transaction-deposit-count`,
  corporate_transaction_spending_to_employee: `${process.env.REACT_APP_API}/corporate-transactions/get-corporate-transaction-spending-to-employee`,
  corporate_transaction_by_payment_method: `${process.env.REACT_APP_API}/corporate-transactions/group-corporate-transactions-by-payment-method`,
  group_transaction_by_status: `${process.env.REACT_APP_API}/transactions/group-transactions-by-status`,
  corporate_transaction_by_status: `${process.env.REACT_APP_API}/corporate-transactions/group-corporate-transactions-by-status`,
  total_corporate_deposit: `${process.env.REACT_APP_API}/corporate-transactions/get-total-corporate-deposits`,
  total_transaction_deposits: `${process.env.REACT_APP_API}/transactions/get-total-transaction-deposits`,
  get_total_deposits: `${process.env.REACT_APP_API}/transactions/get-total-deposits`,
  total_driver_fees: `${process.env.REACT_APP_API}/driver-fees/get-total-driver-fees`,
  total_corporate_balance: `${process.env.REACT_APP_API}/corporate-wallets/get-total-corporate-wallet-balance`,
  group_passenger_by_status: `${process.env.REACT_APP_API}/passengers/group-passengers-by-status`,
  group_passenger_by_gender: `${process.env.REACT_APP_API}/passengers/group-passengers-by-gender`,
  group_vehicle_by_status: `${process.env.REACT_APP_API}/owners/group-vehicles-by-status`,
  passenger_by_created_date: `${process.env.REACT_APP_API}/passengers/group-passengers-by-created-date`,
  group_vehicles_by_created_date: `${process.env.REACT_APP_API}/owners/group-vehicles-by-created-date`,
  group_passenger_by_created_date: `${process.env.REACT_APP_API}/passengers/group-passengers-by-created-date`,
  group_owners_by_created_date: `${process.env.REACT_APP_API}/owners/group-owners-by-created-date`,
  export_vehicles: `${process.env.REACT_APP_API}/owners/export-vehicles`,
  export_owners: `${process.env.REACT_APP_API}/owners/export-owners`,
  export_corporates: `${process.env.REACT_APP_API}/corporates/export-corporates`,
  export_passengers: `${process.env.REACT_APP_API}/passengers/export-passengers`,
  export_parents: `${process.env.REACT_APP_API}/parents/export-parents`,
  export_kids: `${process.env.REACT_APP_API}/parents/export-kids`,
};

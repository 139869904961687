import { Outlet, RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import AssignmentBookingsComponent from "../component/bookings-component";
import RouteAssignmentFormComponent from "../component/route-assignment.form.component";
import RouteAssignmentPage from "../page/route-assignment-page";
import AssignmentPaymentHistoryDetailComponent from "../sub-component/payment-history-detail";
import AssignmentPaymentPage from "../sub-component/payment-page";
import TripPage from "../../trip/page/trip-list-page";
export const RouteAssignmentRoute: RouteObject = {
  path: "/assignment",
  element: (
    <AuthGuard role={["super_admin", "admin", "finance", "operator"]}>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "payment/",
      element: <AssignmentPaymentPage />,
      children: [
        {
          path: "detail/:type/:id",
          element: (
            <AuthGuard role={["super_admin", "admin", "finance"]}>
              <AssignmentPaymentHistoryDetailComponent />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "detail/:id",
      element: <RouteAssignmentFormComponent editMode="detail" />,
    },
    // { path: "bulk", element: <BulkRouteAssignmentFormComponent /> },
    // { path: "bulk", element: <RouteAssignmentFormComponent editMode="new" /> },
    { path: "new", element: <RouteAssignmentFormComponent editMode="new" /> },
    { path: "", element: <RouteAssignmentPage /> },
    { path: "bookings/:id", element: <AssignmentBookingsComponent /> },
    { path: "trips/:assignmentId", element: <TripPage /> },
  ],
};

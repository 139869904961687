import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import { RolePage } from "../page/role-page";

export const RoleRoute: RouteObject = {
  path: "/user-management/role",
  element: (
    <AuthGuard role={['super_admin']}>
      <RolePage />
    </AuthGuard>
  ),
};

import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { Owner } from "../../../models/owner.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteOwnerMutation,
  useLazyGetArchivedOwnersQuery,
  useRestoreOwnerMutation
} from "../store/owner.query";

export default function ArchivedOwnerComponent() {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "deletedAt", direction: "desc" }],
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedOwners, archivedOwners] = useLazyGetArchivedOwnersQuery();
  const [restoreOwner, restoredOwner] = useRestoreOwnerMutation();
  const [deleteOwner, deleteResponse] = useDeleteOwnerMutation();

  useEffect(() => {
    getArchivedOwners(collection);
  }, [collection]);

  const data: Owner[] | undefined = archivedOwners.data?.data;
  const config: EntityConfig<Owner> = {
    showDetail: false,
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "",
    rootUrl: "/owner",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone", hideSort: true },
      { key: "isCompany", name: "Company", hideSort: true },
      { key: "tinNumber", name: "TIN Number" },

      { key: "createdAt", name: "Registered at", isDate: true, hideSort: true },
      { key: "deletedAt", name: "Archived at", isDate: true, hideSort: true },
      { key: "enabled", name: "Status", hideSort: true },
      {
        name: "Actions",
        hideSort: true,
        key: "",
        print: false,
        render: (owner) => {
          return (
            <div className="flex w-full space-x-1 items-center">
              <Confirmation
                type={"notify"}
                message={`Are you sure you want to restore it`}
                onYes={() => restoreOwner(`${owner.id}`)}
                header={`Restore confirmation `}
              >
                <div className="px-2 bg-primary rounded text-white cursor-pointer relative">
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Confirmation
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                onYes={() => deleteOwner(`${owner?.id}`)}
                header={`Permanent Delete confirmation `}
              >
                <div className="px-2 bg-danger rounded text-white cursor-pointer">
                  <IconTrash size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: owner?.name,
                      reason: owner?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
  };
  return (
    <>
      {/* <LoadingOverlay visible={archivedOwners.isLoading && archivedOwners.isSuccess} /> */}
      <div className="h-full w-full">
        <EntityList
          showNewButton={false}
          viewMode={"list"}
          title={"Archived Owners"}
          total={archivedOwners?.data?.count}
          itemsLoading={archivedOwners.isLoading || archivedOwners.isFetching}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          onPaginationChange={(skip: number, top: number) => {
            setCollection({ ...collection, skip: skip, top: top });
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: [
                "name",
                "email",
                "phoneNumber",
                "tinNumber",
                "website",
              ],
            });
          }}
          onFilterChange={(data: any) =>
            setCollection({ ...collection, filter: data })
          }
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
        <Modal
          opened={archiveReason?.opened}
          onClose={() => setArchiveReason({ ...archiveReason, opened: false })}
          title={`${archiveReason.name} archive reason`}
          centered
          size={"md"}
        >
          {archiveReason?.reason ? (
            <div className={"px-2 flex space-x-1 items-center"}>
              <IconPoint size={18} />
              <Text fz={"sm"}> {archiveReason?.reason}</Text>
            </div>
          ) : (
            <div className="flex w-full justify-center items-center h-full">
              <EmptyIcon />
            </div>
          )}
          <Group mt="xl" position="right">
            <Button
              variant="outline"
              size={"xs"}
              onClick={() =>
                setArchiveReason({ ...archiveReason, opened: false })
              }
            >
              Close
            </Button>
          </Group>
        </Modal>
      </div>
    </>
  );
}

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import AssignmentDashboardPage from "../page/assignment-dashboard-page";
import BookingDashboardPage from "../page/booking-dashboard-page";
import CorporateDashboardPage from "../page/corporate-dashboard-page";
import DashBoardComponent from "../page/dashboard-page";
import EarningDashboardPage from "../page/earning-dashboard";
import KabbaKidsDashboardPage from "../page/kabba-kids-dashboard-page";
import KidsEarningDashboardPage from "../page/kids-earning-dashboard";
import OwnerDashboardPage from "../page/owner-dashboard-page";
import PassengersDashboardPage from "../page/passengers-dashboard";
import UserDashboardPage from "../page/user-dashboard";
import VehicleDashboardPage from "../page/vehicle-dashboard-page";
const DashBoardRoute: RouteObject = {
  path: "/",
  element: (
    <AuthGuard>
      <DashBoardComponent />
    </AuthGuard>
  ),
};

const VehiclesDashboardRoute: RouteObject = {
  path: "/dashboard/vehicles",
  element: (
    <AuthGuard>
      <VehicleDashboardPage />
    </AuthGuard>
  ),
};

const PassengersDashboardRoute: RouteObject = {
  path: "/dashboard/passenger",
  element: (
    <AuthGuard>
      <PassengersDashboardPage />
    </AuthGuard>
  ),
};

const UsersDashboardRoute: RouteObject = {
  path: "/dashboard/users",
  element: (
    <AuthGuard>
      <UserDashboardPage />
    </AuthGuard>
  ),
};

const OwnerDashboardRoute: RouteObject = {
  path: "/dashboard/owner",
  element: (
    <AuthGuard>
      <OwnerDashboardPage />
    </AuthGuard>
  ),
};
const BookingDashboardRoute: RouteObject = {
  path: "/dashboard/booking",
  element: (
    <AuthGuard>
      <BookingDashboardPage />
    </AuthGuard>
  ),
};

const KabbaKidDashboardRoute: RouteObject = {
  path: "/dashboard/kabba-kids",
  element: (
    <AuthGuard>
      <KabbaKidsDashboardPage />
    </AuthGuard>
  ),
};

const AssignmentDashboardRoute: RouteObject = {
  path: "/dashboard/assignment",
  element: (
    <AuthGuard>
      <AssignmentDashboardPage />
    </AuthGuard>
  ),
};
const CorporateDashboardRoute: RouteObject = {
  path: "/dashboard/corporate",
  element: (
    <AuthGuard>
      <CorporateDashboardPage />
    </AuthGuard>
  ),
};
const EarningDashboardRoute: RouteObject = {
  path: "/dashboard/earning",
  element: (
    <AuthGuard>
      <EarningDashboardPage />
    </AuthGuard>
  ),
};
const KidsEarningDashboardRoute: RouteObject = {
  path: "/dashboard/kids-earning",
  element: (
    <AuthGuard>
      <KidsEarningDashboardPage />
    </AuthGuard>
  ),
};

export {
  AssignmentDashboardRoute, BookingDashboardRoute, CorporateDashboardRoute, DashBoardRoute, EarningDashboardRoute, KabbaKidDashboardRoute, KidsEarningDashboardRoute, OwnerDashboardRoute, PassengersDashboardRoute,
  UsersDashboardRoute, VehiclesDashboardRoute
};


import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedParentComponent from "../component/archived-parent-component";
import NewParentComponent from "../component/new-parent-component";
import ParentPage from "../page/parent-page";
export const ParentRoute: RouteObject = {
  path: "/registration/parent",
  element: (
    <AuthGuard role={["super_admin", "admin",'operator']}>
      <ParentPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewParentComponent editMode="detail" />,
    },
    { path: ":id", element: <NewParentComponent editMode="new" /> },
    { path: "archived", element: <ArchivedParentComponent /> },
  ],
};

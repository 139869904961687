import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedJobComponent from "../component/archived-job-component";
import NewJobComponent from "../component/new-job-component";
import JobPage from "../page/job-page";
export const JobRoute: RouteObject = {
  path: "/registration/job",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <JobPage />
     </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewJobComponent editMode="detail" /> },
    { path: ":id", element: <NewJobComponent editMode="new" /> },
    { path: "archived", element: <ArchivedJobComponent /> },
  ],
};

import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order
} from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { Vehicle } from "../../../models/vehicle.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import {
  useDeleteVehicleMutation,
  useLazyGetArchivedVehiclesQuery,
  useRestoreVehicleMutation
} from "../store/vehicle.query";

export default function ArchivedVehiclesComponent() {
  const location = useLocation();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["owner"],
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedVehicles, archivedVehicles] =
    useLazyGetArchivedVehiclesQuery();
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [deleteVehicle, deleteResponse] = useDeleteVehicleMutation();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [restoreVehicle, restoreResponse] = useRestoreVehicleMutation();

  useEffect(() => {
    getCategories({ skip: 0 }, true).then((categories) => {
      const data = categories.data?.data?.map((category) => {
        return {
          field: "categoryId",
          value: category.id,
          name: category.name,
          operator: "=",
        };
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, []);

  useEffect(() => {
    getCategories({ skip: 0 }, true);
    getArchivedVehicles(collection);
  }, [collection]);
  const data: Vehicle[] | undefined = archivedVehicles.data?.data;
  const config: EntityConfig<Vehicle> = {
    primaryColumn: { key: "plateNumber", name: "Plate Number" },
    showDetail: false,
    rootUrl: "/vehicle",
    identity: "id",
    name: "plateNumber",
    visibleColumn: [
      { key: ["owner", "name"], name: "Owner",render:(data)=>{
        return (<span>{`${data?.owner?.name} - ${data?.owner?.phoneNumber}`}</span>)
      } },
      { key: ["category","name"], name: "Category" },
      { key: "model", name: "Model" },
      { key: "code", name: "Code" },
      { key: "plateNumber", name: "Plate Number" },
      { key: "color", name: "Color", hideSort: true },
      { key: "enabled", name: "Status", hideSort: true },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "archivedAt", name: "Archived At", isDate: true },
      {
        key: "",
        name: "Actions",
        print:false,
        hideSort: true,
        render: (vehicle) => {
          return (
            <div className="flex space-x-1 items-center">
              <Confirmation
                type={"notify"}
                message={`Are you sure you want to restore it`}
                onYes={() =>
                  restoreVehicle({
                    id: vehicle?.id,
                    ownerId: vehicle?.ownerId,
                  })
                }
                header={`Restore confirmation `}
              >
                <div className="px-2 bg-primary rounded text-white cursor-pointer relative">
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Confirmation
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                onYes={() =>
                  deleteVehicle({
                    id: vehicle?.id,
                    ownerId: vehicle?.ownerId,
                  })
                }
                header={`Permanent Delete confirmation `}
              >
                <div className="px-2 bg-danger rounded text-white cursor-pointer">
                  <IconTrash size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: `${vehicle?.model} ${vehicle?.plateNumber}`,
                      reason: vehicle?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    filter: [filterData],
  };
  return (
    <div className="w-full">
      <EntityList
        showNewButton={false}
        viewMode={`list`}
        title={"Archived Vehicles"}
        total={archivedVehicles?.data?.count}
        itemsLoading={
          archivedVehicles.isLoading ||
          deleteResponse?.isLoading ||
          restoreResponse?.isLoading ||
          archivedVehicles?.isFetching
        }
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["plateNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Passenger } from "../../../models/passenger.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetPassengersQuery } from "../store/passenger.query";

export default function PassengerPage() {
  const params = useParams();
  const location = useLocation();
  const [filterData] = useState<Filter[]>([
    {
      field: "corporate",
      value: "",
      name: "Corporate",
      operator: "notNull",
    },
    {
      field: "corporate",
      value: "",
      name: "Individual",
      operator: "isNull",
    },
  ]);
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["corporate"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getPassengers, passengers] = useLazyGetPassengersQuery();

  useEffect(() => {
    getPassengers(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/passenger/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Passenger[] | undefined = passengers.data?.data;
  const config: EntityConfig<Passenger> = {
    primaryColumn: {
      key: "name",
      name: "Name",
      render: (passenger) => {
        return (
          <Text size="sm" className={passenger?.deletedAt ? "text-danger" : ""}>
            {passenger.name}
          </Text>
        );
      },
    },
    detailUrl: "detail",
    rootUrl: "/registration/passenger",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (passenger) => {
          return (
            <Text
              size="sm"
              className={passenger?.deletedAt ? "text-danger" : ""}
            >
              {passenger.name}
            </Text>
          );
        },
      },
      { key: "phoneNumber", name: "Phone" },
      {
        key: "gender",
        name: "Gender",
        render: (value) => {
          return value.gender?.toLowerCase() === "male" ? "Male" : "Female";
        },
      },
      {
        key: "",
        name: "Corporate",
        render: (value) => {

          if(value?.corporateId && !value.corporate){
            return <span className="text-red-500">Corporate is archived</span>;
          }else if(value?.corporateId && value.corporate){
            return value.corporate.name;
          }else{
            return "X";
          }
          
        },
      },
      {
        name: "Emergency Contact",
        key: ["emergencyContact", "name"],
        render: (value) => {
          if (value.emergencyContact) {
            return (
              value.emergencyContact.name +
              " (" +
              value.emergencyContact.phoneNumber +
              ")"
            );
          }
          return <div className={"pl-5"}>-----</div>;
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "enabled", name: "Status" },
    ],
    filter: [filterData],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        showNewButton={false}
        title={"Passengers"}
        total={passengers?.data?.count}
        itemsLoading={passengers.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

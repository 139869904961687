/* eslint-disable @typescript-eslint/no-explicit-any */
import { BusStop } from "../../../models/bus-stop.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Passenger } from "../../../models/passenger.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CORPORATE_ENDPOINT } from "../../corporate/corporate.endpoint";
import { BUS_STOP_ENDPOINT } from "../bus-stop.endpoint";

let busStopCollection: CollectionQuery;
let singleBus: string;
let archivedBusStopCollection: CollectionQuery;
let cityBusStopsCollection: { query: CollectionQuery; id: string };

const busStopQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getBusStop: builder.query<BusStop, string>({
      query: (id: string) => ({
        url: `${BUS_STOP_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            singleBus = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archivedBusStop: builder.query<BusStop, string>({
      query: (id: string) => ({
        url: `${BUS_STOP_ENDPOINT.archived_bus_stop}/${id}`,
        method: "get",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedBusStop: builder.query<BusStop, string>({
      query: (id: string) => ({
        url: `${BUS_STOP_ENDPOINT.archivedBusStop}/${id}`,
        method: "get",
      }),
    }),

    getArchivedBusStops: builder.query<Collection<BusStop>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${BUS_STOP_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedBusStopCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getBusStops: builder.query<Collection<BusStop>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${BUS_STOP_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            busStopCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getCityBusStops: builder.query<
      Collection<Passenger>,
      { query: CollectionQuery; id: string }
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.employee}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            cityBusStopsCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createBusStop: builder.mutation<BusStop, BusStop>({
      query: (newData: any) => ({
        url: `${BUS_STOP_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              busStopQuery.util.updateQueryData(
                "getBusStops",
                busStopCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateBusStop: builder.mutation<BusStop, any>({
      query: (newData: any) => ({
        url: `${BUS_STOP_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              busStopQuery.util.updateQueryData(
                "getBusStops",
                busStopCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((busStop) => {
                      if (busStop.id === data.id) return data;
                      else {
                        return busStop;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateBusStop: builder.mutation<BusStop, BusStop>({
      query: (newData) => ({
        url: `${BUS_STOP_ENDPOINT.update}`,
        method: "put",
        data: { ...newData, isActive: !newData.isActive },
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              busStopQuery.util.updateQueryData(
                "getBusStops",
                busStopCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((busStop) => {
                      if (busStop.id === data.id) return data;
                      else {
                        return busStop;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              busStopQuery.util.updateQueryData(
                "getBusStop",
                `${param.id}`,
                (draft) => {
                  draft.isActive = data.isActive;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message ?? "Error try again"
          );
        }
      },
    }),

    archiveBusStop: builder.mutation<BusStop, { id: string; reason: string }>({
      query: (data) => ({
        url: `${BUS_STOP_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;

          dispatch(
            busStopQuery.util.updateQueryData(
              "getBusStops",
              busStopCollection,
              (draft) => {
                draft.data = draft.data.map((busStop) => {
                  if (busStop?.id === data?.data?.id) {
                    return data?.data;
                  }
                  return busStop;
                });
              }
            )
          );
          dispatch(
            busStopQuery.util.updateQueryData(
              "getBusStop",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt;
              }
            )
          );
          dispatch(
            busStopQuery.util.updateQueryData(
              "archivedBusStop",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt ?? "";
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteBusStop: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${BUS_STOP_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              busStopQuery.util.updateQueryData(
                "getArchivedBusStops",
                archivedBusStopCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (busStop) => busStop.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreBusStop: builder.mutation<BusStop, string>({
      query: (id: string) => ({
        url: `${BUS_STOP_ENDPOINT.restore}/${id}`,
        method: "post",
        permission:'manage-bus-stops'
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            busStopQuery.util.updateQueryData(
              "archivedBusStop",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          dispatch(
            busStopQuery.util.updateQueryData(
              "getBusStop",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          dispatch(
            busStopQuery.util.updateQueryData(
              "getBusStops",
              busStopCollection,
              (draft) => {
                draft.data=draft.data.map((busStop)=>{
                  if(busStop.id===id){
                    return data
                  }
                  else{
                    return busStop
                  }
                })
              }
            )
          );
          notification("success", "Successfully restored");
         
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetBusStopQuery,
  useLazyGetCityBusStopsQuery,
  useLazyGetArchivedBusStopQuery,
  useLazyGetBusStopsQuery,
  useLazyGetArchivedBusStopsQuery,
  useCreateBusStopMutation,
  useUpdateBusStopMutation,
  useArchiveBusStopMutation,
  useActivateBusStopMutation,
  useRestoreBusStopMutation,
  useDeleteBusStopMutation,
  useLazyArchivedBusStopQuery,
} = busStopQuery;

import { Button, Text, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CollectionQuery,
  Filter,
  Order,
} from "../../../models/collection.model";
import { CustomRoute } from "../../../models/custom-route.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetGroupedCustomRoutesQuery } from "../store/custom-route.query";

export default function CustomRoutePage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    // includes: ["route"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getGroupedCustomRoutes, groupedCustomRoutes] =
    useLazyGetGroupedCustomRoutesQuery();

  useEffect(() => {
    getGroupedCustomRoutes(collection).then((groupedCustomRoutes) => {
      const data: SetStateAction<Filter[]> = [];
      //add any new city to cityList
      // groupedCustomRoutes.data?.data?.map((route) => {
      //   const cityName = route?.route?.city?.name;
      //   if (cityName) {
      //     if (Object.keys(cityList).indexOf(cityName) == -1) {
      //       cityList[cityName] = route?.route?.cityId;
      //     }
      //   }
      // });
      // setup filter from the cityList
      // Object.keys(cityList).map((key) => {
      //   data.push({
      //     field: "cityId",
      //     value: cityList[key],
      //     name: key,
      //     operator: "=",
      //   });
      // });
      // if (data) {
      //   setFilterData(data);
      // }
    });
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/route/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: CustomRoute[] | undefined = groupedCustomRoutes.data?.data;
  const config: EntityConfig<CustomRoute> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/suggested-route",
    showDetail: false,
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (route) => {
          return (
            <Text size="sm" className={route?.deletedAt ? "text-danger" : ""}>
              {route?.routeName}
            </Text>
          );
        },
      },
      { key: "count", name: "No. of suggestions" },
      {
        key: "shift",
        name: "Shift",
        render: (value: CustomRoute) =>
          value?.shift
            ? value?.shift?.charAt(0).toUpperCase() + value.shift.slice(1)
            : "",
      },
      {
        key: "morningPickupTime",
        name: "Morning Pickup Time",
        render: (value) => value?.morningPickupTime || "---",
      },
      {
        key: "afternoonPickupTime",
        name: "Afternoon Pickup Time",
        render: (value) => value?.afternoonPickupTime || "---",
      },

      { key: "createdAt", name: "Suggested At", isDate: true },
      {
        key: "actions",
        name: "Actions",
        render: (value) => (
          <Tooltip label={<span className="text-sm">View route</span>}>
            <Button
              size="small"
              className={
                " text-white text-2xs flex  items-center justify-center"
              }
              type="button"
              onClick={() => {
                navigate(
                  `/settings-and-configurables/route/detail/active/${value?.routeId}`
                );
              }}
            >
              {<IconEye />}
            </Button>
          </Tooltip>
        ),
      },

      // { key: "isActive", name: "Status" },
    ],
    // filter: [filterData],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Suggested Routes"}
        showNewButton={false}
        showExport={false}
        showSelector={false}
        showArchivedCheckBox={false}
        total={groupedCustomRoutes?.data?.count}
        itemsLoading={groupedCustomRoutes?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["routeName"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

import {
  Accordion,
  Avatar,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Rating,
  Skeleton,
  Stack,
  Text
} from "@mantine/core";
import dateFormat from "dateformat";
import { lazy, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditCircle } from "tabler-icons-react";
import { DriverFee } from "../../../models/driver-fee.model";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useDriversFeeStatusUpdateMutation,
  useLazyGetDriverAssignmentsQuery,
  useLazyGetDriverFeeQuery
} from "../store/route-assignment.query";

const PaymentStatusModalComponent =lazy(()=>import("../sub-component/payment-status-modal-component"));

export default function AssignmentPaymentHistoryDetailComponent() {
  const params = useParams();

  const [openStatusModal,setOpenStatusModal] = useState(false); 
  const [getDriverFee, driverFee] = useLazyGetDriverFeeQuery();
  const [getDriverAssignment, driverAssignment] = useLazyGetDriverAssignmentsQuery();
  const [updatePaymentStatus, updatePaymentStatusResult] = useDriversFeeStatusUpdateMutation();

  useEffect(() => {
    getDriverFee(`${params.id}`);
    getDriverAssignment({
      collection: {
        skip: 0,
        top: 5,
        orderBy: [{ field: "createdAt", direction: "desc" }],
      },
      id: `${params.id}`,
    });
  }, [params.id]);
  const data: DriverFee | undefined = driverFee.data;

  const onPaymnetStatusUpdate = (data:any)=>{
    updatePaymentStatus({driverFeeIds:[`${params.id}`],paymentStatus:data.status,transactionNumber:data.transaction_number??null})

  }

  return (
    <div className="h-full mt-2 w-full border p-2">
      <div className="flex w-full justify-end">
        <Button className="bg-primary text-white" onClick={()=>setOpenStatusModal(true)} size="xs" leftIcon={<EditCircle/>}>
            Update Sstatus
        </Button>
      </div>
      <Stack className={"relative"}>
        <LoadingOverlay visible={driverFee.isFetching} />
        <Accordion defaultValue={"detail"}>
          <Accordion.Item value="detail">
            <Accordion.Control>Detail</Accordion.Control>
            <Accordion.Panel>
              <Stack spacing={15} p={5}>
                <Group position={"apart"}>
                  <Text>Fee</Text>
                  <Text fw={500}>{`${data?.fee??0} Birr`}</Text>
                </Group>
                <Group position={"apart"}>
                  <Text>Status</Text>
                  <Text fw={500}>
                    {data?.paymentStatus}
                  </Text>
                </Group>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="driver">
            <Accordion.Control>Driver</Accordion.Control>
            <Accordion.Panel>
              <Stack className={"p-2 border-2"}>
                <Group position={"center"}>
                  <Text fz={"lg"} fw={500}>
                    {data?.driver?.name ?? "Driver"}
                  </Text>
                </Group>
                <Skeleton animate={driverFee.isFetching} visible={!data}>
                  <Group position={"apart"}>
                    <Avatar
                      radius="xl"
                      size="xl"
                      src={ downloadUrlParser({ path: data?.driver?.profileImage?.path, filename: data?.driver?.profileImage?.filename, originalname: data?.driver?.profileImage?.originalname, mimetype: data?.driver?.profileImage?.mimetype })}
                    />
                    <Rating
                      defaultValue={data?.driver?.averageRate?.rate ?? 1}
                      value={data?.driver?.averageRate?.rate}
                      readOnly
                    />
                  </Group>
                  <Divider mt={10} />
                  <Stack spacing={15} p={5}>
                    <Group position={"apart"}>
                      <Text>Gender </Text>
                      <Text fw={500}>
                        {data?.driver?.gender
                          ? data?.driver?.gender === "male"
                            ? "Male"
                            : "Female"
                          : "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Phone Number</Text>
                      <Text fw={500}>{data?.driver?.phoneNumber ?? "---"}</Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Licence Due Date</Text>
                      <Text fw={500}>
                        {dateFormat(data?.driver?.licenseDueDate, "mmmm, dd yyyy")}
                      </Text>
                    </Group>
                  </Stack>
                  <Divider mt={10} />
                  <Stack spacing={15} p={5}>
                    <Group position={"apart"}>
                      <Text>Vehicle Model</Text>
                      <Text fw={500}>
                        {data?.driver?.vehicle?.model ?? "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Code</Text>
                      <Text fw={500}>
                        {data?.driver?.vehicle?.code ?? "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Licence Plate Number</Text>
                      <Text fw={500}>
                        {data?.driver?.vehicle?.plateNumber ?? "---"}
                      </Text>
                    </Group>
                  </Stack>
                </Skeleton>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          
          {driverFee.isSuccess &&
            driverFee?.data?.assignments?.length > 0 && (
              <Accordion.Item value="assignments">
                <Accordion.Control>Assignments</Accordion.Control>
                <Accordion.Panel>
                  <Stack className={"p-2 border-2"}>
                    {driverFee?.data?.assignments?.map((assignment: any) => {
                      return [
                        <Stack spacing={15} p={5}>
                          <Group position={"apart"}>
                            <Text>Payment Status</Text>
                            <Text fw={500}>
                              {assignment?.isPaid ? "Paid " : ""}
                            </Text>
                          </Group>
                          <Group position={"apart"}>
                            <Text>Assignment Date</Text>
                            <Text fw={500}>
                              {dateFormat(
                                assignment?.assignmentDate,
                                "dddd, mmm dd, yyyy"
                              )}
                            </Text>
                          </Group>
                          <Group position={"apart"}>
                            <Text>Fee</Text>
                            <Text fw={500}>{Intl.NumberFormat('en').format(assignment.fee)  ?? "---"}</Text>
                          </Group>
                          <Group position={"apart"}>
                            <Text>Status</Text>
                            <Text fw={500}>{assignment?.status}</Text>
                          </Group>
                        </Stack>,
                        <Divider mt={10} />,
                      ];
                    })}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            )}
        </Accordion>
      </Stack>
     {
      openStatusModal && <PaymentStatusModalComponent data={{status:driverFee?.data?.paymentStatus??''}} isLoading={false} opened={openStatusModal} close={()=>setOpenStatusModal(false)} submit={(data)=>onPaymnetStatusUpdate(data)} />
     }
    </div>
  );
}

import { Card, Checkbox, Input, LoadingOverlay, Table } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { ReactElement, useEffect, useState } from "react";
import EmptyIcon from "../../empty-icon/empty-icon";
import { Pagination } from "../../pagination/pagination";
import { CollectionSelectorConfig } from "../model/collection-selector-config";

/* eslint-disable-next-line */
export interface CollectionSelectorProps {
  config?: CollectionSelectorConfig;
  items?: any[];
  checkedItemsProp?: any[];
  total?: number;
  collectionQuery?: any;
  title?: any;
  filter?: any;
  order?: any;
  paginationChange?: any;
  search: (event: any) => void;
  onCheck?: (event: any) => void;

  loading?: boolean;
  searchBar?: boolean;
  className?:string;
  footer?:ReactElement
  //pass a reference, so when ever this attribute changes the selection is reset
  resetSelection?: string;
}

export function CollectionSelector(props: CollectionSelectorProps) {
  const { searchBar = true, className,checkedItemsProp,footer } = props;
  const [items, setItems] = useState(props.items);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

  const onPageIndexChange = (page1: any, pageSize1: any) => {
    const request = {
      skip: +page1,
      top: pageSize1,
    };
    props?.paginationChange?.(request.skip, request.top);
  };

  const checkAll = (event: any) => {
    if (event.target.checked) {
      setCheckedItems(items ? items : []);
      setIsCheckAll(true);
    } else {
      setCheckedItems([]);
      setIsCheckAll(false);
    }
  };

  const handleCheckbox = (event: any, id: any) => {
    if (event.target.checked) {
      setCheckedItems((prev) => [...prev, id]);
    } else {
      const checked = checkedItems?.filter((e) => e !== id);
      setCheckedItems(checked);
      setIsCheckAll(false);
    }
  };

  useEffect(() => {
    checkedItems?.length === items?.length
      ? setIsCheckAll(true)
      : setIsCheckAll(false);
  }, [checkAll, handleCheckbox]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    onCheck();
  }, [checkedItems]);

  useEffect(()=>{
    if(checkedItemsProp){
      setCheckedItems(checkedItemsProp)
    }
  },[checkedItemsProp])
  
  let headers, rows;

  if (props.config?.visibleColumn) {
    headers = props.config?.visibleColumn.map((col) => {
      return <th key={col.key}>{col?.name}</th>;
    });

    if (props.items && props.items.length > 0) {
      rows = props.items?.map((element, index) => (
        <tr key={index}>
          <td>
            <Checkbox
              onChange={(event) => handleCheckbox(event, element)}
              checked={checkedItems?.some(item=>item.id===element.id)}
            />
          </td>

          {props.config?.visibleColumn.map((col) => {
            return col.render ? (
              <td>{col.render(element)}</td>
            ) : (
              <td>{element[col.key]}</td>
            );
          })}
        </tr>
      ));
    } else {
      rows = (
        <tr>
          <td colSpan={props.config?.visibleColumn.length + 1}>
            <EmptyIcon />
          </td>
        </tr>
      );
    }
  }

  const onCheck = () => {
    if (props.onCheck) {
      props.onCheck(checkedItems);
    }
  };

  useEffect(() => {
    if(props.resetSelection){
      setCheckedItems([]);
      setIsCheckAll(false);
    }
  }, [props.resetSelection])

  return (
    <div className={`flex w-full relative ${className}`}>
      <Card
        className="w-full rounded-none"
        style={{ minHeight: "fit-content" }}
        shadow={"sm"}
      >
        <Card.Section className="rounded-none">
          {props.title}
          {props.config?.title && (
            <div className="text-bold px-2 py-2 text-lg">
              {props.config?.title}
            </div>
          )}
        </Card.Section>
        {/* search */}
        {searchBar && (
          <Card.Section className="px-4 py-2">
            <div>
              <Input
                size="sm"
                onKeyUp={(event: any) => props?.search(event.target.value)}
                placeholder="search"
                rightSection={<IconSearch className="inline-block" size={16} />}
                rightSectionWidth={40}
                styles={{ rightSection: { pointerEvents: "none" } }}
              />
            </div>
          </Card.Section>
        )}
        {/* table */}
        <Card.Section className="px-4 py-2">
          <Table horizontalSpacing="sm">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={isCheckAll}
                    onChange={(event) => checkAll(event)}
                  />
                </th>

                {headers}
              </tr>
            </thead>
            <tbody className={"relative"}>
              {props.loading && (
                <tr>
                  <td>
                    <LoadingOverlay visible={props.loading ?? false} />
                  </td>
                </tr>
              )}
              {rows}
            </tbody>
          </Table>
        </Card.Section>
        {/* pagination */}
        {!footer && items && items?.length > 0 && <Card.Section className="mt-4 mb-2 flex w-full justify-end ">
          <div className="mr-2 mt-1">
            <Pagination
                total={props.total ?? 1}
                pageSize={[5, 10, 20]}
                onPaginationChange={onPageIndexChange}
            />
          </div>
        </Card.Section>
        }
        {footer}
      </Card>
    </div>
  );
}

export default CollectionSelector;

export const CITY_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/cities/get-city`,
    delete:`${process.env.REACT_APP_API}/cities/delete-city`,
    toggleStatus:`${process.env.REACT_APP_API}/cities/activate-or-block-city`,
    archive:`${process.env.REACT_APP_API}/cities/archive-city`,
    restore:`${process.env.REACT_APP_API}/cities/restore-city`,
    archivedList:`${process.env.REACT_APP_API}/cities/get-archived-cities`,
    archivedCity:`${process.env.REACT_APP_API}/cities/get-archived-city`,
    list:`${process.env.REACT_APP_API}/cities/get-cities`,
    create:`${process.env.REACT_APP_API}/cities/create-city`,
    update:`${process.env.REACT_APP_API}/cities/update-city`,
    city_bus_stop:`${process.env.REACT_APP_API}/cities/get-bus-stops-by-city-id`
}

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FileInput,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Service } from "../../../models/service.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { useLazyGetActivitiesQuery } from "../../../shared/store/query/sharedQuery";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useArchiveServiceMutation,
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useLazyGetArchivedServiceQuery,
  useLazyGetServiceQuery,
  useRestoreServiceMutation,
  useUpdateServiceMutation,
} from "../store/service.query";

import { Link, RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import SubScript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

interface Props {
  editMode: string;
}

export default function NewServiceComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [file, setFile] = useState<any>();
  const [modal, setModal] = useState(false);
  const [opened, setOpened] = useState(false);
  const [archiveReason, setArchiveReason] = useState<string>("");
  const [activityLogger, setActivityLogger] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  let schema = yup.object().shape({
    title: yup.string().required("Name required"),
    description: yup.string(),
    coverImage: yup.mixed().when("title", (title, schema) => {
      if (editMode !== "detail")
        return schema
          .required("Cover image is required")
          .test(
            "fileSize",
            "Cover image file should be less than 2MB",
            (value: any) => {
              return value && value?.size <= 2000000;
            }
          )
          .test(
            "type",
            "Only the following formats are accepted: .jpeg, .jpg",
            (value: any) => {
              return (
                value &&
                (value.type === "image/jpeg" ||
                  value.type === "image/png" ||
                  value.type === "image/jpg" ||
                  value.mimetype === "image/jpeg" ||
                  value.mimetype === "image/png" ||
                  value.mimetype === "image/jpg")
              );
            }
          );
      return schema;
    }),
  });

  const defaultValue: Service = {
    id: "",
    title: "",
    description: "",
    coverImage: undefined,
  };

  const [getService, service] = useLazyGetServiceQuery();
  const [getArchivedService, archivedService] =
    useLazyGetArchivedServiceQuery();
  const [createService, newService] = useCreateServiceMutation();
  const [updateService, updateResponse] = useUpdateServiceMutation();
  const [archiveService, archiveResponse] = useArchiveServiceMutation();
  const [getActivities, activities] = useLazyGetActivitiesQuery();
  const [restoreService, restoredService] = useRestoreServiceMutation();
  const [deleteService, deleteResponse] = useDeleteServiceMutation();

  const {
    register,
    setValue,
    setError,
    getValues,
    reset,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Underline,
        Link,
        Superscript,
        SubScript,
        Highlight,
        TextAlign.configure({ types: ["heading", "paragraph"] }),
      ],
      content: watch("description"),
    },
    [watch("description")]
  );

  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type === "archived") {
        getArchivedService(`${params?.id}`).then((response: any) =>
          reset({ ...response.data, coverImage: undefined })
        );
      } else {
        getService(`${params?.id}`).then((response: any) =>
          reset({ ...response.data, coverImage: undefined })
        );
      }
    } else {
      reset(defaultValue);
    }
  }, [params.id, editMode]);

  useEffect(() => {
    if (editor?.getHTML() !== undefined) {
      setContent(editor?.getHTML());
    }
  }, [editor?.getHTML()]);

  useEffect(() => {
    if (service?.data) {
      setFile(service?.data?.coverImage);
    }
  }, [service?.data]);

  const onSubmit = (data: any) => {
    if (content) {
      setValue("description", content);
    } else {
      setError("description", {
        type: "manual",
        message: "Description required",
      });
    }

    if (getValues("description")) {
      const formData = new FormData();
      if (editMode === "new") {
        formData.append("coverImage", data.coverImage);
        formData.append("title", data.title);
        formData.append("description", content);

        createService(formData).then((response: any) => {
          if (!response?.error) {
            reset(defaultValue);
          }
        });
      } else {
        const service = { id: params.id, ...data, description: content };
        if (data?.coverImage) {
          formData.append("coverImage", data.coverImage);
        }
        formData.append("title", service?.title);
        formData.append("description", service?.description);
        formData.append("id", service?.id);
        updateService(formData);
      }
    }
  };

  const onError = (errors: any) => {};

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Service" : service?.data?.title ?? ""}
        item={service}
        path={`/service/detail/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            service.isLoading || service?.isFetching || updateResponse.isLoading
          }
        />
        <div className="flex  justify-center h-full">
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="w-3/4 gap-y-4 dark:text-white"
          >
            <div className="flex-col space-y-4">
              <div className="flex w-full space-x-4  justify-between">
                <TextInput
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                  error={errors.title && `${errors?.title?.message}`}
                  withAsterisk
                  label="Title"
                  placeholder="Title"
                  {...register("title")}
                />
                <div className="w-full">
                  <Controller
                    control={control}
                    name="coverImage"
                    render={({ field }) => (
                      <FileInput
                        error={
                          errors.coverImage && `${errors.coverImage?.message}`
                        }
                        withAsterisk
                        label="Cover image"
                        //@ts-ignore
                        placeholder="Service image"
                        icon={<IconUpload size={14} />}
                        {...field}
                      />
                    )}
                  />
                  {service.data?.coverImage?.["originalname"] && (
                    <span
                      onClick={() => {
                        window
                          .open(
                            downloadUrlParser({
                              path: service?.data?.coverImage?.path,
                              filename: service?.data?.coverImage?.filename,
                              originalname:
                                service?.data?.coverImage?.originalname,
                              mimetype: service?.data?.coverImage?.mimetype,
                            }),
                            "_blank"
                          )
                          ?.focus();
                      }}
                      className=" cursor-pointer text-xs"
                    >
                      {service.data?.coverImage?.["originalname"]}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-full">
                {/* <Textarea
                  classNames={{ label: "dark:text-white " }}
                  className="w-full"
                  error={
                    errors.description && `${errors?.description?.message}`
                  }
                  label="Description"
                  withAsterisk
                  placeholder="description"
                  mt="sm"
                  {...register("description")}
                /> */}
                <RichTextEditor editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Highlight />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                      <RichTextEditor.Subscript />
                      <RichTextEditor.Superscript />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>
                  <RichTextEditor.Content />
                </RichTextEditor>
                <span className="text-red-500 text-xs">
                  {errors.description && `${errors?.description?.message}`}
                </span>
              </div>
              <div className="w-full flex justify-end items-center">
                <Group position="right" mt="xl">
                  {editMode === "new" && (
                    <>
                      <Button
                        classNames={{ label: "flex space-x-1 " }}
                        variant="default"
                        className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                        type="reset"
                        size="xs"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-current h-6"
                            viewBox="0 0 32 32"
                          >
                            <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                          </svg>
                        </span>
                        <span>Reset</span>
                      </Button>
                      <Button
                        variant="filled"
                        className="bg-primary text-white"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={newService.isLoading}
                      >
                        <span>
                          <IconDeviceFloppy size={22} strokeWidth={1.5} />
                        </span>
                        <span>Submit</span>
                      </Button>
                    </>
                  )}
                  {editMode === "detail" && (
                    <>
                      {(params?.type === "archived" &&
                        archivedService?.data?.deletedAt) ||
                      (params?.type === "active" &&
                        service?.data?.deletedAt) ? (
                        <>
                          <Confirmation
                            type={"danger"}
                            message={
                              "Are you sure you want to delete it permanently?"
                            }
                            onYes={() => deleteService(`${params?.id}`)}
                            header={`Permanent Delete confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={deleteResponse.isLoading}
                              className={`bg-danger text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconTrash size={20} strokeWidth={1.5} />
                              <span>Delete</span>{" "}
                            </Button>
                          </Confirmation>
                          <Confirmation
                            type={"notify"}
                            message={`Are you sure you want to restore it`}
                            onYes={() => restoreService(`${params?.id}`)}
                            header={`Restore confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={restoredService.isLoading}
                              className={`bg-green-500 text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconArrowBackUp size={20} strokeWidth={1.5} />
                              <span>Restore</span>
                            </Button>
                          </Confirmation>
                        </>
                      ) : (
                        <>
                          <Confirmation
                            type={"notify"}
                            message={
                              "Are you sure you want to archive this service?"
                            }
                            header={`Archive ${service?.data?.title}`}
                            result={"single"}
                            resultRequired={true}
                            selectorLabel={"Please select a reason"}
                            labelDescription={
                              "Why are you archiving this service?"
                            }
                            options={[
                              `It's not longer needed`,
                              `It is incorrect and can't update it`,
                            ].map((reason) => {
                              return { label: reason, value: reason };
                            })}
                            customInput={true}
                            yesText={"Archive"}
                            onYes={(reason: string) => {
                              archiveService({
                                id: service.data?.id,
                                reason: reason,
                              });
                            }}
                          >
                            <Button
                              variant="filled"
                              loading={archiveResponse.isLoading}
                              className="bg-danger text-white"
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                              onClick={() => setOpened(true)}
                            >
                              <span>
                                <IconArchive size={22} strokeWidth={1.5} />
                              </span>
                              <span>Archive</span>
                            </Button>
                          </Confirmation>
                          <Button
                            variant="filled"
                            className="bg-primary text-white"
                            type="submit"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                            loading={newService.isLoading}
                          >
                            <span>
                              <IconDeviceFloppy size={22} strokeWidth={1.5} />
                            </span>
                            <span>Update</span>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Group>
              </div>
            </div>
          </form>
        </div>
      </ActivityLogWrapperComponent>
      {modal && (
        <div
          className={`group absolute top-0 bottom-0 right-0 left-0 z-50 h-full w-full items-center justify-center `}
        >
          <div className="relative h-full w-3/4">
            <div className="fixed top-0 bottom-0 right-0 left-0 h-full w-full bg-black opacity-40"></div>
            <div className="fixed top-6 right-6 z-50 w-full flex justify-end items-center ">
              <IconX className="text-white" onClick={() => setModal(false)} />
            </div>
            <div className=" fixed top-8 left-20 right-0 flex h-full w-full items-center justify-center px-10">
              <iframe
                allow="allow-same-origin allow-scripts allow-popups allow-forms"
                ref={ref}
                className="w-3/4 h-3/4"
                src={
                  downloadUrlParser({
                    path: file?.path,
                    filename: file?.filename,
                    originalname: file?.originalname,
                    mimetype: file?.mimetype,
                  }) + `&output=embed`
                }
                title={`${file?.originalname}`}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

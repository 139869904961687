import {
  Button,
  Checkbox,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Menu,
  Modal,
  Table,
} from "@mantine/core";
import { IconArrowBackUp, IconFilter, IconPlus } from "@tabler/icons";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { Transaction } from "../../../models/transaction.model";
import Card from "../../../shared/component/Card/card-component";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { useLazyGetCorporateQuery } from "../../corporate/store/corporate.query";
import { useLazyGetPassengerQuery } from "../../passenger/store/passenger.query";
import {
  useAddBalanceToCorporateMutation,
  useAddBalanceToPassengerMutation,
  useLazyGetCorporateTransactionQuery,
  useLazyGetCorporateWalletQuery,
  useLazyGetPassengerTransactionQuery,
  useLazyGetPassengerWalletQuery,
} from "../store/wallet.query";

export default function WalletDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [balanceModal, openBalanceModal] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [amountError, setAmountError] = useState("");
  const [opened, setOpened] = useState(false);
  const [transactions, setTransactions] = useState<any>();
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getPassengerTransaction, passengerTransactions] =
    useLazyGetPassengerTransactionQuery();
  const [getCorporateTransaction, corporateTransaction] =
    useLazyGetCorporateTransactionQuery();
  const [getPassenger, passenger] = useLazyGetPassengerQuery();
  const [getPassengerWallet, passengerWallet] =
    useLazyGetPassengerWalletQuery();
  const [getCorporateWallet, corporateWallet] =
    useLazyGetCorporateWalletQuery();
  const [getCorporate, corporate] = useLazyGetCorporateQuery();
  const [addCorporateBalance, addBalanceResponse] =
    useAddBalanceToCorporateMutation();
  const [addPassengerBalance, passengerBalance] =
    useAddBalanceToPassengerMutation();

  const rows = transactions?.data?.data?.map(
    (transaction: any, index: number) => (
      <tr
        onClick={() => {
          setSelectedTransaction(transaction);
          setOpened(true);
        }}
        key={index}
        className={`border-0 border-l-2 cursor-pointer  ${
          parseFloat(transaction?.amount) > 0
            ? "light:bg-green-50 border-green-500"
            : "light:bg-red-50 border-red-500"
        }`}
      >
        <td className="capitalize items-center space-x-2 flex">
          {transaction?.method?.toLowerCase() === "chapa" ? (
            <Image
              className="text-gray-700"
              height={35}
              width={60}
              radius="md"
              src="/chapa-logo.png"
              alt="logo"
            />
          ) : transaction?.method?.toLowerCase() === "telebirr" ? (
            <Image
              className="light:bg-gray-400 light:py-2"
              height={32}
              width={70}
              radius="md"
              src="/ktelebirr-logo.png"
              alt="logo"
            />
          ) : (
            <>
              <Image
                className="light:bg-gray-400 light:py-2"
                height={16}
                width={16}
                radius="md"
                src="/favicon-32x32.ico"
                alt="logo"
              />
              <div>{` Kabba`}</div>
            </>
          )}
        </td>
        <td className={params?.key === "corporate" ? "hidden" : ""}>
          {transaction?.depositedBy?.name}
        </td>
        <td className="text-xs">{transaction?.reason}</td>
        <td className="text-xs">
          {transaction?.status === "2" && "success"}
          {transaction?.status === "1" && "pending"}
          {transaction?.status === "0" && "failed"}
          {transaction?.status === "success" && "success"}
          {transaction?.status === "pending" && "pending"}
          {transaction?.status === "failed" && "failed"}
        </td>
        <td
          className={` ${
            transaction?.amount && parseFloat(transaction?.amount) > 0
              ? "text-green-500"
              : "text-red-500"
          }`}
        >
          {transaction?.amount && parseFloat(transaction?.amount)?.toFixed(2)}{" "}
          ETB
        </td>
        <td>{dateFormat(transaction.createdAt, "mmmm dS, yyyy hh:mm TT")}</td>
      </tr>
    )
  );
  useEffect(() => {
    if (params.key === "passenger") {
      getPassenger(`${params.id}`);
      getPassengerWallet(`${params.id}`);
    } else if (params.key === "corporate") {
      getCorporateWallet(`${params.id}`);
      getCorporate(`${params.id}`);
    }
  }, [
    params,
    getPassenger,
    getCorporateWallet,
    getCorporate,
    getPassengerWallet,
  ]);

  useEffect(() => {
    if (params.key === "passenger") {
      getPassengerTransaction({
        passengerId: `${params.id}`,
        collection: collection,
      }).then((response) => setTransactions(response));
    } else if (params.key === "corporate") {
      getCorporateTransaction({
        corporateId: `${params.id}`,
        collection: collection,
      }).then((response) => setTransactions(response));
    }
  }, [params, getCorporateTransaction, getPassengerTransaction, collection]);

  useEffect(() => {
    if (params.key === "passenger") {
      setTransactions(passengerTransactions);
    } else if (params.key === "corporate") {
      setTransactions(corporateTransaction);
    }
  }, [passengerTransactions, corporateTransaction]);

  useEffect(() => {
    const filter = [
      filterValue.map((value) => {
        return { field: "method", value: value, operator: "=" };
      }),
    ];
    setCollection({ ...collection, filter: filter });
  }, [filterValue]);

  const title = <div className="w-full flex justify-between">
  <div>
    {params.key === "passenger"
      ? passenger.data?.name ?? ""
      : corporate.data?.name ?? ""}
    {" Transactions"}
  </div>
  <div className="flex px-4  space-x-4 items-center h-full">
    <Button
      size="xs"
      variant="outline"
      onClick={() => openBalanceModal(true)}
    >
      <div className="flex space-x-1 items-center">
        <IconPlus size={14} />
        <span>Add Balance</span>
      </div>
    </Button>
    <IconArrowBackUp
      className="cursor-pointer"
      onClick={() => navigate("/wallets")}
      size={24}
    />
  </div>
</div>
  return (
    <Card
    title={title}
    titleParentClassNames="w-full"
    >
      <div className="">
        <div className="w-full  p-2  flex justify-between items-center border mb-2 ">
          <div className="h-full w-full  flex px-4">
            <Image
              className="text-gray-700"
              height={60}
              width={150}
              radius="md"
              src="/kabba-logo.jpg"
              alt="logo"
            />
          </div>
          <div className="w-full flex justify-end">
            <div className="flex-col h-fit space-y-2">
              <div className="font-semibold text-gray-700 ">
                {params.key === "passenger"
                  ? passenger.data?.name
                  : corporate.data?.name}
              </div>
              <div className=" flex text-sm space-x-2 text-gray-700 ">
                <span>Balance:</span>
                <span className=" flex items-center">
                  {params.key === "passenger"
                    ? passengerWallet?.data?.balance?.toFixed(2)
                    : corporateWallet?.data?.balance}
                  ETB
                </span>
              </div>
            </div>
          </div>
        </div>

        <>
          <Table
            className="w-full relative"
            horizontalSpacing="lg"
            withBorder
            highlightOnHover
            verticalSpacing={"md"}
          >
            <thead>
              <tr>
                <th>
                  <div className="flex space-x-2 items-center">
                    <span>Done via</span>
                    <Menu shadow="md" width={200} classNames={{item:'text-xs'}}>
                      <Menu.Target>
                        <span>
                          <IconFilter size={16} />
                        </span>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Label>Filter By Payment Gateway</Menu.Label>
                        <Menu.Item className="w-full">
                          <Checkbox.Group
                            value={filterValue}
                            onChange={setFilterValue}
                            className="flex-col space-y-4 text-xs"
                          >
                            <Checkbox
                              className="w-full"
                              value="Chapa"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-normal",
                              }}
                              label="Chapa"
                            />
                            <Checkbox
                              className="w-full"
                              value="Telebirr"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-normal",
                              }}
                              label="Telebirr"
                            />
                            <Checkbox
                              className="w-full"
                              value="Kabba"
                              classNames={{
                                body: "flex items-center",
                                label: "text-sm font-normal",
                              }}
                              label="Kabba"
                            />
                          </Checkbox.Group>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </th>
                <th className={params.key === "corporate" ? "hidden" : ""}>
                  Deposited By
                </th>
                <th>Reason</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Deposited At</th>
              </tr>
            </thead>
            <tbody>
              {passengerTransactions?.isLoading ||
              passengerTransactions?.isFetching ||
              corporateTransaction?.isLoading ||
              corporateTransaction?.isFetching ||
              !transactions?.data?.data.length ? (
                <tr className="relative">
                  <td
                    colSpan={6}
                    className="w-full justify-center items-center"
                  >
                    <>
                      <LoadingOverlay
                        visible={
                          passengerTransactions?.isLoading ||
                          passengerTransactions?.isFetching ||
                          corporateTransaction?.isLoading ||
                          corporateTransaction?.isFetching
                        }
                      />
                      <EmptyIcon />
                    </>
                  </td>
                </tr>
              ) : (
                rows
              )}
            </tbody>
          </Table>
          {transactions?.isSuccess && transactions?.data?.data?.length > 0 && (
            <div className="w-full flex justify-end items-center mt-2">
              <Pagination
                total={transactions?.data ? transactions?.data?.count : 0}
                defaultPageSize={10}
                pageSize={[10, 15, 20]}
                onPaginationChange={(skip: number, top: number) => {
                  setCollection({ ...collection, skip: skip, top: top });
                }}
              />
            </div>
          )}
        </>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<span className="font-semibold">Transaction</span>}
        size={"md"}
        centered
      >
        <div className="w-full flex-col space-y-2 border">
          <div className="flex justify-between px-2 bg-gradient-to-r from-gray-700 to-green-100">
            <div className=" w-full flex items-center">
              <Image
                className=" text-gray-700"
                height={40}
                width={100}
                radius="md"
                src="/kabba-logo.png"
                alt="logo"
              />
            </div>
            <div className=" w-full items-center space-x-2 justify-end  flex ">
              {selectedTransaction?.method.toLowerCase() === "chapa" ? (
                <Image
                  className="text-gray-700"
                  height={40}
                  width={75}
                  radius="md"
                  src="/chapa-logo.png"
                  alt="logo"
                />
              ) : selectedTransaction?.method.toLowerCase() === "telebirr" ? (
                <Image
                  className="light:bg-gray-400 light:py-2"
                  height={32}
                  width={72}
                  radius="md"
                  src="/ktelebirr-logo.png"
                  alt="logo"
                />
              ) : (
                <>
                  <Image
                    className="light:bg-gray-400 light:py-2"
                    height={32}
                    width={32}
                    radius="md"
                    src="/favicon-32x32.ico"
                    alt="logo"
                  />
                  <div>{` Kabba`}</div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex text-xs item-center px-2">
              {dateFormat(
                selectedTransaction?.createdAt,
                "mmmm dS, yyyy hh:mm TT"
              )}
            </div>
          </div>
          <div className="flex px-2 justify-between border items-center border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Passenger</div>
            <div className="h-12 text-xs flex items-center">
              {passenger.data?.name}
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">
              Transaction number
            </div>
            <div className="h-12 text-xs flex items-center">
              {selectedTransaction?.transactionNumber}
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Narrator</div>
            <div className="h-12 text-xs flex items-center">
              {selectedTransaction?.depositedBy?.name}
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Amount</div>
            <div className="h-12 text-xs flex items-center">
              {Number(selectedTransaction?.amount)?.toFixed(2)} ETB
            </div>
          </div>
          <div className="flex px-2 justify-between items-center border border-r-0 border-l-0">
            <div className="h-12 text-sm flex items-center">Reason</div>
            <div className="h-12 text-xs flex items-center">
              {selectedTransaction?.reason}
            </div>
          </div>
        </div>
      </Modal>

      {/* Add Balance Modal */}
      <Modal
        opened={balanceModal}
        onClose={() => {
          openBalanceModal(false);
          setAmountError("");
          setAmount(undefined);
        }}
        size="md"
        title={<span className="font-semibold">Add Balance</span>}
        centered
      >
        <LoadingOverlay
          visible={addBalanceResponse.isLoading || passengerBalance.isLoading}
        />
        <Input.Wrapper
          id="input-demo"
          withAsterisk
          label="Amount"
          description="Please enter the amount"
          error={amountError !== "" && amountError}
        >
          <Input
            type={"number"}
            required
            onChange={(event: any) => {
              setAmount(event.target.value);
              setAmountError("");
            }}
            id="input-demo"
            placeholder="Put amount here"
          />
        </Input.Wrapper>

        <Group position="right" mt="xl">
          <Button
            size="xs"
            className="text-xs text-white bg-primary"
            variant="default"
            onClick={() => {
              if (amount === undefined) {
                setAmountError("Please give the amount");
              } else if (amount < 1) {
                setAmountError("Amount should not be less than 1");
              } else {
                if (params.key === "passenger") {
                  addPassengerBalance({
                    passengerId: params?.id,
                    balance: amount,
                  })
                    .unwrap()
                    .then((res) => {
                      if (res) {
                        openBalanceModal(false);
                      }
                    });
                } else {
                  addCorporateBalance({
                    corporateId: params?.id,
                    balance: amount,
                  })
                    .unwrap()
                    .then((res) => {
                      if (res) {
                        openBalanceModal(false);
                      }
                    });
                }
              }
            }}
          >
            Add
          </Button>
        </Group>
      </Modal>
    </Card>
  );
}

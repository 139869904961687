import {
  Anchor,
  Button,
  Group,
  LoadingOverlay,
  Radio,
  Table,
  Text,
} from "@mantine/core";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconFileExport,
} from "@tabler/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { lazy, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Corporate } from "../../../models/corporate.model";
import { decodeMonth } from "../../../shared/constants/Constants";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { useLazyGetCorporatesQuery } from "../../corporate/store/corporate.query";
import { StatsRing } from "../component/status-ring";
import {
  useLazyGroupCorporatesByCreatedDateQuery,
  useLazyGroupCorporatesByCreatedMonthQuery,
  useLazyGroupCorporatesByStatusQuery,
  useLazyGroupOwnersByGenderQuery,
} from "../store/dashboard.query";

const CorporateExportModal = lazy(
  () => import("../component/corporate-export-modal")
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface StatsRingProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
    icon: "up" | "down";
  }[];
}
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Vehicles Status",
    },
  },
};

export const donut_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    labels: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Owner Status by Gender",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Registered Corporates",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const active = [100, 500, 550, 635, 500, 600, 900];
const deactive = [10, 3, 200, 100, 4, 14, 62];

export const data = {
  labels,
  datasets: [
    {
      label: "Active",
      data: labels.map((_, index) => active[index]),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Inactive",
      data: labels.map((_, deactiveIndex) => deactive[deactiveIndex]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export default function CorporateDashboardPage() {
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [donut_state_data, setDonutData] = useState<any>();
  const [bar_state_data, setBarData] = useState<any>();
  const [corporatesCircleProgress, setCorporatesCircleProgress] = useState<
    any[]
  >([]);
  const [groupOwnersByGender, genderGroupedOwners] =
    useLazyGroupOwnersByGenderQuery();
  const [groupCorporatesByStatus, statusGroupedCorporates] =
    useLazyGroupCorporatesByStatusQuery();
  const [getCorporates, corporates] = useLazyGetCorporatesQuery();
  const [getNewCorporatesCountEveryMonth, newCorporatesPerMonth] =
    useLazyGroupCorporatesByCreatedMonthQuery();
  const [groupCorporatesByCreatedDate, createdDateresponse] =
    useLazyGroupCorporatesByCreatedDateQuery();

  useEffect(() => {
    getCorporates({
      skip: 0,
      top: 6,
      orderBy: [{ field: "createdAt", direction: "desc" }],
    }).then((ownerResponse) => {
      groupCorporatesByStatus({
        groupBy: ["enabled"],
      }).then((response) => {
        if (response?.data && ownerResponse?.data) {
          let total = ownerResponse?.data?.count ?? 1;

          const data = response.data.map((item: any) => {
            return {
              label: item.status ? "Active" : "Inactive",
              stats: item.count,
              progress: (item.count / total) * 100,
              color: item.status ? "green" : "red",
              icon: item.status ? "up" : "down",
            };
          });
          setCorporatesCircleProgress(data);
        }
      });
    });
  }, []);

  useEffect(() => {
    let labels: any[] = [];
    let data: any[] = [];

    async function getVehiclesByCategory() {
      const response = await groupOwnersByGender({ groupBy: ["gender"] });
      if (response.data) {
        labels = response.data.map((item: any) => {
          return item.gender;
        });
        data = response.data.map((item: any) => {
          return item.count;
        });
      }

      const donut_data = {
        labels: labels,
        datasets: [
          {
            label: "Owners",
            data: data ?? [0],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
      setDonutData(donut_data);
    }
    getVehiclesByCategory();
  }, []);
  useEffect(() => {
    let labels: string[];
    let data: number[];
    async function groupingCorporatesByCreateddate() {
      const response = await groupCorporatesByCreatedDate(createdDateFormat);
      if (response?.data) {
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? decodeMonth(item.createdAt)
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response?.data?.map((item: any) => item.count);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Corporates",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    groupingCorporatesByCreateddate();
  }, [createdDateFormat]);

  return (
    <div className="w-full p-4">
      <div className="flex w-full h-16 px-2 justify-between items-center mt-2 border border-sky-300 mb-2">
        <div className="flex-col space-y-2">
          <div className="capitalize font-semibold text-primary">
            Corporate dashboard report
          </div>
          <div className="capitalize font-semibold text-primary">
            {"Total: "}
            {corporates?.data?.count}
          </div>
        </div>

        <div className="flex space-x-4">
          <Button
            leftIcon={<IconFileExport color="gray" />}
            size="sm"
            variant="default"
            onClick={() => setOpenExport(true)}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="flex space-x-2 justify-between">
        <div className=" w-full shadow border rounded shadow-sky-100 p-2">
          <div className="mb-2 flex justify-between px-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Owner status
            </Text>
            <Text className="text-sm text-primary text-semibold">
              {"Total: "}
              {corporates?.data?.count}
            </Text>
          </div>
          {statusGroupedCorporates?.data && (
            <StatsRing data={corporatesCircleProgress} />
          )}
          {/* <div className=" w-full border rounded p-2 mt-2">
            <Table verticalSpacing="xs">
              <thead>
                <tr className="text-sm leading-5">
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Email</td>
                  <td>Registered At</td>
                </tr>
              </thead>
              <tbody>
                {corporates.isLoading ? (
                  <td
                    colSpan={5}
                    className="w-full justify-center items-center"
                  >
                    <LoadingOverlay visible />
                  </td>
                ) : (
                  corporates?.data?.data?.map((corporate: Corporate) => (
                    <tr className="hover:shadow z-20 cursor-pointer text-xs">
                      <td className="text-xs">
                        <Anchor<"a">
                          size="sm"
                          onClick={(event) => event.preventDefault()}
                        >
                          {corporate.name}
                        </Anchor>
                      </td>
                      <td className="text-xs">{corporate.phoneNumber}</td>
                      <td className="text-xs">{corporate.email}</td>
                      <td className="text-xs">
                        {dateFormat(
                          corporate.createdAt,
                          "mmmm dS, yyyy hh:mm TT"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div> */}
        </div>
        <div className=" w-full border rounded p-2 mt-2">
          <Table verticalSpacing="xs">
            <thead>
              <tr className="text-sm leading-5">
                <td>Name</td>
                <td>Phone</td>
                <td>Email</td>
                <td>Registered At</td>
              </tr>
            </thead>
            <tbody>
              {corporates.isLoading ? (
                <td colSpan={5} className="w-full justify-center items-center">
                  <LoadingOverlay visible />
                </td>
              ) : (
                corporates?.data?.data?.map((corporate: Corporate) => (
                  <tr className="hover:shadow z-20 cursor-pointer text-xs">
                    <td className="text-xs">
                      <Anchor<"a">
                        size="sm"
                        onClick={(event) => event.preventDefault()}
                      >
                        {corporate.name}
                      </Anchor>
                    </td>
                    <td className="text-xs">{corporate.phoneNumber}</td>
                    <td className="text-xs">{corporate.email}</td>
                    <td className="text-xs">
                      {dateFormat(corporate.createdAt)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="flex-col space-y-4 justify-between mt-5 shadow p-2 border rounded">
        <div className="flex space-x-12  ">
          {" "}
          <Radio.Group
            // label="Date Format"
            defaultValue={createdDateFormat}
            onChange={(e) => {
              setCreatedDateFormat(e);
            }}
          >
            <Group mt="xs">
              <Radio value="month" label="Monthly" />
              <Radio value="year" label="Yearly" />
            </Group>
          </Radio.Group>
        </div>
        <div className="flex space-x-2 justify-between mt-10 shadow">
          <div className="h-92 w-full border rounded flex justify-center shadow-lg shadow-sky-100 p-2">
            {createdDateresponse?.isLoading ? (
              <LoadingOverlay visible />
            ) : (
              bar_state_data && (
                <Bar options={bar_options} data={bar_state_data} />
              )
            )}
          </div>
        </div>
      </div>

      {openExport && (
        <CorporateExportModal
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}
    </div>
  );
}

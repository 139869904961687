import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Avatar,
  Button,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  Radio,
  Select,
  Tabs,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDebouncedValue, useDisclosure, useId } from "@mantine/hooks";
import {
  IconArchive,
  IconArrowBackUp,
  IconBuildingBank,
  IconDeviceFloppy,
  IconFilePlus,
  IconPlus,
  IconTrash,
  IconUser,
} from "@tabler/icons";
import { IconBrandTelegram, IconUsersGroup } from "@tabler/icons-react";
import dateFormat from "dateformat";
import { Suspense, forwardRef, lazy, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { City } from "../../../models/city.model";
import { CollectionQuery } from "../../../models/collection.model";
import GroupAssignment from "../../../models/group-assignment.model";
import {
  CredentialType,
  Notification,
  NotificationMethod,
} from "../../../models/notification.model";
import {
  Bank,
  DriverFor,
  DriverType,
  Provider,
} from "../../../models/provider.model";
import { Vehicle } from "../../../models/vehicle.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Constants } from "../../../shared/constants/Constants";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import countryCodes from "../../../shared/utility/countryCodes.json";
import locations from "../../../shared/utility/locations.json";
import { useLazyGetCitiesQuery } from "../../city/store/city.query";
import { useRemoveGroupAssignmentMutation } from "../../group/store/group.query";
import { useCreateNotificationMutation } from "../../notification/store/notification.query";
import {
  useActivateProviderMutation,
  useArchiveProviderMutation,
  useCreateProviderMutation,
  useDeleteProviderMutation,
  useLazyGetArchivedProviderQuery,
  useLazyGetProviderKidAssignmentQuery,
  useLazyGetProviderQuery,
  useLazyGetUnassignedVehiclesQuery,
  useRestoreProviderMutation,
  useUpdateProviderMutation,
} from "../store/provider.query";
const FileUploadComponent = lazy(
  () => import("./provider-file-upload.component")
);
interface Props {
  editMode: "new" | "detail";
}
let schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Name is not valid")
    .min(3, "Name must have at least 3 characters")
    .max(100, "Name must be 100 characters and below")
    .required("Name is required"),
  specificLocation: yup.string().required("Specific Location is required"),
  email: yup.string().email(),
  driverType: yup.string().required("Driver Type is required"),
  driverFor: yup.string().required("Driver For is required"),
  address: yup.object().shape({
    country: yup.string().required("Country is Required"),
    city: yup.string().required("City is Required"),
    subCity: yup.string(),
    woreda: yup.string(),
    houseNumber: yup.string().required("House Number is required"),
  }),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "Phone number is not valid"
    ),
  gender: yup.string().required("Gender is required"),
  emergencyContact: yup.object().shape({
    phoneNumber: yup
      .string()
      .required("Emergency phone number is required")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Phone number is not valid"
      ),
    name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Name is not valid")
      .min(3, "Name must have at least 3 characters")
      .max(100, "Name must be 100 characters and below")
      .required("Emergency name is required"),
  }),
  vehicleId: yup
    .string()
    .typeError("Vehicle must be registered")
    .required("Vehicle is Required"),
});

const BankAccountList = lazy(
  () => import("../component/bank-account-list.component")
);
const BankAccountFormComponent = lazy(
  () => import("../component/bank-account-form.component")
);

export default function NewProviderComponent(props: Props) {
  const { editMode } = props;
  const id = useId();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);
  const [bankAccountEditMode, setBankAccountEditMode] = useState<
    "new" | "detail"
  >("new");
  const [selectedAccount, setSelectedAccount] = useState<Bank>();
  const [data] = useState<any>(countryCodes);
  const [specificLocations] = useState<any>(locations);
  const [code, setCode] = useState("+251");
  const [emergencyCode, setEmergencyCode] = useState("+251");
  const [searchKey, setSearchKey] = useState<string>();
  const [debounced] = useDebouncedValue(searchKey, 1000);
  const [vehicleData, setVehicleData] = useState<any[]>([]);
  const [providerPhoto, setPhoto] = useState<{
    filename: string;
    path: string;
    originalname: string;
    size: string;
    mimetype: string;
  }>();
  const [currentProvider, setCurrentProvider] = useState<Provider>();
  const [getCities, cities] = useLazyGetCitiesQuery();
  const [getProvider, provider] = useLazyGetProviderQuery();
  const [getArchivedProvider, archivedProvider] =
    useLazyGetArchivedProviderQuery();
  const [createProvider, newProvider] = useCreateProviderMutation();
  const [updateProvider, updateResponse] = useUpdateProviderMutation();
  const [archiveProvider, archiveResponse] = useArchiveProviderMutation();
  const [activateProvider, activateResponse] = useActivateProviderMutation();
  const [restoreProvider, restoreResponse] = useRestoreProviderMutation();
  const [deleteProvider, deleteResponse] = useDeleteProviderMutation();
  const [getVehicles, vehicles] = useLazyGetUnassignedVehiclesQuery();
  const [getProviderKidAssignments, kidAssignments] =
    useLazyGetProviderKidAssignmentQuery();
  const [removeGroupAssignment, removeAssignmentResponse] =
    useRemoveGroupAssignmentMutation();

  const [createNotification, notificationResponse] =
    useCreateNotificationMutation();

  const [kidAssignmentCollection, setKidAssignmentCollection] =
    useState<CollectionQuery>({
      skip: 0,
      top: 100,
      includes: ["group", "kid", "school", "driver", "parent"],
    });

  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(false);

  const defaultValue: Provider = {
    id: "",
    name: "",
    email: "",
    phoneNumber: "",
    driverType: DriverType.Both,
    driverFor: DriverFor.Both,
    address: {
      country: "Ethiopia",
      city: "",
      subCity: "",
      woreda: "",
      houseNumber: "",
    },
    specificLocation: "",
    gender: "",
    emergencyContact: { phoneNumber: "", name: "" },
    vehicleId: "",
    splitType: "",
    splitAmount: 0,
  };

  const defaultNotificationValue: Notification = {
    title: "",
    body: "",
    type: CredentialType.Driver,
    status: undefined,
    method: NotificationMethod.Both,
  };

  let notificationSchema = yup
    .object()
    .shape({
      type: yup.string().typeError("Type should be string").required(),
      body: yup.string().typeError("Body should be string").required(),
      title: yup.string().typeError("Title should be string").required(),
      method: yup.string().typeError("Method  should be string").required(),
      // status: yup.string().nullable().notRequired().oneOf([
      //   ParentStatus.New,
      //   ParentStatus.Assigned,
      //   ParentStatus.PartiallyAssigned,
      //   ParentStatus.WaitingToBeAssigned,
      //   ParentStatus.Declined,
      //   ParentStatus.Agreed,
      //   ParentStatus.ContractSigned,
      // ])
    })
    .required();
  const {
    register: notificationRegister,
    setValue: notificationSetValue,
    control: notificationControl,
    handleSubmit: notificationHandleSubmit,
    reset: notificationReset,
    formState: { errors: notificationErrors },
  } = useForm({
    resolver: yupResolver(notificationSchema),
    mode: "onBlur",
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Provider>({
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    image: string;
    label: string;
    description: string;
  }
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  useEffect(() => {
    getVehicles(
      { skip: 0, top: 10, search: searchKey, searchFrom: ["plateNumber"] },
      true
    ).then((response: any) => {
      const data = response.data?.data?.map((vehicle: Vehicle, idx: number) => {
        if (vehicle) {
          return {
            value: vehicle.id,
            label: vehicle.plateNumber,
            description: vehicle.model,
          };
        }
      });
      setVehicleData(data ? data : []);
    });
  }, [debounced]);

  useEffect(() => {
    getCities({
      skip: 0,
      top: 100,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
    });
  }, []);
  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type === "archived") {
        getArchivedProvider(`${params?.id}`).then((response: any) => {
          if (response?.data) {
            reset(response?.data);
            setCurrentProvider(response?.data);
            setPhoto(response?.data?.profileImage);
            setValue("driverType", response?.data?.driverType);
            setValue("driverFor", response?.data?.driverFor);
          }
        });
      } else if (params?.type === "active") {
        getProvider(`${params?.id}`).then((response: any) => {
          if (response?.data) {
            reset(response?.data);
            setCurrentProvider(response?.data);
            setPhoto(response?.data?.profileImage);
            setValue("driverType", response?.data?.driverType);
            setValue("driverFor", response?.data?.driverFor);
          }
        });
      }
    } else {
      reset(defaultValue);
    }
  }, [params.id, editMode]);

  useEffect(() => {
    if (editMode === "detail") {
      getProviderKidAssignments({
        ...kidAssignmentCollection,
        filter: [[{ field: "driverId", operator: "=", value: params.id }]],
      }).then((response: any) => {});
    }
  }, [params.id, editMode]);

  const onSubmit = (data: Provider) => {
    if (editMode === "new") {
      createProvider({
        ...data,
        phoneNumber: data.phoneNumber.split(" ").join(""),
        splitType: data?.splitType ? data.splitType : undefined,
        splitAmount: data?.splitAmount ? +data.splitAmount : undefined,
      }).then((response: any) => {
        if (!response?.error) {
          reset(defaultValue);
        }
      });
    } else {
      const provider: Provider = {
        id: params.id,
        ...data,
        phoneNumber: data.phoneNumber.split(" ").join(""),
        splitType: data?.splitType ? data.splitType : undefined,
        splitAmount: data?.splitAmount ? +data.splitAmount : undefined,
      };
      updateProvider(provider).then((response: any) => {
        if (response?.data) {
          setCurrentProvider(response?.data);
        }
      });
    }
  };

  const onNotificationSubmit = async (data: any) => {
    createNotification({
      ...data,
      receiver: params?.id,
      type: CredentialType.Driver,
    })
      .unwrap()
      .then((response) => {
        notificationReset(defaultNotificationValue);
        setOpenNotificationModal(false);
        setShowStatus(false);
        notificationReset(defaultNotificationValue);
      });
  };

  const onError = (error: any) => {};
  const actions = location.pathname === `/provider/detail/${params.id}` && (
    <div className="flex items-center space-x-4 justify-end">
      <Button
        variant="default"
        className="text-primary"
        type="button"
        classNames={{ label: "flex space-x-1" }}
        size={"xs"}
        onClick={() => navigate("/bank/accounts", { state: { id: params.id } })}
      >
        <span>
          <IconBuildingBank size={22} strokeWidth={1.5} />
        </span>
        <span>Bank Accounts</span>
      </Button>
    </div>
  );

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? (
            "New Provider"
          ) : (
            <div className="h-full space-x-2 flex items-center">
              {providerPhoto !== undefined && (
                <Avatar
                  src={downloadUrlParser({
                    path: providerPhoto?.path,
                    filename: providerPhoto?.filename,
                    originalname: providerPhoto?.originalname,
                    mimetype: providerPhoto?.mimetype,
                  })}
                  alt="photo"
                />
              )}
              <div>{provider?.data?.name}</div>
              <div>{actions}</div>
              <div>
                <Button
                  disabled={currentProvider?.deletedAt ? true : false}
                  classNames={{ label: "flex space-x-1 " }}
                  variant="default"
                  className={`bg-primary text-white ${
                    currentProvider?.deletedAt
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  type="button"
                  size="xs"
                  onClick={() => {
                    setOpenNotificationModal(true);
                    notificationReset(defaultNotificationValue);
                  }}
                >
                  <IconBrandTelegram />
                  <span>Send Notification</span>
                </Button>
              </div>
            </div>
          )
        }
        item={provider}
        path={`/registration/provider/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={provider.isLoading || updateResponse.isLoading}
        />
        {
          <>
            <Tabs defaultValue="Vehicle">
              <Tabs.List>
                <Tabs.Tab value="Vehicle" icon={<IconUser size={14} />}>
                  Provider basic info
                </Tabs.Tab>
                {editMode === "detail" && (
                  <>
                    <Tabs.Tab
                      value="document"
                      icon={<IconFilePlus size={14} />}
                    >
                      Documents
                    </Tabs.Tab>
                    <Tabs.Tab
                      value="bank_account"
                      icon={<IconBuildingBank size={14} />}
                    >
                      Bank Account
                    </Tabs.Tab>
                    <Tabs.Tab
                      value="kids_assignment"
                      icon={<IconUsersGroup size={14} />}
                    >
                      Kids Assignment
                    </Tabs.Tab>
                  </>
                )}
              </Tabs.List>

              <Tabs.Panel value="Vehicle" pt="xs">
                <div className="flex  justify-center h-full">
                  <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    className="w-full gap-y-4 dark:text-white"
                  >
                    <Accordion
                      defaultValue={["info", "address", "paymentInfo"]}
                      multiple
                      variant="separated"
                      classNames={{ control: "bg-secondary text-gray-700" }}
                    >
                      <Accordion.Item value="info">
                        <Accordion.Control>
                          Personal Information
                        </Accordion.Control>
                        <Accordion.Panel>
                          <div className="flex-col space-y-4">
                            <div className="flex w-full space-x-4  justify-between">
                              <div className="flex  space-x-2 w-full">
                                <Controller
                                  control={control}
                                  name="driverType"
                                  render={({ field }) => (
                                    <Select
                                      className="w-full"
                                      withAsterisk
                                      searchable
                                      nothingFound={
                                        <div className="fle w-full justify-center items-center">
                                          <EmptyIcon />
                                        </div>
                                      }
                                      error={
                                        errors.driverType &&
                                        `${errors?.driverType?.message}`
                                      }
                                      radius={"xs"}
                                      classNames={{
                                        label: "dark:text-white",
                                      }}
                                      label="Driver Type"
                                      data={[
                                        {
                                          label: "Both",
                                          value: DriverType.Both,
                                        },
                                        {
                                          label: "One Time",
                                          value: DriverType.OneTime,
                                        },
                                        {
                                          label: "Monthly",
                                          value: DriverType.Monthly,
                                        },
                                      ]}
                                      maxDropdownHeight={400}
                                      {...field}
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="driverFor"
                                  render={({ field }) => (
                                    <Select
                                      className="w-full"
                                      withAsterisk
                                      searchable
                                      nothingFound={
                                        <div className="fle w-full justify-center items-center">
                                          <EmptyIcon />
                                        </div>
                                      }
                                      error={
                                        errors.driverFor &&
                                        `${errors?.driverFor?.message}`
                                      }
                                      radius={"xs"}
                                      classNames={{
                                        label: "dark:text-white",
                                      }}
                                      label="Driver For"
                                      data={[
                                        {
                                          label: "Both",
                                          value: DriverFor.Both,
                                        },
                                        {
                                          label: "Kabba Kids",
                                          value: DriverFor.KabbaKids,
                                        },
                                        {
                                          label: "Kabba Public",
                                          value: DriverFor.KabbaMain,
                                        },
                                      ]}
                                      maxDropdownHeight={400}
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.name && `${errors?.name?.message}`
                                }
                                withAsterisk
                                label="Name"
                                placeholder="Provider"
                                {...register("name")}
                              />
                            </div>
                            <div className="flex w-full space-x-4 justify-between">
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.email && `${errors?.email?.message}`
                                }
                                label="Email"
                                placeholder="email@example.com"
                                {...register("email")}
                              />
                              <div className="flex w-full">
                                <Select
                                  radius={"xs"}
                                  searchable
                                  nothingFound={
                                    <div className="fle w-full justify-center items-center">
                                      <EmptyIcon />
                                    </div>
                                  }
                                  onChange={(code) => {
                                    code && setCode(code);
                                  }}
                                  classNames={{
                                    input:
                                      "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                                  }}
                                  value={code}
                                  label="Code"
                                  data={data?.map((item: any) => ({
                                    label: `${item.name} (${item.dial_code})`,
                                    value: item.dial_code,
                                    key: item.name,
                                  }))}
                                  maxDropdownHeight={400}
                                />
                                <Input.Wrapper
                                  id={id}
                                  className="w-full"
                                  label="Phone number"
                                  required
                                  error={
                                    errors.phoneNumber &&
                                    `${errors?.phoneNumber?.message}`
                                  }
                                >
                                  <Input
                                    classNames={{ input: "rounded-l-none" }}
                                    component={InputMask}
                                    mask={`${code} 999 999 999`}
                                    id={id}
                                    placeholder="Provider phone number"
                                    {...register("phoneNumber")}
                                  />
                                </Input.Wrapper>
                              </div>
                            </div>
                            <div className="flex w-full  space-x-4">
                              <div className="w-1/2 pt-4">
                                <Controller
                                  name="gender"
                                  control={control}
                                  render={({ field }) => (
                                    <Radio.Group
                                      label="Gender"
                                      {...field}
                                      withAsterisk
                                      className="flex space-x-2"
                                    >
                                      <Group>
                                        <Radio value="male" label="Male" />
                                        <Radio value="female" label="Female" />
                                      </Group>
                                      <span className="text-red-500 text-xs">
                                        {errors.gender &&
                                          errors.gender?.message}
                                      </span>
                                    </Radio.Group>
                                  )}
                                />
                              </div>
                              <div className="w-1/2">
                                {" "}
                                <Controller
                                  name="vehicleId"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      className="w-full"
                                      label="Vehicle"
                                      withAsterisk
                                      itemComponent={SelectItem}
                                      placeholder="Search using plate number"
                                      searchable
                                      nothingFound={
                                        <div className="relative">
                                          <LoadingOverlay
                                            visible={
                                              vehicles?.isFetching ||
                                              vehicles?.isLoading
                                            }
                                          />
                                          <EmptyIcon />
                                        </div>
                                      }
                                      error={
                                        errors.vehicleId &&
                                        `${errors?.vehicleId?.message}`
                                      }
                                      data={
                                        editMode === "detail"
                                          ? [
                                              ...vehicleData,
                                              {
                                                value:
                                                  provider?.data?.vehicle?.id ??
                                                  "",
                                                label:
                                                  provider?.data?.vehicle
                                                    ?.plateNumber ?? "",
                                                description:
                                                  provider?.data?.vehicle
                                                    ?.model ?? "",
                                              },
                                            ]
                                          : [...vehicleData]
                                      }
                                      onKeyUp={(event) =>
                                        setSearchKey(event.currentTarget.value)
                                      }
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="flex w-full space-x-4 justify-between">
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.emergencyContact?.name &&
                                  `${errors?.emergencyContact?.name.message}`
                                }
                                withAsterisk
                                label="Emergency Person Name"
                                placeholder="Andrew"
                                {...register("emergencyContact.name")}
                              />
                              <div className="flex w-full">
                                <Select
                                  searchable
                                  nothingFound={
                                    <div className="fle w-full justify-center items-center">
                                      <EmptyIcon />
                                    </div>
                                  }
                                  radius={"xs"}
                                  onChange={(code) => {
                                    code && setEmergencyCode(code);
                                  }}
                                  classNames={{
                                    input:
                                      "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                                  }}
                                  value={emergencyCode}
                                  label="Code"
                                  data={data?.map((item: any) => ({
                                    label: `${item.name} (${item.dial_code})`,
                                    value: item.dial_code,
                                    key: item.name,
                                  }))}
                                  maxDropdownHeight={400}
                                />
                                <Input.Wrapper
                                  id={id}
                                  className="w-full"
                                  label="Emergency Phone number"
                                  required
                                  error={
                                    errors.emergencyContact?.phoneNumber &&
                                    `${errors?.emergencyContact?.phoneNumber.message}`
                                  }
                                >
                                  <Input
                                    classNames={{ input: "rounded-l-none" }}
                                    component={InputMask}
                                    mask={`${emergencyCode} 999 999 999`}
                                    id={id}
                                    placeholder="Emergency person phone"
                                    {...register(
                                      "emergencyContact.phoneNumber"
                                    )}
                                  />
                                </Input.Wrapper>
                              </div>
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                      {/* <Accordion.Item value="preference">
                        <Accordion.Control>
                          <Accordion.Panel>
                            <div className="Flex space-x-4 items-center">
                              <Controller
                                name="datePreferences"
                                control={control}
                                render={({ field }) => (
                                  <MultiSelect
                                    className="w-full"
                                    searchable
                                    withAsterisk
                                    error={
                                      errors.datePreferences &&
                                      `${errors?.datePreferences?.message}`
                                    }
                                    label="Preferred Days"
                                    placeholder="Select days"
                                    data={[
                                      { value: "Mon", label: "Monday" },
                                      { value: "Tue", label: "Tuesday" },
                                      { value: "Wed", label: "Wednesday" },
                                      { value: "Thu", label: "Thursday" },
                                      { value: "Fri", label: "Friday" },
                                      { value: "Sat", label: "Saturday" },
                                      { value: "Sun", label: "Sunday" },
                                    ]}
                                    maxDropdownHeight={400}
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="preference"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    className="w-full"
                                    searchable
                                    withAsterisk
                                    error={
                                      errors.routeId &&
                                      `${errors?.routeId?.message}`
                                    }
                                    label="Route"
                                    placeholder="Select a route"
                                    data={parseRoutes()}
                                    maxDropdownHeight={400}
                                    onKeyUp={debounce((event: any) => {
                                      event.target.value
                                        ? setRouteCollection({
                                            ...routeCollection,
                                            skip: 0,
                                            search: event.target.value,
                                            searchFrom: ["name"],
                                          })
                                        : setRouteCollection({
                                            ...routeCollection,
                                            skip: 0,
                                            search: "",
                                            searchFrom: [],
                                          });
                                    }, 1000)}
                                  />
                                )}
                              />
                            </div>
                          </Accordion.Panel>
                        </Accordion.Control>
                      </Accordion.Item> */}
                      <Accordion.Item value="address">
                        <Accordion.Control>Address</Accordion.Control>
                        <Accordion.Panel>
                          <div className="flex-col space-y-4">
                            <div className="flex w-full space-x-4  justify-between">
                              <Controller
                                control={control}
                                name="address.country"
                                render={({ field }) => (
                                  <Select
                                    className="w-full"
                                    withAsterisk
                                    searchable
                                    nothingFound={
                                      <div className="fle w-full justify-center items-center">
                                        <EmptyIcon />
                                      </div>
                                    }
                                    error={
                                      errors.address?.country &&
                                      `${errors?.address?.country?.message}`
                                    }
                                    radius={"xs"}
                                    classNames={{
                                      label: "dark:text-white",
                                    }}
                                    label="Country"
                                    data={data?.map((item: any) => ({
                                      label: `${item.name}`,
                                      value: item.name,
                                      key: item.name,
                                    }))}
                                    maxDropdownHeight={400}
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name="address.city"
                                render={({ field }) => (
                                  <Select
                                    withAsterisk
                                    className="w-full"
                                    searchable
                                    nothingFound={
                                      <div className="fle w-full justify-center items-center">
                                        <EmptyIcon />
                                      </div>
                                    }
                                    error={
                                      errors.address?.city &&
                                      `${errors?.address?.city?.message}`
                                    }
                                    radius={"xs"}
                                    classNames={{
                                      label: "dark:text-white",
                                    }}
                                    defaultValue="Ethiopia"
                                    label="City"
                                    data={
                                      cities.data
                                        ? cities.data.data.map(
                                            (item: City) => ({
                                              label: `${item.name}`,
                                              value: item.name ?? "",
                                            })
                                          )
                                        : []
                                    }
                                    maxDropdownHeight={400}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                            <div className="flex w-full space-x-4 justify-between">
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.address?.subCity &&
                                  `${errors?.address?.subCity.message}`
                                }
                                label="Sub City"
                                placeholder="Kirkos"
                                {...register("address.subCity")}
                              />
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.address?.woreda &&
                                  `${errors?.address?.woreda?.message}`
                                }
                                label="Woreda/Kebele"
                                placeholder="Woreda/Kebele 7"
                                {...register("address.woreda")}
                              />
                            </div>
                            <div className="flex w-full space-x-4 justify-between">
                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.address?.houseNumber &&
                                  `${errors?.address?.houseNumber?.message}`
                                }
                                withAsterisk
                                label="House Number"
                                placeholder="B456"
                                {...register("address.houseNumber")}
                              />

                              <Controller
                                control={control}
                                name="specificLocation"
                                render={({ field }) => (
                                  <Select
                                    withAsterisk
                                    className="w-full"
                                    searchable
                                    nothingFound={
                                      <div className="fle w-full justify-center items-center">
                                        <EmptyIcon />
                                      </div>
                                    }
                                    error={
                                      errors.specificLocation &&
                                      `${errors?.specificLocation?.message}`
                                    }
                                    radius={"xs"}
                                    classNames={{
                                      label: "dark:text-white",
                                    }}
                                    // defaultValue="Ethiopia"
                                    label="Specific Location"
                                    placeholder="Select Specific Location"
                                    data={specificLocations
                                      ?.filter(
                                        (l: { name: string }) =>
                                          l.name !== "all"
                                      )
                                      .map((location: any) => ({
                                        label: location.name,
                                        value: location.name,
                                      }))}
                                    maxDropdownHeight={400}
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                      <Accordion.Item value="paymentInfo">
                        <Accordion.Control>
                          Payment Information
                        </Accordion.Control>
                        <Accordion.Panel>
                          <div className="flex-col space-y-4">
                            <div className="flex w-full space-x-4  justify-between">
                              <Controller
                                control={control}
                                name="splitType"
                                render={({ field }) => (
                                  <Select
                                    className="w-full"
                                    searchable
                                    nothingFound={
                                      <div className="fle w-full justify-center items-center">
                                        <EmptyIcon />
                                      </div>
                                    }
                                    error={
                                      errors.splitType &&
                                      `${errors?.splitType?.message}`
                                    }
                                    radius={"xs"}
                                    classNames={{
                                      label: "dark:text-white",
                                    }}
                                    label="Split Type"
                                    data={[
                                      {
                                        label: "Percentage",
                                        value: "percentage",
                                      },
                                      { label: "Flat", value: "flat" },
                                    ]}
                                    maxDropdownHeight={400}
                                    {...field}
                                  />
                                )}
                              />

                              <TextInput
                                classNames={{ label: "dark:text-white" }}
                                className="w-full"
                                error={
                                  errors.splitAmount &&
                                  `${errors?.splitAmount.message}`
                                }
                                label="Split Amount"
                                placeholder="Split Amount"
                                {...register("splitAmount")}
                              />
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    <div className="w-full flex justify-end items-center">
                      <Group position="right" mt="xl">
                        {editMode === "new" && (
                          <>
                            <Button
                              classNames={{ label: "flex space-x-1 " }}
                              variant="default"
                              className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                              type="reset"
                              size="xs"
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="fill-current h-6"
                                  viewBox="0 0 32 32"
                                >
                                  <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                                </svg>
                              </span>
                              <span>Reset</span>
                            </Button>
                            <Button
                              variant="filled"
                              className="bg-primary text-white"
                              type="submit"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                              loading={newProvider.isLoading}
                            >
                              <span>
                                <IconDeviceFloppy size={22} strokeWidth={1.5} />
                              </span>
                              <span>Submit</span>
                            </Button>
                          </>
                        )}
                        {editMode === "detail" && (
                          <>
                            {(params?.type === "archived" &&
                              archivedProvider?.data?.deletedAt) ||
                            (params?.type === "active" &&
                              provider?.data?.deletedAt) ? (
                              <>
                                <Confirmation
                                  type={"danger"}
                                  message={
                                    "Are you sure you want to delete it permanently?"
                                  }
                                  onYes={() => {
                                    deleteProvider(`${params?.id}`);
                                    navigate(-1);
                                  }}
                                  header={`Permanent Delete confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={deleteResponse.isLoading}
                                    className={`bg-danger text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconTrash size={20} strokeWidth={1.5} />
                                    <span>Delete</span>{" "}
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={`Are you sure you want to restore it`}
                                  onYes={() =>
                                    restoreProvider(`${params?.id}`).then(
                                      (response: any) => {
                                        if (response?.data) {
                                          setCurrentProvider(response?.data);
                                        }
                                      }
                                    )
                                  }
                                  header={`Restore confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={restoreResponse.isLoading}
                                    className={`bg-green-500 text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconArrowBackUp
                                      size={20}
                                      strokeWidth={1.5}
                                    />
                                    <span>Restore</span>
                                  </Button>
                                </Confirmation>
                              </>
                            ) : (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this provider?"
                                  }
                                  header={`Archive ${provider?.data?.name}`}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this provider?"
                                  }
                                  options={Constants.ProviderArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(reason: string) => {
                                    archiveProvider({
                                      id: `${params.id}`,
                                      reason: reason,
                                    }).then((response: any) => {
                                      if (response?.data) {
                                        setCurrentProvider(response?.data);
                                      }
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={`Are you sure you want to ${
                                    provider.data?.enabled
                                      ? "Deactivate"
                                      : "Activate"
                                  } it`}
                                  onYes={() => activateProvider(`${params.id}`)}
                                  header={`${
                                    provider.data?.enabled
                                      ? "Deactivation"
                                      : "Activation"
                                  } confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={activateResponse.isLoading}
                                    className={`${
                                      provider.data?.enabled
                                        ? " bg-danger"
                                        : "bg-green-500"
                                    } text-white`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="fill-current h-6"
                                        viewBox="0 0 48 48"
                                      >
                                        <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                      </svg>
                                    </span>
                                    {provider.data?.enabled ? (
                                      <span>Deactivate</span>
                                    ) : (
                                      <span>Activate</span>
                                    )}
                                  </Button>
                                </Confirmation>

                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </Group>
                    </div>
                  </form>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="document" pt="xs">
                <div className="flex-col w-full space-y-4">
                  {editMode === "detail" && (
                    <FileUploadComponent
                      isArchived={currentProvider?.deletedAt ? true : false}
                    />
                  )}
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="bank_account" pt="xs">
                <div className="flex-col w-full space-y-4">
                  {editMode === "detail" && (
                    <Card
                      titleParentClassNames="w-full"
                      title={
                        <div className="flex w-full p-2 justify-between items-center">
                          <div>Bank Accounts</div>
                          <div>
                            <Button
                              disabled={
                                currentProvider?.deletedAt ? true : false
                              }
                              className={`bg-primary flex space-x-1 items-center ${
                                currentProvider?.deletedAt
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                              size="xs"
                              onClick={open}
                            >
                              <IconPlus /> Add Account
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <Suspense
                        fallback={
                          <Group position="center">
                            <LoadingOverlay visible />
                          </Group>
                        }
                      >
                        <BankAccountList
                          isArchived={currentProvider?.deletedAt ? true : false}
                          onEdit={(data: Bank) => {
                            open();
                            setBankAccountEditMode("detail");
                            setSelectedAccount(data);
                          }}
                        />
                      </Suspense>
                      <Modal
                        opened={opened}
                        size={"50%"}
                        onClose={() => {
                          close();
                          setSelectedAccount(undefined);
                          setBankAccountEditMode("new");
                        }}
                      >
                        <Suspense
                          fallback={
                            <Group position="center">
                              <LoadingOverlay visible />
                            </Group>
                          }
                        >
                          <BankAccountFormComponent
                            account={selectedAccount}
                            editMode={bankAccountEditMode}
                          />
                        </Suspense>
                      </Modal>
                    </Card>
                  )}
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="kids_assignment">
                <div className="relative overflow-x-auto">
                  <LoadingOverlay visible={kidAssignments?.isFetching} />
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          Group
                        </th>
                        <th scope="col" className="py-3 px-6">
                          School
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Transportation Time
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Travel With
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Parent
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Kid
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Driver
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Amount
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Registered At
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Status
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kidAssignments.isSuccess &&
                      kidAssignments.data.data.length > 0 ? (
                        kidAssignments.data?.data.map(
                          (assignment: GroupAssignment, idx: number) => (
                            <tr
                              key={idx}
                              className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                            >
                              <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {assignment?.group?.name}
                              </th>
                              <td className="py-4 px-6 text-xs">
                                {assignment?.school?.name}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {assignment?.kid?.transportationTime}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {assignment?.kid?.kidTravelStatus}
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {assignment?.parent?.name} (
                                {assignment?.parent?.phoneNumber})
                              </td>
                              <td className="py-4 px-6 text-xs">
                                {assignment?.kid?.name}
                              </td>
                              <td className="py-4 px-6">
                                {assignment?.driver?.name &&
                                assignment?.driver?.phoneNumber ? (
                                  <>
                                    {assignment?.driver?.name} (
                                    {assignment?.driver?.phoneNumber})
                                  </>
                                ) : (
                                  "Unassigned"
                                )}
                              </td>
                              <td className="py-4 px-6 text-center">
                                {assignment?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "ETB",
                                })}
                              </td>
                              <td className="py-4 px-6 text-center">
                                {dateFormat(
                                  assignment?.createdAt,
                                  "mmmm dS, yyyy hh:mm TT"
                                )}
                              </td>

                              <td className="py-4 px-6 text-center">
                                {assignment?.status ? "Active" : "Inactive"}
                              </td>
                              <td className="py-4 px-2">
                                <div className="flex space-x-2">
                                  <Confirmation
                                    type={"notify"}
                                    message={
                                      "Are you sure you want to remove this group assignment?"
                                    }
                                    header={"Delete Confirmation"}
                                    yesText={"Remove"}
                                    onYes={(data: string) => {
                                      removeGroupAssignment({
                                        id: assignment?.id ?? "",
                                        groupId: assignment?.groupId ?? "",
                                      });
                                    }}
                                  >
                                    <Button
                                      variant="filled"
                                      loading={
                                        removeAssignmentResponse.isLoading
                                      }
                                      className="bg-danger text-white"
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <span>
                                        <IconArchive
                                          size={22}
                                          strokeWidth={1.5}
                                        />
                                      </span>
                                    </Button>
                                  </Confirmation>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>

                  {(kidAssignments?.data?.data?.length ?? 0) < 1 ? (
                    <div className="w-full relative flex justify-center items-center h-56">
                      {/* <LoadingOverlay
                              visible={kids.isLoading}
                              overlayBlur={2}
                          /> */}
                      <EmptyIcon />
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* {kids?.isSuccess &&
                kids?.data?.data?.length > 0 && (
                    <div className="w-full flex justify-end items-center mt-2 mb-2">
                        <Pagination
                            total={
                                kids?.data ? kids?.data?.count : 0
                            }
                            defaultPageSize={10}
                            pageSize={[5, 10, 15, 20]}
                            onPaginationChange={(skip: number, top: number) => {
                                setCollection({...collection, skip: skip, top: top});
                            }}
                        />
                    </div>
                )} */}
                </div>
              </Tabs.Panel>
            </Tabs>
          </>
        }
      </ActivityLogWrapperComponent>

      <Modal
        opened={openNotificationModal}
        onClose={() => {
          setOpenNotificationModal(false);
          setShowStatus(false);
          reset(defaultValue);
        }}
        className="my-modal-content"
        withCloseButton={false}
        title="Notification"
        padding={20}
        size={"lg"}
      >
        <form
          onSubmit={notificationHandleSubmit(onNotificationSubmit, onError)}
          className={"mt-2"}
        >
          <div className="w-full">
            <div className="flex w-full space-x-4  justify-center">
              <Controller
                render={({ field }) => (
                  <Select
                    radius={"xs"}
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={
                      notificationErrors?.method &&
                      `${notificationErrors?.method?.message}`
                    }
                    label="Notification Method"
                    placeholder="Notification Method..."
                    // onSelect={(e) =>
                    //   e.currentTarget.value.toLowerCase() ==
                    //   CredentialType.Parent
                    //     ? setShowStatus(true)
                    //     : setShowStatus(false)
                    // }
                    data={[
                      { label: "Both", value: NotificationMethod.Both },
                      {
                        label: "Notification",
                        value: NotificationMethod.Notification,
                      },
                      { label: "Sms", value: NotificationMethod.Sms },
                    ]}
                    withAsterisk
                    {...field}
                  />
                )}
                name="method"
                control={notificationControl}
              />
              <TextInput
                classNames={{ label: "dark:text-white" }}
                className="w-full "
                error={
                  notificationErrors.title &&
                  `${notificationErrors?.title?.message}`
                }
                withAsterisk
                label="Title"
                placeholder="Title"
                {...notificationRegister("title")}
              />
            </div>

            <div className="flex w-full space-x-4  justify-center">
              <Textarea
                placeholder="Body..."
                label="Body"
                className="w-full"
                withAsterisk
                error={
                  notificationErrors?.body &&
                  `${notificationErrors?.body?.message}`
                }
                {...notificationRegister("body")}
              />
            </div>
          </div>
          <Group position="right" mt="xl">
            <>
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                type="reset"
                onClick={() => notificationReset(defaultNotificationValue)}
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current h-6"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>
              <Button
                variant="filled"
                className="bg-primary text-white"
                type="submit"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={notificationResponse.isLoading}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </>
          </Group>
        </form>
      </Modal>
    </div>
  );
}

export const ACTIVITY_LOG_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/activities/get-activity`,
    delete:`${process.env.REACT_APP_API}/activities/delete-activity`,
    toggleStatus:`${process.env.REACT_APP_API}/activities/activate-or-block-activity`,
    archive:`${process.env.REACT_APP_API}/activities/archive-activity`,
    restore:`${process.env.REACT_APP_API}/activities/restore-activity`,
    archivedList:`${process.env.REACT_APP_API}/activities/get-archived-activities`,
    archivedActivity:`${process.env.REACT_APP_API}/activities/get-archived-activity`,
    list:`${process.env.REACT_APP_API}/activities/get-activities`,
    create:`${process.env.REACT_APP_API}/activities/create-activity`,
    update:`${process.env.REACT_APP_API}/activities/update-activity`
}
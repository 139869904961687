import { Input, LoadingOverlay, Table, Text } from "@mantine/core";
import { IconRoute, IconSearch, IconUserCheck } from "@tabler/icons";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { useLazyGetRouteAssignmentsQuery } from "../../route/store/route.query";
import { ReportCard } from "../component/ReportCard";
import {
  useLazyGroupAssignmentsByRoutesQuery,
  useLazyGroupAssignmentsByStatusQuery,
  useLazyMostBookedRoutesQuery,
} from "../store/dashboard.query";

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Earnings",
    },
  },
};
const colorPicker = (code: string) => {
  switch (code) {
    case "Created":
      return "blue";
    case "Boarded":
      return "yellow";
    case "Arrived":
      return "green";
    case "Cancelled":
      return "red";
    case "Missed":
      return "black";
    default:
      return "pink";
  }
};

export default function AssignmentDashboardPage() {
  const navigate = useNavigate();

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [assignmentCircleProgress, setAssignmentCircleProgress] = useState<
    any[]
  >([]);

  const [getAssignments, assignments] = useLazyGetRouteAssignmentsQuery();
  const [groupAssignmentByRoutes, routeGroupedAssignement] =
    useLazyGroupAssignmentsByRoutesQuery();
  const [groupAssignmentsByRoutes, routeGroupedAssignements] =
    useLazyGroupAssignmentsByRoutesQuery();
  const [groupAssignmentsByStatus, statusGroupedAssignments] =
    useLazyGroupAssignmentsByStatusQuery();
  const [getMostBookedRoutes, mostBookedRoutes] =
    useLazyMostBookedRoutesQuery();

  useEffect(() => {
    groupAssignmentsByRoutes(collection);
  }, [collection]);
  useEffect(() => {
    groupAssignmentByRoutes({ skip: 0, top: 8 });
    getMostBookedRoutes({ skip: 0, top: 8 });
    getAssignments({ count: true });
    groupAssignmentsByStatus({
      groupBy: ["status"],
    }).then((response) => {
      if (response?.data) {
        let total = assignments?.data?.count ?? 1;

        const data = response.data.map((item: any) => {
          return {
            label: item.createdAt,
            stats: item.count,
            progress: (item.count / total) * 100,
            color: colorPicker(item.createdAt),
            icon:
              item.createdAt === "Cancelled" || item.createdAt === "Missed"
                ? "down"
                : "up",
          };
        });
        setAssignmentCircleProgress(data);
      }
    });
  }, []);

  const rows = routeGroupedAssignements?.data?.data?.map(
    (element: any, index: number) => (
      <tr key={index}>
        <td>{element.routeName}</td>
        <td>{element.availableSeats}</td>
        <td>{element.count}</td>
        <td>
          {dayjs(
            new Date().toDateString() +
              " " +
              element?.pickupTime?.replace("+00", "+03")
          ).format("LT")}
        </td>
      </tr>
    )
  );
  return (
    <div className="flex-col space-y-4 py-4">
      <div className="flex-col space-y-4 p-4 hidden">
        <div className="mb-2 flex justify-between px-2">
          <Text className="text-sm text-gray-600 text-semibold">
            Assignment status by routes
          </Text>
          <Text className="text-sm text-primary text-semibold">
            {"Total: "}
            {assignments?.data?.count}
          </Text>
        </div>
        <div className="grid grid-cols-4 grid-flow-row gap-4 p-4">
          {routeGroupedAssignement?.data?.data?.map((route: any) => (
            <ReportCard
              {...{
                title: route?.routeName,
                description: "Number of Schools",
                count: parseInt(route?.count),
                loading:
                  routeGroupedAssignement?.isLoading ||
                  routeGroupedAssignement?.isFetching,
                icon: <IconRoute size={100} />,
              }}
            />
          ))}
        </div>
      </div>

      <div className="flex-col space-y-4 p-4">
        <div className="mb-2 flex justify-between px-2">
          <Text className="text-sm text-gray-600 text-semibold">
            Assignment status
          </Text>
        </div>
        <div className="w-full flex justify-center">
          <div className="grid grid-cols-4 grid-flow-row gap-4 p-4">
            {statusGroupedAssignments?.data?.map((assignment: any) => (
              <ReportCard
                {...{
                  title: assignment?.status,
                  description: "Number of Assignments",
                  count: parseInt(assignment?.count),
                  loading:
                    statusGroupedAssignments?.isLoading ||
                    statusGroupedAssignments?.isFetching,
                  icon: <IconUserCheck size={100} />,
                }}
                onClick={() => {
                  navigate("/assignment", {
                    state: {
                      status: assignment?.status,
                    },
                  });
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {routeGroupedAssignements?.data?.data && (
        <>
          <div className="px-6 space-y-2  mb-4 relative">
            <div className="w-full flex justify-end">
              <Input
               className="w-1/2"
                placeholder="Search here"
                rightSection={
                 <IconSearch size="1rem"/>

                }
                onKeyUp={debounce((e: any) => {
                  setCollection({...collection, search: e.target.value,searchFrom:['routeName']})},1000)}
              />
            </div>
            <Table fontSize={10} className="" withBorder>
              <LoadingOverlay
                visible={
                  routeGroupedAssignements?.isLoading ||
                  routeGroupedAssignements?.isFetching
                }
              />
              <thead>
                <tr>
                  <th>Route Name</th>
                  <th>Available Seats Left</th>
                  <th>Booked Seats</th>
                  <th>Pickup Time</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
          <div className="flex w-full justify-end px-4">
            <Pagination
              sizeChanger={true}
              defaultPageSize={8}
              total={routeGroupedAssignements?.data?.count ?? 0}
              pageSize={[8, 20, 30, 50, 100]}
              onPaginationChange={(skip: number, top: number) =>
                setCollection({...collection, skip: skip, top: top })
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

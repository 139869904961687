import { Address } from "./address.model";
import { EmergencyContact } from "./emergencyContact.model";

export interface School {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    address: Address;
    website: string;
    isPrivate: boolean;
    enabled: boolean;
    daysOff: number;
    contactPerson: EmergencyContact;
    status: SchoolStatus;
    lng: number;
    lat: number;
    archiveReason?: string;
    createdBy?: string;
    updatedBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    deletedBy?: string;
    logo?: Logo;
}


interface Logo {
    filename: string;
    path: string;
    originalname: string;
    mimetype: string;
    size: number;
}

export enum SchoolStatus {
    Active = '1',
    Inactive = '0',
}

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";

import NewFaqCategoryComponent from "../component/new-faq-category-component";

import FaqCategoryPage from "../page/faq-category-page";
export const FaqCategoryRoute: RouteObject = {
  path: "/faq-Category",
  element: (
    <AuthGuard role={["super_admin", "admin", "operator"]}>
      <FaqCategoryPage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: <NewFaqCategoryComponent editMode="detail" />,
    },
    { path: ":id", element: <NewFaqCategoryComponent editMode="new" /> },
  ],
};

import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import {
  CollectionQuery,
  Filter,
  Order
} from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { Passenger } from "../../../models/passenger.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeletePassengerMutation,
  useLazyGetArchivedPassengersQuery,
  useRestorePassengerMutation
} from "../store/passenger.query";

export default function ArchivedPassengerComponent() {
  const [filterData] = useState<Filter[]>([
    {
      field: "corporateId",
      value: "",
      name: "Corporate",
      operator: "notNull",
    },
    {
      field: "corporateId",
      value: "",
      name: "Individual",
      operator: "isNull",
    },
  ]);
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["corporate"],
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedPassengers, archivedPassengers] =
    useLazyGetArchivedPassengersQuery();
  const [restorePassenger, restoreCall] = useRestorePassengerMutation();
  const [deletePassenger, deleteCall] = useDeletePassengerMutation();
  const [id, setId] = useState("");

  useEffect(() => {
    getArchivedPassengers(collection);
  }, [collection]);

  const data: Passenger[] | undefined = archivedPassengers.data?.data;
  const config: EntityConfig<Passenger> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/passenger",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "phoneNumber", name: "Phone" },
      {
        key: "gender",
        name: "Gender",
        render: (value) => {
          return value.gender?.toLowerCase() === "male" ? "Male" : "Female";
        },
      },
      {
        key: "",
        name: "Corporate",
        render: (value) => {
          return value?.corporate?.name ?? "X";
        },
      },
      {
        name: "Emergency Contact",
        key: ["emergencyContact", "name"],
        render: (value) => {
          if (value.emergencyContact) {
            return (
              value.emergencyContact.name +
              " (" +
              value.emergencyContact.phoneNumber +
              ")"
            );
          }
          return <div className={"pl-5"}>-----</div>;
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      { key: "enabled", name: "Status" },
      {
        key: "",
        print:false,
        name: "Action",
        render: (passenger) => {
          return (
            <div className="flex flex-wrap space-y-1 space-x-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${passenger.id}`);
                  restorePassenger(`${passenger.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <Tooltip label="Restore Passenger">
                <ActionIcon
                  variant="filled"
                  className="bg-primary text-white"
                  size={"sm"}
                  loading={restoreCall.isLoading && `${passenger.id}` === id}
                >
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </ActionIcon>
                </Tooltip>
                
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${passenger.id}`);
                  deletePassenger(`${passenger?.id}`).then(() => setId(""));
                }}
              >
                <Tooltip label="Delete Passenger">
                <ActionIcon
                  variant="filled"
                  className="bg-danger text-white"
                  size={"sm"}
                  loading={deleteCall.isLoading && `${passenger.id}` === id}
                >
                  <IconTrash size={20} strokeWidth={1.5} />
                </ActionIcon>
                </Tooltip>
               
              </Confirmation>

              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  size={'sm'}
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: passenger?.name,
                      reason: passenger?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    filter: [filterData],
  };

  return (
    <div className={"w-full"}>
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Passengers"}
        total={archivedPassengers?.data?.count}
        itemsLoading={archivedPassengers.isFetching||restoreCall?.isLoading||deleteCall?.isLoading}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
       <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Activity } from "../../../models/activity.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { User } from "../../../models/user.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetAccountsQuery } from "../../chat/store/chat.query";
import { useLazyGetActivitiesQuery } from "../store/activity.query";

export default function ActivityPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy: [{field: "createdAt", direction: "desc"}]
  });

  const [getActivities, activities] = useLazyGetActivitiesQuery();
  const [getAccounts, accounts] = useLazyGetAccountsQuery();

  useEffect(() => {
    getActivities(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id, location]);

  const data: Activity[] | undefined = activities.data?.data;
  const config: EntityConfig<Activity> = {
    primaryColumn: { key: "action", name: "Action" },
    showDetail:false,
    detailUrl: "detail",
    rootUrl: "/activity",
    identity: "id",
    name: "action",
    visibleColumn: [
      { key: "action", name: "Action" },
      { key: ["user", "name"], name: "Done By" },
      { key: "modelName", name: "Acted On" },
      { key: "createdAt", name: "Date/Time", isDate: true },
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Activities"}
        total={activities?.data?.count}
        itemsLoading={activities.isFetching}
        config={config}
        items={data}
        showNewButton={false}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          getAccounts({searchFrom: ["name", "phoneNumber"], search: data}).then((response: any) =>{
            const f : any = [];
            response?.data?.data?.map((r:User) => {
                f.push(r.id);
            })
            setCollection({...collection, filter: [[{field: "userId", operator: "in", value: f}]] })
          })
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

import { ActionIcon, LoadingOverlay, Table } from "@mantine/core";
import { IconCheck, IconDots, IconEdit, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Bank } from "../../../models/provider.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import {
  useDeleteBankAccountMutation,
  useLazyGetArchivedProviderQuery,
  useLazyGetProviderQuery,
} from "../store/provider.query";

interface Props {
  onEdit?: (data: Bank) => void;
  isArchived?: boolean;
}
export default function BankAccountList(props: Props) {
  const { onEdit } = props;
  const params = useParams();
  const location = useLocation();
  const [accountId, setAccountId] = useState<string>("");
  const [getProvider, provider] = useLazyGetProviderQuery();
  const [deleteBankAccount, deleteResponse] = useDeleteBankAccountMutation();
  const [getArchivedProvider, archivedProvider] =
    useLazyGetArchivedProviderQuery();

  useEffect(() => {
    if (params?.type === "archived") {
      getArchivedProvider(`${params?.id}`);
    } else {
      getProvider(`${params?.id}`);
    }
  }, [params?.id]);

  const rows = (
    params?.type === "active"
      ? provider?.data?.bankAccounts
      : archivedProvider?.data?.bankAccounts
  )?.map((bank: Bank) => {
    return (
      <tr>
        <td>{bank.bankName}</td>
        <td>{bank.accountNumber}</td>
        <td>
          {bank.isPreferred ? <IconCheck size={14} /> : <IconDots size={14} />}
        </td>
        <td>
          {bank.isActive ? <IconCheck size={14} /> : <IconDots size={14} />}
        </td>
        <td>
          <div className="flex space-x-2 items-center">
            {props.isArchived ? (
              ""
            ) : (
              <>
                <ActionIcon
                  onClick={() => onEdit?.(bank)}
                  className="text-primary cursor-pointer"
                >
                  <IconEdit size={16} />
                </ActionIcon>
                <Confirmation
                  type={"danger"}
                  message={"Are you sure you want to delete it permanently?"}
                  onYes={() =>
                    deleteBankAccount({
                      id: bank.id,
                      driverId: bank.driverId,
                    })
                  }
                  header={`Permanent Delete confirmation `}
                >
                  <ActionIcon
                    loading={accountId === bank.id && deleteResponse?.isLoading}
                    onClick={() => setAccountId(`${bank.id}`)}
                    className=" text-danger cursor-pointer"
                  >
                    <IconTrash size={16} strokeWidth={1.5} />
                  </ActionIcon>
                </Confirmation>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="h-full p-4 relative">
      {provider?.isFetching ||
      provider?.isLoading ||
      archivedProvider?.isLoading ||
      archivedProvider?.isFetching ? (
        <LoadingOverlay visible />
      ) : (
        <Table fontSize={"xs"}>
          <thead className="text-xs">
            <tr>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Preferred</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      )}
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconPercentage,
  IconTrash,
} from "@tabler/icons";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Category, CategoryStatus } from "../../../models/category.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { useLazyGetActivitiesQuery } from "../../../shared/store/query/sharedQuery";
import {
  useArchiveCategoryMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetArchivedCategoryQuery,
  useLazyGetCategoryQuery,
  useRestoreCategoryMutation,
  useUpdateCategoryMutation,
} from "../store/category.query";
interface Props {
  editMode: string;
}
let schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string(),
  initialFee: yup
    .number()
    .typeError("Initial Fare must be a number")
    .positive("Initial fee must be positive"),
  capacity: yup
    .number()
    .typeError("Capacity must be a number")
    .positive("Capacity must be positive number")
    .required("Capacity is required"),

  kidsPerKilometerCost: yup
    .number()
    .typeError("Per kilometer cost should be a number"),
  kidInitialFee: yup
    .number()
    .typeError("Initial fee should be a number")
    .nullable(),
  isForKabbaKids: yup
    .string()
    .oneOf([
      CategoryStatus.Both,
      CategoryStatus.ForKabbaKids,
      CategoryStatus.ForKabbaMain,
    ])
    .required("Is for kabba kids is required"),
  perKilometerCost: yup.object().shape({
    easy: yup
      .number()
      .typeError("Easy cost must be a number")
      .positive("Easy cost must be positive number")
      .required("Easy Cost is required"),
    moderate: yup
      .number()
      .typeError("Moderate cost must be a number")
      .positive("Moderate cost must be positive number")
      .required("Moderate Cost is required"),
    difficult: yup
      .number()
      .typeError("Difficult cost must be a number")
      .positive("Difficult cost must be positive number")
      .required("Difficult Cost is required"),
    extreme: yup
      .number()
      .typeError("Extreme cost must be a number")
      .positive("Extreme must be positive number")
      .required("Extreme Cost is required"),
  }),
  perTrafficJamCost: yup.object().shape({
    easy: yup
      .number()
      .typeError("Easy cost must be a number")
      .positive("Easy cost must be positive number")
      .required("Easy Cost is required"),
    moderate: yup
      .number()
      .typeError("Moderate cost must be a number")
      .positive("Moderate cost must be positive number")
      .required("Moderate Cost is required"),
    difficult: yup
      .number()
      .typeError("Difficult cost must be a number")
      .positive("Difficult cost must be positive number")
      .required("Difficult Cost is required"),
    extreme: yup
      .number()
      .typeError("Extreme cost must be a number")
      .positive("Extreme must be positive number")
      .required("Extreme Cost is required"),
  }),
  driverCommission: yup
    .number()
    .typeError("Driver Commission must be a number")
    .positive("Driver Commission must be positive number")
    .min(1, "Driver Commission minimum is 1%")
    .max(100, "Driver Commission Maximum is 100%")
    .default(0),
});

export default function NewCategoryComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [archiveReason, setArchiveReason] = useState<string>("");
  const [activityLogger, setActivityLogger] = useState<boolean>(false);

  const defaultValue: Category = {
    id: "",
    name: "",
    description: "",
    initialFee: 0,
    capacity: 0,
    perKilometerCost: { easy: 0, moderate: 0, difficult: 0, extreme: 0 },
    perTrafficJamCost: { easy: 0, moderate: 0, difficult: 0, extreme: 0 },
    driverCommission: 1,

    isForKabbaKids: CategoryStatus.ForKabbaMain,
    kidInitialFee: 0,
    kidsPerKilometerCost: 0,
  };

  const [getCategory, category] = useLazyGetCategoryQuery();
  const [getArchivedCategory, archivedCategory] =
    useLazyGetArchivedCategoryQuery();
  const [createCategory, newCategory] = useCreateCategoryMutation();
  const [updateCategory, updateResponse] = useUpdateCategoryMutation();
  const [archiveCategory, archiveResponse] = useArchiveCategoryMutation();
  const [getActivities, activities] = useLazyGetActivitiesQuery();
  const [restoreCategory, restoredCategory] = useRestoreCategoryMutation();
  const [deleteCategory, deleteResponse] = useDeleteCategoryMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Category>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    if (editMode === "detail") {
      if (params.type === "archived") {
        getArchivedCategory(`${params?.id}`).then((response: any) => {
          if (!response?.error) {
            reset(response?.data);
          }
        });
      } else {
        getCategory(`${params?.id}`).then((response: any) => {
          if (!response?.error) {
            reset(response?.data);
          }
        });
      }
    } else {
      reset(defaultValue);
    }
  }, [params, editMode]);

  const onSubmit = (data: any) => {
    if (editMode === "new") {
      createCategory(data).then((response: any) => {
        if (!response?.error) {
          reset(defaultValue);
        }
      });
    } else {
      const category: Category = { id: params.id, ...data };
      updateCategory(category);
    }
  };

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Category" : category?.data?.name ?? ""}
        item={category}
        path={`/settings-and-configurables/category/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
        className="relative"
      >
        <LoadingOverlay
          visible={
            category.isLoading ||
            category.isFetching ||
            updateResponse.isLoading ||
            archivedCategory?.isLoading ||
            archivedCategory?.isFetching
          }
        />
        <div className="flex  justify-center h-full">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-3/4 gap-y-4 dark:text-white"
          >
            <div className="flex-col space-y-4">
              <div className="flex w-full space-x-4  justify-between">
                <TextInput
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                  error={errors.name && `${errors?.name?.message}`}
                  withAsterisk
                  label="Name"
                  placeholder="Category Name"
                  {...register("name")}
                />
                <TextInput
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                  error={errors.capacity && `${errors?.capacity?.message}`}
                  withAsterisk
                  type={"number"}
                  label="Capacity"
                  placeholder="14"
                  {...register("capacity")}
                />
              </div>
              <div className="w-full flex space-x-4 ">
                <TextInput
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                  error={errors.initialFee && `${errors?.initialFee?.message}`}
                  withAsterisk
                  type={"number"}
                  label="Initial Fee"
                  placeholder="14"
                  {...register("initialFee")}
                />

                <TextInput
                  label={"Driver Commission"}
                  placeholder="10"
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                  type={"number"}
                  withAsterisk
                  error={
                    errors.driverCommission &&
                    `${errors?.driverCommission?.message}`
                  }
                  rightSection={<IconPercentage />}
                  {...register("driverCommission")}
                />
              </div>
              <div className="w-full space-y-2">
                <span className="font-semibold text-sm">
                  Per Kilometer Cost
                </span>
                <div className="flex w-full space-x-4 justify-between">
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perKilometerCost?.easy &&
                      `${errors?.perKilometerCost?.easy.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Easy Cost"
                    placeholder="2"
                    {...register("perKilometerCost.easy")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perKilometerCost?.moderate &&
                      `${errors?.perKilometerCost?.moderate.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Moderate Cost"
                    placeholder="2"
                    {...register("perKilometerCost.moderate")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perKilometerCost?.difficult &&
                      `${errors?.perKilometerCost?.difficult.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Difficult Cost"
                    placeholder="2"
                    {...register("perKilometerCost.difficult")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perKilometerCost?.extreme &&
                      `${errors?.perKilometerCost?.extreme.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Extreme Cost"
                    placeholder="0.2"
                    {...register("perKilometerCost.extreme")}
                  />
                </div>
              </div>
              <div className="w-full space-y-2">
                <span className="font-semibold text-sm">
                  Per Traffic Jam Cost
                </span>
                <div className="flex w-full space-x-4 justify-between">
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perTrafficJamCost?.easy &&
                      `${errors?.perTrafficJamCost?.easy.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Easy Cost"
                    placeholder="2"
                    {...register("perTrafficJamCost.easy")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perTrafficJamCost?.moderate &&
                      `${errors?.perTrafficJamCost?.moderate.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Moderate Cost"
                    placeholder="2"
                    {...register("perTrafficJamCost.moderate")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perTrafficJamCost?.difficult &&
                      `${errors?.perTrafficJamCost?.difficult.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Difficult Cost"
                    placeholder="2"
                    {...register("perTrafficJamCost.difficult")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.perTrafficJamCost?.extreme &&
                      `${errors?.perTrafficJamCost?.extreme.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Extreme Cost"
                    placeholder="0.2"
                    {...register("perTrafficJamCost.extreme")}
                  />
                </div>
              </div>

              <div className="w-full space-y-2">
                <span className="font-semibold text-sm">Kabba Kids</span>
                <div className="flex w-full space-x-3 justify-between">
                  <Controller
                    render={({ field }) => (
                      <Select
                        radius={"xs"}
                        classNames={{ label: "dark:text-white" }}
                        className="w-full"
                        error={
                          errors?.isForKabbaKids &&
                          `${errors?.isForKabbaKids?.message}`
                        }
                        label="Is for"
                        placeholder="Is for"
                        data={[
                          {
                            label: "For Kabba main",
                            value: CategoryStatus.ForKabbaMain,
                          },
                          {
                            label: "For Kabba Kids",
                            value: CategoryStatus.ForKabbaKids,
                          },
                          { label: "Both", value: CategoryStatus.Both },
                        ]}
                        withAsterisk
                        {...field}
                      />
                    )}
                    name="isForKabbaKids"
                    control={control}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.kidsPerKilometerCost &&
                      `${errors?.kidsPerKilometerCost?.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Per kilometer cost"
                    placeholder="2"
                    {...register("kidsPerKilometerCost")}
                  />
                  <TextInput
                    classNames={{ label: "dark:text-white text-xs" }}
                    className="w-full"
                    error={
                      errors.kidInitialFee &&
                      `${errors?.kidInitialFee?.message}`
                    }
                    withAsterisk
                    type={"number"}
                    label="Initial fee"
                    placeholder="0.2"
                    {...register("kidInitialFee")}
                  />
                </div>
              </div>

              <div className="w-full">
                <Textarea
                  classNames={{ label: "dark:text-white " }}
                  className="w-full"
                  error={
                    errors.description && `${errors?.description?.message}`
                  }
                  label="Description"
                  placeholder="description"
                  mt="sm"
                  {...register("description")}
                />
              </div>
              <div className="w-full flex justify-end items-center">
                <Group position="right" mt="xl">
                  {editMode === "new" && (
                    <>
                      <Button
                        classNames={{ label: "flex space-x-1 " }}
                        variant="default"
                        className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                        type="reset"
                        size="xs"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="fill-current h-6"
                            viewBox="0 0 32 32"
                          >
                            <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                          </svg>
                        </span>
                        <span>Reset</span>
                      </Button>
                      <Button
                        variant="filled"
                        className="bg-primary text-white"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={newCategory.isLoading}
                      >
                        <span>
                          <IconDeviceFloppy size={22} strokeWidth={1.5} />
                        </span>
                        <span>Submit</span>
                      </Button>
                    </>
                  )}
                  {editMode === "detail" && (
                    <>
                      {(params?.type === "archived" &&
                        archivedCategory?.data?.deletedAt) ||
                      (params?.type === "active" &&
                        category?.data?.deletedAt) ? (
                        <>
                          <Confirmation
                            type={"danger"}
                            message={
                              "Are you sure you want to delete it permanently?"
                            }
                            onYes={() =>
                              deleteCategory(`${params?.id}`).then(
                                (response: any) => {
                                  if (response?.data) {
                                    navigate(-1);
                                  }
                                }
                              )
                            }
                            header={`Permanent Delete confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={deleteResponse.isLoading}
                              className={`bg-danger text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconTrash size={20} strokeWidth={1.5} />
                              <span>Delete</span>{" "}
                            </Button>
                          </Confirmation>
                          <Confirmation
                            type={"notify"}
                            message={`Are you sure you want to restore it`}
                            onYes={() => restoreCategory(`${params?.id}`)}
                            header={`Restore confirmation `}
                          >
                            <Button
                              variant="filled"
                              loading={restoredCategory.isLoading}
                              className={`bg-green-500 text-white flex space-x-2 items-center`}
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <IconArrowBackUp size={20} strokeWidth={1.5} />
                              <span>Restore</span>
                            </Button>
                          </Confirmation>
                        </>
                      ) : (
                        <>
                          <Confirmation
                            type={"notify"}
                            message={
                              "Are you sure you want to archive this category?"
                            }
                            header={`Archive ${category?.data?.name}`}
                            result={"single"}
                            resultRequired={true}
                            selectorLabel={"Please select a reason"}
                            labelDescription={
                              "Why are you archiving this category?"
                            }
                            options={[
                              `It's not longer needed`,
                              `It is incorrect and can't update it`,
                            ].map((reason) => {
                              return { label: reason, value: reason };
                            })}
                            customInput={true}
                            yesText={"Archive"}
                            onYes={(reason: string) => {
                              archiveCategory({
                                id:
                                  params?.type === "archived"
                                    ? archivedCategory?.data?.id
                                    : category?.data?.id,
                                reason: reason,
                              });
                            }}
                          >
                            <Button
                              variant="filled"
                              loading={archiveResponse.isLoading}
                              className="bg-danger text-white"
                              type="button"
                              classNames={{ label: "flex space-x-1" }}
                              size={"xs"}
                            >
                              <span>
                                <IconArchive size={22} strokeWidth={1.5} />
                              </span>
                              <span>Archive</span>
                            </Button>
                          </Confirmation>
                          <Button
                            variant="filled"
                            className="bg-primary text-white"
                            type="submit"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                            loading={newCategory.isLoading}
                          >
                            <span>
                              <IconDeviceFloppy size={22} strokeWidth={1.5} />
                            </span>
                            <span>Update</span>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Group>
              </div>
            </div>
          </form>
        </div>
      </ActivityLogWrapperComponent>
    </div>
  );
}

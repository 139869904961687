import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { Provider } from "../../../models/provider.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteProviderMutation,
  useLazyGetArchivedProvidersQuery,
  useRestoreProviderMutation
} from "../store/provider.query";

export default function ArchivedProviderComponent() {
  const params = useParams();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedProviders, archivedProviders] =
    useLazyGetArchivedProvidersQuery();
  const [restoreProvider, restoreResponse] = useRestoreProviderMutation();
  const [deleteProvider, deleteResponse] = useDeleteProviderMutation();

  useEffect(() => {
    getArchivedProviders(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id]);

  const data: Provider[] | undefined = archivedProviders.data?.data;
  const config: EntityConfig<Provider> = {
    primaryColumn: { key: "name", name: "Name" },
    showDetail: false,
    rootUrl: "/provider/archived",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "phoneNumber", name: "Phone Number", hideSort: true },
      { key: ["vehicle","plateNumber"], name: "Vehicle", render:(provider)=>{
        return (<span>{`${provider?.vehicle?.model} - ${provider?.vehicle?.plateNumber}`}</span>)
      } },
      { key: "gender", name: "Gender" },
      {
        key: "emergencyContact",
        name: "Emergency Contact",
        hideSort: true,
        render: (provider) => {
          return (
            <div className="flex space-x-1">
              <span>{provider?.emergencyContact?.name}</span>
              {"-"}
              <span>{provider?.emergencyContact?.phoneNumber}</span>
            </div>
          );
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      { key: "enabled", name: "Status", isBoolean: true, hideSort: true },
      {
        key: "",
        name: "Action",
        hideSort: true,
        render: (provider) => {
          return (
            <div className="flex flex-wrap space-x-1 items-center justify-center">
              <Confirmation
                type={"notify"}
                message={`Are you sure you want to restore it`}
                onYes={() => restoreProvider(`${provider?.id}`)}
                header={`Restore confirmation `}
              >
                <div className="px-2 bg-primary rounded text-white cursor-pointer relative">
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Confirmation
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                onYes={() => deleteProvider(`${provider?.id}`)}
                header={`Permanent Delete confirmation `}
              >
                <div className="px-2 bg-danger rounded text-white cursor-pointer">
                  <IconTrash size={20} strokeWidth={1.5} />
                </div>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: provider?.name,
                      reason: provider?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    filter: [
      [
        { field: "gender", value: "male", operator: "=", name: "Male" },
        { field: "gender", value: "female", operator: "=", name: "Female" },
      ],
    ],
  };

  return (
    <div className="w-full">
      <EntityList
        viewMode={viewMode}
        showNewButton={false}
        title={"Archived Providers"}
        total={archivedProviders?.data?.count}
        itemsLoading={
          archivedProviders.isLoading ||
          archivedProviders.isFetching ||
          restoreResponse?.isLoading ||
          deleteResponse?.isLoading
        }
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

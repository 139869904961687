export const PASSENGER_ENDPOINT={
    detail:`${process.env.REACT_APP_API}/passengers/get-passenger`,
    delete:`${process.env.REACT_APP_API}/passengers/delete-passenger`,
    toggleStatus:`${process.env.REACT_APP_API}/passengers/activate-or-block-passenger`,
    archive:`${process.env.REACT_APP_API}/passengers/archive-passenger`,
    restore:`${process.env.REACT_APP_API}/passengers/restore-passenger`,
    archivedList:`${process.env.REACT_APP_API}/passengers/get-archived-passengers`,
    archivedPassenger:`${process.env.REACT_APP_API}/passengers/get-archived-passenger`,
    list:`${process.env.REACT_APP_API}/passengers/get-passengers`,
    create:`${process.env.REACT_APP_API}/passengers/create-passenger`,
    update:`${process.env.REACT_APP_API}/passengers/update-passenger`
}
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, Switch, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Configuration } from "../../../models/configuration.model";
import Card from "../../../shared/component/Card/card-component";
import {
  useLazyGetConfigurationQuery,
  useUpdateConfigurationMutation,
} from "../store/configuration.query";

export default function ConfigurationPage() {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      globalConfigurations: yup.object().shape({
        groupingRadius: yup
          .number()
          .typeError("Grouping Radius should be a number")
          .required("Grouping Radius is required"),
        timeout: yup
          .number()
          .typeError("Timeout should be a number")
          .required("Timeout is required"),
        recentParentIosVersion: yup
          .string()
          .required("Recent parents IOS version is required"),
        recentPassengerIosVersion: yup
          .string()
          .required("Recent passengers IOS version is required"),
        recentDriverIosVersion: yup
          .string()
          .required("Recent drivers IOS version is required"),
        isBeingMaintained: yup
          .boolean()
          .typeError("Maintenance Mode should be a boolean")
          .required("Maintenance Mode is required"),
        telebirrStatus: yup
          .boolean()
          .typeError("Telebirr status should be a boolean")
          .required("Telebirr status is required"),
        chapaStatus: yup
          .boolean()
          .typeError("Chapa status should be a boolean")
          .required("Chapa status is required"),
        kidsNumberOfSchoolDays: yup
          .number()
          .typeError("Kids Number of School Days should be a number")
          .required("Kids Number of School Days is required"),
        minimumDistance: yup
          .number()
          .typeError("Minimum distance should be a number")
          .required("Minimum distance is required"),
        onDemandInitialFee: yup
          .number()
          .typeError("On Demand Initial Fee should be a number")
          .required("On Demand Initial Fee is required"),
        onDemandPerKiloMeterCost: yup
          .number()
          .typeError("On Demand Per Kilometer cost should be a number")
          .required("On Demand Per Kilometer cost is required"),
        daysAfterExpiry: yup
          .number()
          .typeError("Days After Expiry should be a number")
          .required("Days After Expiry is required"),
      }),
    })
    .required();

  const defaultValue: Configuration = {
    globalConfigurations: {
      groupingRadius: 5,
      timeout: 0,
      isBeingMaintained: false,
      telebirrStatus: true,
      chapaStatus: true,
      isParentLive: false,
      isPassengerLive: false,
      isDriverLive: false,
      kidsNumberOfSchoolDays: 23,
      daysAfterExpiry: 5,
      onDemandInitialFee: 220,
      onDemandPerKiloMeterCost: 16,
      kidsDriverCommission: 70,
      minimumDistance: 6,
    },
  };
  const [getConfiguration, configuration] = useLazyGetConfigurationQuery();
  const [updateConfiguration, updateConfigurationResponse] =
    useUpdateConfigurationMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });

  const onSubmit = (data: any) => {
    data.globalConfigurations.kidsDriverCommission = new Number(
      data.globalConfigurations.kidsDriverCommission
    );
    data.globalConfigurations.minimumDistance = new Number(
      data.globalConfigurations.minimumDistance
    );
    updateConfiguration({
      id: configuration?.data?.id ?? "",
      globalConfigurations: data.globalConfigurations,
    })
      .unwrap()
      .then(() => {})
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getConfiguration("")
      .unwrap()
      .then((response: Configuration) => {
        setValue(
          "globalConfigurations.daysAfterExpiry",
          response.globalConfigurations.daysAfterExpiry
        );
        setValue(
          "globalConfigurations.groupingRadius",
          response.globalConfigurations.groupingRadius
        );
        setValue(
          "globalConfigurations.isBeingMaintained",
          response.globalConfigurations.isBeingMaintained
        );
        setValue(
          "globalConfigurations.kidsNumberOfSchoolDays",
          response.globalConfigurations.kidsNumberOfSchoolDays
        );
        setValue(
          "globalConfigurations.timeout",
          response.globalConfigurations.timeout
        );
        setValue(
          "globalConfigurations.kidsDriverCommission",
          response.globalConfigurations.kidsDriverCommission
        );
        setValue(
          "globalConfigurations.minimumDistance",
          response.globalConfigurations.minimumDistance
        );
        setValue(
          "globalConfigurations.onDemandInitialFee",
          response.globalConfigurations.onDemandInitialFee
        );
        setValue(
          "globalConfigurations.onDemandPerKiloMeterCost",
          response.globalConfigurations.onDemandPerKiloMeterCost
        );
        setValue(
          "globalConfigurations.recentDriverIosVersion",
          response.globalConfigurations.recentDriverIosVersion
        );
        setValue(
          "globalConfigurations.recentParentIosVersion",
          response.globalConfigurations.recentParentIosVersion
        );
        setValue(
          "globalConfigurations.recentPassengerIosVersion",
          response.globalConfigurations.recentPassengerIosVersion
        );
        setValue(
          "globalConfigurations.chapaStatus",
          response.globalConfigurations.chapaStatus
        );
        setValue(
          "globalConfigurations.telebirrStatus",
          response.globalConfigurations.telebirrStatus
        );
        setValue(
          "globalConfigurations.isDriverLive",
          response.globalConfigurations.isDriverLive
        );
        setValue(
          "globalConfigurations.isParentLive",
          response.globalConfigurations.isParentLive
        );
        setValue(
          "globalConfigurations.isPassengerLive",
          response.globalConfigurations.isPassengerLive
        );
      })
      .catch((e: Error) => {});
  }, [getConfiguration]);
  const onError = (error: any) => {};

  const config: Configuration | undefined = configuration?.data;

  return (
    <div className="h-full p-4">
      <Card title={"Configuration"}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="w-full gap-y-4 dark:text-white m-2 p-2"
        >
          <div className="flex-col space-y-4">
            <div className="flex w-full space-x-4  justify-start">
              <TextInput
                placeholder="Radius"
                className="w-full"
                label="Radius"
                error={
                  errors?.globalConfigurations?.groupingRadius &&
                  `${errors?.globalConfigurations?.groupingRadius?.message}`
                }
                {...register("globalConfigurations.groupingRadius")}
                withAsterisk
              />
              <TextInput
                placeholder="Timeout"
                className="w-full"
                label="Timeout"
                error={
                  errors?.globalConfigurations?.timeout &&
                  `${errors?.globalConfigurations?.timeout?.message}`
                }
                {...register("globalConfigurations.timeout")}
                withAsterisk
              />
            </div>
            <div className="flex w-full space-x-4  justify-start">
              <TextInput
                placeholder="Kids Number of school days"
                label="Kids Number of school days"
                className="w-full"
                error={
                  errors?.globalConfigurations?.kidsNumberOfSchoolDays &&
                  `${errors?.globalConfigurations?.kidsNumberOfSchoolDays?.message}`
                }
                {...register("globalConfigurations.kidsNumberOfSchoolDays")}
                withAsterisk
              />
              <TextInput
                placeholder="Days after expiry"
                label="Days after expiry"
                className="w-full"
                error={
                  errors?.globalConfigurations?.daysAfterExpiry &&
                  `${errors?.globalConfigurations?.daysAfterExpiry?.message}`
                }
                {...register("globalConfigurations.daysAfterExpiry")}
                withAsterisk
              />
            </div>
            <div className="flex w-full space-x-4  justify-start">
              <TextInput
                placeholder="On Demand per kilometer cost"
                label="On Demand per kilometer cost"
                className="w-full"
                error={
                  errors?.globalConfigurations?.onDemandPerKiloMeterCost &&
                  `${errors?.globalConfigurations?.onDemandPerKiloMeterCost?.message}`
                }
                {...register("globalConfigurations.onDemandPerKiloMeterCost")}
                withAsterisk
              />
              <TextInput
                placeholder="On Demand Initial Fee"
                label="On Demand Initial Fee"
                className="w-full"
                error={
                  errors?.globalConfigurations?.onDemandInitialFee &&
                  `${errors?.globalConfigurations?.onDemandInitialFee?.message}`
                }
                {...register("globalConfigurations.onDemandInitialFee")}
                withAsterisk
              />
            </div>
            <div className="flex w-full space-x-4  justify-start">
              <TextInput
                placeholder="Kids Driver Commision"
                label="Kids Driver Commision"
                className="w-full"
                type="number"
                error={
                  errors?.globalConfigurations?.kidsDriverCommission &&
                  `${errors?.globalConfigurations?.kidsDriverCommission?.message}`
                }
                {...register("globalConfigurations.kidsDriverCommission")}
                withAsterisk
              />
              <TextInput
                placeholder="Minimum Distance"
                label="Minimum Distance"
                className="w-full"
                type="number"
                error={
                  errors?.globalConfigurations?.minimumDistance &&
                  `${errors?.globalConfigurations?.minimumDistance?.message}`
                }
                {...register("globalConfigurations.minimumDistance")}
                withAsterisk
              />
            </div>
            <div className="flex w-full space-x-4  justify-start">
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Passengers IOS Version"
                  className="w-full"
                  error={
                    errors?.globalConfigurations?.recentPassengerIosVersion &&
                    `${errors?.globalConfigurations?.recentPassengerIosVersion?.message}`
                  }
                  {...register(
                    "globalConfigurations.recentPassengerIosVersion"
                  )}
                  withAsterisk
                />
                <Switch
                  label="Is Passenger App Live?"
                  className="w-full mt-6"
                  {...register("globalConfigurations.isPassengerLive")}
                  error={
                    errors?.globalConfigurations?.isPassengerLive &&
                    `${errors?.globalConfigurations?.isPassengerLive?.message}`
                  }
                />
              </div>
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Drivers IOS Version"
                  className="w-full"
                  error={
                    errors?.globalConfigurations?.recentDriverIosVersion &&
                    `${errors?.globalConfigurations?.recentDriverIosVersion?.message}`
                  }
                  {...register("globalConfigurations.recentDriverIosVersion")}
                  withAsterisk
                />
                <Switch
                  label="Is Driver App Live?"
                  className="w-full mt-6"
                  {...register("globalConfigurations.isDriverLive")}
                  error={
                    errors?.globalConfigurations?.isDriverLive &&
                    `${errors?.globalConfigurations?.isDriverLive?.message}`
                  }
                />
              </div>
            </div>

            <div className="flex w-full space-x-5  justify-start items-center">
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Parents IOS Version"
                  className="w-full"
                  error={
                    errors?.globalConfigurations?.recentParentIosVersion &&
                    `${errors?.globalConfigurations?.recentParentIosVersion?.message}`
                  }
                  {...register("globalConfigurations.recentParentIosVersion")}
                  withAsterisk
                />
                <Switch
                  label="Is Parent App Live?"
                  className="w-full mt-6"
                  {...register("globalConfigurations.isParentLive")}
                  error={
                    errors?.globalConfigurations?.isParentLive &&
                    `${errors?.globalConfigurations?.isParentLive?.message}`
                  }
                />
              </div>
              <div className="flex w-full justify-between">
                {" "}
                <Switch
                  label="Telebirr Status"
                  className="w-full"
                  {...register("globalConfigurations.telebirrStatus")}
                  error={
                    errors?.globalConfigurations?.telebirrStatus &&
                    `${errors?.globalConfigurations?.telebirrStatus?.message}`
                  }
                />
                <Switch
                  label="Chapa Status"
                  className="w-full "
                  {...register("globalConfigurations.chapaStatus")}
                  error={
                    errors?.globalConfigurations?.chapaStatus &&
                    `${errors?.globalConfigurations?.chapaStatus?.message}`
                  }
                />
                <Switch
                  label="Maintenance Mode"
                  className="w-full mt-2"
                  {...register("globalConfigurations.isBeingMaintained")}
                  error={
                    errors?.globalConfigurations?.isBeingMaintained &&
                    `${errors?.globalConfigurations?.isBeingMaintained?.message}`
                  }
                />
              </div>
            </div>

            <div className="w-full flex justify-end items-center">
              <Group position="center" mt="xl">
                <Button
                  variant="filled"
                  className="bg-primary w-40 text-white"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={updateConfigurationResponse?.isLoading}
                >
                  <span>Update</span>
                </Button>
              </Group>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

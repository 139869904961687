import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedCorporateComponent from "../component/archived-corporate-component";
import CorporateEmployeeSelectComponent from "../component/employee/corporate-employee-select-component";
import NewCorporateComponent from "../component/new-corporate-component";
import CorporatePage from "../page/corporate-page";
import CorporateTransaction from "../page/corporate-transaction.page";
export const CorporateRoute: RouteObject = {
  path: "/registration/corporate",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <CorporatePage />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id/add",
      element: <CorporateEmployeeSelectComponent />,
    },
    {
      path: "detail/:type/:id",
      element: <NewCorporateComponent editMode="detail" />,
    },
    { path: ":id", element: <NewCorporateComponent editMode="new" /> },
    { path: "archived", element: <ArchivedCorporateComponent /> },
  ],
};
export const CorporateTransactionRoute: RouteObject = {
  path: "/registration/corporate/transaction/:id",
  element: (
    <AuthGuard role={["admin",'super_admin','finance','operator']}>
      <CorporateTransaction />
    </AuthGuard>
  ),
};

import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { User } from "../../../models/user.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteUserMutation,
  useLazyGetArchivedUsersQuery,
  useRestoreUserMutation
} from "../store/user.query";

export default function ArchivedUserComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedUsers, archivedUsers] = useLazyGetArchivedUsersQuery();
  const [restoreUser, restoreCall] = useRestoreUserMutation();
  const [deleteUser, deleteCall] = useDeleteUserMutation();
  const [id, setId] = useState("");

  useEffect(() => {
    getArchivedUsers(collection);
  }, [collection]);

  const data: User[] | undefined = archivedUsers.data?.data;

  const config: EntityConfig<User> = {
    primaryColumn: { key: "name", name: "Name" },
    showFullScreen:true,
    detailUrl: "detail",
    rootUrl: "/user/archived",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone" },
      {
        key: "gender",
        name: "Gender",
        render: (value) => {
          return value.gender.toLowerCase() === "male" ? "Male" : "Female";
        },
      },
     
      {
        name: "Emergency Contact",
        key: "",
        render: (value) => {
          if (value.emergencyContact) {
            return (
              value.emergencyContact.name +
              " (" +
              value.emergencyContact.phoneNumber +
              ")"
            );
          } else {
            return "------";
          }
        },
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      {
        key: "enabled",
        name: "Status",
      },
      {
        key: "",
        name: "Action",
        print:false,
        render: (user) => {
          return (
            <div className="flex flex-wrap space-x-1 space-y-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${user.id}`);
                  restoreUser(`${user.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <ActionIcon  className="bg-primary text-white"
                loading={restoreCall.isLoading && `${user.id}` === id}>
                  <IconArrowBackUp size={16} strokeWidth={1.5} />
                </ActionIcon>
               
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${user.id}`);
                  deleteUser(`${user?.id}`).then(() => setId(""));
                }}
              >
                 <ActionIcon  className="bg-danger text-white"
                 loading={deleteCall.isLoading && `${user.id}` === id}>
                   <IconTrash size={16} strokeWidth={1.5} />
                </ActionIcon>
                
              </Confirmation>
              
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  size={'sm'}
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: user?.name,
                      reason: user?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className="h-full w-full">
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Users"}
        total={archivedUsers?.data?.count}
        itemsLoading={archivedUsers.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
       <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import { RouteObject } from "react-router-dom";
export const TripRoute: RouteObject = {
  // path: "/trips",
  // element: (
  //   <AuthGuard
  //     role={["super_admin", "admin", "finance", "operator", "transport"]}
  //   >
  //     <TripPage />
  //   </AuthGuard>
  // ),
  // children: [{ path: "detail/:type/:id", element: <TripDetailComponent /> }],
};

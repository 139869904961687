import { Group } from "./group.model";
import { Provider } from "./provider.model";

export interface Job {
  id: string;
  title: string;
  startLocation: string;
  description: string;
  status?: JobStatus;
  group?: Group;
  groupId?: string;
  driverId?: string;

  maxApplicantNumber: number;
  minimumRating?: number;
  routePreference?: string;

  archiveReason?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  deletedBy?: string;
}

export interface Candidate {
  id: string;
  status: CandidateStatus;

  jobId?: string;
  job?: Job;
  driverId?: string;
  driver?: Provider;

  archiveReason?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  deletedBy?: string;
}

export enum CandidateStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum JobStatus {
  Assigned = 'Assigned',
  Open = "Open",
  Close = "Close",
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from "../../../shared/component/notification/utility/notification";
import { appApi } from "../../../store/app.api";
import { ACCOUNT_ENDPOINT } from "../account.endpoints";


const accountQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forgetPassword: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${ACCOUNT_ENDPOINT.forget}`,
        method: "POST",
        data:data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch(error:any) {
          notification('error',error?.error?.data?.message ? error?.error?.data?.message:'Error try again')

        }
      },
    }),
    changePassword: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${ACCOUNT_ENDPOINT.change_password}`,
        method: "POST",
       data:data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notification('success','Password changed successfully')
        } catch(error:any) {
          notification('error',error?.error?.data?.message ? error?.error?.data?.message:'Error try again')

        }
      },
    }),
    resetPassword: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${ACCOUNT_ENDPOINT.reset_password}`,
        method: "POST",
       data:data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notification('success','Password changed successfully')
        } catch(error:any) {
          notification('error',error?.error?.data?.message ? error?.error?.data?.message:'Error try again')

        }
      },
    })
  }),
  overrideExisting: true,
});
export const {
 useForgetPasswordMutation,
 useChangePasswordMutation,
 useResetPasswordMutation
} = accountQuery;

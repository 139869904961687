import {
  ActionIcon,
  Alert,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
  Timeline
} from "@mantine/core";
import {
  IconAlertCircle,
  IconBusStop,
  IconDeviceFloppy,
  IconMap2,
  IconMinus,
  IconPlus,
  IconRoute,
  IconRouteOff
} from "@tabler/icons";
import { random } from "lodash-es";
import { useEffect, useMemo, useRef, useState } from "react";
import { BusStop } from "../../../models/bus-stop.model";
import { CollectionQuery } from "../../../models/collection.model";
import { RouteStation } from "../../../models/route-station.model";
import { Route } from "../../../models/route.model";
import ImagePreview from "../../../shared/component/ImagePreview/ImagePreview";
import { CalculateDistance } from "../../../shared/component/Map/Map.utils";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { notification } from "../../../shared/component/notification/utility/notification";
import { useLazyGetCityBusStopsQuery } from "../../city/store/city.query";
import {
  useAddRouteStationsMutation,
  useLazyGetRouteStationsQuery
} from "../store/route.query";

type StationProps = {
  route?: Route;
};

export default function StationComponent(props: StationProps) {
  const { route } = props;

  const inputRef=useRef(null);
  const [stationCount, setStationCount] = useState(0);
  const [stations, setStations] = useState<RouteStation[]>([]);
  const [isPickUp, setIsPickUp] = useState<boolean>(false);
  const [isDestination, setIsDestination] = useState<boolean>(false);
  const [selectedBusStopId, setSelectedBusStopId] = useState<string>("");
  const [stationPosition, setStationPosition] = useState<number>(0);

  const [collection] = useState<CollectionQuery>({
    skip: 0,
    top: 1000,
  });

  const [getCityBusStops, cityBusStops] = useLazyGetCityBusStopsQuery();
  const [getRouteStations, routeStations] = useLazyGetRouteStationsQuery();
  const [addStations, addedStations] = useAddRouteStationsMutation();

  const switchMemo = useMemo(() => {
    const pick = stations?.some((station) => station?.isPickup === true);
    const destination = stations?.some(
      (station) => station?.isDestination === true
    );
    if (pick) {
      setIsPickUp(false);
    }
    if (destination) {
      setIsDestination(false);
    }
    return { pick: pick, destination: destination };
  }, [stations]);

  useEffect(() => {
    if (route?.cityId) {
      getCityBusStops({ query:{ ...collection,filter: [[{ field: "isActive", value: true, operator: "=" }]],
    }, CityId: route?.cityId });
    }
    if (route?.id) {
      getRouteStations({
        query: {
          ...collection,
          includes: ["busStop"],
          orderBy: [{ field: "position", direction: "asc" }],
          
        },
        id: route?.id,
      }).then((response: any) => {
        if (response && !response.error) {
         
          const writableData: RouteStation[] = JSON.parse(
            JSON.stringify(response.data.data)
          );
          
          SortAndSetStations(writableData);
        }
       
        setStationPosition(0);
        setSelectedBusStopId("");
      });
    }
  }, [route]);

  function filteredBusStopList() {
    const response = cityBusStops.data;
    if (!cityBusStops.isFetching && response) {
      if (response.data) {
        const stationIds: string[] = [];
        stations
          .filter((station) => station?.busStopId)
          .map((station) => {
            stationIds.push(station.busStopId);
          });
        const filtered: BusStop[] = response.data.filter(
          (station) => station?.id && stationIds.indexOf(station?.id) === -1
        );
        if (filtered.length > 0) {
          return filtered.map((item: BusStop) => ({
            label: `${item.name}`,
            value: item.id ?? "",
          }));
        }
      }
    }
    return [{ value: "empty", label: "List Empty", disabled: true }];
  }

  const Calculate = async (
    _stations: RouteStation[]
  ): Promise<Record<string, number>> => {
    const BustStopId_DistanceMap: Record<string, number> = {};
    for (let index = 0; index < _stations.length; index++) {
      let station = _stations[index];
      if (!station.isDestination) {
        const stationId = station.busStopId;
        const nextStation = _stations[index + 1];
        if (nextStation && nextStation.busStop && station.busStop) {
          const distance = await CalculateDistance(
            { lat: station.busStop.lat, lng: station.busStop.lng },
            { lat: nextStation.busStop.lat, lng: nextStation.busStop.lng }
          );
          BustStopId_DistanceMap[stationId] = Number(
            (distance / 1000).toFixed(2)
          );
        }
      }
    }
    return BustStopId_DistanceMap;
  };

  const distanceMatrix = async (_stations: RouteStation[]) => {
    const empty = _stations.filter((_station) => !_station.busStop);
    if (empty.length === 0) {
      await Calculate(_stations).then((map) => {
        _stations.map((_station) => {
          if (map[_station.busStopId]) {
            _station.distanceToNextBusStop = map[_station.busStopId];
          }
        });
        setStations([..._stations]);
      });
    }
  };

  function SortAndSetStations(_stations: RouteStation[]) {
    _stations.map((station, index) => {
      station.position = index + 1;
    });
    // Reverse array: to make it easier to set nextBusStopId
    _stations = _stations.reverse();
    let lastBusStopId = "";
    _stations.map((station) => {
      if (lastBusStopId) station.nextBusStopId = lastBusStopId;
      lastBusStopId = station.busStopId;
    });
    _stations = _stations.reverse();
    // Undo Reverse array.
    setStations([..._stations]);
    setStationCount(_stations.length);
    setStationPosition(0);
    distanceMatrix(_stations);
  }
  function addStation(position: number) {
    if (route?.id) {
      stations.splice(position, 0, {
        busStopId: "",
        distanceToNextBusStop: 0,
        isDestination: switchMemo?.pick && !switchMemo?.destination,
        isPickup: isPickUp,
        nextBusStopId: isDestination ? "" : "not empty",
        position: 0,
        routeId: route?.id,
      });
      SortAndSetStations(stations);
    }
  }

  function assignBusStopToStation() {
    const response = cityBusStops.data;
    if (!cityBusStops.isFetching && response) {
      if (response.data) {
        const filter = response.data.filter(
          (bus_stop: BusStop) => bus_stop.id === selectedBusStopId
        );
        if (filter.length > 0) {
          const bus_stop = filter[0];

          if (stations?.length >= 2) {
            stations.map((station) => {
              if (station.position === stationPosition) {
                station.busStopId = bus_stop.id ?? "";
                station.busStop = bus_stop;
              }
            });
          } else {
            stations.push({
              busStopId: bus_stop.id ?? "",
              busStop: bus_stop,
              distanceToNextBusStop: 0,
              isDestination: isDestination,
              isPickup: isPickUp,
              nextBusStopId: "",
              position: isDestination ? 2 : 1,
              routeId: route?.id ?? "",
            });
          }
        }
      }
    }
    
    if(inputRef){
     setSelectedBusStopId('');
    }
    
    setStationPosition(0);
    SortAndSetStations([...stations]);
  }

  function removeStation(position: number) {
    const _stations: RouteStation[] = stations.filter(
      (station) => station.position !== position
    );
    SortAndSetStations([..._stations]);
  }

  function parseStations() {
    let _stations = stations
      .filter((station) => station.busStopId !== "")
      .sort((a, b) => a.position - b.position);

    let last_id = "";
    _stations = _stations.reverse().map((station) => {
      station.nextBusStopId = last_id;
      last_id = station.busStopId;
      return station;
    });

    return _stations.reverse();
  }

  function submitData() {
    if (!route) return;
    const length = stations.length;
    const lastIndex = length - 1;

    if (!stations[lastIndex].isDestination) {
      SortAndSetStations([...stations]);
      notification(
        "warning",
        "Please check your station assignments and resubmit!"
      );
      return;
    }

    if (
      length < 2 ||
      !stations[0].isPickup ||
      !stations[lastIndex].isDestination
    ) {
      notification(
        "error",
        "Please refresh the page and redo station assignation!"
      );
      return;
    }

    const empty = stations.filter((station) => !station.busStop);
    if (empty.length > 0) {
      notification("error", "Please complete station assignation!");
      return;
    }

    const _stations = parseStations();
    const data = {
      routeId: route.id,
      stations: _stations,
    };
    addStations(data);
  }

  function buildMapUrl() {
    let url =
      "https://img.freepik.com/free-vector/collection-frame-quote-flat-design_23-2147635701.jpg?w=2000";

    setPreviewUrl(url);
  }

  const [preview, setPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  return (
    <div className="p-4 relative" style={{ height: "70vh" }}>
      <ImagePreview
        opened={preview}
        setOpened={setPreview}
        url={previewUrl}
        placeHolder={"Static Map of the route stations"}
      />
      <LoadingOverlay visible={routeStations.isFetching} />
      <Group position={"apart"} grow className={"items-start"}>
        {stations?.length > 0 ? (
          <Timeline
            style={{ maxHeight: "65vh" }}
            className={"max-w-96 overflow-y-auto"}
            active={stations.length * 2 + 1}
            bulletSize={25}
            lineWidth={2}
          >
            {stations.map((station, index) => {
              return [
                <Timeline.Item
                  key={index}
                  className={"px-10"}
                  lineVariant={"dotted"}
                  bullet={<IconBusStop size={10} />}
                  title={station?.busStop?.name ?? "{Station Name}"}
                >
                  <Group position={"apart"} className={"px-5"}>
                    <Stack spacing={2}>
                      {(station.isDestination || station.isPickup) && (
                        <Text color="dimmed" size="sm">
                          {station?.isPickup
                            ? "Initial Station"
                            : station?.isDestination
                            ? "Final Station"
                            : "Station " + station?.position}
                        </Text>
                      )}

                      {!station.isDestination &&
                        !station.isPickup &&
                        station?.position > 0 && (
                          <Text c={"dimmed"} size={"xs"}>
                            Station: {station?.position}
                          </Text>
                        )}

                      {!station.isDestination &&
                        station?.distanceToNextBusStop > 0 && (
                          <Text c={"dimmed"} size={"xs"}>
                            Next Station:{" "}
                            {(station?.distanceToNextBusStop ?? 0) + " KM"}
                          </Text>
                        )}
                    </Stack>

                    {
                      //  Only applies for stations in between
                      !station?.busStopId ? (
                        <Group position={"center"}>
                          <Button
                            variant="filled"
                            className="bg-primary text-white"
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                            loading={station.position === stationPosition}
                            onClick={() => {
                              setStationPosition(station.position);
                            }}
                          >
                            <span>
                              <IconRoute size={22} strokeWidth={1.5} />
                            </span>
                          </Button>
                        </Group>
                      ) : (
                        <Confirmation
                          header={"Remove Confirmation"}
                          type={"danger"}
                          message={
                            "Are you sure you want to remove the station?"
                          }
                          yesText={"Remove"}
                          onYes={() => {
                            removeStation(station.position);
                          }}
                        >
                          <Button
                            variant="filled"
                            className="bg-danger text-white"
                            type="button"
                            size={"xs"}
                          >
                            <span>
                              <IconRouteOff size={22} />
                            </span>
                          </Button>
                        </Confirmation>
                      )
                    }
                  </Group>
                </Timeline.Item>,
                !station.isDestination && (
                  <Timeline.Item
                    key={random(200, 1000)}
                    className={"cursor-pointer"}
                    lineVariant={"dotted"}
                    bullet={<IconPlus size={10} />}
                    onClick={() => {
                      addStation(station?.position);
                    }}
                  >
                    <Button
                      variant="filled"
                      className="bg-primary text-white"
                      classNames={{ label: "flex space-x-1" }}
                      size={"xs"}
                    >
                      Add Station
                    </Button>
                  </Timeline.Item>
                ),
              ];
            })}
          </Timeline>
        ) : (
          <div className="flex w-full justify-center h-40 items-center">
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Info"
              color="red"
            >
              There are no stations for this route, please add a route station!
            </Alert>
          </div>
        )}

        <Stack
          justify="space-between"
          className={"max-w-96 bg-sky-100 "}
          style={{ height: "50vh" }}
        >
          <Group position={"center"} spacing={"sm"} px={"xl"} className="p-2 bg-primary text-white">
            <Text fw={500} fz={"sm"}>
              Number of stations
            </Text>
            <Group spacing={5}>
              <Confirmation
                header={"Remove Confirmation"}
                type={"danger"}
                message={"Are you sure you want to remove the station?"}
                yesText={"Remove"}
                onYes={() => {
                  removeStation(stations.length - 1);
                }}
              >
                <ActionIcon
                  disabled={stationCount === 0}
                  variant="default"
                  className="bg-danger text-white border-0"
                >
                  <IconMinus />
                </ActionIcon>
              </Confirmation>

              <NumberInput
                value={stationCount}
                min={2}
                disabled
                hideControls
                size="xs"
                styles={{ input: { width: 54, textAlign: "center" } }}
              />

              <ActionIcon
                variant="default"
                onClick={() => {
                  addStation(stations.length - 1);
                }}
                className="bg-green-500 text-white border-0"
              >
                <IconPlus />
              </ActionIcon>
            </Group>
          </Group>

          <Stack className={"mt-2"} px={"xl"} classNames="p-2">
            <Select
            ref={inputRef}
              searchable
              value={selectedBusStopId??''}
              classNames={{input:'border-0',label:'text-xs font-medium'}}
              className="w-full"
              id="assign_station"
              label="Assign Station"
              data={filteredBusStopList()}
              maxDropdownHeight={400}
              onChange={(value) => {
                setSelectedBusStopId(value ?? "");
              }}
            />
            <div className="flex space-x-2 mb-2 justify-center items-center w-full">
              {!switchMemo?.pick && (
                <Switch
                  onLabel="Pickup"
                  size="lg"
                  offLabel="Not Pickup"
                  checked={isPickUp && !isDestination}
                  onChange={(event) => {
                    setIsPickUp(event.currentTarget.checked);
                    if (event?.currentTarget?.checked) {
                      setIsDestination(false);
                    }
                  }}
                />
              )}
              {!switchMemo?.destination && (
                <Switch
                  onLabel="Destination"
                  size="lg"
                  offLabel="Not Destination"
                  checked={isDestination && !isPickUp}
                  onChange={(event) => {
                    setIsDestination(event.currentTarget.checked);
                    if (event.currentTarget.checked) {
                      setIsPickUp(false);
                    }
                  }}
                />
              )}
            </div>
            <Group position={"right"}>
              <Button
                variant="filled"
                className="bg-primary text-white"
                type="button"
                disabled={
                  selectedBusStopId === "" ||
                  filteredBusStopList()[0]?.value === "empty"
                }
                size={"xs"}
                onClick={() => {
                  assignBusStopToStation();
                }}
              >
                <span>Assign Station</span>
              </Button>
            </Group>
          </Stack>
          <Group position={"right"} className="mt-4 bg-sky-100 p-2">
            <Button
              variant="filled"
              className="bg-primary text-white"
              type="button"
              size={"xs"}
              onClick={() => {
                setPreview(true);
              }}
              leftIcon={<IconMap2 />}
            >
              Preview
            </Button>
            <Button
              variant="filled"
              className="bg-primary text-white"
              type="button"
              size={"xs"}
              loading={addedStations.isLoading}
              onClick={() => {
                submitData();
              }}
              leftIcon={<IconDeviceFloppy />}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </Group>
    </div>
  );
}

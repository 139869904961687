import { yupResolver } from "@hookform/resolvers/yup";
import {
  Accordion,
  Button,
  Divider,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Radio,
  Select,
  Tabs,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconCurrentLocation,
  IconDeviceFloppy,
  IconMap,
  IconSlash,
  IconTrash,
} from "@tabler/icons";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { phoneRegExAll } from "../../../shared/utility/Tools/RegEx";
import countryCodes from "../../../shared/utility/countryCodes.json";
import {
  useActivateParentMutation,
  useArchiveParentMutation,
  useCreateParentMutation,
  useCreateParentPreferenceMutation,
  useDeleteParentMutation,
  useLazyGetArchivedParentQuery,
  useLazyGetParentQuery,
  useRestoreParentMutation,
  useUpdateParentMutation,
} from "../store/parent.query";

import { useId } from "@mantine/hooks";
import { IconBrandTelegram } from "@tabler/icons-react";
import InputMask from "react-input-mask";
import { City } from "../../../models/city.model";
import {
  Notification,
  NotificationMethod,
} from "../../../models/notification.model";
import {
  Parent,
  ParentStatus,
  RidePreference,
} from "../../../models/parent.model";
import AuthContext from "../../../shared/auth/context/authContext";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import { Location } from "../../../shared/component/Map/map-component";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { notification } from "../../../shared/component/notification/utility/notification";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetCitiesQuery } from "../../city/store/city.query";
import { useCreateNotificationMutation } from "../../notification/store/notification.query";
import KidComponent from "./kids/kid-component";

const MapComponent = lazy(
  () => import("../../../shared/component/Map/map-component")
);

export interface Props {
  editMode: string;
}

export default function NewParentComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();

  const defaultValue: Parent = {
    id: "",
    gender: "",
    //Main
    name: "",
    email: null,
    phoneNumber: "",
    distanceFromSchool: 0,
    parentType: RidePreference.Monthly,
    // parentPreferences: [],
    address: {
      country: "Ethiopia",
      city: "",
      subCity: "",
      woreda: "",
      houseNumber: "",
    },
    lng: 38.7663447,
    lat: 8.993441,
    archiveReason: "",
    status: ParentStatus.New,
  };

  const defaultNotificationValue: Notification = {
    title: "",
    body: "",
    status: undefined,
    method: NotificationMethod.Both,
  };

  const nameRegEx =
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s'\-]*)$/gi;
  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 5 characters")
      .max(100, "Name should not be more than 100 characters")
      .matches(nameRegEx, "Remove invalid characters")
      .required("Name is required"),
    email: Yup.string().email("Invalid email").nullable().notRequired(),
    gender: Yup.string().typeError("Gender should be a string.").required(),
    phoneNumber: Yup.string()
      .matches(phoneRegExAll, "Phone number is not valid")
      .required("Phone number is required"),

    lat: Yup.number().typeError("Latitude should be a number").required(),
    lng: Yup.number().typeError("Longitude should be a number").required(),

    parentType: Yup.string()
      .typeError("Ride Preference should be a string")
      .required(),
    address: Yup.object()
      .shape({
        country: Yup.string()
          .typeError("Country should be a string")
          .required("Country is required"),
        city: Yup.string()
          .typeError("City should be a string")
          .required("City is required"),
        subCity: Yup.string().typeError("Sub City should be a string"),

        woreda: Yup.string()
          .typeError("Woreda should be a string")
          .required("Woreda is required"),
        houseNumber: Yup.string()
          .typeError("House Number should be a string")
          .required("House Number is required"),
      })
      .required(),
  });

  const Auth = useContext(AuthContext);
  const [getParent, parent] = useLazyGetParentQuery();
  const [getArchivedParent, archivedParent] = useLazyGetArchivedParentQuery();
  const [createParent, newParent] = useCreateParentMutation();
  const [updateParent, updateResponse] = useUpdateParentMutation();

  const [archiveParent, archiveResponse] = useArchiveParentMutation();
  const [activateParent, activateResponse] = useActivateParentMutation();
  const [restoreParent, restoreCall] = useRestoreParentMutation();
  const [deleteParent, deleteCall] = useDeleteParentMutation();

  const [createNotification, notificationResponse] =
    useCreateNotificationMutation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<Parent>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [selectedDatePreferences, setSelectedDatePreferences] =
    useState<string[]>();

  let notificationSchema = Yup.object()
    .shape({
      body: Yup.string().typeError("Body should be string").required(),
      title: Yup.string().typeError("Title should be string").required(),
      method: Yup.string().typeError("Method  should be string").required(),
      // status: yup.string().nullable().notRequired().oneOf([
      //   ParentStatus.New,
      //   ParentStatus.Assigned,
      //   ParentStatus.PartiallyAssigned,
      //   ParentStatus.WaitingToBeAssigned,
      //   ParentStatus.Declined,
      //   ParentStatus.Agreed,
      //   ParentStatus.ContractSigned,
      // ])
    })
    .required();
  const {
    register: notificationRegister,
    setValue: notificationSetValue,
    control: notificationControl,
    handleSubmit: notificationHandleSubmit,
    reset: notificationReset,
    formState: { errors: notificationErrors },
  } = useForm({
    resolver: yupResolver(notificationSchema),
    mode: "onBlur",
  });

  const [data] = useState<any>(countryCodes);
  const id = useId();
  const e_id = useId();
  const [code, setCode] = useState("+251");
  const [e_code, setE_Code] = useState("+251");
  const [country, setCountry] = useState("Ethiopia");

  const [, setParentId] = useState("");

  const locationDefault = { lat: 8.993441, lng: 38.7663447 };

  const [location, setLocation] = useState<Location>(locationDefault);

  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [openNotificationModal, setOpenNotificationModal] =
    useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [createParentPreference, createParentPreferenceResponse] =
    useCreateParentPreferenceMutation();
  const [getCities, cities] = useLazyGetCitiesQuery();
  function checkLocation() {
    setLocationLoading(true);
    setTimeout(async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          notification("success", "Location has been resolved successfully!");
          setLocationLoading(false);
        },
        (err) => notification("error", "Error resolving your location!")
      );
    }, 0);
  }

  function resetForm() {
    reset(defaultValue);
    setCountry("Ethiopia");
    setSelectedDatePreferences([]);
  }

  useEffect(() => {
    getCities({
      skip: 0,
      top: 100,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
    });
  }, []);

  useEffect(() => {
    if (editMode === "detail") {
      reset(defaultValue);

      if (params?.type === "archived") {
        getArchivedParent(`${params?.id}`).then((response: any) => {
          reset(response.data);
          if (response.data) {
            setLocation({ lat: response.data.lat, lng: response.data.lng });
            setParentId(response.data.id);
            setCountry(response?.data?.address?.country);
            
            if (
              response.data.parentPreferences &&
              Array.isArray(response.data.parentPreferences) &&
              response.data.parentPreferences.length > 0 &&
              response.data.parentPreferences[0]?.days
            ) {
              setSelectedDatePreferences(
                response.data.parentPreferences[0]?.days
              );
            }
          }
        });
      } else if (params?.type === "active") {
        getParent(`${params?.id}`).then((response: any) => {
          reset(response.data);
          if (response.data) {
            setLocation({ lat: response.data.lat, lng: response.data.lng });
            setParentId(response.data.id);
            setCountry(response?.data?.address?.country);
           
            if (
              response.data.parentPreferences &&
              Array.isArray(response.data.parentPreferences) &&
              response.data.parentPreferences.length > 0 &&
              response.data.parentPreferences[0]?.days
            ) {
              setSelectedDatePreferences(
                response.data.parentPreferences[0]?.days
              );
            }
          }
        });
      }
    } else {
      resetForm();
    }
  }, [params.id, editMode, updateParent]);

  function onSubmit(data: any) {
    if (data.email == "") data.email = null;
    data.phoneNumber = data.phoneNumber.replace(/\s+/g, "");

    if (editMode === "new") {
      createParent(data)
        .unwrap()
        .then((result) => {
          resetForm();
        });
    } else {
      const parent: Parent = { id: params.id, ...data };

      updateParent(parent)
        .unwrap()
        .then((response) => {});
    }
  }

  function setName(name: string) {}

  const onNotificationSubmit = async (data: any) => {
    createNotification({
      ...data,
      receiver: params?.id,
    })
      .unwrap()
      .then((response) => {
        notificationReset(defaultNotificationValue);
        setOpenNotificationModal(false);
        setShowStatus(false);
        notificationReset(defaultNotificationValue);
      });
  };

  const onError = (errors: any, e: any) => {};

  function setSearchLocation(position: google.maps.LatLngLiteral) {
    setLocation({ lat: position.lat, lng: position.lng });
  }

  const [activeTab, setActiveTab] = useState<string | null>("main");

  return (
    <div className="h-full relative w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? (
            "New Parent"
          ) : (
            <div className="h-full space-x-2 flex items-center">
              <div>{parent?.data?.name}</div>
              <div>
                <Button
                  classNames={{ label: "flex space-x-1 " }}
                  variant="default"
                  className="bg-primary text-white"
                  type="button"
                  size="xs"
                  onClick={() => {
                    setOpenNotificationModal(true);
                    notificationReset(defaultNotificationValue);
                  }}
                >
                  <IconBrandTelegram />
                  <span>Send Notification</span>
                </Button>
              </div>
            </div>
          )
        }
        item={parent}
        path={`/registration/parent/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
        className="relative overflow-x-scroll"
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (parent.isFetching ||
              updateResponse.isLoading ||
              newParent.isLoading ||
              archivedParent?.isLoading ||
              archivedParent?.isFetching)
          }
        />

        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
            {editMode === "detail" &&  <Tabs.Tab value="preferences">Preferences</Tabs.Tab>}
            <Tabs.Tab value="location">Location</Tabs.Tab>
            {editMode === "detail" && <Tabs.Tab value="kids">Kids</Tabs.Tab>}
            {/* {editMode === "detail" && (
              <Tabs.Tab value="profile">Profile</Tabs.Tab>
            )} */}
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex  justify-center h-full">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <Accordion
                  defaultValue={["info", "address", "other", "preference"]}
                  multiple
                  variant="separated"
                  classNames={{ control: "bg-secondary text-gray-700" }}
                >
                  <Accordion.Item value="info">
                    <Accordion.Control>Parent Information</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.name && `${errors?.name?.message}`}
                            withAsterisk
                            label="Name"
                            placeholder="Name"
                            {...register("name")}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.email && `${errors?.email?.message}`}
                            label="Email"
                            placeholder="Email"
                            {...register("email")}
                          />
                        </div>

                        <div className="flex w-full space-x-4 justify-between">
                          <div className="flex w-full">
                            <Select
                              radius={"xs"}
                              searchable
                              nothingFound={
                                <div className="fle w-full justify-center items-center">
                                  <EmptyIcon />
                                </div>
                              }
                              onChange={(code) => {
                                code && setCode(code);
                              }}
                              classNames={{
                                input:
                                  "border border-gray-400/70 border-r-0 rounded rounded-r-none",
                              }}
                              value={code}
                              label="Code"
                              data={data?.map((item: any) => ({
                                label: `${item.name} (${item.dial_code})`,
                                value: item.dial_code,
                                key: item.name,
                              }))}
                              maxDropdownHeight={400}
                            />
                            <Input.Wrapper
                              id={id}
                              className="w-full"
                              label="Phone number"
                              required
                              error={
                                errors.phoneNumber &&
                                `${errors?.phoneNumber?.message}`
                              }
                            >
                              <Input
                                classNames={{ input: "rounded-l-none" }}
                                component={InputMask}
                                mask={`${code} 999 999 999`}
                                id={id}
                                {...register("phoneNumber")}
                              />
                            </Input.Wrapper>
                          </div>
                          <div className="px-4 w-full flex items-center justify-start">
                            {/* <TextInput
                               classNames={{label: "dark:text-white"}}
                                className="w-full"
                                error={errors.website && `${errors?.website?.message}`}
                                withAsterisk
                                label="Website"
                                {...register("website")}
                            /> */}

                            <div className="px-4 w-full flex items-center justify-start">
                              <Radio.Group label="Gender" withAsterisk>
                                <Group>
                                  <Radio
                                    value="male"
                                    label="Male"
                                    {...register("gender")}
                                  />
                                  <Radio
                                    value="female"
                                    label="Female"
                                    {...register("gender")}
                                  />
                                </Group>

                                <span className="text-red-500 text-xs">
                                  {errors.gender && errors.gender?.message}
                                </span>
                              </Radio.Group>
                            </div>

                            {editMode === "detail" && (
                              <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    radius={"sm"}
                                    classNames={{ label: "dark:text-white" }}
                                    className="w-full"
                                    label="Status"
                                    placeholder="Status"
                                    {...field}
                                    data={[
                                      {
                                        label: "New",
                                        value: ParentStatus.New,
                                      },
                                      {
                                        label: "Agreed",
                                        value: ParentStatus.Agreed,
                                      },
                                      {
                                        label: "Declined",
                                        value: ParentStatus.Declined,
                                      },
                                      {
                                        label: "Contract Signed",
                                        value: ParentStatus.ContractSigned,
                                      },
                                      {
                                        label: "Waiting to be assigned",
                                        value: ParentStatus.WaitingToBeAssigned,
                                      },
                                      {
                                        label: "Assigned",
                                        value: ParentStatus.Assigned,
                                      },
                                      {
                                        label: "Partially Assigned",
                                        value: ParentStatus.PartiallyAssigned,
                                      },
                                    ]}
                                    withAsterisk
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex w-full space-x-4  justify-between"></div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value="address">
                    <Accordion.Control>Address</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <Controller
                            control={control}
                            name="address.country"
                            render={({ field }) => (
                              <Select
                                className="w-full"
                                withAsterisk
                                searchable
                                nothingFound={
                                  <div className="fle w-full justify-center items-center">
                                    <EmptyIcon />
                                  </div>
                                }
                                error={
                                  errors.address?.country &&
                                  `${errors?.address?.country?.message}`
                                }
                                radius={"xs"}
                                classNames={{
                                  label: "dark:text-white",
                                }}
                                label="Country"
                                placeholder="Country"
                                data={data?.map((item: any) => ({
                                  label: `${item.name}`,
                                  value: item.name,
                                  key: item.name,
                                }))}
                                maxDropdownHeight={400}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            control={control}
                            name="address.city"
                            render={({ field }) => (
                              <Select
                                withAsterisk
                                className="w-full"
                                searchable
                                nothingFound={
                                  <div className="fle w-full justify-center items-center">
                                    <EmptyIcon />
                                  </div>
                                }
                                error={
                                  errors.address?.city &&
                                  `${errors?.address?.city?.message}`
                                }
                                radius={"xs"}
                                classNames={{
                                  label: "dark:text-white",
                                }}
                                defaultValue="Ethiopia"
                                label="City"
                                data={
                                  cities.data
                                    ? cities.data.data.map((item: City) => ({
                                        label: `${item.name}`,
                                        value: item.name ?? "",
                                      }))
                                    : []
                                }
                                maxDropdownHeight={400}
                                {...field}
                              />
                            )}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.subCity &&
                              `${errors?.address?.subCity?.message}`
                            }
                            label="Sub-City"
                            placeholder="Sub-city"
                            {...register("address.subCity")}
                          />
                        </div>
                        <div className="flex w-full space-x-4  justify-between">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.woreda &&
                              `${errors?.address?.woreda?.message}`
                            }
                            withAsterisk
                            label="Woreda/Kebele"
                            placeholder="Woreda/kebele"
                            {...register("address.woreda")}
                          />
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={
                              errors.address?.houseNumber &&
                              `${errors?.address?.houseNumber?.message}`
                            }
                            withAsterisk
                            label="House Number/ Specific Location"
                            placeholder="House Number/ Specific Location"
                            {...register("address.houseNumber")}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between mt-5">
                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.lng && `${errors?.lng?.message}`}
                            withAsterisk
                            label="Longitude"
                            value={location.lng}
                            onChange={(e) => {
                              try {
                                const val = parseFloat(
                                  e.currentTarget.value ?? 0
                                );
                                setValue("lng", isNaN(val) ? 0 : val);
                                setLocation({
                                  lng: isNaN(val) ? 0 : val,
                                  lat: location.lat ?? 0,
                                });
                              } catch (e) {}
                            }}
                          />

                          <TextInput
                            classNames={{ label: "dark:text-white" }}
                            className="w-full"
                            error={errors.lat && `${errors?.lat?.message}`}
                            withAsterisk
                            label="Latitude"
                            value={location.lat}
                            onChange={(e) => {
                              try {
                                const val = parseFloat(
                                  e.currentTarget.value ?? 0
                                );
                                setValue("lat", isNaN(val) ? 0 : val);
                                setLocation({
                                  lat: isNaN(val) ? 0 : val,
                                  lng: location.lng,
                                });
                              } catch (e) {}
                            }}
                          />
                        </div>

                        <div className="flex w-full space-x-4  justify-between">
                          <Button
                            variant={"default"}
                            onClick={() => {
                              setActiveTab("location");
                            }}
                            className="w-1/5 bg-primary text-white py-2 hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                            classNames={{ label: "dark:text-white" }}
                          >
                            <IconCurrentLocation />
                            <IconSlash />
                            <IconMap />
                          </Button>
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value="preference">
                    <Accordion.Control>Preference</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4 justify-between">
                          <div className="flex w-full">
                            <Controller
                              control={control}
                              name="parentType"
                              render={({ field }) => (
                                <Select
                                  withAsterisk
                                  className="w-full"
                                  searchable
                                  nothingFound={
                                    <div className="fle w-full justify-center items-center">
                                      <EmptyIcon />
                                    </div>
                                  }
                                  error={
                                    errors.parentType &&
                                    `${errors?.parentType?.message}`
                                  }
                                  radius={"xs"}
                                  classNames={{
                                    label: "dark:text-white",
                                  }}
                                  defaultValue="Ethiopia"
                                  label="Preference"
                                  data={[
                                    {
                                      label: "Both",
                                      value: RidePreference.Both,
                                    },
                                    {
                                      label: "Monthly",
                                      value: RidePreference.Monthly,
                                    },
                                    {
                                      label: "One Time",
                                      value: RidePreference.OneTime,
                                    },
                                  ]}
                                  maxDropdownHeight={400}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex w-full space-x-4  justify-between"></div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current h-6"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="bg-primary text-white"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={newParent.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}

                    {editMode === "detail" && (
                      <>
                        {(params?.type === "archived" &&
                          archivedParent?.data?.deletedAt) ||
                        (params?.type === "active" &&
                          parent?.data?.deletedAt) ? (
                          <>
                            <Confirmation
                              type={"danger"}
                              message={
                                "Are you sure you want to delete it permanently?"
                              }
                              onYes={() => {
                                deleteParent(`${params?.id}`);
                                navigate(-1);
                              }}
                              header={`Permanent Delete confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={deleteCall.isLoading}
                                className={`bg-danger text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconTrash size={20} strokeWidth={1.5} />
                                <span>Delete</span>{" "}
                              </Button>
                            </Confirmation>
                            <Confirmation
                              type={"notify"}
                              message={`Are you sure you want to restore it`}
                              onYes={() => restoreParent(`${params?.id}`)}
                              header={`Restore confirmation `}
                            >
                              <Button
                                variant="filled"
                                loading={restoreCall.isLoading}
                                className={`bg-green-500 text-white flex space-x-2 items-center`}
                                type="button"
                                classNames={{ label: "flex space-x-1" }}
                                size={"xs"}
                              >
                                <IconArrowBackUp size={20} strokeWidth={1.5} />
                                <span>Restore</span>
                              </Button>
                            </Confirmation>
                          </>
                        ) : (
                          <>
                            {Auth?.user?.id !== parent?.data?.id && (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this Parent?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this Parent?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveParent({
                                      id: `${params.id}`,
                                      reason: data,
                                    });
                                    navigate(-1);
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="bg-danger text-white"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>

                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to " +
                                    (parent.data?.enabled
                                      ? "deactivate"
                                      : "activate") +
                                    " it?"
                                  }
                                  onYes={() => activateParent(`${params.id}`)}
                                  header={
                                    (parent.data?.enabled
                                      ? "Deactivate"
                                      : "Activate") + " Confirmation"
                                  }
                                >
                                  <Button
                                    variant="filled"
                                    loading={activateResponse.isLoading}
                                    className={`${
                                      parent.data?.enabled
                                        ? " bg-danger"
                                        : "bg-green-500"
                                    } text-white`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="fill-current h-6"
                                        viewBox="0 0 48 48"
                                      >
                                        <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                      </svg>
                                    </span>
                                    {parent.data?.enabled ? (
                                      <span>Deactivate</span>
                                    ) : (
                                      <span>Activate</span>
                                    )}
                                  </Button>
                                </Confirmation>
                                <Button
                                  variant="filled"
                                  className="bg-primary text-white"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="location" pt="xs">
            <div className="flex p-5 justify-between h-full">
              <div>
                <Text>Latitude: {location.lat}</Text>
                <Text>Longitude: {location.lng}</Text>
              </div>
              <div className="flex-col space-y-4">
                <Button
                  classNames={{ label: "flex space-x-1 " }}
                  variant="default"
                  className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                  leftIcon={<IconCurrentLocation />}
                  loading={locationLoading}
                  onClick={checkLocation}
                >
                  <span>Load Current Location</span>
                </Button>
              </div>
            </div>
            <Divider className={"my-2"} />

            {/*StaticMap Component*/}
            <Suspense>
              <MapComponent
                setLocation={setLocation}
                location={location}
                placesProps={{
                  setLocation: setSearchLocation,
                  setName: setName,
                }}
              />
            </Suspense>

            <Divider className={"my-2"} />
            <div className="w-full flex justify-end items-center">
              <Button
                variant="filled"
                className="bg-primary text-white"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={locationLoading}
                onClick={() => {
                  setValue("lat", location.lat);
                  setValue("lng", location.lng);
                  setActiveTab("main");
                }}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </div>
          </Tabs.Panel>

                {
                  editMode == "detail" && (

          <Tabs.Panel value="preferences" pt="xs">
            <div className="flex  justify-center h-full mt-16">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <Accordion
                  defaultValue={["info", "address", "other", "preference"]}
                  multiple
                  variant="separated"
                  classNames={{ control: "bg-secondary text-gray-700" }}
                >
                  <Accordion.Item value="address">
                    <Accordion.Control>Date Preference</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex-col space-y-4">
                        <div className="flex w-full space-x-4  justify-between">
                          <MultiSelect
                            withAsterisk
                            className="w-full"
                            searchable
                            // nothingFound={
                            //   <div className="fle w-full justify-center items-center">
                            //     <EmptyIcon />
                            //   </div>
                            // }
                            radius={"xs"}
                            value={selectedDatePreferences}
                            required
                            onChange={setSelectedDatePreferences}
                            classNames={{
                              label: "dark:text-white",
                            }}
                            // defaultValue="Ethiopia"
                            label="Date Preference"
                            data={[
                              {
                                label: "Monday",
                                value: "mon",
                              },
                              {
                                label: "Tuesday",
                                value: "tue",
                              },
                              {
                                label: "Wednesday",
                                value: "wed",
                              },
                              {
                                label: "Thursday",
                                value: "thu",
                              },
                              {
                                label: "Friday",
                                value: "fri",
                              },
                              {
                                label: "Saturday",
                                value: "sat",
                              },
                              {
                                label: "Sunday",
                                value: "sun",
                              },
                            ]}
                            maxDropdownHeight={250}
                          />
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>

                <div className="w-full flex justify-end items-center">
                  <Group position="right" mt="xl">
                   
                       <Button
                        variant="filled"
                        className="bg-primary text-white"
                        type="button"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        onClick={() => {
                          createParentPreference({
                            days: selectedDatePreferences,
                            driverGender: "male",
                            parentId: params?.id,
                          });
                        }}
                        loading={createParentPreferenceResponse.isLoading}
                      >
                        <span>
                          <IconDeviceFloppy size={22} strokeWidth={1.5} />
                        </span>
                        <span>Submit</span>
                      </Button>
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>
                  )
                }

          {editMode === "detail" &&
            ((params?.type === "archived" &&
              archivedParent?.data?.deletedAt !== null) ||
              (params?.type === "active" &&
                parent?.data?.deletedAt === null)) && (
              <Tabs.Panel value="kids" pt="xs">
                <div className="flex-col space-y-4 overflow-x-auto">
                  <KidComponent />
                </div>
              </Tabs.Panel>
            )}
        </Tabs>
      </ActivityLogWrapperComponent>

      <Modal
        opened={openNotificationModal}
        onClose={() => {
          setOpenNotificationModal(false);
          setShowStatus(false);
          reset(defaultValue);
        }}
        className="my-modal-content"
        withCloseButton={false}
        title="Notification"
        padding={20}
        size={"lg"}
      >
        <form
          onSubmit={notificationHandleSubmit(onNotificationSubmit, onError)}
          className={"mt-2"}
        >
          <div className="w-full">
            <div className="flex w-full space-x-4  justify-center">
              <Controller
                render={({ field }) => (
                  <Select
                    radius={"xs"}
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                    error={
                      notificationErrors?.method &&
                      `${notificationErrors?.method?.message}`
                    }
                    label="Notification Method"
                    placeholder="Notification Method..."
                    // onSelect={(e) =>
                    //   e.currentTarget.value.toLowerCase() ==
                    //   CredentialType.Parent
                    //     ? setShowStatus(true)
                    //     : setShowStatus(false)
                    // }
                    data={[
                      { label: "Both", value: NotificationMethod.Both },
                      {
                        label: "Notification",
                        value: NotificationMethod.Notification,
                      },
                      { label: "Sms", value: NotificationMethod.Sms },
                    ]}
                    withAsterisk
                    {...field}
                  />
                )}
                name="method"
                control={notificationControl}
              />
              <TextInput
                classNames={{ label: "dark:text-white" }}
                className="w-full "
                error={
                  notificationErrors.title &&
                  `${notificationErrors?.title?.message}`
                }
                withAsterisk
                label="Title"
                placeholder="Title"
                {...notificationRegister("title")}
              />
            </div>

            <div className="flex w-full space-x-4  justify-center">
              <Textarea
                placeholder="Body..."
                label="Body"
                className="w-full"
                withAsterisk
                error={
                  notificationErrors?.body &&
                  `${notificationErrors?.body?.message}`
                }
                {...notificationRegister("body")}
              />
            </div>
          </div>
          <Group position="right" mt="xl">
            <>
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                type="reset"
                onClick={() => notificationReset(defaultNotificationValue)}
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current h-6"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>
              <Button
                variant="filled"
                className="bg-primary text-white"
                type="submit"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={notificationResponse.isLoading}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </>
          </Group>
        </form>
      </Modal>
    </div>
  );
}

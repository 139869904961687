/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { RootState } from "../../../../store/app.store";
import AuthContext from "../../context/authContext";
interface Account {
  phoneNumber: string;
  password: string;
  type: string;
}
function Login() {
  const { login } = useContext(AuthContext);
  const [userType,setUserType] = useState<'employee'|'corporate'>('employee');
 const  loading = useSelector((state:RootState)=>state.authReducer.loading)
  const schema = yup
    .object({
      phoneNumber: yup.string().required("Phone number is required").min(13,'Phone number length must be 13').max(13,'Phone number length must be 13'),
      password: yup.string().min(8,'Password length must be greater than 8').max(25,'Password length must be 25 and less').required("password"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm<Account>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  function onSubmit(data: Account) {
    const loginData={
      ...data,type:userType
    }
    login(loginData);
  }
  
  const onError = (error:any)=>{
    
  }

 

  return (
    <div className="relative flex min-h-screen justify-center bg-sky-200 p-2 lg:p-8 ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-2 left-2"
        viewBox="0 0 410.13 334.4"
        width="406"
        height="306"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="249.51"
            y1="115.72"
            x2="144.97"
            y2="400.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.01" />
            <stop offset="0.83" stopOpacity="0.07" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="1860.43"
            y1="236.15"
            x2="1879.7"
            y2="236.15"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-3"
            x1="1874.68"
            y1="236.52"
            x2="1881.69"
            y2="236.52"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="0.43" stopColor="#fdfdfd" />
            <stop offset="0.68" stopColor="#f5f5f5" />
            <stop offset="0.87" stopColor="#e7e7e8" />
            <stop offset="0.97" stopColor="#dddedf" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="1737.39"
            y1="250.65"
            x2="1824.91"
            y2="266.45"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-5"
            x1="1783.81"
            y1="258.18"
            x2="1796.02"
            y2="258.18"
          />
          <linearGradient
            id="linear-gradient-6"
            x1="1569.64"
            y1="250.65"
            x2="1657.16"
            y2="266.45"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="1616.06"
            y1="258.18"
            x2="1628.27"
            y2="258.18"
          />
          <linearGradient
            id="linear-gradient-8"
            x1="1840.6"
            y1="239.19"
            x2="1864.85"
            y2="239.19"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-9"
            x1="1859.37"
            y1="239.59"
            x2="1867.03"
            y2="239.59"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="1803.63"
            y1="117.31"
            x2="1486.76"
            y2="166.74"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-11"
            x1="1677.04"
            y1="50.04"
            x2="1661.84"
            y2="186.79"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="1667.7"
            y1="113.09"
            x2="1680.72"
            y2="58.17"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="1788.84"
            y1="87.18"
            x2="1677"
            y2="104.81"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2b2b2b" />
            <stop offset="0.22" stopColor="#2e2e2e" />
            <stop offset="0.41" stopColor="#383939" />
            <stop offset="0.6" stopColor="#494a4b" />
            <stop offset="0.77" stopColor="#616264" />
            <stop offset="0.94" stopColor="#7f8184" />
            <stop offset="1" stopColor="#8d8f92" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-14"
            x1="1830.6"
            y1="295.04"
            x2="1808.11"
            y2="135.19"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="1834.88"
            y1="79.32"
            x2="1808.14"
            y2="227.62"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-16"
            x1="1772.69"
            y1="123.48"
            x2="1775.79"
            y2="101.45"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" stopOpacity="0" />
            <stop offset="0.12" stopColor="#fff" stopOpacity="0.04" />
            <stop offset="0.33" stopColor="#fff" stopOpacity="0.14" />
            <stop offset="0.59" stopColor="#fff" stopOpacity="0.3" />
            <stop offset="0.89" stopColor="#fff" stopOpacity="0.52" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.6" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-17"
            x1="1653.5"
            y1="317.31"
            x2="1676.6"
            y2="187.24"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="188.42"
            y1="188.25"
            x2="358.99"
            y2="188.25"
          />
          <linearGradient
            id="linear-gradient-19"
            x1="279.15"
            y1="151.79"
            x2="321.95"
            y2="151.79"
          />
          <linearGradient
            id="linear-gradient-20"
            x1="201.77"
            y1="134.03"
            x2="281.59"
            y2="198.05"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="239.24"
            y1="163.48"
            x2="253.83"
            y2="152.81"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="226.2"
            y1="181.88"
            x2="231.87"
            y2="147.31"
          />
          <linearGradient
            id="linear-gradient-23"
            x1="213.02"
            y1="179.12"
            x2="230.52"
            y2="153.72"
          />
          <linearGradient
            id="linear-gradient-24"
            x1="225.04"
            y1="188.14"
            x2="214.23"
            y2="148.7"
          />
          <linearGradient
            id="linear-gradient-25"
            x1="227.04"
            y1="133.74"
            x2="236.95"
            y2="133.74"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#e5c5d5" />
            <stop offset="0.42" stopColor="#ead4d1" />
            <stop offset="1" stopColor="#f0e9cb" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-26"
            x1="235.5"
            y1="122.76"
            x2="232.14"
            y2="141.24"
          />
          <linearGradient
            id="linear-gradient-27"
            x1="226.04"
            y1="123.52"
            x2="243.72"
            y2="123.52"
          />
          <clipPath id="clip-path" transform="translate(-1.98 -2.49)">
            <path
              d="M170.73,186.7c-4.75-25.38-10.45-45-37-40.1-17.85,3.32-62.94,20.29-83.55,28.38,0,0,0-5.68.11-7.3.56-11.34.14-17.13,3.6-18.79S117,107.75,143.41,99.08s27.51-5,31.62,9.65c3,10.6,5.72,45.71,7.28,78l-11.54.2A1.85,1.85,0,0,0,170.73,186.7Z"
              fill="none"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-28"
            x1="352.81"
            y1="229.67"
            x2="359.75"
            y2="229.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.02" stopColor="#fff" stopOpacity="0" />
            <stop offset="0.39" stopColor="#fff" stopOpacity="0.39" />
            <stop offset="1" stopColor="#fff" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-29"
            x1="188.78"
            y1="235.81"
            x2="202.84"
            y2="235.81"
          />
          <linearGradient
            id="linear-gradient-30"
            x1="197.59"
            y1="241.77"
            x2="207.85"
            y2="241.77"
          />
          <linearGradient
            id="linear-gradient-31"
            x1="211.75"
            y1="243.72"
            x2="219.5"
            y2="243.72"
          />
          <linearGradient
            id="linear-gradient-32"
            x1="351.91"
            y1="239.5"
            x2="359.89"
            y2="239.5"
          />
          <linearGradient
            id="linear-gradient-33"
            x1="345.86"
            y1="242.73"
            x2="351.89"
            y2="242.73"
          />
          <linearGradient
            id="linear-gradient-34"
            x1="-1194.32"
            y1="290.32"
            x2="-1194.32"
            y2="293.14"
            gradientTransform="matrix(1.02, 0.05, -0.01, 0.98, 1381.74, -20.88)"
          />
          <linearGradient
            id="linear-gradient-35"
            x1="-1242.55"
            y1="332.27"
            x2="-1242.55"
            y2="334.36"
            gradientTransform="matrix(1.02, 0.05, -0.01, 0.98, 1381.74, -20.88)"
          />
          <linearGradient
            id="linear-gradient-36"
            x1="92.08"
            y1="199.3"
            x2="95.59"
            y2="280.88"
          />
          <linearGradient
            id="linear-gradient-37"
            x1="288.06"
            y1="310.68"
            x2="282.79"
            y2="201.68"
          />
          <linearGradient
            id="linear-gradient-38"
            x1="1671.64"
            y1="187.06"
            x2="1632.74"
            y2="283.09"
            gradientTransform="matrix(-1, 0, 0, 1, 1936.33, 0)"
          />
          <linearGradient
            id="linear-gradient-39"
            x1="258.89"
            y1="156.52"
            x2="268.44"
            y2="156.52"
          />
          <linearGradient
            id="linear-gradient-40"
            x1="228.14"
            y1="162.41"
            x2="240.48"
            y2="162.41"
          />
          <linearGradient
            id="linear-gradient-41"
            x1="225.39"
            y1="155.98"
            x2="214.45"
            y2="148.62"
          />
        </defs>
        <title>Bus Driver</title>
        <g style={{ isolation: "isolate" }}>
          <g id="Layer_1" data-name="Layer 1">
            <path
              d="M391.13,312.68c-19.74,19-50.41,23.39-78.75,24-27.15.85-55.26-1-81.68-7.61-23.81-5.92-49-3.41-73.85-3.13-36.24.42-75,.16-105.14-19.9C22.24,286.48,6.5,250.85,3.11,215.63c-.21-2.2-.4-4.39-.54-6.59-3.3-47.29,7.15-93.33,36.56-130.56,26.7-33.8,63-60.94,105.41-70.83,22.84-5.33,46.69-5.64,70.23-4.8,25.48.89,51.39,3.54,75.66,11.18C324,24.6,358.74,48.3,368.23,81.68c10.58,37.3,17.91,74.75,30.07,112,1.09,3.36,2.19,6.72,3.26,10.1,5.31,16.73,9.95,33.69,10.5,51.08C412.72,275.75,406.73,297.68,391.13,312.68Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
              opacity="0.18"
              style={{ isolation: "isolate" }}
            />
            <path
              d="M391.13,312.68c-19.74,19-50.41,23.39-78.75,24-27.15.85-55.26-1-81.68-7.61-23.81-5.92-49-3.41-73.85-3.13-36.24.42-75,.16-105.14-19.9C22.24,286.48,6.5,250.85,3.11,215.63c-.21-2.2-.4-4.39-.54-6.59l399-5.27c5.31,16.73,9.95,33.69,10.5,51.08C412.72,275.75,406.73,297.68,391.13,312.68Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
              opacity="0.48"
            />
            <path
              d="M17.43,241.78c.91,3.95,4.72,6.43,8.28,8.36,9.95,5.4,20.42,9.78,30.86,14.16l72.16,30.23c11,4.62,22.17,9.28,33.93,11.5,11.91,2.25,24.14,2,36.26,1.64,52.26-1.35,104.61-2.69,156.54-8.71,6.83-.79,14.77-2.44,17.66-8.66,2.6-5.57-.45-12.46-5.33-16.2s-11.11-5.08-17.13-6.32L312.93,260c-54-11.13-108-22.27-162.57-29.93C137.24,228.23,9.64,207.84,17.43,241.78Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient)"
            />
            <path
              d="M60.64,194.09c35,6.13,72.76-23.48,102.78,30.17,4.28,8.55,5.51,25.93,5.82,43.47-35.87-10.25-114.88-35.94-116.31-37.17-2.52-2.15-2.52-10.59-2.42-18.13C50.55,209.57,55,202.49,60.64,194.09Z"
              transform="translate(-1.98 -2.49)"
              fill="#1a1a1a"
            />
            <path
              d="M62.1,214.32c3.26.09,13.54,9.49,13.79,21.35.22,10.72-2.74,20-5.54,21.47l-10.7.84,1.26-2.13c-2.3-3.1-4.07-11-4.26-20.22C56.4,223.78,58.84,214.24,62.1,214.32Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M62.1,214.32c3.26.09,13.54,9.49,13.79,21.35.22,10.72-2.74,20-5.54,21.47l-10.7.84,1.26-2.13c-2.3-3.1-4.07-11-4.26-20.22C56.4,223.78,58.84,214.24,62.1,214.32Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-2)"
            />
            <line
              x1="68.37"
              y1="254.65"
              x2="57.67"
              y2="255.49"
              fill="#3f3d56"
            />
            <path
              d="M53.05,236.37c.25,11.85,3.1,21.53,6.36,21.61s5.7-9.45,5.45-21.3-3.09-21.54-6.36-21.62S52.8,224.51,53.05,236.37Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M54.64,236.43c.15,7,1.84,12.75,3.76,12.8s3.38-5.6,3.24-12.62-1.84-12.75-3.77-12.8S54.5,229.41,54.64,236.43Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-3)"
            />
            <path
              d="M56,236.48c.07,3.38.88,6.15,1.81,6.17s1.63-2.7,1.56-6.08-.88-6.15-1.81-6.18S56,233.09,56,236.48Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M153.31,219.52c5.68.15,30,17.38,30.45,38,.39,18.67-3,34-7.91,36.7L149,295.55l2.2-3.72c-4-5.4-7.09-19.16-7.43-35.2C143.38,236,147.63,219.37,153.31,219.52Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M153.31,219.52c5.68.15,30,17.38,30.45,38,.39,18.67-3,34-7.91,36.7L149,295.55l2.2-3.72c-4-5.4-7.09-19.16-7.43-35.2C143.38,236,147.63,219.37,153.31,219.52Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-4)"
            />
            <path
              d="M137.55,257.91c.43,20.64,5.39,37.49,11.07,37.64s9.93-16.46,9.49-37.1-5.39-37.5-11.06-37.65S137.12,237.27,137.55,257.91Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M140.32,258c.25,12.22,3.19,22.21,6.55,22.29s5.88-9.75,5.63-22-3.19-22.21-6.56-22.3S140.06,245.79,140.32,258Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-5)"
            />
            <path
              d="M142.74,258.1c.12,5.89,1.54,10.71,3.16,10.75s2.83-4.7,2.71-10.6-1.54-10.71-3.16-10.75S142.61,252.2,142.74,258.1Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M321.06,219.52c5.68.15,30,17.38,30.45,38,.39,18.67-3.05,34-7.91,36.7l-26.81,1.31,2.2-3.72c-4-5.4-7.09-19.16-7.43-35.2C311.13,236,315.38,219.37,321.06,219.52Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M321.06,219.52c5.68.15,30,17.38,30.45,38,.39,18.67-3.05,34-7.91,36.7l-26.81,1.31,2.2-3.72c-4-5.4-7.09-19.16-7.43-35.2C311.13,236,315.38,219.37,321.06,219.52Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-6)"
            />
            <path
              d="M305.3,257.91c.44,20.64,5.39,37.49,11.07,37.64s9.93-16.46,9.49-37.1S320.47,221,314.8,220.8,304.87,237.27,305.3,257.91Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M308.07,258c.26,12.22,3.19,22.21,6.55,22.29s5.89-9.75,5.63-22-3.19-22.21-6.56-22.3S307.81,245.79,308.07,258Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-7)"
            />
            <path
              d="M310.49,258.1c.12,5.89,1.54,10.71,3.16,10.75s2.83-4.7,2.71-10.6-1.54-10.71-3.16-10.75S310.36,252.2,310.49,258.1Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M77.45,215.37c3.56.09,18,10.66,18.26,23.59.24,11.7-1.92,21.34-5,23l-16,1.05,1.38-2.33c-2.52-3.38-4.44-12-4.65-22.06C71.23,225.69,73.89,215.28,77.45,215.37Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M77.45,215.37c3.56.09,18,10.66,18.26,23.59.24,11.7-1.92,21.34-5,23l-16,1.05,1.38-2.33c-2.52-3.38-4.44-12-4.65-22.06C71.23,225.69,73.89,215.28,77.45,215.37Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-8)"
            />
            <path
              d="M67.57,239.42c.28,12.94,3.38,23.5,6.94,23.59s6.22-10.31,5.95-23.25-3.38-23.49-6.93-23.59S67.3,226.49,67.57,239.42Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M69.31,239.49c.16,7.66,2,13.92,4.11,14s3.68-6.11,3.52-13.78-2-13.91-4.11-14S69.15,231.83,69.31,239.49Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-9)"
            />
            <path
              d="M70.83,239.55c.07,3.69,1,6.71,2,6.73s1.78-2.95,1.71-6.64-1-6.71-2-6.74S70.75,235.85,70.83,239.55Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M163.42,224.33c-30-53.65-67.77-24-102.78-30.17,9.21-13.83,21.42-31.24,21.7-39.28.45-12.94-.65-30.42,2.77-32.57S139.34,82,165.49,69.56s171.94-8.42,176.37,5.6,15,86.05,17.7,130.39c1.95,31.66,1.71,34.45,1.4,60.09,0,0-149.09,6.34-174.77,6.34-1.66,0-7.95-1.6-16.95-4.18C168.93,250.26,167.7,232.88,163.42,224.33Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M182.23,74.46c5.26-4.19,150.49-3.23,154.87-2.09a9.65,9.65,0,0,1,4.76,2.79,39.78,39.78,0,0,1,2.95,7.22,190.7,190.7,0,0,1,5.24,23c2.54,15.86,5.87,40.66,8.19,75.09.26,4.13.51,8.42.74,12.84.08,3.94-.08,11.55-2.38,12.68-3.11,1.52-152.65,2.85-159.76-2.29s-7.34-9.9-8.61-28.17-5-67.19-7-77.47S177,78.65,182.23,74.46Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-10)"
            />
            <path
              d="M182.23,74.46c5.26-4.19,150.49-3.23,154.87-2.09a9.65,9.65,0,0,1,4.76,2.79,39.78,39.78,0,0,1,2.95,7.22,190.7,190.7,0,0,1,5.24,23c2.54,15.86,5.87,40.66,8.19,75.09.26,4.13.51,8.42.74,12.84.08,3.94-.08,11.55-2.38,12.68a42.5,42.5,0,0,1-6.42.5c-2-32.51-.73-41.57-7.59-83.44-5.33-24.54-13.88-22.4-26.07-22.4,2.52,37.7,6.87,68.83,8.75,106.53-45.62.4-123.82-.15-128.43-3.48-7.12-5.14-7.34-9.9-8.61-28.17s-5-67.19-7-77.47S177,78.65,182.23,74.46Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-11)"
            />
            <path
              d="M182.23,74.46c5.26-4.19,150.49-3.23,154.87-2.09a9.65,9.65,0,0,1,4.76,2.79,39.78,39.78,0,0,1,2.95,7.22c.87,2.74,1.84,6.41,2.87,11L180.53,94.72C178.79,85.84,177.54,78.19,182.23,74.46Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-12)"
            />
            <path
              d="M342.55,241.43s9.17-2.23,13.36-17.14c4.64-8.43,6.36,5.69,6,16.46-.27,7.89-14.26,8.23-18.48,7.39S337.7,243.73,342.55,241.43Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M340.13,243.24l.13-.16a5.67,5.67,0,0,1,2.15-1.5A14.7,14.7,0,0,0,349.2,237a29.13,29.13,0,0,0,6.26-11.26c3.41-3.63,4.68,7,4.39,15.31-.24,6.65-12.83,6.94-16.63,6.23C340.4,246.71,338.93,244.92,340.13,243.24Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M352.81,231.91a31.91,31.91,0,0,0,2.65-6.21c2.52-2.69,3.87,2.45,4.29,8.67C358.51,232.62,355.33,232,352.81,231.91Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M316.62,66.39s-130,0-150,3.29c-11,2.69-108.89,60.68-108.89,60.68l4.85-8.12s72.57-53.81,98.27-64S311.44,41.23,316.62,66.39Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M316.62,66.39s-130,0-150,3.29c-11,2.69-108.89,60.68-108.89,60.68l4.85-8.12s72.57-53.81,98.27-64S311.44,41.23,316.62,66.39Z"
              transform="translate(-1.98 -2.49)"
              opacity="0.5"
              fill="url(#linear-gradient-13)"
              style={{ mixBlendMode: "multiply" }}
            />
            <path
              d="M47.05,223.32c.1-7.54,1.31-55.58,1.76-68.51s-.05-18.53,3.37-20.69S116.35,89.39,142.5,77s27.37-8.87,31.79,5.15,9.38,74.67,10.88,110.65c1.4,33.77,1,85.78,1,85.78l-27.22-8.49c.14-3.06.18-6.26.11-9.55-.48-22.77-5.94-41.36-12.21-41.53s-11,18.17-10.47,40.94c0,1,.06,2.07.1,3.09C118,257.24,97.64,250.79,81.2,245.5c.06-1.74.08-3.55,0-5.41-.29-14.15-3.69-25.7-7.58-25.8s-6.81,11.28-6.51,25.43c0,.41,0,.82,0,1.23l-1.84-.6c0-1.22,0-2.47,0-3.75-.27-12.59-3.29-22.87-6.75-23s-6,10-5.79,22.46a23.14,23.14,0,0,1-3.63-1.46C46.7,232.49,47,230.87,47.05,223.32Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M47.05,223.32c.1-7.54,1.31-55.58,1.76-68.51s-.05-18.53,3.37-20.69S116.35,89.39,142.5,77s27.37-8.87,31.79,5.15,9.38,74.67,10.88,110.65c1.4,33.77,1,85.78,1,85.78l-27.22-8.49c.14-3.06.18-6.26.11-9.55-.48-22.77-5.94-41.36-12.21-41.53s-11,18.17-10.47,40.94c0,1,.06,2.07.1,3.09C118,257.24,97.64,250.79,81.2,245.5c.06-1.74.08-3.55,0-5.41-.29-14.15-3.69-25.7-7.58-25.8s-6.81,11.28-6.51,25.43c0,.41,0,.82,0,1.23l-1.84-.6c0-1.22,0-2.47,0-3.75-.27-12.59-3.29-22.87-6.75-23s-6,10-5.79,22.46a23.14,23.14,0,0,1-3.63-1.46C46.7,232.49,47,230.87,47.05,223.32Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-14)"
            />
            <path
              d="M170.73,186.7c-4.75-25.38-10.45-45-37-40.1-17.85,3.32-62.94,20.29-83.55,28.38,0,0,0-5.68.11-7.3.56-11.34.14-17.13,3.6-18.79S117,107.75,143.41,99.08s27.51-5,31.62,9.65c3,10.6,5.72,45.71,7.28,78l-11.54.2A1.85,1.85,0,0,0,170.73,186.7Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-15)"
            />
            <path
              d="M175.89,186.85l-5.12.08a1.85,1.85,0,0,0,0-.23,169.24,169.24,0,0,0-4-17.64C170.93,173.5,173.72,179.66,175.89,186.85Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-16)"
            />
            <g style={{ mixBlendMode: "multiply" }}>
              <path
                d="M119,110.19l.59-.3,1.17,40-.59.17Z"
                transform="translate(-1.98 -2.49)"
                fill="#cdcdcd"
              />
              <path
                d="M89.46,127l.59-.36.49,33.26-.59.21Z"
                transform="translate(-1.98 -2.49)"
                fill="#cdcdcd"
              />
              <path
                d="M66.52,141.08l.59-.37v27.78l-.59.22Z"
                transform="translate(-1.98 -2.49)"
                fill="#cdcdcd"
              />
            </g>
            <path
              d="M361.62,250.72s-.67,1.9-10.68,2.09-161.21,2.47-166.31-.4c-.07,9.62-.84,26.16,1.58,27.29,3,1.41,157.09-.81,164.21-1.77s9.79-2.09,10.68-8.75S361.62,250.72,361.62,250.72Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M361.62,250.72s-.67,1.9-10.68,2.09-161.21,2.47-166.31-.4c-.07,9.62-.84,26.16,1.58,27.29,3,1.41,157.09-.81,164.21-1.77s9.79-2.09,10.68-8.75S361.62,250.72,361.62,250.72Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-17)"
            />
            <path
              d="M159.29,186.21,156,95.35l.59-.14,6,163.29,20.61,6c.38-35.69-1-68.47-1.43-77.8h.6c.5,10.76,2.15,50.65,1.2,91l-.59-.2q.14-5.78.2-11.51l-20.53-6,.41,11.35-.6-.23L162,259.87l-.06,0,0-.76-2.6-71.36-28.25,2.5,1.17,71.51-.6-.23-1.17-71.23-24.23,2.15,1,61.44-.59-.21-1-61.18-21.79,1.93.84,52.26-.6-.24-.83-52-35.64,3.16,0-1.52Z"
              transform="translate(-1.98 -2.49)"
              fill="#cdcdcd"
              style={{ mixBlendMode: "multiply" }}
            />
            <path
              d="M177.82,186.81c0-.42-.08-.84-.11-1.26l-2.06-32.8c-.43-6.86,1.07-12.58,3.48-14.06,1.28,14.33,2.38,31.55,3.18,48Z"
              transform="translate(-1.98 -2.49)"
              fill="#191919"
              opacity="0.6"
            />
            <path
              d="M356.6,206c-3.12,1.52-152.65,2.85-159.76-2.29-6.75-4.87-7.3-9.41-8.42-25.46,3.89-2.55,11.47-4.45,21.36-5.84,2.34-.33,4.81-.63,7.39-.9,4.68-.49,9.73-.9,15-1.22l3.05-.18q5.73-.32,11.77-.52l4.31-.13c3.91-.1,7.9-.18,11.9-.23,2.12,0,4.26-.05,6.39-.06h3.05c11,0,22,.14,32.26.41,28.71.78,51.57,2.46,52.78,3.51.18,2.42.36,4.87.53,7.4q.4,6.21.74,12.84C359.05,197.25,358.89,204.86,356.6,206Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-18)"
            />
            <path
              d="M322,170.37c-13.18-.35-27.59-.52-41.7-.35l-1.1-22.93c0-7.76,9.4-14,20.92-13.88s20.85,6.46,20.81,14.23Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-19)"
            />
            <path
              d="M251.33,169.45c-15.51.43-30.08,1.34-41.55,3l-1.28-26.58c0-7.76,9.4-14,20.93-13.89s20.84,6.47,20.81,14.24Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-20)"
            />
            <path
              d="M267.53,160.41a3.35,3.35,0,0,1-1.47,1.36h0c-3.58,2.13-7.26,4.09-11,5.85a4.43,4.43,0,0,1-2.05.58,4.59,4.59,0,0,1-2.76-1.61c-2.31-2.2-4.68-4.51-5.84-7.5a22,22,0,0,1-1.09-6.67,62.58,62.58,0,0,1,.06-9.85c.37-3,1.25-5.13,3.17-3.56.8.65,7,18.75,7.68,18.26l4.31-2.91a6.54,6.54,0,0,1,3.43-1.45c2.42,0,4,2.44,5.1,4.6A3.49,3.49,0,0,1,267.53,160.41Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M266.06,161.77h0c-3.58,2.13-7.26,4.09-11,5.85a4.43,4.43,0,0,1-2.05.58,4.59,4.59,0,0,1-2.76-1.61c-2.31-2.2-4.68-4.51-5.84-7.5a22,22,0,0,1-1.09-6.67,62.58,62.58,0,0,1,.06-9.85c.48-.48,1.2,6.15,2,6.83S252.25,161.52,253,161c1.43-1,2.87-1.95,4.31-2.91a6.54,6.54,0,0,1,3.43-1.45c2.42,0,4,2.44,5.09,4.6C265.9,161.43,266,161.6,266.06,161.77Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-21)"
            />
            <path
              d="M247,169.58q-6,.21-11.77.52l-3.05.18c-5.3.32-10.35.73-15,1.22,0-1.21-.14-9.36-.08-10.51.31-5.62-3.86-15.29-2.85-20.83.5-2.77,23.21-8.86,29.44-4.33a7.89,7.89,0,0,1,2.83,3.19,9.24,9.24,0,0,1,.29,4.41c-.42,4.31-.83,8.6-1.24,12.91C245.46,157.37,246.52,164.46,247,169.58Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M247,169.58q-6,.21-11.77.52l-1.8.1-1.25.08c-5.3.32-10.35.73-15,1.22,0-1.21-.14-9.36-.08-10.51a25.29,25.29,0,0,0-.69-6c-1.06-5.1-2.86-10.94-2.16-14.79.5-2.77,23.21-8.86,29.44-4.33a7.89,7.89,0,0,1,2.83,3.19,9.24,9.24,0,0,1,.29,4.41c-.42,4.31-.83,8.6-1.24,12.91C245.46,157.37,246.52,164.46,247,169.58Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-22)"
            />
            <path
              d="M233.45,170.2l-1.25.08c-5.3.32-10.35.73-15,1.22,0-1.21-.14-9.36-.08-10.51a25.29,25.29,0,0,0-.69-6c.33-.73.67-1.46,1-2.18a9.36,9.36,0,0,1,1.27-2.55c1,.5.34,3.15.56,4.19.18.83,2.19,7.28,3.45,9.21a3,3,0,0,0,1,1.06,3.87,3.87,0,0,0,2,.3c3-.1,6-.22,8.94-.39C234.86,166.5,233.68,168.29,233.45,170.2Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-23)"
            />
            <path
              d="M230.58,165.62c-1.41.83.29,3.21-1.35,3.24-2.81,0-11.44-.86-14.25-.81-3,0-6-2-7.88-4.35a6.83,6.83,0,0,1-1.21-5.23,20.44,20.44,0,0,1,1.72-5.23c.95-2.18,5.32-14.52,7.78-13.55,1,.41,1.85,1.79,2.45,3.56a28.27,28.27,0,0,1,1.26,10.69c-.22,1.09-2.84,3-2,3.81a3,3,0,0,0,.87.5A33.33,33.33,0,0,0,230.31,161C231.1,162.39,232,164.79,230.58,165.62Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M229.23,168.86c-2.81,0-11.44-.86-14.25-.81-3,0-6-2-7.88-4.35a6.83,6.83,0,0,1-1.21-5.23,7.2,7.2,0,0,0,4.23,4.72c3.78,1.35,18,2.13,18,2.13l2.43.3C229.17,166.45,230.87,168.83,229.23,168.86Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-24)"
            />
            <path
              d="M228.19,166a4.39,4.39,0,0,0,1.21,2.38,1.44,1.44,0,0,0,.35-.77,4.28,4.28,0,0,0-.55-3.51C228,162.53,228.05,165.23,228.19,166Z"
              transform="translate(-1.98 -2.49)"
              fill="#191919"
            />
            <path
              d="M227.07,132.59c-.1,1.93,0,4,1.26,5.51a7.3,7.3,0,0,0,2.57,1.77,6.53,6.53,0,0,0,2.92.75,2.77,2.77,0,0,0,2.46-1.5,9.24,9.24,0,0,0,.53-4.78c-.58-1.85-.67-3.88-1.62-5.58s-4.33-2-6.49-1.86c-1,.07-1.47.39-1.58,1.37C227,129.65,227.15,131.19,227.07,132.59Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-25)"
            />
            <path
              d="M230.64,135.3a7.39,7.39,0,0,0,2.9,3.37,3.3,3.3,0,0,0,1.6.5,1.85,1.85,0,0,0,1.5-.67,3,3,0,0,0,.39-1.83v-4.6a2,2,0,0,0-.16-.94,1.86,1.86,0,0,0-1.32-.79c-1.42-.35-4.14-1-5.33.22S230.08,134.06,230.64,135.3Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-26)"
            />
            <path
              d="M243.66,128.8c-.15,2.33-.72,5-2.76,6.09a6.55,6.55,0,0,1-2.55.66,11.88,11.88,0,0,1-6.38-.94,10.29,10.29,0,0,1-4.73-5.5,20.59,20.59,0,0,1-1.2-7.27c0-3.53.7-7.62,3.77-9.35a9.21,9.21,0,0,1,3.94-1c3.09-.19,10.12-.43,9.72,3.94S244,124.28,243.66,128.8Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-27)"
            />
            <path
              d="M244,110.13a6.14,6.14,0,0,0-3.59-3.7,11.81,11.81,0,0,0-4.63-.46c-1.61.07-3.22.15-4.83.25a13.65,13.65,0,0,0-5.26,1.06,3.89,3.89,0,0,0-1.93,1.84,16.72,16.72,0,0,0-.46,2c-.24.86-.76,1.62-1,2.46a8.94,8.94,0,0,0,.39,5c.7,2.5,1.51,5.17,3.56,6.75a6,6,0,0,1,.16-4.14,4.11,4.11,0,0,1,1.59,2.84c.5,0,.59-.73.54-1.23a22.74,22.74,0,0,1-.31-4.27,5,5,0,0,1,1.84-3.73c1.81-1.3,4.29-.66,6.48-.27,1.7.31,4.86,1,6.52.32S244.51,111.57,244,110.13Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M272.67,169.15h-3.05c-.77-7.93-8.17-14.16-17.15-14.16-9.34,0-17,6.74-17.22,15.1-1,.06-2,.11-3.05.18.15-10.12,9.18-18.32,20.27-18.32C263.14,152,271.91,159.55,272.67,169.15Z"
              transform="translate(-1.98 -2.49)"
              fill="#191919"
            />
            <g opacity="0.5">
              <g clipPath="url(#clip-path)">
                <path
                  d="M182.35,161.25c1.21,5.45,7.63,9.65,14.34,9.38h0c6.71-.28,11.17-4.92,10-10.37l-5-22.43c-1.21-5.45-7.63-9.65-14.34-9.37h0c-6.71.27-11.17,4.91-10,10.36Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M156.27,162.31c1.21,5.45,14.34,31.4,21.06,31.12l5-2.07c6.71-.28,6.19-2.84,5-8.29l-11.7-44.17c-1.21-5.46-7.63-9.66-14.34-9.38h0c-6.72.27-11.17,4.91-10,10.36Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M139.24,165c1.27,4.75,5,8,8.39,7.33h0c3.36-.69,5.05-5.1,3.78-9.85L146.17,143c-1.27-4.75-5-8-8.39-7.34h0c-3.36.7-5.05,5.11-3.78,9.86Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M126.17,167.71c1.28,4.74,5,8,8.39,7.33h0c3.36-.69,5.06-5.11,3.78-9.85l-5.23-19.53c-1.27-4.75-5-8-8.4-7.34h0c-3.36.7-5,5.11-3.78,9.86Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M113.23,169.17c1.11,4.15,4.4,7,7.34,6.42h0c2.94-.61,4.42-4.48,3.31-8.63l-4.58-17.1c-1.12-4.16-4.41-7-7.35-6.42h0c-2.94.61-4.43,4.47-3.31,8.63Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M101.64,172.49c1.12,4.16,4.4,7,7.34,6.42h0c3-.61,4.43-4.47,3.31-8.63l-4.58-17.1c-1.11-4.15-4.4-7-7.35-6.42h0c-2.94.61-4.42,4.47-3.31,8.63Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M91.26,177.86c1.14,4.27,4.52,7.23,7.55,6.6h0c3-.63,4.55-4.6,3.4-8.87L97.5,158c-1.15-4.28-4.53-7.23-7.56-6.6h0c-3,.62-4.55,4.6-3.4,8.87Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M79.2,181.92c1.15,4.27,4.53,7.22,7.56,6.6h0c3-.63,4.54-4.6,3.4-8.87l-4.72-17.59c-1.14-4.27-4.53-7.22-7.55-6.6h0c-3,.63-4.55,4.6-3.41,8.87Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M68.21,184.24c1,3.64,3.84,6.14,6.41,5.61h0c2.58-.53,3.87-3.9,2.89-7.53l-4-14.94c-1-3.63-3.85-6.14-6.42-5.61h0c-2.57.53-3.86,3.9-2.89,7.54Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
                <path
                  d="M56.3,186.22c.52,3.72,3.08,6.56,5.69,6.34h0c2.61-.22,4.31-3.41,3.78-7.14l-2.18-15.31c-.53-3.72-3.08-6.56-5.7-6.34h0c-2.61.22-4.3,3.42-3.78,7.14Z"
                  transform="translate(-1.98 -2.49)"
                  fill="#191919"
                />
              </g>
            </g>
            <path
              d="M228.29,241.58s-17-3.62-28.19-13.32c-8.82-7.62-13.65,5.15-11.43,14.84s27.06,7.43,35.08,6.66S233,242.85,228.29,241.58Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M226.45,241.61s-15.91-3.1-26.44-11.4c-8.27-6.52-12.81,4.41-10.72,12.7s25.38,6.35,32.9,5.7S230.87,242.69,226.45,241.61Z"
              transform="translate(-1.98 -2.49)"
              fill="#3f3d56"
            />
            <path
              d="M189.46,243.4a4,4,0,0,1-.17-.49c-2.09-8.29,2.45-19.22,10.72-12.7.91.71,1.85,1.39,2.83,2C196.15,233.78,191.28,237.7,189.46,243.4Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M197.59,241.77c0,3.29,2.3,6,5.13,6s5.13-2.67,5.13-6-2.3-6-5.13-6S197.59,238.48,197.59,241.77Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M211.75,243.72c0,2.49,1.74,4.5,3.88,4.5s3.87-2,3.87-4.5-1.73-4.5-3.87-4.5S211.75,241.23,211.75,243.72Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M359.89,239.5c0,2.58-1.79,4.67-4,4.67s-4-2.09-4-4.67,1.79-4.68,4-4.68S359.89,236.92,359.89,239.5Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M351.89,242.73a3.05,3.05,0,1,1-3-3.53A3.31,3.31,0,0,1,351.89,242.73Z"
              transform="translate(-1.98 -2.49)"
              fill="#68e1fd"
            />
            <path
              d="M352.81,231.91a31.91,31.91,0,0,0,2.65-6.21c2.52-2.69,3.87,2.45,4.29,8.67C358.51,232.62,355.33,232,352.81,231.91Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-28)"
            />
            <path
              d="M189.46,243.4a4,4,0,0,1-.17-.49c-2.09-8.29,2.45-19.22,10.72-12.7.91.71,1.85,1.39,2.83,2C196.15,233.78,191.28,237.7,189.46,243.4Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-29)"
            />
            <path
              d="M197.59,241.77c0,3.29,2.3,6,5.13,6s5.13-2.67,5.13-6-2.3-6-5.13-6S197.59,238.48,197.59,241.77Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-30)"
            />
            <path
              d="M211.75,243.72c0,2.49,1.74,4.5,3.88,4.5s3.87-2,3.87-4.5-1.73-4.5-3.87-4.5S211.75,241.23,211.75,243.72Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-31)"
            />
            <path
              d="M359.89,239.5c0,2.58-1.79,4.67-4,4.67s-4-2.09-4-4.67,1.79-4.68,4-4.68S359.89,236.92,359.89,239.5Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-32)"
            />
            <path
              d="M351.89,242.73a3.05,3.05,0,1,1-3-3.53A3.31,3.31,0,0,1,351.89,242.73Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-33)"
            />
            <path
              d="M168.19,208.1c0,.32-.11.57-.24.55l-3.39-.42c-.13,0-.23-.29-.23-.61v-1.36c0-.32.1-.56.23-.54l3.4.42c.13,0,.23.28.23.6Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-34)"
            />
            <path
              d="M118.45,246.39c0,.24-.08.42-.18.41l-2.52-.31c-.1,0-.17-.22-.17-.45v-1c0-.24.08-.42.17-.41l2.53.32c.09,0,.17.21.17.45Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-35)"
            />
            <path
              d="M95.06,241.87c0,.19-.06.34-.14.33L92.85,242c-.08,0-.14-.18-.14-.37v-.83c0-.2.06-.34.14-.33l2.07.25c.08,0,.14.18.14.37Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-36)"
            />
            <polygon
              points="238.48 68.82 323.29 204.69 354.62 203.49 277.38 70.04 238.48 68.82"
              fill="#68e1fd"
              opacity="0.18"
              style={{ isolation: "isolate" }}
            />
            <polygon
              points="72.76 134.26 95.51 155.52 124.22 146.01 95.45 120.08 72.76 134.26"
              fill="#68e1fd"
              opacity="0.18"
              style={{ isolation: "isolate" }}
            />
            <rect
              x="252.66"
              y="226.64"
              width="63.62"
              height="19.45"
              fill="url(#linear-gradient-37)"
            />
            <path
              d="M197,216.43a8.19,8.19,0,0,1,.16-3.38c4.59,13.23,41.07,23.72,83.51,23.72,40.33,0,72-9.49,75.11-21.78a9,9,0,0,1,.37,1.42c2.39,13.88-31.31,25.15-75.25,25.15S199.4,230.32,197,216.43Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-38)"
            />
            <path
              d="M260.53,152.7a4,4,0,0,0-1.16,1,4.08,4.08,0,0,0-.46,2,2.17,2.17,0,0,0,.25,1.45c.31.4,1.06.45,1.24,0a1.8,1.8,0,0,0,.78,1.34,1.11,1.11,0,0,0,1.44-.22,2.28,2.28,0,0,0,.21,1.51c.3.43,1.07.52,1.29.05.1.54.3,1.19.83,1.32a1.05,1.05,0,0,0,1-.41,4.92,4.92,0,0,0,.56-1,.69.69,0,0,1,.21-.26.71.71,0,0,1,.38,0,4.1,4.1,0,0,0,.86,0,.62.62,0,0,0,.38-.15.65.65,0,0,0,.09-.3c.11-1.47-1-2.68-1.79-4a6.18,6.18,0,0,0-2.52-2.47,3.8,3.8,0,0,0-1.64-.68A3.74,3.74,0,0,0,260.53,152.7Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-39)"
            />
            <path
              d="M230.68,161a4.47,4.47,0,0,0-2.5,2.9,2.51,2.51,0,0,0,1.89,3,5.85,5.85,0,0,0,1.84-.29c1.34-.29,2.83-.15,4-.89l.37.53a1.71,1.71,0,0,0,1.67-.78c.39-.52.61-1.16,1-1.7s.92-1.09,1.26-1.72A2.83,2.83,0,0,0,239,158.2a3.3,3.3,0,0,0-2.56.07c-.93.38-1.61,1.14-2.49,1.55C232.9,160.31,231.72,160.43,230.68,161Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-40)"
            />
            <path
              d="M219.1,153.94c-.22,1.09-2.84,3-2,3.81a4,4,0,0,1-2.64-2.11c-.56-1.16.45-2.89,1.87-5.72a17.84,17.84,0,0,0,1.42-6.79l.12.12A28.27,28.27,0,0,1,219.1,153.94Z"
              transform="translate(-1.98 -2.49)"
              fill="url(#linear-gradient-41)"
            />
            <path
              d="M222.07,136.75l3.45,10.74,5.57-.31s-3.88-12.87-4-14.59S221.77,132.49,222.07,136.75Z"
              transform="translate(-1.98 -2.49)"
              fill="#fff"
            />
            <path
              d="M237.72,146.23a32,32,0,0,0,5.87-.15c-.24-3.62-1.89-7-3.52-10.22a.59.59,0,0,0-.17-.24.62.62,0,0,0-.36-.08l-1.55,0a1.51,1.51,0,0,0-.92.17c-.6.39.26,3.9.33,4.69C237.57,142.3,237.68,144.27,237.72,146.23Z"
              transform="translate(-1.98 -2.49)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="wave"
        style={{ transform: "rotate(0deg)", transition: "0.3s" }}
        viewBox="0 0 1440 490"
        version="1.1"
        className="absolute left-0 bottom-0 right-0 hidden md:block"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 0px)", opacity: 1 }}
          fill="url(#sw-gradient-0)"
          d="M0,441L120,367.5C240,294,480,147,720,147C960,147,1200,294,1440,310.3C1680,327,1920,212,2160,179.7C2400,147,2640,196,2880,179.7C3120,163,3360,82,3600,98C3840,114,4080,229,4320,245C4560,261,4800,180,5040,155.2C5280,131,5520,163,5760,179.7C6000,196,6240,196,6480,204.2C6720,212,6960,229,7200,212.3C7440,196,7680,147,7920,171.5C8160,196,8400,294,8640,277.7C8880,261,9120,131,9360,122.5C9600,114,9840,229,10080,228.7C10320,229,10560,114,10800,65.3C11040,16,11280,33,11520,106.2C11760,180,12000,310,12240,367.5C12480,425,12720,408,12960,351.2C13200,294,13440,196,13680,196C13920,196,14160,294,14400,326.7C14640,359,14880,327,15120,334.8C15360,343,15600,392,15840,367.5C16080,343,16320,245,16560,187.8C16800,131,17040,114,17160,106.2L17280,98L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 50px)", opacity: 0.9 }}
          fill="url(#sw-gradient-1)"
          d="M0,343L120,343C240,343,480,343,720,326.7C960,310,1200,278,1440,236.8C1680,196,1920,147,2160,155.2C2400,163,2640,229,2880,253.2C3120,278,3360,261,3600,220.5C3840,180,4080,114,4320,114.3C4560,114,4800,180,5040,196C5280,212,5520,180,5760,163.3C6000,147,6240,147,6480,163.3C6720,180,6960,212,7200,228.7C7440,245,7680,245,7920,220.5C8160,196,8400,147,8640,122.5C8880,98,9120,98,9360,155.2C9600,212,9840,327,10080,318.5C10320,310,10560,180,10800,114.3C11040,49,11280,49,11520,98C11760,147,12000,245,12240,302.2C12480,359,12720,376,12960,351.2C13200,327,13440,261,13680,212.3C13920,163,14160,131,14400,171.5C14640,212,14880,327,15120,334.8C15360,343,15600,245,15840,212.3C16080,180,16320,212,16560,212.3C16800,212,17040,180,17160,163.3L17280,147L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 100px)", opacity: 0.8 }}
          fill="url(#sw-gradient-2)"
          d="M0,147L120,171.5C240,196,480,245,720,277.7C960,310,1200,327,1440,343C1680,359,1920,376,2160,392C2400,408,2640,425,2880,408.3C3120,392,3360,343,3600,302.2C3840,261,4080,229,4320,179.7C4560,131,4800,65,5040,65.3C5280,65,5520,131,5760,138.8C6000,147,6240,98,6480,122.5C6720,147,6960,245,7200,245C7440,245,7680,147,7920,122.5C8160,98,8400,147,8640,204.2C8880,261,9120,327,9360,334.8C9600,343,9840,294,10080,245C10320,196,10560,147,10800,106.2C11040,65,11280,33,11520,57.2C11760,82,12000,163,12240,196C12480,229,12720,212,12960,179.7C13200,147,13440,98,13680,89.8C13920,82,14160,114,14400,138.8C14640,163,14880,180,15120,187.8C15360,196,15600,196,15840,171.5C16080,147,16320,98,16560,138.8C16800,180,17040,310,17160,375.7L17280,441L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
        <defs>
          <linearGradient id="sw-gradient-3" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(62, 84, 243, 1)" offset="0%" />
            <stop stopColor="rgba(11, 238, 255, 1)" offset="100%" />
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 150px)", opacity: 0.7 }}
          fill="url(#sw-gradient-3)"
          d="M0,147L120,179.7C240,212,480,278,720,310.3C960,343,1200,343,1440,294C1680,245,1920,147,2160,106.2C2400,65,2640,82,2880,130.7C3120,180,3360,261,3600,261.3C3840,261,4080,180,4320,138.8C4560,98,4800,98,5040,98C5280,98,5520,98,5760,98C6000,98,6240,98,6480,81.7C6720,65,6960,33,7200,57.2C7440,82,7680,163,7920,179.7C8160,196,8400,147,8640,114.3C8880,82,9120,65,9360,89.8C9600,114,9840,180,10080,228.7C10320,278,10560,310,10800,285.8C11040,261,11280,180,11520,130.7C11760,82,12000,65,12240,89.8C12480,114,12720,180,12960,212.3C13200,245,13440,245,13680,220.5C13920,196,14160,147,14400,179.7C14640,212,14880,327,15120,359.3C15360,392,15600,343,15840,343C16080,343,16320,392,16560,392C16800,392,17040,343,17160,318.5L17280,294L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"
        />
      </svg>
      <div className="h-5/12 z-20 mb-20 flex w-full bg-white lg:w-7/12">
        <div className="flex w-full flex-col space-y-4 self-center p-4 lg:w-1/2">
          <div className="mt-4 flex w-full items-center justify-center text-2xl font-medium text-sky-700">
            Welcome to Kabba Transport
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit,onError)}>
              <div className="flex flex-col space-y-2 px-4">
                <div className="flex items-center space-x-4">
                  <TextInput
                    required
                    {...register(`phoneNumber`)}
                    label="Phone number"
                    className="w-full"
                    error={errors.phoneNumber?.message}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <PasswordInput
                    required
                    label="Password"
                    {...register(`password`)}
                    className="w-full"
                    error={errors.password?.message}
                  />
                </div>
              </div>
              <div className="p-4">
                <Button
                  variant={"gradient"}
                  className="w-full bg-primary"
                  type="submit"
                  loading={loading}
                >
                  Login
                </Button>
              </div>
              <div className="mb-4 cursor-pointer pl-4 text-sm ">
                <NavLink to={'/accounts/forget-password'}>Did you forget your password ?</NavLink>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden h-full w-1/2 bg-white p-4 md:block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 351.9 396.01"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa flex h-full w-full items-center text-primary"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="258.91"
                y1="124.54"
                x2="299.95"
                y2="123.91"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.01" />
                <stop offset="0.13" stopOpacity="0.69" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="linear-gradient-2"
                x1="383.34"
                y1="84.62"
                x2="71.09"
                y2="410.77"
              />
              <linearGradient
                id="linear-gradient-3"
                x1="299.88"
                y1="206.77"
                x2="210.25"
                y2="300.4"
              />
              <linearGradient
                id="linear-gradient-4"
                x1="169.25"
                y1="193.08"
                x2="290.38"
                y2="268.95"
              />
              <linearGradient
                id="linear-gradient-5"
                x1="226.76"
                y1="234.44"
                x2="321.06"
                y2="234.44"
              />
              <linearGradient
                id="linear-gradient-6"
                x1="311.61"
                y1="323.82"
                x2="285.66"
                y2="317.16"
              />
              <linearGradient
                id="linear-gradient-7"
                x1="300.4"
                y1="335.06"
                x2="327.3"
                y2="335.06"
              />
              <linearGradient
                id="linear-gradient-8"
                x1="200.19"
                y1="81.05"
                x2="227.92"
                y2="104.12"
              />
              <linearGradient
                id="linear-gradient-9"
                x1="274.89"
                y1="85.38"
                x2="223.64"
                y2="105.68"
              />
              <linearGradient
                id="linear-gradient-10"
                x1="188.44"
                y1="144.76"
                x2="251.94"
                y2="142.56"
              />
              <linearGradient
                id="linear-gradient-11"
                x1="241.5"
                y1="248.75"
                x2="312.72"
                y2="218.8"
              />
            </defs>
            <path
              d="M406.19,147.48A70.63,70.63,0,0,1,395,206.08c-9,12.25-21.83,21.41-29.82,34.31-17.27,27.85-8,65.88-23.84,94.57-13.24,24-40.67,35.79-66.14,45.91C255.2,388.81,235,396.8,213.8,400s-44,1.17-62.37-9.86c-15.15-9.07-26.28-23.45-36.56-37.81C89.6,317,66.76,278.53,59,235.79s1.92-90.68,33.74-120.25c15.49-14.4,35.78-24.33,46.71-42.44,11.47-19,11.63-45.51,29.46-58.75,6.58-4.89,14.76-7.16,22.88-8.24A92.17,92.17,0,0,1,248.4,17c17.47,9.79,31.81,25.35,50.84,31.61,24.63,8.11,56.24,1.88,72.61,25.88C385,93.79,400.35,125,406.19,147.48Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
              opacity="0.18"
              style={{ isolation: "isolate" }}
            />
            <path
              d="M301.39,133.82a5.7,5.7,0,0,1-1.15,1.62,27.65,27.65,0,0,1-14.16,8,25.86,25.86,0,0,1-5.83.66c-1.15-7.42-2.32-14.85-3.22-22.3h0c-.71-5.82-1.24-11.63-1.49-17.47-.17-3.66-8.1-16.42-6.48-19.84,1.79-3.76,14.68,9.11,16.13,11.56,8.05,13.5,10.94,23.94,16.46,35.55A2.94,2.94,0,0,1,301.39,133.82Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M287.38,142.08a9.47,9.47,0,0,1-1.3,1.38,25.86,25.86,0,0,1-5.83.66c-1.15-7.42-2.32-14.85-3.22-22.3h0q.51-8.78,1.85-17.49c-.38,2.43,2.61,7.73,3.42,10.11l3.51,10.37C287.64,130.22,291.55,136.8,287.38,142.08Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient)"
            />
            <path
              d="M283.07,154.77c.8,4.88,1.82,9.82,4.23,14.14,1.24,2.22,3.09,4.4,5.6,4.79a8.65,8.65,0,0,0,4.1-.61,28.52,28.52,0,0,0,7.52-3.92,56.68,56.68,0,0,0,6.19-5.51,76.29,76.29,0,0,0,7.93-8.74c1.11-1.5,2.12-3.08,3.33-4.51,5-5.95,13-8.62,18.67-13.93,1.53-1.42,4.63-7.92,4.4-10-2.08-1.2-6.35,3.63-8.65,4.33s-4.45,1.84-6.76,2.49a16.54,16.54,0,0,1-8.47.11c-.94-.24-2.06-15.07-3-14.73a3.48,3.48,0,0,0-1.24.88c-1.94,2-3,19.07-4.61,21.29-2.21,3-5.27,5.28-8.3,7.46-.27.19-.61.39-.91.24a.91.91,0,0,1-.36-.62c-.79-2.93-1.68-5.84-2.65-8.71-.68-2-1.64-4.2-3.65-4.8-1.79-.53-3.65.48-5.25,1.45-2.06,1.26-4.14,2.5-6.19,3.77-1.14.7-2.52,1.38-3.06,2.67-.66,1.55-.1,3.69.09,5.28C282.33,150,282.68,152.39,283.07,154.77Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M301.32,290.78c0,3.06-.15,6.07-.42,9.06a100.21,100.21,0,0,1-200-8c0-.34,0-.67,0-1a100.22,100.22,0,1,1,200.43,0Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M301.32,290.78c0,3.06-.15,6.07-.42,9.06-23.06,15.69-57,25.59-94.85,25.59-44,0-82.66-13.37-105.15-33.64,0-.34,0-.67,0-1a100.22,100.22,0,1,1,200.43,0Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-2)"
            />
            <path
              d="M247.06,251.6c4,1.44,8.35,2.47,11.63,5.2,2.91,2.41,4.68,5.9,6.11,9.39a74.79,74.79,0,0,1,5,37c-.4,3.34-1,6.78-.13,10s3.84,6.24,7.18,5.84c4.63-9.2,4.73-19.94,4.74-30.24,0-7.52,0-15.2-2.52-22.29-2.06-5.84-5.7-11-9.46-15.91-13.71-18-30.3-34.79-51.42-42.88a4.17,4.17,0,0,0-2.84-.35c-4.35,1.59-3.64,9.33-1.95,12.44a115.45,115.45,0,0,0,8,12.39A54.91,54.91,0,0,0,247.06,251.6Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-3)"
            />
            <path
              d="M207.51,229a23.71,23.71,0,0,1,.52-4,3.66,3.66,0,0,1,2.67-2.71,12.81,12.81,0,0,0,1.64-.15c1.11-.34,1.58-1.63,1.9-2.74L217.79,207c.68-2.4,1.37-4.92.85-7.35-.82-3.8-4.31-6.37-7.71-8.23-2.47-1.36-5.25-2.57-8-2.06-4.26.79-6.73,5.19-8.32,9.22a43.46,43.46,0,0,0-3.37,13.59c-.28,5.41-.74,18.66,2.61,23.39C198.49,242.2,206.77,235,207.51,229Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-4)"
            />
            <path
              d="M261.45,213.35c9.07,3.07,18.74,4.36,27.48,8.27a2.13,2.13,0,0,1,1.3,1.11,2.19,2.19,0,0,1-.16,1.28,239.87,239.87,0,0,0-12.36,68,51,51,0,0,0,15.84.35,3.61,3.61,0,0,0,1.67-.53,3.69,3.69,0,0,0,1.06-1.58q12.69-28.49,22.78-58.05c1.19-3.49,2.37-7.14,1.89-10.8-.69-5.33-4.7-9.53-8.51-13.32l-29.69-29.62a8.05,8.05,0,0,0-2.72-2,7.74,7.74,0,0,0-3.68-.18c-11.71,1.45-27.7,1.9-49.22,1-2.79-.12,11,15.77,12.15,18.82.92,2.42,3.83,5.54,5.61,7.42C249.35,208.2,255.37,211.29,261.45,213.35Z"
              transform="translate(-56.44 -5.34)"
              fill="#525263"
            />
            <path
              d="M261.45,213.35c9.07,3.07,18.74,4.36,27.48,8.27a2.13,2.13,0,0,1,1.3,1.11,2.19,2.19,0,0,1-.16,1.28,239.87,239.87,0,0,0-12.36,68,51,51,0,0,0,15.84.35,3.61,3.61,0,0,0,1.67-.53,3.69,3.69,0,0,0,1.06-1.58q12.69-28.49,22.78-58.05c1.19-3.49,2.37-7.14,1.89-10.8-.69-5.33-4.7-9.53-8.51-13.32l-29.69-29.62a8.05,8.05,0,0,0-2.72-2,7.74,7.74,0,0,0-3.68-.18c-11.71,1.45-27.7,1.9-49.22,1-2.79-.12,11,15.77,12.15,18.82.92,2.42,3.83,5.54,5.61,7.42C249.35,208.2,255.37,211.29,261.45,213.35Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-5)"
            />
            <path
              d="M276.19,305.14a10.05,10.05,0,0,0-1.45,3.73,59.94,59.94,0,0,0-1.18,7.05c-.2,1.84-.27,3.84.78,5.35s2.78,2.06,4.4,2.69a99.47,99.47,0,0,1,11.86,5.83c2.58,1.42,5.27,2.89,8.21,3s6.19-1.5,6.86-4.36a8.35,8.35,0,0,0-.34-4.11,32.59,32.59,0,0,0-9.1-15,13.13,13.13,0,0,0-2.53-1.93,16.51,16.51,0,0,0-2.75-1.12,59.8,59.8,0,0,0-8.19-2.35C280.33,303.53,277.87,302.81,276.19,305.14Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M276.19,305.14a10.05,10.05,0,0,0-1.45,3.73,59.94,59.94,0,0,0-1.18,7.05c-.2,1.84-.27,3.84.78,5.35s2.78,2.06,4.4,2.69a99.47,99.47,0,0,1,11.86,5.83c2.58,1.42,5.27,2.89,8.21,3s6.19-1.5,6.86-4.36a8.35,8.35,0,0,0-.34-4.11,32.59,32.59,0,0,0-9.1-15,13.13,13.13,0,0,0-2.53-1.93,16.51,16.51,0,0,0-2.75-1.12,59.8,59.8,0,0,0-8.19-2.35C280.33,303.53,277.87,302.81,276.19,305.14Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-6)"
            />
            <path
              d="M279.16,292.76a66.11,66.11,0,0,0-1.18,12c0,1.77.12,3.73,1.39,5a5.79,5.79,0,0,0,4.26,1.22,16.5,16.5,0,0,0,5.37-1.11,2.35,2.35,0,0,0,1-.62,2.45,2.45,0,0,0,.41-.95,71.59,71.59,0,0,0,2.41-17.54,4.53,4.53,0,0,0-.55-2.68c-.95-1.33-2.92-1.27-4.52-.95a36.89,36.89,0,0,0-6,1.83C279.91,289.6,279.53,290.86,279.16,292.76Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M298.88,311.56a38.27,38.27,0,0,1,1.76,5.47c.31,1.45.49,3,1.42,4.16,1.18,1.45,3.23,1.82,5.1,1.85a10.32,10.32,0,0,0,4.51-.76,3.5,3.5,0,0,0,1.76-1.52,4.28,4.28,0,0,0,.28-2.16A34.5,34.5,0,0,0,310,304a56.15,56.15,0,0,0-4.44-6.72,4.17,4.17,0,0,0-2.43-2,4,4,0,0,0-2.91,1.11c-1.65,1.24-5.28,3.31-4.9,5.66C295.8,305.18,297.76,308.59,298.88,311.56Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M311,315.25a37.74,37.74,0,0,0-3.29,2.07c-1.65.91-3.59,1.09-5.4,1.61a2.42,2.42,0,0,0-1.26.67,2.36,2.36,0,0,0-.45,1.15,25.08,25.08,0,0,0,.33,8.19q1.38,8.74,3.22,17.41c.51,2.38,1.12,4.89,2.85,6.6s4.36,2.3,6.82,2.37a14.23,14.23,0,0,0,6.7-1.25,12.58,12.58,0,0,0,6.44-8.84,19.49,19.49,0,0,0-1.32-11.09,54.45,54.45,0,0,0-3.58-6.91q-2.7-4.64-5.38-9.28C315.32,315.59,314.08,313.94,311,315.25Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M311,315.25a37.74,37.74,0,0,0-3.29,2.07c-1.65.91-3.59,1.09-5.4,1.61a2.42,2.42,0,0,0-1.26.67,2.36,2.36,0,0,0-.45,1.15,25.08,25.08,0,0,0,.33,8.19q1.38,8.74,3.22,17.41c.51,2.38,1.12,4.89,2.85,6.6s4.36,2.3,6.82,2.37a14.23,14.23,0,0,0,6.7-1.25,12.58,12.58,0,0,0,6.44-8.84,19.49,19.49,0,0,0-1.32-11.09,54.45,54.45,0,0,0-3.58-6.91q-2.7-4.64-5.38-9.28C315.32,315.59,314.08,313.94,311,315.25Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-7)"
            />
            <path
              d="M189.84,132.21a27.32,27.32,0,0,0-1.74,6.86,164.27,164.27,0,0,0-1.82,18.39c-.5,11.76.26,23.54-.07,35.3-.34,12.23-1.78,24.91,2.62,36.32a14.07,14.07,0,0,0,3.38,5.5,6.4,6.4,0,0,0,6,1.64,6.66,6.66,0,0,0,4-4.35,19.83,19.83,0,0,0,.72-6.06l.16-11.18c0-.23,0-.5.23-.6a.58.58,0,0,1,.57.11,30.35,30.35,0,0,1,2.45,2.37c.89.72,2.31,1.07,3.1.24a2.59,2.59,0,0,0,.47-2.06c-.48-4.84-4.7-8.33-7.21-12.5-3.62-6-3.62-13.56-2.71-20.54,1.13-8.74,3.49-17.27,5.84-25.77l2-7.19c1.51-5.46,3-11.42.8-16.65a13.93,13.93,0,0,0-9.77-8C194.68,123.27,191.35,128.81,189.84,132.21Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M282.68,177.68c-1.29,3.47-8.57-.58-12.73,1.5-7.94,4-17-.11-25.85,0-9.42.17-15.92,9.45-25.27,10.63a5.1,5.1,0,0,1-2.06,0c-1.29-.38-2.05-1.67-2.6-2.88a25.15,25.15,0,0,1-1.72-5.83c-1.16-6.64,0-13.76,1.24-20.51q1.56-8.58,3.11-17.17,1.8-10,3.63-20.12a103.26,103.26,0,0,1,3-13.17c.24-.75.49-1.48.76-2.2A114.66,114.66,0,0,1,232.58,91c2-3.47,4-7,7.09-9.63,3.45-2.91,7.9-4.36,12.37-5.06a25,25,0,0,1,12.07.7c2.23.78,3.07,3.14,4.84,4.59a30.21,30.21,0,0,1,5.95,5.84,26.55,26.55,0,0,1,3.65,8.74c6.27,24.38-2.8,50.68,3.16,75.13C282.39,174.18,283.71,174.94,282.68,177.68Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <polygon
              points="217.72 86.7 218.5 91.81 215.5 90.14 215.5 103.68 212.08 88.64 215.39 83.82 217.72 86.7"
              fill="url(#linear-gradient-8)"
            />
            <path
              d="M248.76,91.71l1.89,1,3,2.61-8,21.46s11.62-17.88,12-27a50.63,50.63,0,0,0-2.07-16.6Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-9)"
            />
            <path
              d="M223.14,153.22a57.73,57.73,0,0,0,0,14.8c.41,3.09,1.07,6.17.86,9.27a2.71,2.71,0,0,1-.55,1.68,2.93,2.93,0,0,1-1.65.65q-4.69.74-9.39,1.51c-1.16-6.64,0-13.76,1.24-20.51q1.56-8.58,3.11-17.17,1.8-10,3.63-20.12a103.26,103.26,0,0,1,3-13.17c.24-.75.49-1.48.76-2.2,3.1-1.72,7.41-3,9.33-.22,2,3-.63,10-1.41,13C229.23,131.61,224.57,142,223.14,153.22Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-10)"
            />
            <path
              d="M295.07,66.52a16.57,16.57,0,0,1-.11,3.73,2.75,2.75,0,0,1-2.55,2.3c-3.12-.23-5.37,2.76-7.85,4.68a13.23,13.23,0,0,1-13.72,1.31,1.8,1.8,0,0,0-2.27,1,5.22,5.22,0,0,0-.2,2.75l1.15,10.94c.22,2.11.24,4.69-1.55,5.83s-3.91.15-5.39-1.14a18,18,0,0,1-4.87-8.17A38,38,0,0,1,265.82,53a23.39,23.39,0,0,1,5.77-4.68,24.89,24.89,0,0,1,7.2-2.43,19.57,19.57,0,0,1,6.86-.38A13.88,13.88,0,0,1,293.59,50c1.52,1.66,3,6.61,4.6,7.42C297,60.44,295.12,63.11,295.07,66.52Z"
              transform="translate(-56.44 -5.34)"
              fill="#f5bcba"
            />
            <path
              d="M271.27,23.58a8.67,8.67,0,0,0-4.44.13c-2.82,1-4.4,3.95-5.42,6.76s-1.86,5.88-4.08,7.87c-1.91,1.72-4.62,2.41-6.34,4.31-2,2.21-2.19,5.48-2,8.45a11.87,11.87,0,0,0,1.81,6.3c1.06,1.44,2.69,2.44,3.52,4,.94,1.79,1.26,4.56,3.28,4.68a3.37,3.37,0,0,0,2.23-1,15.3,15.3,0,0,0,4.46-6.59c.49-1.42.81-3,1.89-4s3.28-1.06,3.77.36a4.52,4.52,0,0,1-.4,2.65c-.26.88-.15,2.07.71,2.37,1.62-2.5,2.53-5.44,4.45-7.71a11,11,0,0,1,17.37.82c1,1.43,1.68,3.13,3.08,4.15a8.78,8.78,0,0,0,4.37,1.28c3.44.34,7.08.47,10.18-1.06s5.39-5.24,4.2-8.49c-.94-2.55-3.62-4-6.22-4.82s-5.38-1.23-7.67-2.69c-3.11-2-4.77-5.56-6.58-8.79s-4.26-6.55-7.88-7.31a14.5,14.5,0,0,0-4.46,0,16.76,16.76,0,0,1-4.39.34C274.91,25.22,273.17,24,271.27,23.58Z"
              transform="translate(-56.44 -5.34)"
              fill="#2e2e3f"
            />
            <path
              d="M271.27,105.75a127.9,127.9,0,0,1,2,35.15c-.79,9.78-2.67,19.49-2.6,29.3a49.62,49.62,0,0,1-.25,8.27c-.44,2.72-18.68,1.68-21.34,1.65-4.48-.06,1.2,6-2.17,3.08-5.29-4.61-7.65-11.8-8.3-18.78-.93-9.95,1.11-19.91,3.33-29.66,1.54-6.75,3.18-13.52,5.94-19.87,3.42-7.85,8.83-17.84,10.57-26.23.25-1.23-4-5.7-2.17-5.64,1.19,0,.63,1.85.84,2.77a8.73,8.73,0,0,0,2.09,3.74,9.82,9.82,0,0,0,5.38,3.21c1.35.25,2.89.19,3.87,1.15,1.19,1.16,1,3.42,1.34,5C270.29,101.15,270.81,103.44,271.27,105.75Z"
              transform="translate(-56.44 -5.34)"
              fill="#f2f2f2"
            />
            <path
              d="M230.78,76c-4.84,2.22-9,5.66-12.93,9.26a108.36,108.36,0,0,0-9.2,9.36c-2.54,3-4.86,6.11-7.54,8.94-3.7,3.89-8.32,7.65-9.05,13a16.53,16.53,0,0,0,1.42,8.14,23.48,23.48,0,0,0,4.15,7.13,16.36,16.36,0,0,0,8.59,5,7.6,7.6,0,0,0,3.18.14,9.92,9.92,0,0,0,4-2.24,71.82,71.82,0,0,0,7.39-6.43c2.36-2.45,4.36-5.24,6.66-7.75,2-2.16,4.13-4.11,6.13-6.23,2.39-2.54,4.52-5.31,6.65-8.08a109.5,109.5,0,0,0,8.27-11.83,54.27,54.27,0,0,0,4.93-11.52c.77-2.62,2-5.94-.66-7.83C247.12,71.08,236.41,73.37,230.78,76Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M230.78,76c-4.84,2.22-9,5.66-12.93,9.26a108.36,108.36,0,0,0-9.2,9.36c-2.54,3-4.86,6.11-7.54,8.94-3.7,3.89-8.32,7.65-9.05,13a16.53,16.53,0,0,0,1.42,8.14,23.48,23.48,0,0,0,4.15,7.13,16.36,16.36,0,0,0,8.59,5,7.6,7.6,0,0,0,3.18.14,9.92,9.92,0,0,0,4-2.24,71.82,71.82,0,0,0,7.39-6.43c2.36-2.45,4.36-5.24,6.66-7.75,2-2.16,4.13-4.11,6.13-6.23,2.39-2.54,4.52-5.31,6.65-8.08a109.5,109.5,0,0,0,8.27-11.83,54.27,54.27,0,0,0,4.93-11.52c.77-2.62,2-5.94-.66-7.83C247.12,71.08,236.41,73.37,230.78,76Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M314.61,121.66a4.92,4.92,0,0,0-1.61,6.66,25,25,0,0,0,6.62,7.5c1.6,1.72,5.2,2,8.4.12s18.47-10.19,26.1-15.62a5,5,0,0,0,1.13-7.17l-6.39-8a4.92,4.92,0,0,0-6.48-1.08Z"
              transform="translate(-56.44 -5.34)"
              fill="#3d3d54"
            />
            <path
              d="M214.5,190l-.23-.23"
              transform="translate(-56.44 -5.34)"
              fill="none"
            />
            <path
              d="M213.12,207.85a30.09,30.09,0,0,0,5.87,11.3c3,3.5,7.08,6,11,8.38l16.51,10.05a57.31,57.31,0,0,1,6.93,4.69c5.41,4.46,9,10.68,12.49,16.76L290,301c1.22,2.15,2.93,4.58,5.4,4.5A6.91,6.91,0,0,0,299,303.9c3.09-2.17,6.41-4.64,7.33-8.31.64-2.56,0-5.25-.77-7.79-3.7-12.51-9.5-24.29-15.32-36Q276.37,224,262.09,196.31c-2.53-4.9-5.6-12.53-9.78-16.13,0,0-38.84-2.36-39.82,1.49A48.73,48.73,0,0,0,213.12,207.85Z"
              transform="translate(-56.44 -5.34)"
              fill="#525263"
            />
            <path
              d="M213.12,207.85a30.09,30.09,0,0,0,5.87,11.3c3,3.5,7.08,6,11,8.38l16.51,10.05a57.31,57.31,0,0,1,6.93,4.69c5.41,4.46,9,10.68,12.49,16.76L290,301c1.22,2.15,2.93,4.58,5.4,4.5A6.91,6.91,0,0,0,299,303.9c3.09-2.17,6.41-4.64,7.33-8.31.64-2.56,0-5.25-.77-7.79-3.7-12.51-9.5-24.29-15.32-36Q276.37,224,262.09,196.31c-2.53-4.9-5.6-12.53-9.78-16.13,0,0-38.84-2.36-39.82,1.49A48.73,48.73,0,0,0,213.12,207.85Z"
              transform="translate(-56.44 -5.34)"
              fill="url(#linear-gradient-11)"
            />
            <polygon
              points="200.36 65.18 194.87 67.35 191.54 85.98 202.61 85.48 200.36 65.18"
              fill="#68e1fd"
            />
            <path
              d="M268.42,90.37c0,.84,0,1.68,0,2.52a9.82,9.82,0,0,0,.06,1.09,10.08,10.08,0,0,0,.24,1.09l1.38,5.44.57-8.84c1.63.79,3.3,1.51,5,2.14a12.59,12.59,0,0,0-1-3.84c-1-2.56-1.92-5.13-3-7.64a6.28,6.28,0,0,0-1.08-1.84,2.31,2.31,0,0,0-1.9-.8c-1,.42-.37,4.17-.36,5Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
            <path
              d="M349.32,117.28,329,130.13a1.78,1.78,0,0,1-2.19-.4l-6.46-7.28a1.2,1.2,0,0,1,.16-1.88l20.36-12.84a1.78,1.78,0,0,1,2.19.4l6.46,7.27A1.2,1.2,0,0,1,349.32,117.28Z"
              transform="translate(-56.44 -5.34)"
              fill="#68e1fd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Login;

import { Carousel } from "@mantine/carousel";
import { Text } from "@mantine/core";
import { IconCards } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode
} from "../../../models/entity-config.model";
import { Service } from "../../../models/service.model";
import Card from "../../../shared/component/Card/card-component";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import { ServiceCard } from "../component/service-card-component";
import { useLazyGetServicesQuery } from "../store/service.query";

export default function ServicePage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [view, setView] = useState("list");
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    orderBy:[{field:'createdAt',direction:'desc'}]
  });

  const [getServices, services] = useLazyGetServicesQuery();


  useEffect(() => {
    getServices(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/service/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const slides = services.data?.data?.map((item) => (
    <Carousel.Slide key={item.id}>
      <ServiceCard
      service={item}
        onArchive={() => {
          
        }}
        onEdit={() => {
          navigate(`/service/detail/${item.id}`);
          setView("list");
        }}
        title={item?.title}
        description={item?.description}
        image={ downloadUrlParser({ path: item?.coverImage?.path, filename: item?.coverImage?.filename, originalname: item?.coverImage?.originalname, mimetype: item?.coverImage?.mimetype })}
      />
    </Carousel.Slide>
  ));

  const data: Service[] | undefined = services.data?.data;
  const config: EntityConfig<Service> = {
    primaryColumn: { key: "title", name: "Title" },
    rootUrl: "/service",
    identity: "id",
    name: "title",
    visibleColumn: [
      { key: "title", name: "Title", render: (service) => {
        return (
          <Text size="sm" className={service?.deletedAt ? "text-danger" : ""}>
            {service.title}
          </Text>
        );
      }, },
      { key: "createdAt", name: "Created At", isDate: true },
    ],
  };

  return (
    <div className="h-full p-4">
      <div className="flex w-full justify-end px-2 mb-2">
        {view === "card" && (
          <div
            className="text-gray-500 dark:text-white"
            onClick={() => setView("list")}
          >
            <IconCards size={24} />
          </div>
        )}
        {view === "list" && (
          <div
            className="text-gray-500 dark:text-white"
            onClick={() => setView("card")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current h-6"
              viewBox="0 0 30 30"
            >
              <path d="M9.984375 3.9863281 A 1.0001 1.0001 0 0 0 9.8398438 4L6 4C4.9069372 4 4 4.9069372 4 6L4 9.8320312 A 1.0001 1.0001 0 0 0 4 10.158203L4 14.832031 A 1.0001 1.0001 0 0 0 4 15.158203L4 19.832031 A 1.0001 1.0001 0 0 0 4 20.158203L4 24C4 25.093063 4.9069372 26 6 26L9.8320312 26 A 1.0001 1.0001 0 0 0 10.158203 26L14.832031 26 A 1.0001 1.0001 0 0 0 15.158203 26L19.832031 26 A 1.0001 1.0001 0 0 0 20.158203 26L24 26C25.093063 26 26 25.093063 26 24L26 20.167969 A 1.0001 1.0001 0 0 0 26 19.841797L26 15.167969 A 1.0001 1.0001 0 0 0 26 14.841797L26 10.167969 A 1.0001 1.0001 0 0 0 26 9.8417969L26 6C26 4.9069372 25.093063 4 24 4L20.154297 4 A 1.0001 1.0001 0 0 0 19.984375 3.9863281 A 1.0001 1.0001 0 0 0 19.839844 4L15.154297 4 A 1.0001 1.0001 0 0 0 14.984375 3.9863281 A 1.0001 1.0001 0 0 0 14.839844 4L10.154297 4 A 1.0001 1.0001 0 0 0 9.984375 3.9863281 z M 6 6L9 6L9 9L6 9L6 6 z M 11 6L14 6L14 9L11 9L11 6 z M 16 6L19 6L19 9L16 9L16 6 z M 21 6L24 6L24 9L21 9L21 6 z M 7 7L7 8L8 8L8 7L7 7 z M 12 7L12 8L13 8L13 7L12 7 z M 17 7L17 8L18 8L18 7L17 7 z M 22 7L22 8L23 8L23 7L22 7 z M 6 11L9 11L9 14L6 14L6 11 z M 11 11L14 11L14 14L11 14L11 11 z M 16 11L19 11L19 14L16 14L16 11 z M 21 11L24 11L24 14L21 14L21 11 z M 7 12L7 13L8 13L8 12L7 12 z M 6 16L9 16L9 19L6 19L6 16 z M 11 16L14 16L14 19L11 19L11 16 z M 16 16L19 16L19 19L16 19L16 16 z M 21 16L24 16L24 19L21 19L21 16 z M 7 17L7 18L8 18L8 17L7 17 z M 6 21L9 21L9 24L6 24L6 21 z M 11 21L14 21L14 24L11 24L11 21 z M 16 21L19 21L19 24L16 24L16 21 z M 21 21L24 21L24 24L21 24L21 21 z M 7 22L7 23L8 23L8 22L7 22 z" />
            </svg>
          </div>
        )}
      </div>
      {view === "card" && (
        <Card title={"Services"}>
          <Carousel
            loop
            slideSize="25%"
            breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: 2 }]}
            slideGap="xl"
            align="start"
            slidesToScroll={4}
          >
            {slides}
          </Carousel>
          <div className="h-8 flex justify-end px-4 my-10">
          <Pagination
            total={services?.data ? services?.data?.count : 0}
            onPaginationChange={(skip: number, top: number) => {
              setCollection({ ...collection, skip: skip, top: top });
            }}
          />
          </div>
        </Card>
      )}
      {view === "list" && (
        <EntityList
          viewMode={viewMode}
          title={"Services"}
          total={services?.data?.count}
          itemsLoading={services.isLoading}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          onArchivedChecked={(e) =>
            setCollection({
              ...collection,
              withArchived: e,
            })
          }
          onPaginationChange={(skip: number, top: number) => {
            setCollection({ ...collection, skip: skip, top: top });
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: ["title", "description"],
            });
          }}
          onFilterChange={(data: any) => {
            if (collection?.filter || data.length > 0) {
              setCollection({ ...collection, filter: data });
            }
          }}
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
      )}
    </div>
  );
}

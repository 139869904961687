import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import FeedbackPage from "../page/feedback-page";
export const FeedbackRoute: RouteObject = {
  path: "/feedback",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <FeedbackPage />
    </AuthGuard>
  ),
 
};

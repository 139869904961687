/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Request, RequestForm } from "../../../models/request.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { REQUEST_ENDPOINT } from "../request.endpoint";

let requestCollection: CollectionQuery;
let archivedRequestCollection: CollectionQuery;

const requestQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequest: builder.query<Request, string>({
      query: (id: string) => ({
        url: `${REQUEST_ENDPOINT.detail}/${id}`,
        method: "get",
        permission: "manage-requests",
      }),
    }),

    getArchivedRequest: builder.query<Request, string>({
      query: (id: string) => ({
        url: `${REQUEST_ENDPOINT.archivedRequest}/${id}`,
        method: "get",
        permission: "manage-requests",
      }),
    }),

    getArchivedRequests: builder.query<Collection<Request>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${REQUEST_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
        permission: "manage-requests",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedRequestCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getRequests: builder.query<Collection<Request>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${REQUEST_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
        permission: "manage-requests",
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            requestCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createRequest: builder.mutation<Request, RequestForm>({
      query: (newData: any) => ({
        url: `${REQUEST_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission: "manage-requests",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRequest: builder.mutation<Request, RequestForm>({
      query: (newData: any) => ({
        url: `${REQUEST_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-requests",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequest",
                data?.id,
                (draft) => {
                  draft = data;
                }
              )
            );
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((request) => {
                      if (request.id === data.id) return data;
                      else {
                        return request;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    assignDriverForRequest: builder.mutation<
      Request,
      { requestId?: string; driverId?: string }
    >({
      query: (newData: { requestId?: string; driverId?: string }) => ({
        url: `${REQUEST_ENDPOINT.assignDriverForRequest}`,
        method: "post",
        data: newData,
        permission: "manage-requests",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((request) => {
                      if (request.id === data.id) return data;
                      else {
                        return request;
                      }
                    });
                  }
                }
              )
            );

            dispatch(
              requestQuery.util.updateQueryData(
                "getRequest",
                param?.requestId ?? "",
                (draft) => {
                  draft.driverId = data.driverId;
                  draft.driver = data.driver;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    payForRequestManually: builder.mutation<any, any>({
      query: (data) => ({
        url: `${REQUEST_ENDPOINT.payForRequestManually}`,
        method: "post",
        data: data,
        permission: "manage-requests",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            requestQuery.util.updateQueryData(
              "getRequests",
              requestCollection,
              (draft) => {
                draft.data = draft?.data?.map((request) => {
                  if (request.id === data?.id) {
                    return data;
                  } else {
                    return request;
                  }
                });
              }
            )
          );

          dispatch(
            requestQuery.util.updateQueryData(
              "getRequest",
              query?.requestId ?? "",
              (draft) => {
                draft.isPaid = true;
              }
            )
          );

          notification("success", "Successfully paid");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveRequest: builder.mutation<Request, { id: string; reason: string }>({
      query: (data) => ({
        url: `${REQUEST_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-requests",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  draft.data = draft?.data?.map((group) => {
                    if (group.id === query?.id) {
                      return { ...group, deletedAt: data?.deletedAt };
                    } else {
                      return group;
                    }
                  });
                }
              )
            );
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequest",
                query?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              requestQuery.util.updateQueryData(
                "getArchivedRequest",
                query?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRequest: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${REQUEST_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-groups",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (request) => request.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            dispatch(
              requestQuery.util.updateQueryData(
                "getArchivedRequests",
                archivedRequestCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (request) => request.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully deleted");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreRequest: builder.mutation<Request, string>({
      query: (id: string) => ({
        url: `${REQUEST_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-groups",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              requestQuery.util.updateQueryData(
                "getRequests",
                requestCollection,
                (draft) => {
                  draft.data = draft?.data?.map((group) => {
                    if (group.id === id) {
                      return data;
                    } else {
                      return group;
                    }
                  });
                }
              )
            );
            dispatch(
              requestQuery.util.updateQueryData("getRequest", id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              requestQuery.util.updateQueryData(
                "getArchivedRequest",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetArchivedRequestQuery,
  useLazyGetArchivedRequestsQuery,
  useLazyGetRequestQuery,
  useLazyGetRequestsQuery,

  useUpdateRequestMutation,
  useCreateRequestMutation,
  useDeleteRequestMutation,
  useArchiveRequestMutation,
  useRestoreRequestMutation,

  useAssignDriverForRequestMutation,
  usePayForRequestManuallyMutation,
} = requestQuery;

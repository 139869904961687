import {
  ActionIcon, Badge, Card, createStyles, Group, Image,
  Text
} from '@mantine/core';
import { IconArchive, IconEdit } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../../models/service.model';
import Confirmation from '../../../shared/component/confirmation/action-confirmation';
import { useArchiveServiceMutation } from '../store/service.query';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: 'absolute',
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: 'none',
  },

  title: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: 2,
  },

  action: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));

interface ArticleCardProps {
  image: string;
  link?: string;
  title: string;
  description: string;
  rating?: string;
  onArchive?:any
  onEdit?:any;
  service?:Service;
}

export function ServiceCard({
  className,
  image,
  link,
  title,
  service,
  description,
  rating,
  onArchive,
  onEdit,
  ...others
}: ArticleCardProps & Omit<React.ComponentPropsWithoutRef<'div'>, keyof ArticleCardProps>) {
  const { classes, cx, theme } = useStyles();
  const linkProps = { href: link, target: '_blank', rel: 'noopener noreferrer' };

  const navigate = useNavigate();
  const [archiveService, archiveResponse] = useArchiveServiceMutation();


  return (
    <Card withBorder radius="md" className={cx(classes.card, className)} {...others}>
      <Card.Section>
        <a {...linkProps}>
          <Image src={image} height={180} />
        </a>
      </Card.Section>

      <Badge className={classes.rating} variant="gradient" gradient={{ from: 'yellow', to: 'red' }}>
        {rating}
      </Badge>

      <Text className={classes.title} weight={500} component="a" {...linkProps}>
        {title}
      </Text>

      <Text size="sm" color="dimmed" lineClamp={4}>
        {description}
      </Text>

      <Group position="right" className={classes.footer}>
        {/* <Center>
          <Avatar src={author.image} size={24} radius="xl" mr="xs" />
          <Text size="sm" inline>
            {author.name}
          </Text>
        </Center> */}

        <Group spacing={8} position="right" >
        <Confirmation
            type={"notify"}
            message={"Are you sure you want to archive this service?"}
            header={`Archive ${service?.title}`}
            result={"single"}
            resultRequired={true}
            selectorLabel={"Please select a reason"}
            labelDescription={"Why are you archiving this service?"}
            options={[
              `It's not longer needed`,
              `It is incorrect and can't update it`,
            ].map((reason) => {
              return { label: reason, value: reason };
            })}
            customInput={true}
            yesText={"Archive"}
            onYes={(reason: string) => {
              archiveService({
                id: service?.id,
                reason: reason,
              }).then((response) => {
                navigate("/service/archived");
              });
            }}
          >
            <ActionIcon loading={archiveResponse?.isLoading} className={classes.action} onClick={onArchive}>
            <IconArchive size={16} color={theme.colors.red[6]} />
          </ActionIcon>
          </Confirmation>
          {/* <ActionIcon className={classes.action} onClick={onArchive}>
            <IconArchive size={16} color={theme.colors.red[6]} />
          </ActionIcon> */}
          <ActionIcon className={classes.action} onClick={onEdit}>
            <IconEdit size={16} color={theme.colors.blue[7]} />
          </ActionIcon>
        </Group>
      </Group>
    </Card>
  );
}
import { ActionIcon, Button, LoadingOverlay } from "@mantine/core";
import { IconArchive, IconArrowBackUp, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { Vehicle } from "../../../models/vehicle.model";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import {
  useArchiveOwnerVehicleMutation,
  useDeleteOwnerVehicleMutation,
  useLazyGetArchivedOwnerVehiclesQuery,
  useLazyGetOwnerVehiclesQuery,
  useRestoreOwnerVehicleMutation
} from "../store/owner.query";

export default function OwnerVehiclesComponent() {
  const params = useParams();
  const location = useLocation();
  const [fetchedVehicles, setFetchedVehicles] = useState<any>();
  const [current, setCurrent] = useState<"active" | "archived">("active");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [archiveOwnerVehicle, ownerVehicleArchiveResponse] =
    useArchiveOwnerVehicleMutation();
  const [getOwnerVehicles, vehicles] = useLazyGetOwnerVehiclesQuery();
  const [deleteOwnerVehicle,deleteOwnerVehicleResponse] = useDeleteOwnerVehicleMutation();
  const [getArchivedOwnerVehicles, archivedVehicles] =
    useLazyGetArchivedOwnerVehiclesQuery();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [restoreOwnerVehicle, ownerVehicleRestoreResponse] =
    useRestoreOwnerVehicleMutation();

  useEffect(() => {
    getCategories({ skip: 0 }, true);
    if (current === "active") {
      getOwnerVehicles({
        ...collection,
        filter: [[{ field: "ownerId", value: `${params.id}`, operator: "=" }]],
      }).then((response) => setFetchedVehicles(response));
    } else {
      getArchivedOwnerVehicles({
        ...collection,
        filter: [[{ field: "ownerId", value: `${params.id}`, operator: "=" }]],
      }).then((response) => setFetchedVehicles(response));
    }
  }, [collection, params?.id, current]);

  const actions = (
    <>
      <div className="flex space-x-2 justify-end">
        <Button
          variant="default"
          loading={vehicles.isLoading}
          className={`${current === "active" && "bg-primary text-white"} `}
          type="button"
          classNames={{ label: "flex space-x-1" }}
          size={"xs"}
          onClick={() => setCurrent("active")}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current h-4"
              viewBox="0 0 50 50"
            >
              <path d="M25 2C12.309534 2 2 12.309534 2 25C2 37.690466 12.309534 48 25 48C37.690466 48 48 37.690466 48 25C48 12.309534 37.690466 2 25 2 z M 25 4C36.609534 4 46 13.390466 46 25C46 36.609534 36.609534 46 25 46C13.390466 46 4 36.609534 4 25C4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453L23.970703 30.476562L16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734L24.316406 33.525391L35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z" />
            </svg>
          </span>
          <span>Active</span>
        </Button>
        <Button
          variant="default"
          loading={archivedVehicles.isLoading}
          className={`${current === "archived" && "bg-primary text-white"} `}
          type="button"
          classNames={{ label: "flex space-x-1" }}
          size={"xs"}
          onClick={() => setCurrent("archived")}
        >
          <span>
            <IconArchive size={16} strokeWidth={1.5} />
          </span>
          <span>Archived</span>
        </Button>
      </div>
    </>
  );

  return (
    <Card title={"Vehicles"} action={actions}>
      <LoadingOverlay
        visible={
          current === "active"
            ? vehicles.isLoading|| vehicles?.isFetching || ownerVehicleArchiveResponse.isLoading
            : archivedVehicles.isLoading||archivedVehicles.isFetching ||ownerVehicleRestoreResponse.isLoading||deleteOwnerVehicleResponse.isLoading
        }
      />
      {location.pathname === "/owner/vehicles" ? (
        <div className="flex items-center justify-center">
          <span className="dark:text-white text-gray-700 font-semibold">
            Select an owner
          </span>
        </div>
      ) : (
        <div className="relative">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Plate Number
                </th>
                <th scope="col" className="py-3 px-6">
                  Model
                </th>
                <th scope="col" className="py-3 px-6">
                  Category
                </th>
                <th scope="col" className="py-3 px-6">
                  code
                </th>
                <th scope="col" className="py-3 px-6">
                  Color
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {fetchedVehicles?.isSuccess &&
                fetchedVehicles?.data.data.length > 0 &&
                fetchedVehicles?.data?.data.map(
                  (vehicle: Vehicle, idx: number) => (
                    <tr
                      key={idx}
                      className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {vehicle.plateNumber}
                      </th>
                      <td className="py-4 px-6 text-xs">{vehicle.model}</td>
                      <td className="py-4 px-6">
                        {
                          categories.data?.data.filter(
                            (category) => category.id === vehicle.categoryId
                          )?.[0]?.name
                        }
                      </td>
                      <td className="py-4 px-6">{`code${vehicle.code}`}</td>
                      <td className="py-4 px-6">{vehicle.color}</td>
                      <td className="py-4 px-2">
                        <div className="flex space-x-1 items-center">
                          {current === "active" ? (
                            <Confirmation
                              type={"notify"}
                              message={
                                "Are you sure you want to archive this vehicle?"
                              }
                              header={"Archive Confirmation"}
                              result={"single"}
                              resultRequired={true}
                              selectorLabel={"Please select a reason"}
                              labelDescription={
                                "Why are you archiving this vehicle?"
                              }
                              options={[
                                `It's not longer needed`,
                                `It is incorrect and can't update it`,
                              ].map((reason) => {
                                return { label: reason, value: reason };
                              })}
                              customInput={true}
                              yesText={"Archive"}
                              onYes={(reason: string) =>
                                archiveOwnerVehicle({
                                  id: vehicle.id,
                                  ownerId: params.id,
                                  reason: reason,
                                }).then((response: any) => {
                                  if (response) {
                                    const data =
                                      fetchedVehicles.data?.data.filter(
                                        (archivedVehicle: Vehicle) =>
                                          archivedVehicle.id !== vehicle.id
                                      );
                                    setFetchedVehicles({
                                      ...fetchedVehicles,
                                      data: { data: data },
                                    });
                                  }
                                })
                              }
                            >
                              <div className="px-2  flex justify-center items-center relative">
                                <ActionIcon variant="outline" color={"red"}>
                                  <IconArchive size={16} />
                                </ActionIcon>
                              </div>
                            </Confirmation>
                          ) : (
                            <>
                              <Confirmation
                                type={"notify"}
                                message={"Are you sure you want to restore it"}
                                onYes={() =>
                                  restoreOwnerVehicle({
                                    id: vehicle.id,
                                    ownerId: params.id,
                                  }).then((response) => {
                                    if (response) {
                                      const data =
                                        fetchedVehicles.data?.data.filter(
                                          (archivedVehicle: Vehicle) =>
                                            archivedVehicle.id !== vehicle.id
                                        );
                                      setFetchedVehicles({
                                        ...fetchedVehicles,
                                        data: { data: data },
                                      });
                                    }
                                  })
                                }
                                header="Restore confirmation"
                              >
                                <div className="px-2 bg-primary flex justify-center items-center rounded text-white cursor-pointer relative">
                                  <IconArrowBackUp
                                    size={20}
                                    strokeWidth={1.5}
                                  />
                                </div>
                              </Confirmation>

                              <Confirmation
                                type={"danger"}
                                message={
                                  "Are you sure you want to delete it permanently?"
                                }
                                onYes={() =>
                                  deleteOwnerVehicle({
                                    id: vehicle.id,
                                    ownerId: params.id,
                                  }).then((response) => {
                                    if (response) {
                                      const data =
                                        fetchedVehicles.data?.data.filter(
                                          (archivedVehicle: Vehicle) =>
                                            archivedVehicle.id !== vehicle.id
                                        );
                                      setFetchedVehicles({
                                        ...fetchedVehicles,
                                        data: { data: data },
                                      });
                                    }
                                  })
                                }
                                header="Permanent delete confirmation"
                              >
                                <div className="px-2 bg-danger flex justify-center items-center rounded text-white cursor-pointer">
                                  <IconTrash size={20} strokeWidth={1.5} />
                                </div>
                              </Confirmation>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
          {fetchedVehicles?.isLoading || !fetchedVehicles?.data?.data.length ? (
            <div className="w-full relative flex justify-center items-center h-56">
              <LoadingOverlay
                visible={fetchedVehicles?.isLoading}
                overlayBlur={2}
              />
              <EmptyIcon />
            </div>
          ) : (
            <></>
          )}
          {fetchedVehicles?.isSuccess &&
            fetchedVehicles?.data?.data?.length > 0 && (
              <div className="w-full flex justify-end items-center mt-2 mb-2">
                <Pagination
                  total={
                    fetchedVehicles?.data ? fetchedVehicles?.data?.count : 0
                  }
                  defaultPageSize={10}
                  pageSize={[10, 15, 20]}
                  onPaginationChange={(skip: number, top: number) => {
                    setCollection({ skip: skip, top: top, ...collection });
                  }}
                />
              </div>
            )}
        </div>
      )}
    </Card>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Blog } from "../../../models/blog.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { BLOG_ENDPOINT } from "../blog.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;
let blogDetail: string;

const newsQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getNews: builder.query<Collection<Blog>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${BLOG_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createNews: builder.mutation<Blog, any>({
      query: (data: Blog) => ({
        url: `${BLOG_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission: "manage-news",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            newsQuery.util.updateQueryData(
              "getNews",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count = draft.count + 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteNews: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${BLOG_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission: "manage-news",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            newsQuery.util.updateQueryData(
              "getNews",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((news) => news.id !== id);
                draft.count -= 1;
              }
            )
          );
          dispatch(
            newsQuery.util.updateQueryData(
              "getArchivedNews",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter((news) => news.id !== id);
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getNewsById: builder.query<Blog, string>({
      query: (id: string) => ({
        url: `${BLOG_ENDPOINT.news}/${id}`,
        method: "GET",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          blogDetail = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateNews: builder.mutation<Blog, any>({
      query: (data: Blog) => ({
        url: `${BLOG_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission: "manage-news",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            newsQuery.util.updateQueryData(
              "getNews",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((news) => {
                  if (news.id === data.id) return data;
                  else {
                    return news;
                  }
                });
              }
            )
          );
          dispatch(
            newsQuery.util.updateQueryData(
              "getNewsById",
              blogDetail,
              (draft) => {
                if(data){
                  Object.assign(draft,data);
                }
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveNews: builder.mutation<Blog, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${BLOG_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission: "manage-news",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;

          dispatch(
            newsQuery.util.updateQueryData(
              "getNews",
              currentCollection,
              (draft) => {
                draft.data = draft.data.map((news) => {
                  if (news.id !== data?.data?.id) {
                    return { ...news, deletedAt: data?.data?.deletedAt };
                  } else return news;
                });
              }
            )
          );
          dispatch(
            newsQuery.util.updateQueryData("getNewsById", arg.id, (draft) => {
              draft.deletedAt = data?.data?.deletedAt;
            })
          );
          dispatch(
            newsQuery.util.updateQueryData(
              "getArchivedNewsById",
              arg.id,
              (draft) => {
                draft.deletedAt = data?.data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedNews: builder.query<Collection<Blog>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${BLOG_ENDPOINT.archived_news}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedNewsById: builder.query<Blog, string>({
      query: (id: string) => ({
        url: `${BLOG_ENDPOINT.archived_news}/${id}`,
        method: "GET",
      }),
    }),
    restoreNews: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${BLOG_ENDPOINT.restore_news}/${id}`,
        method: "POST",
        permission: "manage-news",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            newsQuery.util.updateQueryData(
              "getArchivedNewsById",
              id,
              (draft) => {
                draft.deletedAt = data?.deletedAt ?? "";
              }
            )
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetNewsQuery,
  useLazyGetNewsByIdQuery,
  useCreateNewsMutation,
  useDeleteNewsMutation,
  useUpdateNewsMutation,
  useArchiveNewsMutation,
  useLazyGetArchivedNewsQuery,
  useRestoreNewsMutation,
  useLazyGetArchivedNewsByIdQuery,
} = newsQuery;

export const FAQCATEGORY_ENDPOINT = {
  list: `${process.env.REACT_APP_API}/faq-categories/get-faq-categories`,
  faq_category: `${process.env.REACT_APP_API}/faq-categories/get-faq-category`,
  archived_categories: `${process.env.REACT_APP_API}/faq-categories/get-archived-faq-categories`,
  archived_category: `${process.env.REACT_APP_API}/faq-categories/get-archived-faq-category`,
  create: `${process.env.REACT_APP_API}/faq-categories/create-faq-category`,
  delete: `${process.env.REACT_APP_API}/faq-categories/delete-faq-category`,
  update: `${process.env.REACT_APP_API}/faq-categories/update-faq-category`,
  archive: `${process.env.REACT_APP_API}/faq-categories/archive-faq-category`,

  restore_category: `${process.env.REACT_APP_API}/faq-categories/restore-faq-category`,
};

import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { Corporate } from "../../../models/corporate.model";
import { EntityConfig } from "../../../models/entity-config.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useDeleteCorporateMutation,
  useLazyGetArchivedCorporatesQuery,
  useRestoreCorporateMutation
} from "../store/corporate.query";

export default function CorporateEmployeeComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedCorporates, archivedCorporates] =
    useLazyGetArchivedCorporatesQuery();
  const [restoreCorporate, restoreCall] = useRestoreCorporateMutation();
  const [deleteCorporate, deleteCall] = useDeleteCorporateMutation();
  const [id, setId] = useState("");

  useEffect(() => {
    getArchivedCorporates(collection);
  }, [collection]);

  const data: Corporate[] | undefined = archivedCorporates.data?.data;
  const config: EntityConfig<Corporate> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/corporate",
    identity: "id",
    name: "name",

    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone" },
      { key: "tinNumber", name: "TIN Number" },
      {
        name: "Contact Person",
        key: ["contactPerson", "name"],
        render: (value: Corporate) => {
          return (
            value.contactPerson.name +
            " (" +
            value.contactPerson.phoneNumber +
            ")"
          );
        },
      },

      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      {
        key: "enabled",
        name: "Status",
      },
      {
        key: "",
        name: "Action",
        print:false,
        render: (corporate) => {
          return (
            <div className="flex flex-wrap space-y-1 space-x-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${corporate.id}`);
                  restoreCorporate(`${corporate.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <ActionIcon
                  variant="filled"
                  className="bg-primary text-white "
                  size={"sm"}
                  loading={restoreCall.isLoading && `${corporate.id}` === id}
                >
                  <IconArrowBackUp size={16} strokeWidth={1.5} />
                </ActionIcon>
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${corporate.id}`);
                  deleteCorporate(`${corporate?.id}`).then(() => setId(""));
                }}
              >
                <ActionIcon
                  variant="filled"
                  className="bg-danger text-white"
                  size={"sm"}
                  loading={deleteCall.isLoading && `${corporate.id}` === id}
                >
                  <IconTrash size={16} strokeWidth={1.5} />
                </ActionIcon>
              </Confirmation>

              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  size={"sm"}
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: corporate?.name,
                      reason: corporate?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Corporates"}
        total={archivedCorporates?.data?.count}
        itemsLoading={
          archivedCorporates.isFetching ||
          archivedCorporates?.isLoading ||
          restoreCall?.isLoading ||
          deleteCall?.isLoading
        }
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber", "tinNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />

      {/* Archive Modal */}
      <Modal
        opened={archiveReason?.opened}
        onClose={() => setArchiveReason({ ...archiveReason, opened: false })}
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason, opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

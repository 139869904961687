export const VEHICLE_ENDPOINT={
    list:`${process.env.REACT_APP_API}/owners/get-vehicles`,
    vehicle:`${process.env.REACT_APP_API}/owners/get-vehicle`,
    create:`${process.env.REACT_APP_API}/owners/add-owner-vehicle`,
    delete:`${process.env.REACT_APP_API}/owners/remove-owner-vehicle`,
    update:`${process.env.REACT_APP_API}/owners/update-owner-vehicle`,
    archive:`${process.env.REACT_APP_API}/owners/archive-owner-vehicle`,
    archived_vehicle:`${process.env.REACT_APP_API}/owners/get-archiver-vehicle`,
    archived_owners_vehicles:`${process.env.REACT_APP_API}/owners/get-archived-vehicles`,
    vehicles_by_category:`${process.env.REACT_APP_API}/owners/get-vehicles-by-category`,
    restore_vehicle:`${process.env.REACT_APP_API}/owners/restore-owner-vehicle`,
    activate_vehicle:`${process.env.REACT_APP_API}/owners/activate-or-block-vehicle`,
    upload_document:`${process.env.REACT_APP_API}/owners/update-vehicle-document`,
}
import { Button, HoverCard, Text } from "@mantine/core";
import { IconArrowBackUp, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { Job } from "../../../models/job.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useDeleteJobMutation, useLazyGetArchivedJobsQuery, useRestoreJobMutation } from "../store/job.query";

export default function ArchivedJobComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [getArchivedJobs, archivedJobs] = useLazyGetArchivedJobsQuery();
  const [restoreJob, restoreCall] = useRestoreJobMutation();
  const [deleteJob, deleteCall] = useDeleteJobMutation();
  const [id, setId] = useState("");

  useEffect(() => {
    getArchivedJobs(collection);
  }, [collection]);

  const data: Job[] | undefined = archivedJobs.data?.data;

  const config: EntityConfig<Job> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/school/archived",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: ["group", "name"], name: "Group Name" },
      { key: "startLocation", name: "Start Location" },
      { key: "description", name: "Description" },
      {
        key: "status",
        name: "Status",
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      {
        key: "",
        name: "Archive Reason",
        render: (value) => {
          return (
              <HoverCard width={280} shadow="md">
                <HoverCard.Target>
                  <Text size="sm" className={"cursor-pointer"}>
                    {value.archiveReason && value.archiveReason.length > 25
                        ? value.archiveReason.substring(0, 22) + "..."
                        : value.archiveReason}
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown
                    className={"text-justify break-all wrap max-h-60 overflow-auto"}
                >
                  <Text size="sm">{value?.archiveReason}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
          );
        },
      },
      {
        key: "",
        name: "Action",
        render: (job) => {
          return (
            <div className="flex-col space-y-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${job.id}`);
                  restoreJob(`${job.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <Button
                  variant="filled"
                  className="bg-primary text-white w-16"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={restoreCall.isLoading && `${job.id}` === id}
                >
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </Button>
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${job.id}`);
                  deleteJob(`${job?.id}`).then(() => setId(""));
                }}
              >
                <Button
                  variant="filled"
                  className="bg-danger text-white w-16"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={deleteCall.isLoading && `${job.id}` === id}
                >
                  <IconTrash size={20} strokeWidth={1.5} />
                </Button>
              </Confirmation>
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className={"w-full"}>
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Jobs"}
        total={archivedJobs?.data?.count}
        itemsLoading={archivedJobs.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

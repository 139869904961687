
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../component/header.component";
import SideNav from "../component/side-nav.component";

export default function Dashboard() {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [loading,setLoading]=useState(true);

  useEffect(()=>{
   setTimeout(()=>{
    setLoading(false);
   },1000)
  },[])
  return (
    
    <div className="flex h-full relative ">
      <div className={`h-full fixed top-0 left-0 z-40 ${collapse ? 'w-14':'w-56'} duration-500`}>
        <SideNav collapse={collapse}/>
      </div>
      <div className={`h-full dark:bg-gray-700 overflow-y-auto fixed duration-500 ${collapse ?'left-14 right-0':'left-56 right-0'}`} >
        <Header onCollapse={()=>setCollapse(!collapse)} />
        <div className="w-full items-center justify-center min-h-screen">
         {!loading &&  <Outlet />}
        </div>
      </div>
    </div>
  );
}

import { Address } from "./address.model";

export interface Parent {
    id: string;
    name: string;
    email: string | null;
    phoneNumber: string;
    gender: string;
    address: Address;
    enabled?: boolean;

    parentType?: RidePreference;
    parentPreferences?: { days?: string[]}[];
    
    status: ParentStatus;
    distanceFromSchool: number;
    totalPayment?: number;
    lng: number;
    lat: number;
    archiveReason?: string;
    createdBy?: string;
    updatedBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    deletedBy?: string;
}

export enum ParentStatus {
    New = 'New',
    Assigned = 'Assigned',
    PartiallyAssigned = 'Partially_Assigned',
    WaitingToBeAssigned = 'Waiting_To_Be_Assigned',
    Declined = 'Declined',
    Agreed = 'Agreed',
    ContractSigned = 'Contract_Signed',
  }


export enum RidePreference {
    Both = 'Both',
    OneTime = 'OneTime',
    Monthly = "Monthly"
}
export const WALLET_ENDPOINT={
    list:`${process.env.REACT_APP_API}/wallets/get-wallets`,
    corporate_wallets:`${process.env.REACT_APP_API}/corporate-wallets/get-wallets`,
    archived_wallets:`${process.env.REACT_APP_API}/wallets/get-archived-wallets`,
    passenger_wallet:`${process.env.REACT_APP_API}/wallets/get-passenger-wallet`,
    corporate_wallet:`${process.env.REACT_APP_API}/corporate-wallets/get-corporate-wallet`,
    passenger_transactions:`${process.env.REACT_APP_API}/transactions/get-passenger-transactions`,
    corporate_transactions:`${process.env.REACT_APP_API}/corporate-transactions/get-corporate-transaction`,
    add_balance_to_corporate:`${process.env.REACT_APP_API}/corporate-wallets/update-wallet`,
    add_balance_to_passenger:`${process.env.REACT_APP_API}/wallets/update-wallet`,
    
}
import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import { Parent, ParentStatus, RidePreference } from "../../../models/parent.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { useLazyGetParentsQuery } from "../store/parent.query";

export default function ParentPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ['parentPreferences'],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getParents, parents] = useLazyGetParentsQuery();

  useEffect(() => {
    getParents(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/parent/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Parent[] | undefined = parents.data?.data;

  const config: EntityConfig<Parent> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/registration/parent",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "name",
        name: "Name",
        render: (parent) => {
          return (
            <Text size="sm" className={parent?.deletedAt ? "text-danger" : ""}>
              {parent.name}
            </Text>
          );
        },
      },
      // { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone" },
      {
        key: "gender",
        name: "Gender",
        render: (value) => {
          if (value.gender) {
            return value.gender === "male" ? "Male" : "Female";
          } else {
            return "Unknown";
          }
        },
      },
      // { key: ["address", "subCity"], name: "Sub City" },
      { key: ["address", "woreda"], name: "Woreda" },
      { key: ["address", "houseNumber"], name: "Specific Location" },
      { key: "parentType", name: "Ride Preference" },
      // { key: "parentType", name: "Date Preference", render(value) {

      //   let daysArr: string[] = [];
        
      //     if(value.parentPreferences && Array.isArray(value.parentPreferences) && value.parentPreferences.length > 0 && value.parentPreferences[0]?.days){
      //       // daysArr.push(daysNameConvertor())

      //         value.parentPreferences[0].days.map((day: string) => daysArr.push(daysNameConvertor(day)))
      //     }
        
      //    return <>
      //     <List size={"xs"} withPadding>
      //       {daysArr.map((day: string) => (
      //         <List.Item>{day}</List.Item>
      //       ))}
      //     </List>
      //    </>
      // }, },
      { key: "status", name: "Assignment Status" },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "enabled", name: "Status" },
    ],
    filter: [
      [
        {
          field: "status",
          value: ParentStatus.New,
          name: "New",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.Agreed,
          name: "Agreed",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.Declined,
          name: "Declined",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.ContractSigned,
          name: "Contract Signed",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.WaitingToBeAssigned,
          name: "Waiting to be assigned",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.PartiallyAssigned,
          name: "Partially Assigned",
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.Assigned,
          name: "Assigned",
          operator: "=",
        },
      ],
      [
        { field: "parentType", value: RidePreference.Both, name: "Both",  operator: "=" },
        { field: "parentType", value: RidePreference.Monthly, name: "Monthly",  operator: "=" },
        { field: "parentType", value: RidePreference.OneTime, name: "One Time", operator: "=" },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        title={"Parents"}
        total={parents?.data?.count}
        itemsLoading={parents.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onArchivedChecked={(e) =>
          setCollection({
            ...collection,
            withArchived: e,
          })
        }
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

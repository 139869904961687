import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedBankAccountComponent from "../component/archived-bank-account-component";
import ArchivedProviderComponent from "../component/archived-provider-component";
import BankAccountFormComponent from "../component/bank-account-form.component";
import BankAccountList from "../component/bank-account-list.component";
import NewProviderComponent from "../component/new-provider-component";
import ProviderPage from "../page/provider-page";
export const ProviderRoute: RouteObject = {
  path: "/registration/provider",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <ProviderPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewProviderComponent editMode="detail" /> },
    { path: ":id", element: <NewProviderComponent editMode="new" /> },
    { path: "archived", element: <ArchivedProviderComponent /> },
  ],
};
export const BankRoute: RouteObject = {
  path: "/registration/provider/bank/accounts",
  element: <BankAccountList />,
  children: [
    {
      path: "detail/:id",
      element: <BankAccountFormComponent editMode="detail" />,
    },
    { path: ":id", element: <BankAccountFormComponent editMode="new" /> },
    { path: "archived", element: <ArchivedBankAccountComponent /> },
  ],
};

import { Center, LoadingOverlay, MantineProvider } from "@mantine/core";
import { NavigationProgress } from "@mantine/nprogress";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import {
  AccountRoute,
  ChangePasswordRoute,
  UpdatePasswordRoute,
} from "./feature/account/route/account.route";
import { ActivityRoute } from "./feature/activity/route/activity.route";
import { BlogRoute } from "./feature/blog/route/blog.route";
import { BookingRoute } from "./feature/booking/route/booking.route";
import { BusStopRoute } from "./feature/bus_stop/route/bus-stop.route";
import { CategoryRoute } from "./feature/category/route/category.route";
import { ChatRoute } from "./feature/chat/route/chat.route";
import { CityRoute } from "./feature/city/route/city.route";
import { ConfigurationRoute } from "./feature/configuration/route/configuration.route";
import {
  CorporateRoute,
  CorporateTransactionRoute,
} from "./feature/corporate/route/corporate.route";
import {
  AssignmentDashboardRoute,
  BookingDashboardRoute,
  CorporateDashboardRoute,
  DashBoardRoute,
  EarningDashboardRoute,
  KabbaKidDashboardRoute,
  KidsEarningDashboardRoute,
  OwnerDashboardRoute,
  PassengersDashboardRoute,
  UsersDashboardRoute,
  VehiclesDashboardRoute,
} from "./feature/dashboard/route/dashboard.route";
import { FeedbackRoute } from "./feature/feedback/route/feedback.route";
import { GroupRoute } from "./feature/group/route/group.route";
import { JobRoute } from "./feature/job/route/job.route";
import { NotificationRoute } from "./feature/notification/route/notification.route";
import { OwnerRoute } from "./feature/owner/route/owner.route";
import { ParentRoute } from "./feature/parent/route/parent.route";
import { PassengerRoute } from "./feature/passenger/route/passenger.route";
import {
  BankRoute,
  ProviderRoute,
} from "./feature/provider/route/provider.route";
import { RequestRoute } from "./feature/request/route/request.route";
import { RoleRoute } from "./feature/role/route/role-route";
import { RouteAssignmentRoute } from "./feature/route-assignment/route/route-assignment.route";
import {
  CustomRoutesRoute,
  RoutesRoute,
} from "./feature/route/route/route.route";
import { SchoolRoute } from "./feature/school/route/school.route";
import { ServiceRoute } from "./feature/service/route/service.route";

import { FaqCategoryRoute } from "./feature/faq-category/route/faq-category.route";
import { FaqRoute } from "./feature/faq/route/faq.route";
import { TripRoute } from "./feature/trip/route/trip.route";
import { UserRoute } from "./feature/user/route/user.route";
import { VehicleRoute } from "./feature/vehicle/route/vehicle.route";
import { WalletRoute } from "./feature/wallet/route/wallet.route";
import Dashboard from "./layout/page/dashboard.page";
import AuthGuard from "./shared/auth/component/auth";
import Login from "./shared/auth/component/login/login-component";
import { AuthContextProvider } from "./shared/auth/context/authContext";
import NotFound from "./shared/component/404-not-found/404-not-found";
import InternetConnectionStatus from "./shared/component/internet-connection-status/internet-connection-status";
import { RootState } from "./store/app.store";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContextProvider>
          <Suspense fallback={<LoadingOverlay visible={true} />}>
            <AuthGuard>
              <Dashboard />
            </AuthGuard>
          </Suspense>
        </AuthContextProvider>
      ),
      children: [
        { path: "*", element: <NotFound /> },
        JobRoute,
        BlogRoute,
        RoleRoute,
        UserRoute,
        BankRoute,
        CityRoute,
        GroupRoute,
        FaqCategoryRoute,
        FaqRoute,
        OwnerRoute,
        SchoolRoute,
        RoutesRoute,
        CustomRoutesRoute,
        WalletRoute,
        ParentRoute,
        TripRoute,
        VehicleRoute,
        BookingRoute,
        ServiceRoute,
        BusStopRoute,
        FeedbackRoute,
        ProviderRoute,
        ActivityRoute,
        CategoryRoute,
        PassengerRoute,
        CorporateRoute,
        DashBoardRoute,
        ConfigurationRoute,
        OwnerDashboardRoute,
        UsersDashboardRoute,
        RouteAssignmentRoute,
        BookingDashboardRoute,
        KabbaKidDashboardRoute,
        VehiclesDashboardRoute,
        CorporateDashboardRoute,
        PassengersDashboardRoute,
        AssignmentDashboardRoute,
        CorporateTransactionRoute,
        CorporateDashboardRoute,
        PassengersDashboardRoute,
        EarningDashboardRoute,
        KidsEarningDashboardRoute,
        UserRoute,
        ActivityRoute,
        PassengerRoute,
        CorporateRoute,
        VehicleRoute,
        CityRoute,
        RouteAssignmentRoute,
        RoutesRoute,
        BusStopRoute,
        CategoryRoute,
        OwnerRoute,
        ProviderRoute,
        BankRoute,
        WalletRoute,
        ServiceRoute,
        BookingRoute,
        CorporateTransactionRoute,
        SchoolRoute,
        ParentRoute,
        GroupRoute,
        JobRoute,
        ConfigurationRoute,
        FeedbackRoute,
        NotificationRoute,
        ChatRoute,
        RequestRoute,
      ],
    },
    AccountRoute,
    ChangePasswordRoute,
    UpdatePasswordRoute,
    {
      path: "/login",
      element: (
        <AuthContextProvider>
          <Login />
        </AuthContextProvider>
      ),
    },
    { path: "*", element: <NotFound /> },
  ]);
  const themeClass = useSelector(
    (state: RootState) => state.themeReducer.class
  );
  return (
    <>
      {loading ? (
        <Center>
          <LoadingOverlay visible={true} />
        </Center>
      ) : (
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: themeClass === "dark" ? "dark" : "light",
            colors: {
              dark: [
                "#d5d7e0",
                "#acaebf",
                "#8c8fa3",
                "#666980",
                "#4d4f66",
                "#34354a",
                "#2b2c3d",
                "#1d1e30",
                "#0c0d21",
                "#01010a",
              ],
              //    Optional Dark Mode: #374151 : #1d1e30,
            },
          }}
        >
          <NavigationProgress />

          <div className={themeClass}>
            <RouterProvider router={router} />
            <InternetConnectionStatus />
          </div>
        </MantineProvider>
      )}
    </>
  );
}

export default App;

import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NotificationPage from "../page/notification-page";
export const NotificationRoute: RouteObject = {
  path: "/notification",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <NotificationPage />
    </AuthGuard>
  ),
};

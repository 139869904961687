/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../models/user.model";
import { userInfo } from "../api/auth.api";

const userDefault: User = {
  id: "",
  email: "",
  name: "",
  enabled: false,
  phoneNumber: "",
  accountId: "",
  gender: "",
  type: "",
  profileImage: {
    filename: "",
    mimetype: "",
    originalname: "",
    size: 0,
    path:''
  },
  address: {
    country: "",
    city: "",
    subCity: "",
    woreda: "",
    houseNumber: "",
  },
  emergencyContact: {
    name: "",
    phoneNumber: ""
  },
  role: [""],
};

const AuthContext = React.createContext({
  user: userDefault,
  authenticated: false,
  login: (account: any) => {},
  logOut: () => {},
});

export const AuthContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [context,setContext] = useState({
    user: userDefault,
    authenticated: false,
    login: Login,
    logOut: logOut,
  })

  useEffect(() => {
    async function init() {
      if (await localStorage.userInfo !== undefined) {
        setContext({...context,user:JSON.parse(await localStorage.userInfo),authenticated:true});
      }
    }
    init();
  }, []);

  async function Login(account: any) {
    const data = await userInfo(account);
    if (data?.id) {
      setContext({...context,user:data,authenticated:true})
      navigate('/')
    }
  }
  
  async function logOut() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    setContext({...context,user:userDefault,authenticated:false});
    navigate('/login');
  }



  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
export default AuthContext;

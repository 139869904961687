import { IconArrowBackUp, IconTrash } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { City } from "../../../models/city.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import {
    useDeleteGroupMutation,
    useLazyGetArchivedGroupsQuery, useRestoreGroupMutation
} from "../store/group.query";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { EntityConfig } from "../../../models/entity-config.model";
import { Button, HoverCard, Text } from "@mantine/core";
import {Group} from "../../../models/group.model";

export default function ArchivedGroupComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [getArchivedGroups, archivedGroups] = useLazyGetArchivedGroupsQuery();
  const [restoreGroup, restoreCall] = useRestoreGroupMutation();
  const [deleteGroup, deleteCall] = useDeleteGroupMutation();
  const [id, setId] = useState("");

  useEffect(() => {
    getArchivedGroups(collection);
  }, [collection]);

  const data: Group[] | undefined = archivedGroups.data?.data;

  const config: EntityConfig<Group> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/group",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      {
        key: "numberOfSeat",
        name: "Number of seat",
      },{
        key: "availableSeats",
        name: "Available Seats",
      },
      { key: "createdAt", name: "Registered At", isDate: true },
      { key: "deletedAt", name: "Archived At", isDate: true },
      {
        key: "",
        name: "Archive Reason",
        render: (value) => {
          return (
            <HoverCard width={280} shadow="md">
              <HoverCard.Target>
                <Text size="sm" className={"cursor-pointer"}>
                  {value.archiveReason && value.archiveReason.length > 25
                    ? value.archiveReason.substring(0, 22) + "..."
                    : value.archiveReason}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown
                className={"text-justify break-all wrap max-h-60 overflow-auto"}
              >
                <Text size="sm">{value?.archiveReason}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        key: "",
        name: "Action",
        render: (group) => {
          return (
            <div className="flex-col space-y-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${group.id}`);
                  restoreGroup(`${group.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <Button
                  variant="filled"
                  className="bg-primary text-white w-16"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={restoreCall.isLoading && `${group.id}` === id}
                >
                  <IconArrowBackUp size={20} strokeWidth={1.5} />
                </Button>
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${group.id}`);
                  deleteGroup(`${group?.id}`).then(() => setId(""));
                }}
              >
                <Button
                  variant="filled"
                  className="bg-danger text-white w-16"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={deleteCall.isLoading && `${group.id}` === id}
                >
                  <IconTrash size={20} strokeWidth={1.5} />
                </Button>
              </Confirmation>
            </div>
          );
        },
      },
    ],
  };

  return (
    <div className={"w-full"}>
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Cities"}
        total={archivedGroups?.data?.count}
        itemsLoading={archivedGroups.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { RoutePrice } from "../../../models/route-price.model";
import { RouteStation } from "../../../models/route-station.model";
import { Route, UpdateRouteTime } from "../../../models/route.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { ROUTE_ENDPOINT } from "../route.endpoint";

let routeCollection: CollectionQuery;
let routeAssignmentCollection: CollectionQuery;
let archivedRouteCollection: CollectionQuery;
let routeBusStationsCollection: { query: CollectionQuery; id: string };
let routePricesCollection: { query: CollectionQuery; routeId: string };

const routeQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.archivedRoute}/${id}`,
        method: "get",
      }),
    }),

    getArchivedRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROUTE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedRouteCollection = param;
          }
        } catch {}
      },
    }),

    getRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROUTE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeCollection = param;
          }
        } catch {}
      },
    }),

    getRoutePrices: builder.query<
      Collection<RoutePrice>,
      { query: CollectionQuery; routeId: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_prices}/${data.routeId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routePricesCollection = param;
          }
        } catch {}
      },
    }),

    getRouteStations: builder.query<
      Collection<RouteStation>,
      { query: CollectionQuery; id: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_stations}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeBusStationsCollection = param;
          }
        } catch {}
      },
    }),

    createRoute: builder.mutation<Route, Route>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRoute: builder.mutation<Route, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateOrBlockRoute: builder.mutation<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.activate_or_block_route}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoute",
                `${param}`,
                (draft) => {
                  draft.isActive = data.isActive;
                }
              )
            );
            notification("success", "Successfully updated status");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveRoute: builder.mutation<Route, { id: string; reason: string }>({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.archive}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  draft.data = draft?.data?.map((route) => {
                    if (route.id === arg.id) {
                      return { ...data, city: route?.city };
                    } else {
                      return route;
                    }
                  });
                }
              )
            );
            dispatch(
              routeQuery.util.updateQueryData("getRoute", arg?.id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              routeQuery.util.updateQueryData(
                "getArchivedRoute",
                arg?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRoute: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.delete}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeQuery.util.updateQueryData(
                "getArchivedRoutes",
                archivedRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((route) => route.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreRoute: builder.mutation<Route, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.restore}/${id}`,
        method: "post",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  draft.data = draft?.data?.map((route) => {
                    if (route.id === id) {
                      return data;
                    } else {
                      return route;
                    }
                  });
                }
              )
            );
            dispatch(
              routeQuery.util.updateQueryData("getRoute", id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              routeQuery.util.updateQueryData(
                "getArchivedRoute",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addRoutePrice: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_prices}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreRoutePrice: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.restore_route_price}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully restored");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutePrices",
                routePricesCollection,
                (draft) => {
                  draft.data = draft?.data?.map((route) => {
                    if (route.id === data.id) {
                      return { ...data, category: route.category };
                    } else {
                      return route;
                    }
                  });
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRoutePrice: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_route_price}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutePrices",
                routePricesCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id)
                        return { ...data, category: route.category };
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreDriverCommission: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.restore_driver_commission}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully restored");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutePrices",
                routePricesCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id)
                        return { ...data, category: route.category };
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateDriverCommission: builder.mutation<RoutePrice, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_driver_commission}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutePrices",
                routePricesCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id)
                        return { ...data, category: route.category };
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_assignment}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addBulkRouteAssignment: builder.mutation<RouteAssignment[], any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_bulk_route_assignment}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    data.map((item) => {
                      draft.data.push(item);
                    });
                    draft.count += data.length;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    addRouteStations: builder.mutation<RouteStation, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_stations}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            // used by new-route-component to ignore notification when we are setting the route stations in the background
            if (!(param.stations && param.stations.length === 0)) {
              notification("success", "Successfully added");
            }
          }
        } catch {
          // used by new-route-component to ignore notification when we are setting the route stations in the background
          if (!(param.stations && param.stations.length === 0)) {
            notification("error", "Unable to add");
          }
        }
      },
    }),

    updateRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_route_assignment}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateRouteTime: builder.mutation<Route, UpdateRouteTime>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_time}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRoutes",
                routeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    cancelRouteAssignment: builder.mutation<
      RouteAssignment,
      { id: string; cancelledReason: { reason: string } }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.cancel_route_assignment}`,
        method: "post",
        data: data,
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRouteAssignment: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.delete_route_assignment}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (assignment) => assignment.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getRouteAssignments: builder.query<
      Collection<RouteAssignment>,
      CollectionQuery
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignments}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeAssignmentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getRouteDrivers: builder.query<
      Collection<Provider>,
      { query: CollectionQuery; routeId: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_drivers}/${data.routeId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetRouteQuery,
  useLazyGetRouteStationsQuery,
  useLazyGetRoutePricesQuery,
  useLazyGetArchivedRouteQuery,
  useLazyGetRoutesQuery,
  useLazyGetArchivedRoutesQuery,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useArchiveRouteMutation,
  useActivateOrBlockRouteMutation,
  useRestoreRouteMutation,
  useDeleteRouteMutation,
  useAddRoutePriceMutation,
  useUpdateRoutePriceMutation,
  useRestoreRoutePriceMutation,

  useUpdateDriverCommissionMutation,
  useRestoreDriverCommissionMutation,

  useAddBulkRouteAssignmentMutation,
  useAddRouteAssignmentMutation,
  useCancelRouteAssignmentMutation,
  useAddRouteStationsMutation,
  useUpdateRouteAssignmentMutation,
  useDeleteRouteAssignmentMutation,
  useLazyGetRouteAssignmentsQuery,
  useLazyGetRouteDriversQuery,
  useUpdateRouteTimeMutation,
} = routeQuery;

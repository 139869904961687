/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { CustomRoute } from "../../../models/custom-route.model";
import { Route } from "../../../models/route.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CUSTOMROUTE_ENDPOINT } from "../custom-route.endpoint";

let customRouteCollection: CollectionQuery;

let archivedCustomRouteCollection: CollectionQuery;

const customRouteQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${CUSTOMROUTE_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedCustomRoute: builder.query<Route, string>({
      query: (id: string) => ({
        url: `${CUSTOMROUTE_ENDPOINT.archivedCustomRoute}/${id}`,
        method: "get",
      }),
    }),
    getGroupedCustomRoutes: builder.query<
      Collection<CustomRoute>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${CUSTOMROUTE_ENDPOINT.groupedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            // customRouteCollection = param;
          }
        } catch {}
      },
    }),

    getArchivedCustomRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CUSTOMROUTE_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedCustomRouteCollection = param;
          }
        } catch {}
      },
    }),

    getCustomRoutes: builder.query<Collection<Route>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${CUSTOMROUTE_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            customRouteCollection = param;
          }
        } catch {}
      },
    }),

    createCustomRoute: builder.mutation<Route, Route>({
      query: (newData: any) => ({
        url: `${CUSTOMROUTE_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getCustomRoutes",
                customRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateCustomRoute: builder.mutation<Route, any>({
      query: (newData: any) => ({
        url: `${CUSTOMROUTE_ENDPOINT.update}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getCustomRoutes",
                customRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === data.id) return data;
                      else {
                        return route;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveCustomRoute: builder.mutation<Route, { id: string; reason: string }>(
      {
        query: (data) => ({
          url: `${CUSTOMROUTE_ENDPOINT.archive}`,
          method: "delete",
          data: data,
        }),

        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              dispatch(
                customRouteQuery.util.updateQueryData(
                  "getCustomRoutes",
                  customRouteCollection,
                  (draft) => {
                    draft.data = draft?.data?.map((route) => {
                      if (route.id === arg.id) {
                        return { ...data, city: route?.city };
                      } else {
                        return route;
                      }
                    });
                  }
                )
              );
              dispatch(
                customRouteQuery.util.updateQueryData(
                  "getCustomRoute",
                  arg?.id,
                  (draft) => {
                    draft.deletedAt = data?.deletedAt;
                  }
                )
              );
              dispatch(
                customRouteQuery.util.updateQueryData(
                  "getArchivedCustomRoute",
                  arg?.id,
                  (draft) => {
                    draft.deletedAt = data?.deletedAt;
                  }
                )
              );
              notification("success", "Successfully archived");
            }
          } catch (error: any) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        },
      }
    ),

    deleteCustomRoute: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CUSTOMROUTE_ENDPOINT.delete}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getArchivedCustomRoutes",
                archivedCustomRouteCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((route) => route.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreCustomRoute: builder.mutation<Route, string>({
      query: (id: string) => ({
        url: `${CUSTOMROUTE_ENDPOINT.restore}/${id}`,
        method: "post",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getCustomRoutes",
                customRouteCollection,
                (draft) => {
                  draft.data = draft?.data?.map((route) => {
                    if (route.id === id) {
                      return data;
                    } else {
                      return route;
                    }
                  });
                }
              )
            );
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getCustomRoute",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              customRouteQuery.util.updateQueryData(
                "getArchivedCustomRoute",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetCustomRouteQuery,
  useLazyGetGroupedCustomRoutesQuery,
  useLazyGetArchivedCustomRouteQuery,
  useLazyGetCustomRoutesQuery,
  useLazyGetArchivedCustomRoutesQuery,
  useCreateCustomRouteMutation,
  useUpdateCustomRouteMutation,
  useArchiveCustomRouteMutation,

  useRestoreCustomRouteMutation,
  useDeleteCustomRouteMutation,
} = customRouteQuery;

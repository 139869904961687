import {Container, Group, Stack} from "@mantine/core";
import {TodayReport} from "../component/TodayReport";
import {Summary} from "../component/Summary";

export default function DashBoardComponent() {
  return (
    <Container size={"xl"} p={"lg"} className={"mt-6 mb-12"}>
       <Stack p={0}>
           <TodayReport />
           <Summary />
       </Stack>
    </Container>
  );
}

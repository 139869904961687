import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import NewCategoryComponent from "../component/new-category-component";
import CategoryPage from "../page/category-page";
export const CategoryRoute: RouteObject = {
  path: "/settings-and-configurables/category",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <CategoryPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewCategoryComponent editMode="detail" /> },
    { path: ":id", element: <NewCategoryComponent editMode="new" /> },
    
  ],
};

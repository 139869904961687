import { RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import ArchivedVehiclesComponent from "../component/archived-vehicle-list.component";
import NewVehicleComponent from "../component/new-vehicle.component";
import VehicleListPage from "../page/vehicle.list";

export const VehicleRoute: RouteObject = {
  path: "/registration/vehicle",
  element: (
    <AuthGuard role={['super_admin','admin','operator']}>
      <VehicleListPage />
    </AuthGuard>
  ),
  children: [
    { path: "detail/:type/:id", element: <NewVehicleComponent editMode="detail" /> },
    { path: ":id", element: <NewVehicleComponent editMode="new" /> },
    { path: "archive", element: <ArchivedVehiclesComponent  /> },
  ],
};

import { Group, LoadingOverlay, Radio, Text } from "@mantine/core";
import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { NumericFormat } from "react-number-format";
import { CollectionQuery } from "../../../models/collection.model";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { StatsRing } from "../component/status-ring";
import {
  useLazyGetBookingValueQuery,
  useLazyGetBookingsQuery,
  useLazyGroupBookingsByCreatedMonthQuery,
  useLazyGroupBookingsByStatusQuery,
} from "../store/dashboard.query";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface StatsRingProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
    icon: "up" | "down";
  }[];
}
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Vehicles Status",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const active = [100, 500, 550, 635, 500, 600, 900];
const deactive = [10, 3, 200, 100, 4, 14, 62];

export const data = {
  labels,
  datasets: [
    {
      label: "Active",
      data: labels.map((_, index) => active[index]),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Inactive",
      data: labels.map((_, deactiveIndex) => deactive[deactiveIndex]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const colorPicker = (code: string) => {
  switch (code) {
    case "Created":
      return "blue";
    case "Boarded":
      return "yellow";
    case "Arrived":
      return "green";
    case "Cancelled":
      return "red";
    case "Missed":
      return "black";
    default:
      return "pink";
  }
};

export default function BookingDashboardPage() {
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const [donut_state_data, setDonutData] = useState<any>();
  const [barFilter, setBarFilter] = useState<any>("Created");
  const [bar_state_data, setBarData] = useState<any>();
  const [passengersCircleProgress, setOwnersCircleProgress] = useState<any[]>(
    []
  );
  const [collection, setCollection] = useState<CollectionQuery>({
    filter: [[{ field: "status", value: barFilter, operator: "=" }]],
  });

  const [groupBookingByCreatedMonth, bookingOnCreatedMonth] =
    useLazyGroupBookingsByCreatedMonthQuery();
  const [getBookings, bookings] = useLazyGetBookingsQuery();
  const [groupBookingsByStatus, statusGroupedBookings] =
    useLazyGroupBookingsByStatusQuery();
  const [getBookingValue, bookingValue] = useLazyGetBookingValueQuery();

  useEffect(() => {
    getBookingValue({ skip: 0 });
    getBookings({ groupBy: ["status"], count: true });
    getBookings({
      skip: 0,
      top: 6,
      orderBy: [{ field: "createdAt", direction: "desc" }],
    }).then((passengerResponse) => {
      groupBookingsByStatus({
        groupBy: ["status"],
      }).then((response) => {
        if (response?.data && passengerResponse?.data) {
          let total = passengerResponse?.data?.count ?? 1;

          const data = response.data.map((item: any) => {
            return {
              label: item.createdAt === "Created" ? "Active" : item.createdAt,
              stats: item.count,
              progress: (item.count / total) * 100,
              color: colorPicker(item.createdAt),
              icon:
                item.createdAt === "Cancelled" || item.createdAt === "Missed"
                  ? "down"
                  : "up",
            };
          });
          setOwnersCircleProgress(data);
        }
      });
    });
  }, []);

  useEffect(() => {
    let labels: string[];
    let data: number[];
    async function groupBookingsByStatusAndCReatedMonth() {
      const response = await groupBookingByCreatedMonth({
        format: createdDateFormat,
        collectionQ: {
          ...collection,
          filter: [[{ field: "status", value: barFilter, operator: "=" }]],
        },
      });
      if (response?.data) {
        // labels = response?.data?.map((item: any) =>
        //   dateFormat(item.createdAt, {
        //     hour: false,
        //     hour12: false,
        //     weekday: false,
        //     minute: false,
        //     day: false,
        //   })
        // );
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? dateFormat(item.createdAt, {
                hour: false,
                hour12: false,
                weekday: false,
                minute: false,
                day: false,
              })
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response?.data?.map((item: any) => item.count);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Bookings",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    groupBookingsByStatusAndCReatedMonth();
  }, [barFilter, createdDateFormat]);

  return (
    <div className="w-full p-4">
      <div className="flex w-full h-16 px-2 justify-between items-center mt-2 border border-sky-300 mb-2">
        <span className="capitalize font-semibold text-primary">
          Booking dashboard report
        </span>
        <div className="flex-col space-y-1">
          <div className="capitalize font-semibold text-primary">
            {"Total: "}
            {bookings?.data?.count}
          </div>
          <div className="capitalize font-semibold text-primary">
            <NumericFormat
              disabled
              suffix={` ETB`}
              className="max-w-fit"
              value={bookingValue?.data?.total ?? 0}
              thousandSeparator=","
              decimalScale={2}
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-2 justify-between">
        <div className=" w-full shadow border rounded shadow-sky-100 p-2">
          <div className="mb-2 flex justify-between px-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Booking status
            </Text>
            <Text className="text-sm text-primary flex-col text-semibold">
              <div>
                {"Total: "}
                {bookings?.data?.count}
              </div>
            </Text>
          </div>
          {statusGroupedBookings?.data && (
            <StatsRing data={passengersCircleProgress} />
          )}
        </div>
        {/* <div className="h-80 w-full border rounded flex justify-center items-center shadow shadow-sky-100 p-2">
          {companyEarnings?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            companyEarnings?.data && (
              <div className="flex-col space-y-2 w-full items-center">
                <div className="text-2xl font-semibold flex justify-center w-full">
                  Total Company Earning
                </div>
                <div className="text-4xl font-bold flex justify-center w-full">{`${companyEarnings?.data?.total?.toFixed(
                  2
                )} ETB`}</div>
              </div>
            )
          )}
        </div> */}
      </div>
      <div className="flex-col space-y-4 justify-between mt-10 shadow p-2 border rounded">
        <div className="flex space-x-12  ">
          {" "}
          <Radio.Group
            description="Booking Status"
            defaultValue={barFilter}
            onChange={(e) => {
              setBarFilter(e);
            }}
          >
            <Group mt="xs">
              <Radio value="Created" label="Active" />
              <Radio value="Boarded" label="Boarded" />
              <Radio value="Arrived" label="Arrived" />
              <Radio value="Cancelled" label="Cancelled" />
              <Radio value="Missed" label="Missed" />
            </Group>
          </Radio.Group>
          <Radio.Group
            description="Date Format"
            defaultValue={createdDateFormat}
            onChange={(e) => {
              setCreatedDateFormat(e);
            }}
          >
            <Group mt="xs">
              <Radio value="month" label="Monthly" />
              <Radio value="year" label="Yearly" />
            </Group>
          </Radio.Group>
        </div>

        <div className="h-92 w-full border rounded flex justify-center shadow-lg shadow-sky-100 p-2">
          {bookingOnCreatedMonth?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            bar_state_data && (
              <Bar options={bar_options} data={bar_state_data} />
            )
          )}
        </div>
      </div>
    </div>
  );
}

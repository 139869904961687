import { ActionIcon, Button, Group, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconArrowBackUp, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CollectionQuery } from "../../../models/collection.model";
import { entityViewMode } from "../../../models/entity-config.model";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import {
  useDeleteBankAccountMutation,
  useLazyGetArchivedBankAccountsQuery,
  useLazyGetProviderQuery,
  useRestoreBankAccountMutation
} from "../store/provider.query";

export default function ArchivedBankAccountComponent() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  const [getArchivedBankAccounts, archivedAccounts] =
    useLazyGetArchivedBankAccountsQuery();
  const [restoreBankAccount, restoreResponse] = useRestoreBankAccountMutation();
  const [deleteBankAccount, deleteResponse] = useDeleteBankAccountMutation();
  const [getProvider, provider] = useLazyGetProviderQuery();

  useEffect(() => {
    getProvider(location?.state?.id);
    getArchivedBankAccounts({
      ...collection,
      filter: [
        [{ field: "driverId", operator: "=", value: location?.state?.id }],
      ],
    });
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id]);

  return (
    <Card title={"Archived Accounts"}>
      <LoadingOverlay
        visible={archivedAccounts?.isFetching||restoreResponse?.isLoading||deleteResponse?.isLoading}
      />

      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Driver Name
              </th>
              <th scope="col" className="py-3 px-6">
                Bank Name
              </th>
              <th scope="col" className="py-3 px-6">
                Bank Account Name
              </th>
              <th scope="col" className="py-3 px-6">
                Active
              </th>
              <th scope="col" className="py-3 px-6">
                Preferred
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {archivedAccounts.isSuccess &&
              archivedAccounts.data.data.length > 0 &&
              archivedAccounts.data?.data.map((account, idx) => (
                <tr
                  key={idx}
                  className="bg-white border-b dark:bg-gray-800 text-xs dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 capitalize whitespace-nowrap dark:text-white"
                  >
                    {provider.data?.name}
                  </th>
                  <td className="py-4 px-6">{account.bankName}</td>
                  <td className="py-4 px-6 text-xs">{account.accountNumber}</td>
                  <td className="py-4 px-6">
                    {account.isActive ? "Yes" : "No"}
                  </td>
                  <td className="py-4 px-6">
                    {account.isPreferred ? "Yes" : "No"}
                  </td>
                  <td className="py-4 px-6">
                    <div className="flex space-x-1 items-center justify-center">
                      <Confirmation
                        type={"notify"}
                        message={`Are you sure you want to restore it`}
                        onYes={() =>
                          restoreBankAccount({
                            id: account.id,
                            driverId: account.driverId,
                          })
                        }
                        header={`Restore confirmation `}
                      >
                        <div className="px-2 bg-primary rounded text-white cursor-pointer relative">
                          <IconArrowBackUp size={20} strokeWidth={1.5} />
                        </div>
                      </Confirmation>
                      <Confirmation
                        type={"danger"}
                        message={
                          "Are you sure you want to delete it permanently?"
                        }
                        onYes={() =>
                          deleteBankAccount({
                            id: account.id,
                            driverId: account.driverId,
                          })
                        }
                        header={`Permanent Delete confirmation `}
                      >
                        <div className="px-2 bg-danger rounded text-white cursor-pointer">
                          <IconTrash size={20} strokeWidth={1.5} />
                        </div>
                      </Confirmation>
                      <Tooltip label="See archive reason">
                        <ActionIcon
                          variant="outline"
                          onClick={() =>
                            setArchiveReason({
                              opened: true,
                              name: `${account?.bankName} ${account?.accountNumber}`,
                              reason: account?.archiveReason,
                            })
                          }
                        >
                          <IconEye size={16} />
                        </ActionIcon>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {archivedAccounts.isLoading || !archivedAccounts?.data?.data.length ? (
          <div className="w-full relative flex justify-center items-center h-56">
            <LoadingOverlay
              visible={archivedAccounts.isLoading}
              overlayBlur={2}
            />
            <EmptyIcon/>
          </div>
        ) : (
          <></>
        )}
        {archivedAccounts?.isSuccess &&
          archivedAccounts?.data?.data?.length > 0 && (
            <div className="w-full flex justify-end items-center mt-2">
              <Pagination
                total={
                  archivedAccounts?.data ? archivedAccounts?.data?.count : 0
                }
                defaultPageSize={10}
                pageSize={[10, 15, 20]}
                onPaginationChange={(skip: number, top: number) => {
                  setCollection({ skip: skip, top: top, ...collection });
                }}
              />
            </div>
          )}
      </div>
      <Modal
        opened={archiveReason?.opened}
        onClose={() =>
          setArchiveReason({ ...archiveReason,opened: false })
        }
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason,opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </Card>
  );
}

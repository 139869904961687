/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Faq } from "../../../models/faq.model";

import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";

import { FAQ_ENDPOINT } from "../faq.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const faqQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFaqs: builder.query<Collection<Faq>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${FAQ_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          if (error.status === 403) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        }
      },
    }),
    createFaq: builder.mutation<Faq, Faq>({
      query: (data: Faq) => ({
        url: `${FAQ_ENDPOINT.create}`,
        method: "POST",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            faqQuery.util.updateQueryData(
              "getFaqs",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count += 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteFaq: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${FAQ_ENDPOINT.delete}/${id}`,
        method: "DELETE",
        permission: "manage-faqs",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            faqQuery.util.updateQueryData(
              "getFaqs",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter((faq) => faq.id !== id);
                draft.count -= 1;
              }
            )
          );
          dispatch(
            faqQuery.util.updateQueryData(
              "getArchivedFaqs",
              archivedCollection,
              (draft) => {
                draft.data = draft.data.filter((faq) => faq.id !== id);
                draft.count -= 1;
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getFaq: builder.query<Faq, string>({
      query: (id: string) => ({
        url: `${FAQ_ENDPOINT.faq}/${id}`,
        method: "GET",
      }),
    }),
    getArchivedFaq: builder.query<Faq, string>({
      query: (id: string) => ({
        url: `${FAQ_ENDPOINT.archived_faq}/${id}`,
        method: "GET",
      }),
    }),
    updateFaq: builder.mutation<Faq, Faq>({
      query: (data: Faq) => ({
        url: `${FAQ_ENDPOINT.update}`,
        method: "PUT",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            faqQuery.util.updateQueryData(
              "getFaqs",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faq) => {
                  if (faq.id === data.id) {
                    return data;
                  } else {
                    return faq;
                  }
                });
              }
            )
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveFaq: builder.mutation<Faq, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${FAQ_ENDPOINT.archive}`,
        method: "DELETE",
        data: data,
        permission: "manage-faqs",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            faqQuery.util.updateQueryData(
              "getFaqs",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faq) => {
                  if (faq.id === arg.id) {
                    return data;
                  } else {
                    return faq;
                  }
                });
              }
            )
          );
          dispatch(
            faqQuery.util.updateQueryData("getFaq", arg?.id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            faqQuery.util.updateQueryData(
              "getArchivedFaq",
              arg?.id,
              (draft) => {
                draft.deletedAt = data?.deletedAt;
              }
            )
          );
          notification("success", "Successfully archived");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    getArchivedFaqs: builder.query<Collection<Faq>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${FAQ_ENDPOINT.archived_faqs}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          archivedCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    restoreFaq: builder.mutation<any, string>({
      query: (id: string) => ({
        url: `${FAQ_ENDPOINT.restore_faq}/${id}`,
        method: "POST",
        permission: "manage-faqs",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            faqQuery.util.updateQueryData(
              "getFaqs",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((faq) => {
                  if (faq.id === id) {
                    return data;
                  } else {
                    return faq;
                  }
                });
              }
            )
          );
          dispatch(
            faqQuery.util.updateQueryData("getFaq", id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          dispatch(
            faqQuery.util.updateQueryData("getArchivedFaq", id, (draft) => {
              draft.deletedAt = data?.deletedAt;
            })
          );
          notification("success", "Successfully restored");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetFaqQuery,
  useLazyGetFaqsQuery,
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useUpdateFaqMutation,
  useArchiveFaqMutation,
  useLazyGetArchivedFaqsQuery,
  useRestoreFaqMutation,

  useLazyGetArchivedFaqQuery,
} = faqQuery;

import { Card, Divider, Group, Stack, Text } from "@mantine/core";
import {
  IconCar,
  IconCheck,
  IconChecks,
  IconCurrencyDollar,
  IconDeviceMobile,
  IconUsers,
} from "@tabler/icons";
import dayjs from "dayjs";
import { useEffect } from "react";
import { CollectionQuery } from "../../../models/collection.model";
import {
  useLazyGetAllProvidersQuery,
  useLazyGetBookingValueQuery,
  useLazyGetBookingsQuery,
  useLazyGetOwnersQuery,
  useLazyGetPassengersQuery,
  useLazyGetTotalDepositQuery,
  useLazyGetTripsQuery,
  useLazyGetVehiclesQuery,
} from "../store/dashboard.query";
import { ReportCard, formatCurrency } from "./ReportCard";

export function TodayReport() {
  const collection: CollectionQuery = {
    skip: 0,
    top: 1,
  };

  const [getPassengersToday, passengersRegisteredToday] =
    useLazyGetPassengersQuery();
  const [getBookingsToday, bookingsRegisteredToday] = useLazyGetBookingsQuery();
  const [getTodayDeposit, todayDeposit] = useLazyGetTotalDepositQuery();
  const [getOwnersToday, ownersRegisteredToday] = useLazyGetOwnersQuery();
  const [getVehiclesToday, vehiclesRegisteredToday] = useLazyGetVehiclesQuery();
  const [getProvidersToday, ProvidersRegisteredToday] =
    useLazyGetAllProvidersQuery();
  const [getTotalTripsToday, tripsToday] = useLazyGetTripsQuery();
  const [getStartedTripsToday, startedTripsToday] = useLazyGetTripsQuery();
  const [getCompletedTripsToday, completedTripsToday] = useLazyGetTripsQuery();
  const [getCancelledTripsToday, cancelledTripsToday] = useLazyGetTripsQuery();
  const [getBookingValue, bookingValue] = useLazyGetBookingValueQuery();

  function fetch() {
    getBookingValue({
      filter: [
        [
          {
            field: "createdAt",
            operator: "between",
            value: [
              dayjs().subtract(1, "days").format(),
              dayjs().add(1, "days").format(),
            ],
          },
        ],
      ],
    });
    getTodayDeposit({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: ">=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
      ],
    });
    getPassengersToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: "between",
            value: [
              dayjs().subtract(1, "days").format(),
              dayjs().add(1, "days").format(),
            ],
          },
        ],
      ],
    });
    getBookingsToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: ">=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
        [
          {
            field: "createdAt",
            operator: "<",
            value: dayjs().add(1, "day").format("YYYY-MM-DD"),
          },
        ],
      ],
    });
    getVehiclesToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: ">=",
            value: dayjs().subtract(1, "days").format(),
          },
        ],
      ],
    });
    getOwnersToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: ">=",
            value: dayjs().subtract(1, "days").format(),
          },
        ],
      ],
    });
    getProvidersToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: ">=",
            value: dayjs().subtract(1, "days").format(),
          },
        ],
      ],
    });
    getTotalTripsToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: "=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
        [
          {
            field: "status",
            operator: "!=",
            value: "Cancelled",
          },
        ],
      ],
    });
    getStartedTripsToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: "=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
        [
          {
            field: "status",
            operator: "=",
            value: "Started",
          },
        ],
      ],
    });
    getCompletedTripsToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: "=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
        [
          {
            field: "status",
            operator: "=",
            value: "Completed",
          },
        ],
      ],
    });
    getCancelledTripsToday({
      count: true,
      filter: [
        [
          {
            field: "createdAt",
            operator: "=",
            value: dayjs().format("YYYY-MM-DD"),
          },
        ],
        [
          {
            field: "status",
            operator: "=",
            value: "Cancelled",
          },
        ],
      ],
    });
  }
  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card shadow={"md"} p={"sm"} withBorder>
      <Card.Section p={"md"}>
        <Text fz={30} fw={500} align={"start"}>
          Today's Report
        </Text>
        <Divider />
      </Card.Section>
      <Stack spacing={"md"}>
        <Group position={"center"} spacing={25} key={1 + "_tab"}>
          <ReportCard
            {...{
              title: "Passengers",
              description: "Number of passengers",
              count: passengersRegisteredToday?.data?.count,
              loading: passengersRegisteredToday.isFetching,
              icon: <IconUsers size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Bookings",
              description: "Number of bookings",
              count: bookingsRegisteredToday?.data?.count,
              loading: bookingsRegisteredToday.isFetching,
              icon: <IconDeviceMobile size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Trips",
              description: "Number of trips taken",
              count: tripsToday?.data?.count,
              loading: tripsToday.isFetching,
              icon: <IconCar size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Started Trips",
              description: "Number of started trips",
              count: startedTripsToday?.data?.count,
              loading: startedTripsToday.isFetching,
              icon: <IconCheck size={100} />,
            }}
          />
        </Group>
        <Group position={"center"} spacing={25} key={2 + "_tab"}>
          <ReportCard
            {...{
              title: "Completed Trips",
              description: "Number of completed trips",
              count: completedTripsToday?.data?.count,
              loading: completedTripsToday.isFetching,
              icon: <IconChecks size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Cancelled Trips",
              description: "Number of cancelled trips",
              count: cancelledTripsToday?.data?.count,
              loading: cancelledTripsToday.isFetching,
              icon: <IconChecks size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Today's Deposit",
              description: "Total money deposit",
              count: formatCurrency(todayDeposit?.data?.total ?? 0) + " ETB",
              loading: todayDeposit.isFetching,
              icon: <IconCurrencyDollar size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Booking Value",
              description: "Today's Booking Value",
              count: formatCurrency(bookingValue?.data?.total ?? 0) + " ETB",
              loading: bookingValue.isFetching,
              icon: <IconCurrencyDollar size={100} />,
            }}
          />
        </Group>
      </Stack>
    </Card>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { DriverAssignment } from "../../../models/driver-assignment.model";
import GroupAssignment, {
  GroupAssignmentRequest,
} from "../../../models/group-assignment.model";
import { Group } from "../../../models/group.model";
import { Parent } from "../../../models/parent.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { GROUP_ENDPOINT } from "../group.endpoint";

let groupCollection: CollectionQuery;
let archivedGroupCollection: CollectionQuery;

let groupAssignmentCollection: CollectionQuery;
let archivedGroupAssignmentCollection: CollectionQuery;
let parentCollection: CollectionQuery;

let parentForAssignmentCollection: CollectionQuery;

const groupQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroup: builder.query<Group, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedGroup: builder.query<Group, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.archivedGroup}/${id}`,
        method: "get",
      }),
    }),

    getArchivedGroups: builder.query<Collection<Group>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${GROUP_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedGroupCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getGroups: builder.query<Collection<Group>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${GROUP_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            groupCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createGroup: builder.mutation<Group, Group>({
      query: (newData: any) => ({
        url: `${GROUP_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission: "manage-groups",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateGroup: builder.mutation<Group, any>({
      query: (newData: any) => ({
        url: `${GROUP_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-groups",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((city) => {
                      if (city.id === data.id) return data;
                      else {
                        return city;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    toggleStatus: builder.mutation<Group, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
        permission: "manage-groups",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((city) => {
                      if (city.id === data.id) return data;
                      else {
                        return city;
                      }
                    });
                  }
                }
              )
            );

            dispatch(
              groupQuery.util.updateQueryData("getGroup", param, (draft) => {
                draft.status = data.status;
              })
            );

            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    archiveGroup: builder.mutation<Group, { id: string; reason: string }>({
      query: (data) => ({
        url: `${GROUP_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "manage-groups",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  draft.data = draft?.data?.map((group) => {
                    if (group.id === query?.id) {
                      return { ...group, deletedAt: data?.deletedAt };
                    } else {
                      return group;
                    }
                  });
                }
              )
            );
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroup",
                query?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            dispatch(
              groupQuery.util.updateQueryData(
                "getArchivedGroup",
                query?.id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteGroup: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-groups",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                archivedGroupCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((city) => city.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully deleted");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    removeGroupAssignment: builder.mutation<
      boolean,
      { id: string; groupId: string }
    >({
      query: (data: { id: string; groupId: string }) => ({
        url: `${GROUP_ENDPOINT.removeGroupAssignment}`,
        method: "delete",
        data: data,
        permission: "manage-groups",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getAssignmentsByGroup",
                { id: param.id, q: groupAssignmentCollection },
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (assignment) => assignment.id !== param.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully removed");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateDriverAssignment: builder.mutation<
      DriverAssignment,
      { id: string; driverId: string; groupId: string }
    >({
      query: (data: { id: string; driverId: string; groupId: string }) => ({
        url: `${GROUP_ENDPOINT.updateDriverAssignment}`,
        method: "put",
        data: data,
        permission: "manage-groups",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data.map((group) => {
                      if (group.id != data.groupId) {
                        return group;
                      }
                      group.driverId = data.driverId;
                      group.driverName = data.driverName;
                      group.driverPhone = data.driverPhone;
                      return {
                        ...group,
                        driverId: data.driverId,
                        driverName: data.driverName,
                        driverPhone: data.driverPhone,
                      };
                    });
                  }
                }
              )
            );

            dispatch(
              groupQuery.util.updateQueryData(
                "getAssignmentsByGroup",
                { id: data?.groupId ?? "", q: groupAssignmentCollection },
                (draft) => {
                  if (data) {
                    draft.data.map((assignment) => {
                      if (assignment.id != data.groupId) {
                        return assignment;
                      }

                      return {
                        ...assignment,
                        driverId: data.driverId,
                        driverPhone: data.driverPhone,
                        driverName: data.driverName,
                      };
                    });
                  }
                }
              )
            );

            dispatch(
              groupQuery.util.updateQueryData(
                "getGroup",
                param?.groupId ?? "",
                (draft) => {
                  draft.driverId = param?.driverId;
                  draft.driverName = data.driverName;
                  draft.driverPhone = data.driverPhone;
                }
              )
            );
            notification("success", "Successfully reassigned");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreGroup: builder.mutation<Group, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-groups",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  draft.data = draft?.data?.map((group) => {
                    if (group.id === id) {
                      return data;
                    } else {
                      return group;
                    }
                  });
                }
              )
            );
            dispatch(
              groupQuery.util.updateQueryData("getGroup", id, (draft) => {
                draft.deletedAt = data?.deletedAt;
              })
            );
            dispatch(
              groupQuery.util.updateQueryData(
                "getArchivedGroup",
                id,
                (draft) => {
                  draft.deletedAt = data?.deletedAt;
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    // group assignment query

    getGroupAssignments: builder.query<
      Collection<GroupAssignment>,
      CollectionQuery
    >({
      query: (data: CollectionQuery) => ({
        url: `${GROUP_ENDPOINT.listAssignment}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            groupAssignmentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
   
    createGroupAssignment: builder.mutation<any, GroupAssignmentRequest>({
      query: (newData: any) => ({
        url: `${GROUP_ENDPOINT.addGroupAssignments}`,
        method: "post",
        data: newData,
        permission: "manage-group-assignment",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully assigned");
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createDriverAssignmentForGroup: builder.mutation<
      DriverAssignment,
      DriverAssignment
    >({
      query: (newData: DriverAssignment) => ({
        url: `${GROUP_ENDPOINT.createDriverAssignmentForGroup}`,
        method: "post",
        data: newData,
      }),
      invalidatesTags: ["group_driver_assignment"],
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              groupQuery.util.updateQueryData(
                "getGroups",
                groupCollection,
                (draft) => {
                  if (data) {
                    draft.data.map((group) => {
                      if (group.id != data.groupId) {
                        return group;
                      }
                      group.driverId = data.driverId;
                      group.driverName = data.driverName;
                      group.driverPhone = data.driverPhone;
                    });
                  }
                }
              )
            );

            dispatch(
              groupQuery.util.updateQueryData(
                "getAssignmentsByGroup",
                { id: data?.groupId ?? "", q: groupAssignmentCollection },
                (draft) => {
                  if (data) {
                    draft.data.map((assignment) => {
                      if (assignment.id != data.groupId) {
                        return assignment;
                      }
                      assignment.driverId = data.driverId;
                      assignment.driverPhone = data.driverPhone;
                      assignment.driverName = data.driverName;
                    });
                  }
                }
              )
            );

            dispatch(
              groupQuery.util.updateQueryData(
                "getGroup",
                param?.groupId ?? "",
                (draft) => {
                  draft.driverId = param?.driverId;
                  draft.driverName = data.driverName;
                  draft.driverPhone = data.driverPhone;
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getParentsForAssignment: builder.query<Collection<Parent>, CollectionQuery>(
      {
        query: (data: CollectionQuery) => ({
          url: `${GROUP_ENDPOINT.getParentsForAssignment}`,
          method: "GET",
          params: collectionQueryBuilder(data),
        }),
        async onQueryStarted(param, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              parentForAssignmentCollection = param;
            }
          } catch (error: any) {
            notification(
              "error",
              error?.error?.data?.message
                ? error?.error?.data?.message
                : "Error try again"
            );
          }
        },
      }
    ),

    getAssignmentsByGroup: builder.query<
      Collection<GroupAssignment>,
      { id: string; q: CollectionQuery }
    >({
      query: (data: { id: string; q: CollectionQuery }) => ({
        url: `${GROUP_ENDPOINT.getAssignmentsByGroup}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.q),
      }),
      providesTags: ["group_driver_assignment"],
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            groupAssignmentCollection = param.q;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getGroupAssignment: builder.query<GroupAssignment, string>({
      query: (id: string) => ({
        url: `${GROUP_ENDPOINT.detailAssignment}/${id}`,
        method: "get",
      }),
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetGroupQuery,
  useLazyGetGroupsQuery,
  useLazyGetArchivedGroupQuery,
  useLazyGetArchivedGroupsQuery,

  useCreateGroupMutation,
  useUpdateGroupMutation,
  useArchiveGroupMutation,
  useDeleteGroupMutation,
  useRestoreGroupMutation,

  //group assignments

  useLazyGetGroupAssignmentQuery,
  useLazyGetAssignmentsByGroupQuery,
  useLazyGetGroupAssignmentsQuery,
  useLazyGetParentsForAssignmentQuery,

  // useAddGroupAssignmentsQuery

  useCreateGroupAssignmentMutation,
  useCreateDriverAssignmentForGroupMutation,
  useToggleStatusMutation,
  useRemoveGroupAssignmentMutation,
  useUpdateDriverAssignmentMutation,

  // useLazyGetTotalPaymentQuery
} = groupQuery;

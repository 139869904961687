import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FileInput, Group } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCalendar,
  IconDeviceFloppy,
  IconUpload,
  IconX,
} from "@tabler/icons";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useLazyGetArchivedVehicleQuery,
  useLazyGetVehicleQuery,
  useUpdateVehicleDocumentMutation,
} from "../store/vehicle.query";
interface Props {
  editMode: string;
}
export default function VehicleFileUploadComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState<any>();
  const ref = useRef<any>(null);
  const date = new Date();
  const schema = yup
    .object()
    .shape({
      bolo: yup.mixed().when("boloDueDate", (boloDueDate: any, schema: any) => {
        if (!vehicle?.data?.bolo?.filename) {
          return schema
            .required("Bolo is required")
            .test(
              "fileSize",
              "Bolo file should be less than 2MB",
              (value: any) => {
                return value && value?.size <= 2000000;
              }
            )
            .test("type", "Only pdf formats are accepted", (value: any) => {
              return value && value.type === "application/pdf";
            });
        }
      }),
      insurance: yup
        .mixed()
        .when("boloDueDate", (boloDueDate: any, schema: any) => {
          if (!vehicle?.data?.insurance?.originalname) {
            return schema
              .required("Insurance is required")
              .test(
                "fileSize",
                "Insurance file should be less than 2MB",
                (value: any) => {
                  return value && value?.size <= 2000000;
                }
              )
              .test("type", "Only pdf formats are accepted", (value: any) => {
                return value && value.type === "application/pdf";
              });
          }
        }),
      insuranceCertificateDueDate: yup
        .date()
        .typeError("Insurance certificate due date should be a valid date ")
        .required("Insurance certificate due date required"),
      boloDueDate: yup
        .date()
        .typeError("Bolo due date should be a valid date")
        .required("Bolo due date required"),
    })
    .required();
  const {
    reset,
    getValues,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [getVehicle, vehicle] = useLazyGetVehicleQuery();
  const [getArchivedVehicle, archivedVehicleResponse] =
    useLazyGetArchivedVehicleQuery();

  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type === "active") {
        getVehicle(`${params?.id}`);
      } else {
        getArchivedVehicle(`${params?.id}`);
      }
    } else {
      reset();
    }
  }, [params.id, editMode]);

  useEffect(() => {
    setValue(
      "boloDueDate",
      params?.type === "active"
        ? vehicle?.data?.boloDueDate
          ? new Date(vehicle?.data?.boloDueDate)
          : null
        : archivedVehicleResponse?.data?.boloDueDate
        ? new Date(archivedVehicleResponse?.data?.boloDueDate)
        : null
    );
    setValue(
      "insuranceCertificateDueDate",
      params?.type === "active"
        ? vehicle?.data?.insuranceCertificateDueDate
          ? new Date(vehicle?.data?.insuranceCertificateDueDate)
          : null
        : archivedVehicleResponse?.data?.insuranceCertificateDueDate
        ? new Date(archivedVehicleResponse?.data?.insuranceCertificateDueDate)
        : null
    );
  }, [vehicle?.data, archivedVehicleResponse?.data]);

  const [uploadVehicleDocument, uploadResponse] =
    useUpdateVehicleDocumentMutation();

  const onSubmit = (data: any) => {
    const formData = new FormData();
    data?.bolo && formData.append("bolo", data.bolo);
    data?.insurance && formData.append("insurance", data.insurance);
    formData.append("id", `${params.id}`);
    formData.append("ownerId", `${vehicle?.data?.ownerId}`);
    formData.append("boloDueDate", dayjs(data.boloDueDate).format());
    formData.append(
      "insuranceCertificateDueDate",
      dayjs(data?.insuranceCertificateDueDate).format()
    );

    uploadVehicleDocument(formData).then((res) => getVehicle(`${params?.id}`));
  };
  const onError = (data: any) => {};
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setModal(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [modal]);
  const onViewFile = (file: any) => {
    window
      .open(
        downloadUrlParser({
          path: file?.path,
          filename: file?.filename,
          originalname: file?.originalname,
          mimetype: file?.mimetype,
        }),
        "_blank"
      )
      ?.focus();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex w-full space-x-2">
          <div className="w-full">
            <Controller
              control={control}
              name="bolo"
              render={({ field }) => (
                <FileInput
                  className="w-full"
                  withAsterisk
                  error={errors.bolo && `${errors.bolo?.message}`}
                  label="Bolo"
                  //@ts-ignore
                  placeholder="Bolo file"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
            {(vehicle?.data?.bolo?.["originalname"] ||
              archivedVehicleResponse?.data?.bolo?.["originalname"]) && (
              <span
                onClick={() =>
                  onViewFile(
                    vehicle?.data?.bolo ?? archivedVehicleResponse?.data?.bolo
                  )
                }
                className=" cursor-pointer text-xs"
              >
                {vehicle?.data?.bolo?.["originalname"] ??
                  archivedVehicleResponse?.data?.bolo?.["originalname"]}
              </span>
            )}
          </div>
          <Controller
            control={control}
            name="boloDueDate"
            render={({ field }) => (
              <DatePickerInput
                icon={<IconCalendar size={16} />}
                className="w-full"
                error={errors.boloDueDate && `${errors.boloDueDate?.message}`}
                //@ts-ignore
                placeholder="Pick date"
                label="Bolo due date"
                excludeDate={(edate) => {
                  if (edate.getFullYear() < date.getFullYear()) {
                    return true;
                  } else if (
                    edate.getFullYear() === date.getFullYear() &&
                    edate.getMonth() < date.getMonth()
                  ) {
                    return true;
                  } else {
                    if (
                      edate.getFullYear() <= date.getFullYear() &&
                      edate.getMonth() === date.getMonth() &&
                      edate.getDate() <= date.getDate()
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }}
                withAsterisk
                {...field}
              />
            )}
          />
        </div>
        <div className="flex w-full space-x-2">
          <div className="w-full">
            <Controller
              control={control}
              name="insurance"
              render={({ field }) => (
                <FileInput
                  withAsterisk
                  className="w-full"
                  error={errors.insurance && `${errors.insurance?.message}`}
                  label="Insurance"
                  //@ts-ignore
                  placeholder="Insurance file"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
            {(vehicle?.data?.insurance?.["originalname"] ||
              archivedVehicleResponse?.data?.insurance?.["originalname"]) && (
              <span
                onClick={() => onViewFile(vehicle?.data?.insurance)}
                className=" cursor-pointer text-xs"
              >
                {vehicle?.data?.insurance?.["originalname"] ??
                  archivedVehicleResponse?.data?.insurance?.["originalname"]}
              </span>
            )}
          </div>

          <Controller
            control={control}
            name="insuranceCertificateDueDate"
            render={({ field }) => (
              <DatePickerInput
                icon={<IconCalendar size={16} />}
                className="w-full"
                excludeDate={(edate) => {
                  if (edate.getFullYear() < date.getFullYear()) {
                    return true;
                  } else if (
                    edate.getFullYear() === date.getFullYear() &&
                    edate.getMonth() < date.getMonth()
                  ) {
                    return true;
                  } else {
                    if (
                      edate.getFullYear() <= date.getFullYear() &&
                      edate.getMonth() === date.getMonth() &&
                      edate.getDate() <= date.getDate()
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }}
                error={
                  errors.insuranceCertificateDueDate &&
                  `${errors.insuranceCertificateDueDate?.message}`
                }
                //@ts-ignore
                placeholder="Pick date"
                label="Insurance due date"
                withAsterisk
                {...field}
              />
            )}
          />
        </div>
        <Group position="right" mt="xl">
          <>
            {/* <Button
              classNames={{ label: "flex space-x-1 " }}
              variant="default"
              className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
              type="reset"
              size="xs"
              onClick={() => reset()}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current h-6"
                  viewBox="0 0 32 32"
                >
                  <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                </svg>
              </span>
              <span>Reset</span>
            </Button> */}
            <Button
              variant="filled"
              className="bg-primary text-white"
              type="submit"
              classNames={{ label: "flex space-x-1" }}
              size={"xs"}
              loading={uploadResponse.isLoading}
            >
              <span>
                <IconDeviceFloppy size={22} strokeWidth={1.5} />
              </span>
              <span>Submit</span>
            </Button>
          </>
        </Group>
      </form>

      {modal && (
        <div
          className={`group absolute top-0 bottom-0 right-0 left-0 z-50 h-full w-full items-center justify-center `}
        >
          <div className="relative h-full w-3/4">
            <div className="fixed top-0 bottom-0 right-0 left-0 h-full w-full bg-black opacity-40"></div>
            <div className="fixed top-6 right-6 z-50 w-full flex justify-end items-center ">
              <IconX className="text-white" onClick={() => setModal(false)} />
            </div>
            <div className=" fixed top-8 left-20 right-0 flex h-full w-full items-center justify-center px-10">
              <iframe
                ref={ref}
                className="w-3/4 h-3/4"
                src={downloadUrlParser({
                  path: file?.path,
                  filename: file?.filename,
                  originalname: file?.originalname,
                  mimetype: file?.mimetype,
                })}
                title={`${file?.originalname}`}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

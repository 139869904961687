import {
  ActionIcon,
  Button,
  Group, Modal,
  Text,
  Tooltip
} from "@mantine/core";
import { IconArrowBack, IconEye, IconPoint, IconTrash } from "@tabler/icons";
import { SetStateAction, useEffect, useState } from "react";
import {
  CollectionQuery,
  Filter,
  Order
} from "../../../models/collection.model";
import { EntityConfig } from "../../../models/entity-config.model";
import { Route } from "../../../models/route.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import { Constants } from "../../../shared/constants/Constants";
import {
  useDeleteRouteMutation,
  useLazyGetArchivedRoutesQuery,
  useRestoreRouteMutation
} from "../store/route.query";

export default function ArchivedRouteComponent() {
  const [getArchivedRoutes, archivedRoutes] = useLazyGetArchivedRoutesQuery();
  const [restoreRoute, restoreCall] = useRestoreRouteMutation();
  const [deleteRoute, deleteCall] = useDeleteRouteMutation();
  const [id, setId] = useState("");

  const [filterData, setFilterData] = useState<Filter[]>([]);
  const [cityList] = useState<Record<string, string>>({});

  //Archive reason state
  const [archiveReason, setArchiveReason] = useState<{
    opened: boolean;
    name: string;
    reason: string | undefined;
  }>({ opened: false, name: "", reason: "" });

  //Collection request state
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: [ "city"],
    orderBy:[{field:'deletedAt',direction:'desc'}]
  });

  useEffect(() => {
    getArchivedRoutes(collection).then((routes) => {
      const data: SetStateAction<Filter[]> = [];
      //add any new city to cityList
      routes.data?.data?.map((route) => {
        const cityName = route?.city?.name;
        if (cityName) {
          if (Object.keys(cityList).indexOf(cityName) == -1) {
            cityList[cityName] = route.cityId;
          }
        }
      });
      // setup filter from the cityList
      Object.keys(cityList).map((key) => {
        data.push({
          field: "cityId",
          value: cityList[key],
          name: key,
          operator: "=",
        });
      });
      if (data) {
        setFilterData(data);
      }
    });
  }, [collection]);

  const data: Route[] | undefined = archivedRoutes.data?.data;
  const config: EntityConfig<Route> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/route",
    identity: "id",
    name: "name",
    showDetail: false,
    visibleColumn: [
      { key: "name", name: "Name" },
      {
        key: "",
        name: "City",
        render: (value) => value?.city?.name ?? " ---- ",
      },
      {
        key: "difficultyLevel",
        name: "Difficulty Level",
        render: (value) => Constants.Levels(value.difficultyLevel),
      },
      {
        key: "trafficJamLevel",
        name: "Traffic Jam Level",
        render: (value) => Constants.Levels(value.trafficJamLevel),
      },
      { key: "createdAt", name: "Registered At", isDate: true },
   
      { key: "deletedAt", name: "Archived At", isDate: true },
      { key: "isActive", name: "Status" },
      {
        key: "",
        name: "Action",
        print:false,
        render: (route) => {
          return (
            <div className="flex-col space-y-1 items-center">
              <Confirmation
                type={"notify"}
                message={"Do you want to restore it?"}
                yesText={"Restore"}
                onYes={() => {
                  setId(`${route.id}`);
                  restoreRoute(`${route.id}`).then(() => setId(""));
                }}
                header={"Restore Confirmation"}
              >
                <Tooltip label="Restore">
                  <ActionIcon
                    variant="filled"
                    className="bg-primary text-white"
                    loading={restoreCall.isLoading && `${route.id}` === id}
                  >
                    <IconArrowBack size={16} strokeWidth={1.5} />
                  </ActionIcon>
                </Tooltip>
              </Confirmation>

              <Confirmation
                header={"Delete Confirmation"}
                type={"danger"}
                message={"Are you sure you want to delete it permanently?"}
                yesText={"Delete"}
                onYes={() => {
                  setId(`${route.id}`);
                  deleteRoute(`${route?.id}`).then(() => setId(""));
                }}
              >
                <Tooltip label="Delete">
                  <ActionIcon
                    variant="filled"
                    className="bg-danger text-white"
                    loading={deleteCall.isLoading && `${route.id}` === id}
                  >
                    <IconTrash size={16} strokeWidth={1.5} />
                  </ActionIcon>
                </Tooltip>
              </Confirmation>
              <Tooltip label="See archive reason">
                <ActionIcon
                  variant="outline"
                  onClick={() =>
                    setArchiveReason({
                      opened: true,
                      name: route?.name,
                      reason: route?.archiveReason,
                    })
                  }
                >
                  <IconEye size={16} />
                </ActionIcon>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    filter: [filterData],
  };

  return (
    <div className="w-full">
      <EntityList
        viewMode={"list"}
        showNewButton={false}
        title={"Archived Routes"}
        total={archivedRoutes?.data?.count}
        itemsLoading={archivedRoutes.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          if (collection.skip !== skip || collection.top !== top) {
            setCollection({ ...collection, skip: skip, top: top });
          }
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            search: data,
            searchFrom: ["name"],
          });
        }}
        onFilterChange={(data: any) =>
          setCollection({ ...collection, filter: data })
        }
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
      <Modal
        opened={archiveReason?.opened}
        onClose={() => setArchiveReason({ ...archiveReason, opened: false })}
        title={`${archiveReason.name} archive reason`}
        centered
        size={"md"}
      >
        {archiveReason?.reason ? (
          <div className={"px-2 flex space-x-1 items-center"}>
            <IconPoint size={18} />
            <Text fz={"sm"}> {archiveReason?.reason}</Text>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center h-full">
            <EmptyIcon />
          </div>
        )}
        <Group mt="xl" position="right">
          <Button
            variant="outline"
            size={"xs"}
            onClick={() =>
              setArchiveReason({ ...archiveReason, opened: false })
            }
          >
            Close
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import {
  Avatar,
  Button,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Tabs,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconId, IconTicket } from "@tabler/icons";
import { lazy, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { useLazyGetActivitiesQuery } from "../../../shared/store/query/sharedQuery";
import {
  useActivatePassengerMutation,
  useArchivePassengerMutation,
  useDeletePassengerMutation,
  useLazyGetArchivedPassengerQuery,
  useLazyGetPassengerQuery,
  useRestorePassengerMutation,
} from "../store/passenger.query";

const PassengerBookingHistory = lazy(
  () => import("./booking-history-componenet")
);

export interface Props {
  editMode: string;
}

export default function PassengerDetailComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [getPassenger, passenger] = useLazyGetPassengerQuery();
  const [getArchivedPassenger, archivedPassenger] =
    useLazyGetArchivedPassengerQuery();
  const [getActivities, activities] = useLazyGetActivitiesQuery();
  const [archivePassenger, archiveResponse] = useArchivePassengerMutation();
  const [activatePassenger, activateResponse] = useActivatePassengerMutation();
  const [restorePassenger, restoreCall] = useRestorePassengerMutation();
  const [deletePassenger, deleteCall] = useDeletePassengerMutation();

  const [, setPassengerId] = useState("");
  const [activityLogger, setActivityLogger] = useState<boolean>(false);

  useEffect(() => {
    if (editMode === "detail") {
      if (params?.type === "archived") {
        getArchivedPassenger(`${params?.id}`).then((response: any) => {
          setPassengerId(response.data.id);
        });
      } else if (params?.type === "active") {
        getPassenger(`${params?.id}`).then((response: any) => {
          setPassengerId(response.data.id);
        });
      }
    }
  }, [params.id, editMode, getPassenger]);

  const actions = location.pathname === `/passenger/detail/${params.id}` && (
    <div className="flex items-center justify-end">
      <Tooltip label={<span className="text-xs">Activity logger</span>}>
        <span
          onClick={() => {
            setActivityLogger(true);
            getActivities({
              filter: [
                [{ field: "modelId", value: `${params?.id}`, operator: "=" }],
              ],
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 fill-current"
            viewBox="0 0 16 16"
          >
            <path d="M7.5 1C6.851563 1 6.300781 1.421875 6.09375 2L3.5 2C2.675781 2 2 2.675781 2 3.5L2 12.5C2 13.324219 2.675781 14 3.5 14L11.5 14C12.324219 14 13 13.324219 13 12.5L13 3.5C13 2.675781 12.324219 2 11.5 2L8.90625 2C8.699219 1.421875 8.148438 1 7.5 1 Z M 7.5 2C7.78125 2 8 2.21875 8 2.5L8 3L9 3L9 4L6 4L6 3L7 3L7 2.5C7 2.21875 7.21875 2 7.5 2 Z M 3.5 3L5 3L5 5L10 5L10 3L11.5 3C11.78125 3 12 3.21875 12 3.5L12 12.5C12 12.78125 11.78125 13 11.5 13L3.5 13C3.21875 13 3 12.78125 3 12.5L3 3.5C3 3.21875 3.21875 3 3.5 3 Z M 4 7L4 8L5 8L5 7 Z M 6 7L6 8L11 8L11 7 Z M 4 10L4 11L5 11L5 10 Z M 6 10L6 11L11 11L11 10Z" />
          </svg>
        </span>
      </Tooltip>
    </div>
  );

  return (
    <div className="h-full relative overflow-x-scroll w-full dark:bg-gray-700 dark:text-white flex space-x-2 justify-center">
      <ActivityLogWrapperComponent
        title={
          editMode === "new" ? "Passenger Detail" : passenger?.data?.name ?? ""
        }
        item={passenger}
        path={`/registration/passenger/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <Tabs defaultValue="detail" className="w-full relative">
          <Tabs.List>
            <Tabs.Tab value="detail" icon={<IconId size="0.8rem" />}>
              Passenger Detail
            </Tabs.Tab>
            <Tabs.Tab value="booking" icon={<IconTicket size="0.8rem" />}>
              Booking History
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="detail" pt="xs">
            <LoadingOverlay
              visible={editMode === "detail" && passenger.isFetching}
            />
            <div className="flex  justify-center h-full">
              <div className={"w-full gap-y-4 dark:text-white"}>
                <div className="flex-col space-y-4">
                  <Group position="apart">
                    <SimpleGrid
                      cols={1}
                      breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                    >
                      <Stack spacing="xs">
                        <Avatar
                          radius="xl"
                          size="xl"
                          src={passenger?.data?.profileImage?.path}
                        />
                        <Title order={3}>{passenger?.data?.name}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Gender
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.gender === "male"
                            ? "Male"
                            : "Female"}
                        </Title>
                      </Stack>
                    </SimpleGrid>
                    <SimpleGrid
                      cols={1}
                      breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                    >
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Email
                        </Title>
                        <Title order={5}>{passenger?.data?.email}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Phone Number
                        </Title>
                        <Title order={5}>{passenger?.data?.phoneNumber}</Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Emergency Contact
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.emergencyContact?.name ?? " -- "}
                        </Title>
                        <Title order={6}>
                          {passenger?.data?.emergencyContact?.phoneNumber ??
                            " -- "}
                        </Title>
                      </Stack>
                    </SimpleGrid>
                    {/*Address*/}
                    <SimpleGrid
                      className={"border-l-2 pl-5"}
                      cols={2}
                      breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                    >
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Country
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.address?.country}
                        </Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          City
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.address?.city}
                        </Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Sub-City
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.address?.subCity}
                        </Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          Woreda
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.address?.woreda}
                        </Title>
                      </Stack>
                      <Stack spacing="xs">
                        <Title
                          order={6}
                          className={"text-gray-500 dark:text-white"}
                        >
                          House Number
                        </Title>
                        <Title order={5}>
                          {passenger?.data?.address?.houseNumber}
                        </Title>
                      </Stack>
                    </SimpleGrid>
                  </Group>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end items-center">
              <Group position="right" mt="xl">
                {editMode === "detail" && (
                  <>
                    {(params?.type === "archived" &&
                      archivedPassenger?.data?.deletedAt) ||
                    (params?.type === "active" &&
                      passenger?.data?.deletedAt) ? (
                      <>
                        {/* <Confirmation
                      type={"danger"}
                      message={
                        "Are you sure you want to delete it permanently?"
                      }
                      onYes={() => deletePassenger(`${params?.id}`)}
                      header={`Permanent Delete confirmation `}
                    >
                      <Button
                        variant="filled"
                        loading={deleteCall.isLoading}
                        className={`bg-danger text-white flex space-x-2 items-center`}
                        type="button"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                      >
                        <IconTrash size={20} strokeWidth={1.5} />
                        <span>Delete</span>{" "}
                      </Button>
                    </Confirmation>
                    <Confirmation
                      type={"notify"}
                      message={`Are you sure you want to restore it`}
                      onYes={() => restorePassenger(`${params?.id}`)}
                      header={`Restore confirmation `}
                    >
                      <Button
                        variant="filled"
                        loading={restoreCall.isLoading}
                        className={`bg-green-500 text-white flex space-x-2 items-center`}
                        type="button"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                      >
                        <IconArrowBackUp size={20} strokeWidth={1.5} />
                        <span>Restore</span>
                      </Button>
                    </Confirmation> */}
                      </>
                    ) : (
                      <>
                        {/* <Confirmation
                      type={"notify"}
                      message={
                        "Are you sure you want to archive this passenger?"
                      }
                      header={"Archive Confirmation"}
                      result={"single"}
                      resultRequired={true}
                      selectorLabel={"Please select a reason"}
                      labelDescription={"Why are you archiving this passenger?"}
                      options={Constants.User_Archive_Reasons.map((reason) => {
                        return { label: reason, value: reason };
                      })}
                      customInput={true}
                      yesText={"Archive"}
                      onYes={(data: string) => {
                        archivePassenger({
                          id: `${params.id}`,
                          reason: data,
                        })
                      }}
                    >
                      <Button
                        variant="filled"
                        loading={archiveResponse.isLoading}
                        className="bg-danger text-white"
                        type="button"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                      >
                        <span>
                          <IconArchive size={22} strokeWidth={1.5} />
                        </span>
                        <span>Archive</span>
                      </Button>
                    </Confirmation> */}
                        <Confirmation
                          type={"notify"}
                          message={
                            "Are you sure you want to " +
                            (passenger.data?.enabled
                              ? "deactivate"
                              : "activate") +
                            " it?"
                          }
                          onYes={() => activatePassenger(`${params.id}`)}
                          header={
                            (passenger.data?.enabled
                              ? "Deactivate"
                              : "Activate") + " Confirmation"
                          }
                        >
                          <Button
                            variant="filled"
                            loading={activateResponse.isLoading}
                            className={`${
                              passenger.data?.enabled
                                ? " bg-danger"
                                : "bg-green-500"
                            } text-white`}
                            type="button"
                            classNames={{ label: "flex space-x-1" }}
                            size={"xs"}
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current h-6"
                                viewBox="0 0 48 48"
                              >
                                <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                              </svg>
                            </span>
                            {passenger.data?.enabled ? (
                              <span>Deactivate</span>
                            ) : (
                              <span>Activate</span>
                            )}
                          </Button>
                        </Confirmation>
                      </>
                    )}
                  </>
                )}
              </Group>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="booking">
            <PassengerBookingHistory />
          </Tabs.Panel>
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from '../shared/auth/auth-slice/auth-slice';
import themeReducer from '../shared/store/slice/theme-slice';
import { appApi } from './app.api';

export const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,themeReducer,authReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

import { Vehicle } from "./vehicle.model";

export interface Provider {
  id?: string;
  driverType: DriverType;
  driverFor?: DriverFor;
  name: string;
  email: string;
  phoneNumber: string;
  gender: string;

  archiveReason?: string;
  profileImage?: {
    filename: string;
    path: string;
    originalname: string;
    mimetype: string;
    size: 0;
  };
  emergencyContact: { phoneNumber: string; name: string };
  address?: {
    country: string;
    city: string;
    subCity: string;
    woreda: string;
    houseNumber: string;
  };
  specificLocation: string;
  isActive?: boolean;
  vehicleId: string;

  datePreferences?: string[];
  preferences?: {
    id: string;
    routeId: string;
    routeName: string;
    driverId: string;
    createdAt: Date;
  }[];
  vehicle?: Vehicle;
  enabled?: boolean;
  passport?: {
    filename: string;
    path: string;
    originalname: string;
    mimetype: string;
    size: 0;
  };
  license?: {
    filename: string;
    path: string;
    originalname: string;
    mimetype: string;
    size: 0;
  };
  licenseDueDate?: string;

  splitType?: string;
  splitAmount?: number;
  fcmId?: string;
  averageRate?: {
    rate: 0;
    totalReviews: 0;
  };
  lat?: 0;
  lng?: 0;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  bankAccounts?: Bank[];
}
export interface Bank {
  id?: string;
  driverId: string;

  accountNumber: string;
  subAccountId?: string;
  archiveReason?: string;
  bankName: string;
  bankCode?: string;
  isPreferred: boolean;
  isActive: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  deletedBy?: string;
}

export enum DriverType {
  Both = "Both",
  OneTime = "OneTime",
  Monthly = "Monthly",
}
export enum DriverFor {
  Both = "Both",
  KabbaKids = "Kabba_Kids",
  KabbaMain = "Kabba_Main",
}

import { Card, Divider, Group, Stack, Text } from "@mantine/core";
import {
  IconCar,
  IconChecks,
  IconCurrencyDollar,
  IconDeviceMobile,
  IconRoad,
  IconUser,
  IconUserOff,
  IconUsers,
} from "@tabler/icons";
import { useEffect } from "react";
import { CollectionQuery } from "../../../models/collection.model";
import {
  useLazyGetBookingsQuery,
  useLazyGetOwnersQuery,
  useLazyGetPassengersQuery,
  useLazyGetAllProvidersQuery,
  useLazyGetTotalDepositQuery,
  useLazyGetTripsQuery,
  useLazyGetVehiclesQuery,
} from "../store/dashboard.query";
import { ReportCard, formatCurrency } from "./ReportCard";

export function Summary() {
  const collection: CollectionQuery = {
    skip: 0,
    top: 1,
  };

  const [getPassengersAll, passengersRegisteredAll] =
    useLazyGetPassengersQuery();
  const [getBookingsAll, bookingsRegisteredAll] = useLazyGetBookingsQuery();
  const [getOwnersAll, ownersRegisteredAll] = useLazyGetOwnersQuery();
  const [getVehiclesAll, vehiclesRegisteredAll] = useLazyGetVehiclesQuery();
  const [getProvidersAll, providersRegisteredAll] =
    useLazyGetAllProvidersQuery();

  const [getTotalTripsAll, tripsAll] = useLazyGetTripsQuery();
  const [getCompletedTripsAll, completedTripsAll] = useLazyGetTripsQuery();

  const [getTotalDeposit, totalDeposit] = useLazyGetTotalDepositQuery();

  function fetch() {
    getPassengersAll(collection);
    getBookingsAll(collection);
    getVehiclesAll(collection);
    getOwnersAll(collection);
    getProvidersAll(collection);

    getTotalTripsAll({
      count: true,
      filter: [
        [
          {
            field: "status",
            operator: "!=",
            value: "Cancelled",
          },
        ],
      ],
    });
    getCompletedTripsAll({
      count: true,
      filter: [
        [
          {
            field: "status",
            operator: "=",
            value: "Completed",
          },
        ],
      ],
    });

    getTotalDeposit(collection);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card shadow={"md"} p={"sm"} withBorder>
      <Card.Section p={"md"}>
        <Text fz={30} fw={500} align={"start"}>
          Summery
        </Text>
        <Divider />
      </Card.Section>
      <Stack>
        <Group position={"center"} spacing={25} key={1 + "_tab"}>
          <ReportCard
            {...{
              title: "Passengers",
              description: "Number of passengers",
              count: passengersRegisteredAll?.data?.count,
              loading: passengersRegisteredAll.isFetching,
              icon: <IconUsers size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Providers",
              description: "Number of providers",
              count: providersRegisteredAll?.data?.count,
              loading: providersRegisteredAll.isFetching,
              icon: <IconUserOff size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Vehicles",
              description: "Number of vehicles",
              count: vehiclesRegisteredAll?.data?.count,
              loading: vehiclesRegisteredAll.isFetching,
              icon: <IconCar size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Owners",
              description: "Number of started trips",
              count: ownersRegisteredAll?.data?.count,
              loading: ownersRegisteredAll.isFetching,
              icon: <IconUser size={100} />,
            }}
          />
        </Group>
        <Group position={"center"} spacing={25} key={3 + "_tab"}>
          <ReportCard
            {...{
              title: "Bookings",
              description: "Number of bookings",
              count: bookingsRegisteredAll?.data?.count,
              loading: bookingsRegisteredAll.isFetching,
              icon: <IconDeviceMobile size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Trips",
              description: "Number of trips",
              count: tripsAll?.data?.count,
              loading: tripsAll.isFetching,
              icon: <IconRoad size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Completed Trips",
              description: "Number of trips",
              count: completedTripsAll?.data?.count,
              loading: completedTripsAll.isFetching,
              icon: <IconChecks size={100} />,
            }}
          />
          <ReportCard
            {...{
              title: "Deposits",
              description: "Total money deposit",
              count: formatCurrency(totalDeposit?.data?.total ?? 0) + " ETB",
              loading: totalDeposit.isFetching,
              icon: <IconCurrencyDollar size={100} />,
            }}
          />
        </Group>
      </Stack>
    </Card>
  );
}

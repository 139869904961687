import { Button, Tooltip } from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Booking } from "../../../models/booking.model";
import { CollectionQuery, Order } from "../../../models/collection.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  ChapaPaymentStatus,
  NewBookingStatus,
} from "../../../shared/utility/enum/other.enum";
import { pickupTimeType } from "../../../shared/utility/enum/pickupTypeTimes.enum";
import {
  useLazyGetBookingListsQuery,
  useUpdatePaymentStatusMutation,
} from "../store/booking.query";

export default function BookingPage() {
  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: [
      "assignment",
      "vehicle",
      "vehicle.category",
      "driver",
      "passenger",
      "route",
    ],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [getBookings, bookings] = useLazyGetBookingListsQuery();
  const [updatPaymentStatus, paymentStatusResponse] =
    useUpdatePaymentStatusMutation();
  useEffect(() => {
    getBookings(collection);
  }, [collection]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
    if (location.pathname === "/booking/archived") {
      setViewMode("detail");
    }
  }, [setViewMode, params?.id, location]);

  const data: Booking[] | undefined = bookings.data?.data;
  const config: EntityConfig<Booking> = {
    primaryColumn: {
      key: "passengerName",
      name: "Passenger Name",
      render: (book) => {
        return (
          <span
            className={book?.deletedAt ? "text-danger" : ""}
          >{`${book?.passenger?.name} - ${book?.passenger?.phoneNumber}`}</span>
        );
      },
    },
    rootUrl: "/bookings",
    identity: "id",
    name: "name",
    visibleColumn: [
      {
        key: "passengerName",
        name: "Passenger",
        render: (book) => {
          return (
            <span
              className={book?.deletedAt ? "text-danger" : ""}
            >{`${book?.passenger?.name} - ${book?.passenger?.phoneNumber}`}</span>
          );
        },
      },
      {
        key: "routeName",
        name: "Route",
        render: (book) => {
          return (
            <span
              className={book?.deletedAt ? "text-danger" : ""}
            >{`${book?.route?.name}`}</span>
          );
        },
      },
      { key: "pickupName", name: "Pickup Name" },
      { key: "destinationName", name: "Destination Name" },
      {
        key: "vehiclePlateNumber",
        name: "Vehicle",
        render: (value) =>
          `${value?.vehicle?.model} - (${value.vehiclePlateNumber})`,
      },
      { key: ["vehicle", "category", "name"], name: "category" },
      {
        key: ["driver", "name"],
        name: "Driver",
        render: (value) =>
          `${value?.driver?.name} - ${value?.driver?.phoneNumber}`,
      },
      {
        key: "shift",
        name: "Shift",
        render: (value: Booking) =>
          value?.shift
            ? value?.shift?.charAt(0).toUpperCase() + value.shift.slice(1)
            : "",
      },
      {
        key: "morningPickupTime",
        name: "Morning Pickup Time ",
        hideSort: true,
        render: (value) => value?.morningPickupTime || "---",
      },
      {
        key: "afternoonPickupTime",
        name: "Afternoon Pickup Time ",
        hideSort: true,
        render: (value) => value?.afternoonPickupTime || "---",
      },
      { key: "createdAt", name: "Booked At", isDate: true },

      // { key: "walletType", name: "Wallet Type", hideSort: true },
      {
        key: "fee",
        name: "Fee",
        render: (value) =>
          `${Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(
            value.fee
          )} ETB`,
      },

      {
        key: "paymentStatus",
        name: "Payment Status",
      },
      {
        key: "status",
        name: "Status",
        render: (value) =>
          value?.status === NewBookingStatus.CREATED ? "Active" : value?.status,
      },
      {
        key: "action",
        name: "Actions",
        render: (eachBooking) => {
          return (
            <>
              {" "}
              <div className="flex space-x-1 items-center">
                <span>
                  {eachBooking?.paymentStatus ===
                    ChapaPaymentStatus.Pending && (
                    <div className="flex space-x-1">
                      <Confirmation
                        type={"notify"}
                        header={`Approve Confirmation `}
                        message={
                          "Are you sure you want to approve this booking payment?"
                        }
                        onYes={() =>
                          updatPaymentStatus({ bookingId: `${eachBooking.id}` })
                        }
                      >
                        <Tooltip
                          label={<span className="text-sm">Approve</span>}
                        >
                          <Button
                            size="small"
                            className={
                              "text-white text-xs flex  items-center justify-center"
                            }
                            type="button"
                            loading={
                              paymentStatusResponse.isLoading &&
                              paymentStatusResponse.originalArgs?.bookingId ===
                                eachBooking.id
                            }
                          >
                            <IconCheck />
                          </Button>
                        </Tooltip>
                      </Confirmation>
                    </div>
                  )}
                </span>
              </div>
            </>
          );
        },
      },
    ],
    filter: [
      [
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Morning,
          name: "Morning",
        },
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Afternoon,
          name: "Afternoon",
        },
        {
          field: "shift",
          operator: "=",
          value: pickupTimeType.Both,
          name: "Both",
        },
      ],
      [
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.CREATED,
          name: "Active",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.BOOKED,
          name: "Booked",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.CANCELLED,
          name: "Cancelled",
        },
        {
          field: "status",
          operator: "=",
          value: NewBookingStatus.EXPIRED,
          name: "Expired",
        },
      ],
      [
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Pending,
          name: "Pending",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Success,
          name: "Success",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Cancelled,
          name: "Cancelled",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Error,
          name: "Error",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Failed,
          name: "Failed",
        },
        {
          field: "paymentStatus",
          operator: "=",
          value: ChapaPaymentStatus.Timeout,
          name: "Timeout",
        },
      ],
    ],
  };

  return (
    <div className="h-full p-4">
      <EntityList
        viewMode={viewMode}
        showNewButton={false}
        title={"Bookings"}
        total={bookings?.data?.count}
        itemsLoading={bookings.isLoading || bookings?.isFetching}
        config={config}
        items={data}
        initialPage={1}
        defaultPageSize={10}
        pageSize={[10, 15, 20]}
        collectionQuery={collection}
        onPaginationChange={(skip: number, top: number) => {
          setCollection({ ...collection, skip: skip, top: top });
        }}
        onSearch={(data: any) => {
          setCollection({
            ...collection,
            filter: [
              [
                { field: "passenger.name", operator: "like", value: data },
                {
                  field: "passenger.phoneNumber",
                  operator: "like",
                  value: data,
                },
                { field: "route.name", operator: "like", value: data },
              ],
            ],
          });
        }}
        onFilterChange={(data: any) => {
          if (collection?.filter || data.length > 0) {
            setCollection({ ...collection, filter: data });
          }
        }}
        onOrder={(data: Order) =>
          setCollection({ ...collection, orderBy: [data] })
        }
      />
    </div>
  );
}
